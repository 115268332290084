/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NotificationTemplateCreateRequest,
  NotificationTemplateResponse,
  NotificationTemplateUpdateRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class NotificationTemplate<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags notification template
   * @name FindById
   * @summary Find an email template
   * @request GET:/api/v1/admin/notification-template/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<NotificationTemplateResponse, any>({
      path: `/api/v1/admin/notification-template/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags notification template
   * @name Update
   * @summary Update an email template
   * @request PUT:/api/v1/admin/notification-template/{id}
   */
  update = (id: number, data: NotificationTemplateUpdateRequest, params: RequestParams = {}) =>
    this.request<NotificationTemplateResponse, any>({
      path: `/api/v1/admin/notification-template/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags notification template
   * @name Delete
   * @summary Delete an email template
   * @request DELETE:/api/v1/admin/notification-template/{id}
   */
  delete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/admin/notification-template/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags notification template
   * @name FindAll
   * @summary Find all email templates
   * @request GET:/api/v1/admin/notification-template
   */
  findAll = (params: RequestParams = {}) =>
    this.request<NotificationTemplateResponse[], any>({
      path: `/api/v1/admin/notification-template`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags notification template
   * @name Create
   * @summary Create an email template
   * @request POST:/api/v1/admin/notification-template
   */
  create = (data: NotificationTemplateCreateRequest, params: RequestParams = {}) =>
    this.request<NotificationTemplateResponse, any>({
      path: `/api/v1/admin/notification-template`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags notification template
   * @name FindAllNotificationTemplateTypes
   * @summary Find all notification template types
   * @request GET:/api/v1/admin/notification-template/types
   */
  findAllNotificationTemplateTypes = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/v1/admin/notification-template/types`,
      method: "GET",
      ...params,
    });
}
