import { Box, Typography } from "@mui/material"
import CheckBlueIcon from "../_assets/checkBlue.svg"

interface Props {
  title: string;
  status: string;
  // Status: "done" | "active" | "inactive";
  index: number;
}

const stepper = {
  background: "inherit",
  border: "1px solid #B1B3B7",
  width: "36px",
  height: "36px",
  flexShrink: 0,
  borderRadius: "36px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}

const inactiveText = {
  opacity: "0.2",
}

export default function LengvaModalStepper({ title, status, index }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        opacity: status === "inactive" ? "0.2" : "1",
      }}
    >
      {status === "inactive" && (
        <Box
          sx={{
            ...stepper,
          }}
        >
          <Typography
            variant='h5'
          >
            {index + 1}
          </Typography>
        </Box>
      )}
      {status === "done" && (
        <Box
          sx={{
            ...stepper,
            borderColor: "#3872E1",
          }}
        >
          <img src={CheckBlueIcon as any} alt=''/>
        </Box>
      )}
      {status === "active" && (
        <Box
          sx={{
            background: "#3872E1",
            border: "1px solid #3872E1",
            width: "36px",
            height: "36px",
            flexShrink: 0,
            borderRadius: "36px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant='h5'
            sx={{
              color: "#FFFFFF !important",
            }}
          >
            {index + 1}
          </Typography>
        </Box>
      )}

      <Typography>{title}</Typography>
    </Box>
  )
}
