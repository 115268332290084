import { Account } from "_api/Account"
import { Appointments } from "_api/Appointments"
import { Auth } from "_api/Auth"
import { AvailableTimes } from "_api/AvailableTimes"
import { Change } from "_api/Change"
import { Dayoff } from "_api/Dayoff"
import { Locations } from "_api/Locations"
import { Montonio } from "_api/Montonio"
import { NotificationTemplate } from "_api/NotificationTemplate"
import { Notifications } from "_api/Notifications"
import { Payment } from "_api/Payment"
import { Paypal } from "_api/Paypal"
import { Schedules } from "_api/Schedules"
import { ServiceDescriptions } from "_api/ServiceDescriptions"
import { Services } from "_api/Services"
import { getBaseUrl } from "_utils"
import { useTranslation } from "react-i18next"
import { toast } from 'react-toastify'

const notifyError = (message: string) => toast.error(message, {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
})

export const createApi = (type: string): any => {
  // eslint-disable-next-line
  const { t } = useTranslation('translation', { keyPrefix: "ApiError" })
  let returnValue
  switch (type) {
    case "appointment": {
      returnValue = new Appointments({ baseURL: getBaseUrl() })
      break
    }

    case "notification": {
      returnValue = new NotificationTemplate({ baseURL: getBaseUrl() })
      break
    }

    case "payment": {
      returnValue = new Payment({ baseURL: getBaseUrl() })
      break
    }

    case "account": {
      returnValue = new Account({ baseURL: getBaseUrl() })
      break
    }

    case "service": {
      returnValue = new Services({ baseURL: getBaseUrl() })
      break
    }

    case "auth": {
      returnValue = new Auth({ baseURL: getBaseUrl() })
      break
    }

    case "serviceDescription": {
      returnValue = new ServiceDescriptions({ baseURL: getBaseUrl() })
      break
    }

    case "dayOff": {
      returnValue = new Dayoff({ baseURL: getBaseUrl() })
      break
    }

    case "location": {
      returnValue = new Locations({ baseURL: getBaseUrl() })
      break
    }

    case "schedule": {
      returnValue = new Schedules({ baseURL: getBaseUrl() })
      break
    }

    case "montonio": {
      returnValue = new Montonio({ baseURL: getBaseUrl() })
      break
    }

    case "availableTime": {
      returnValue = new AvailableTimes({ baseURL: getBaseUrl() })
      break
    }

    case "paypal": {
      returnValue = new Paypal({ baseURL: getBaseUrl() })
      break
    }

    case "change": {
      returnValue = new Change({ baseURL: getBaseUrl() })
      break
    }

    default: {
      console.error("could not find api with type:", type)
      return
    }
  }

  returnValue.instance.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      notifyError(t(error?.response?.data || error?.message || error?.error?.message))
      throw new Error(error?.response?.data ?? error?.message ?? error?.error?.message)
    },
  )

  return returnValue
}
