import { Button, type SxProps, Typography } from "@mui/material"

interface Props {
  title?: string;
  color?:
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | "mindPrimary"
  | "mindSecondary";
  icon?: any;
  endIcon?: any;
  onClick?: Function;
  sx?: SxProps;
  type?: "button" | "submit" | "reset" ;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: "text" | "outlined" | "contained" | "table";
}
// TODO: remove sx from props

export default function LengvaButton({
  title,
  icon,
  endIcon,
  color = "primary",
  onClick,
  sx,
  type = "button",
  fullWidth,
  disabled,
  variant,
}: Props) {
  return (
    <Button
      type={type}
      fullWidth={fullWidth}
      disabled={disabled}
      variant={variant}
      sx={{ display: "flex", gap: (icon || endIcon) ? "" : 0, flexShrink: 0,
        ...sx,
        alignItems: "center"}}
      color={color}
      onClick={() => onClick?.()}
    >
      {icon && (
        <img src={icon} alt=''/>
      )}
      {title && (
        <Typography variant='button'>{title}</Typography>
      )}
      {endIcon && (
        <img src={endIcon} alt=''/>
      )}
    </Button>
  )
}
