import { Avatar, Box, Checkbox, FormControl, RadioGroup, Typography, styled } from "@mui/material"
import { useField, useFormikContext } from "formik"
import { useState } from "react"

interface Props {
  label: string;
  type: "text" | "avatar" | "status";
  options: LengvaFilterChipOption[];
  multiple?: boolean;
}

export interface LengvaFilterChipOption {
  title: string;
  value: string;
  icon?: string;
  status?: "success" | "warning" | "error";
}

const LengvaFilterChipGroup = ({type, options, label, multiple}: Props) => {
  const [field, meta] = useField(label)
  const formikContext = useFormikContext()
  const [showAll, setShowAll] = useState<boolean>(false)

  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  const handleSelect = (value: string) => {
    if (!multiple) {
      if (field.value === value) {
        formikContext.setFieldValue(label, "")
        return
      }

      formikContext.setFieldValue(label, value)
      console.log("value", value)
      return
    }

    // TODO: handle multiple select
    formikContext.setFieldValue(label, value)
  }

  // TODO: how to show the + more button?
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: '0.75rem',
        flexWrap: 'wrap',
      }}
    >
      {type === "text" && options.map((option, index) => (
        <FilterChipBox
          key={option.value}
          isSelected={field.value === option.value}
          type={type}
          onClick={() => handleSelect(option.value)}
        >
          <Typography variant='body2' color={field.value === option.value ? '#FFFFFF' : 'text.secondary'}>
            {option.title}
          </Typography>
        </FilterChipBox>
      ))}
      {type === "avatar" && options.map((option, index) => (
        <FilterChipBox
          key={option.value}
          isSelected={field.value === option.value}
          type={type}
          onClick={() => handleSelect(option.value)}
        >
          <Avatar src={option.icon} sx={{width: "36px", height: "36px"}}/>
          <Typography variant='body2' color={field.value === option.value ? '#FFFFFF' : 'text.secondary'}>
            {option.title}
          </Typography>
        </FilterChipBox>
      ))}
      {type === "status" && options.map((option, index) => (
        <FilterChipBox
          key={option.value}
          isSelected={field.value === option.value}
          type={type}
          onClick={() => handleSelect(option.value)}
        >
          <Checkbox
            size='small'
            sx={{
              padding: "0px",
            }}
            checked={field.value === option.value}

          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                borderRadius: "6px",
                padding: "4px 8px",
                boxSizing: "border-box",
                backgroundColor:
                (option.status === "success" && "#D1EFDB")
                || (option.status === "warning" && "#FCEFCE")
                || (option.status === "error" && "#FBCDCD")
                || "inherit",
              }}
            >
              {option.icon && (
                <img
                  style={{
                    width: "20px",
                    height: "20px",

                  }}
                  src={option.icon} alt=''/>
              )}
              <Typography
                variant='body'
                color='text.secondary'
              >
                {option.title}
              </Typography>
            </Box>
          </Box>
        </FilterChipBox>
      ))}
    </Box>
  )
}

const FilterChipBox = styled(Box)(({type, isSelected}: {type: "text" | "avatar" | "status"; isSelected: boolean}) => {
  const padding = type === "text" ? "8px 16px" : (type === "avatar" ? "4px 12px 4px 4px" : "6px 20px")
  const gap = type === "avatar" ? "8px" : "12px"
  const borderRadius = type === "avatar" ? "32px" : "24px"
  const backgroundColor = isSelected && type !== "status" ? "#3872E1" : "#F4F4F5"
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    cursor: "pointer",
    padding,
    gap,
    borderRadius,
    backgroundColor,
  }
})

export const LengvaFilterChipMore = ({number}: {number: number}) => {
  console.log("LengvaFilterChipMore")

  return (
    <div>
      LengvaFilterChipMore
    </div>
  )
}

export default LengvaFilterChipGroup
