import { CloseRounded } from "@mui/icons-material"
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material"

interface LengvaBannerProps {
  title: string;
  message?: string;
  severity: "error" | "warning" | "info" | "success";
  handleClose: () => void;
}

const LengvaBanner = ({title, message, severity, handleClose}: LengvaBannerProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: getBackgroundColor(severity),
        padding: isMobile ? "0.5rem" : "1rem",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography variant='h5' color={getTextColor(severity)}>
          {title}
        </Typography>
        {message && (
          <Typography variant='h6' color={getTextColor(severity)}>
            {message}
          </Typography>
        )}
      </Box>
      <IconButton
        sx={{
          color: getTextColor(severity),
        }}
        onClick={handleClose}
      >
        <CloseRounded/>
      </IconButton>
    </Box>
  )
}

const getBackgroundColor = (severity: "error" | "warning" | "info" | "success") => {
  switch (severity) {
    case "error": {
      return "#F04F4F"
    }

    case "warning": {
      return "#F7D884"
    }

    case "info": {
      return "#9BB8F0"
    }

    case "success": {
      return "#5FB97E"
    }

    default: {
      return "#9BB8F0"
    }
  }
}

const getTextColor = (severity: "error" | "warning" | "info" | "success") => {
  switch (severity) {
    case "error": {
      return "#FFFFFF"
    }

    case "warning": {
      return "#202632"
    }

    case "info": {
      return "#202632"
    }

    case "success": {
      return "#202632"
    }

    default: {
      return "#202632"
    }
  }
}

export default LengvaBanner

