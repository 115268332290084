import { Auth } from '_api/Auth'
import { type MeResponse, type RoleResponse } from '_api/data-contracts'
import { getBaseUrl } from '_utils'
import { RoleNames } from '_utils/RequireAuth'
import { create } from 'zustand'

interface AuthStore {
  account?: MeResponse;
  isSpecialist?: boolean;
  isAdmin?: boolean;
  isUser?: boolean;
  isManager?: boolean;
  getMe: () => Promise<MeResponse>;
  isStateReady: boolean;
}

const authApi = new Auth({ baseURL: getBaseUrl() })

const useStore = create<AuthStore>(set => ({
  async getMe() {
    set({ isStateReady: false })
    const response = await authApi.getMe()
    if (response) {
      set({
        account: response,
      })

      if (response.role) {
        if (hasRole(RoleNames.USER, response.role)) {
          set({ isUser: true })
        }

        if (hasRole(RoleNames.SPECIALIST, response.role)) {
          set({ isSpecialist: true })
        }

        if (hasRole(RoleNames.ADMIN, response.role)) {
          set({ isAdmin: true })
        }

        if (hasRole(RoleNames.MANAGER, response.role)) {
          set({ isManager: true })
        }
      }
    }

    set({ isStateReady: true })
    return response
  },
  isStateReady: false,
}))

export const hasRole = (role: string, currentRole: RoleResponse) => role === currentRole.name

export default useStore
