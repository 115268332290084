import { CloseRounded } from "@mui/icons-material"
import { Box, Dialog, Divider, IconButton, Typography, useMediaQuery } from "@mui/material"
import { type Appointments } from "_api/Appointments"
import { type AppointmentResponse } from "_api/data-contracts"
import ArrowNarrowLeftIcon from "_assets/arrow-narrow-left.svg"
import BagIcon from '_assets/bag-icon.svg'
import CalendarOutlinedIcon from '_assets/calendar-outlined.svg'
import CheckIcon from '_assets/check.svg'
import EditIcon from '_assets/edit.svg'
import ExternalLinkIcon from '_assets/external-link.svg'
import LinkIcon from '_assets/link.svg'
import PendingIcon from '_assets/pending-icon.svg'
import PersonIcon from '_assets/person-icon.svg'
import ItemDetailRow from "_components/ItemDetailRow"
import LengvaButton from "_components/LengvaButton"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import { DashboardContainer, DashboardSectionContainer, DashboardTitleContainer, getAppointmentLocationFromMeetingProvider } from "_pages/admin/appointments/AppointmentListPage"
import { createApi } from "_utils/ApiCreator"
import { locationIconMap } from "_utils/ObjectUtils"
import { type TFunction } from "i18next"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

interface Props {
  isClient?: boolean;
}

const AppointmentViewPage = ({isClient}: Props) => {
  const { id } = useParams<{ id: string }>()

  const [appointment, setAppointment] = useState<AppointmentResponse | null>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)

  const navigate = useNavigate()
  const appointmentAPI = useRef(createApi('appointment') as Appointments)
  const { t } = useTranslation('translation', { keyPrefix: "AppointmentViewPage" })
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })
  const isMobile = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    getAppointment()
  // eslint-disable-next-line
  }, [id])

  const getAppointment = async () => {
    if (!id) {
      return
    }

    setIsLoading(true)
    const response = await appointmentAPI.current.findById(Number(id))
    setAppointment(response)
    setIsLoading(false)
  }

  const handleEdit = () => {
    navigate(`/admin/appointments/${id}/edit`)
  }

  const handleOpenDialog = () => {
    setConfirmDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setConfirmDialogOpen(false)
  }

  const handleCancel = async () => {
    const response = await appointmentAPI.current.cancel(Number(id))
    handleCloseDialog()
    if (response) {
      navigate('/admin/appointments')
      toast.success(t('Appointment cancelled'))
      return
    }

    toast.error(t('Failed to cancel appointment'))
  }

  return (
    <DashboardContainer>
      {appointment && (
        <>
          <DashboardTitleContainer
            sx={{
              marginBottom: '0.5rem',
            }}
          >
            <LengvaButton
              title={t('Back')}
              color='secondary'
              icon={ArrowNarrowLeftIcon}
              onClick={() => navigate(-1)}
            />
          </DashboardTitleContainer>
          {isLoading && <LengvaLoadingSpinner/> }
          {!isLoading && (
            <DashboardSectionContainer
              sx={{
                gap: '2rem',
                padding: isMobile ? '1rem' : '2rem',
              }}
            >
              {!isClient && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {appointment.status !== "CANCELLED" && (
                      <LengvaButton
                        title={t('Cancel appointment')}
                        color='secondary'
                        onClick={handleOpenDialog}
                      />
                    )}
                    <LengvaButton
                      title={t('Edit')}
                      color='secondary'
                      icon={EditIcon}
                      onClick={handleEdit}
                    />
                  </Box>
                  <Divider
                    sx={{
                      width: 'calc(100% + 4rem)',
                      marginLeft: '-2rem',
                    }}
                  />
                </>
              )}
              <ItemDetailRow
                title={t("DateAndTime")}
                icon={CalendarOutlinedIcon}
                text={new Date(appointment.startDate).toLocaleDateString('lt-LT', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                subtext={new Date(appointment.startDate).toLocaleTimeString('lt-LT', { hour: '2-digit', minute: '2-digit' })
                + " - "
                + new Date(appointment.endDate).toLocaleTimeString('lt-LT', { hour: '2-digit', minute: '2-digit' })}
              />
              <ItemDetailRow
                title={t('Client')}
                icon={PersonIcon}
                text={`${appointment.patient?.name} ${appointment.patient?.lastName}`}
              />
              <ItemDetailRow
                title={t('Service')}
                icon={BagIcon}
                text={appointment.service ? tServices.t(`Services.${appointment.service.serviceDescription.name}`) : ""}
              />
              <ItemDetailRow
                title={t('Location')}
                text={getAppointmentLocationFromMeetingProvider(appointment.location.meetingProvider)}
                subtext={getSubtext(appointment)}
                // icon={GoogleMeetsLogo32}
                icon={locationIconMap.get(appointment.location.meetingProvider)}
                secondaryButtonText={showButton(appointment) ? t('Copy link') : undefined}
                secondaryButtonIcon={LinkIcon}
                secondaryButtonOnClick={async () => navigator.clipboard.writeText(appointment.onlineMeetingLink ?? '')}
                primaryButtonText={showButton(appointment) ? t('Open') : undefined}
                primaryButtonIcon={ExternalLinkIcon}
                primaryButtonOnClick={async () => window.open(appointment.onlineMeetingLink ?? '', '_blank')}
              />
              <ItemDetailRow
                title={t("Payment")}
                text={appointment.payments?.map(payment => payment.status).includes("PAID") ? t('Paid') : t("Pending")}
                textIcon={appointment.payments?.map(payment => payment.status).includes("PAID") ? CheckIcon : PendingIcon}
                subtext={appointment.payments?.map(payment => payment.status).includes("PAID") ? getPaymentProvider(appointment, t) : ""}
                borderColor={appointment.payments?.map(payment => payment.status).includes("PAID") ? "#E9E9EB" : "#EFB008"}
              />
            </DashboardSectionContainer>
          )}
        </>
      )}
      {!appointment && (
        <LengvaLoadingSpinner/>
      )}
      <ConfirmCancelDialog
        open={confirmDialogOpen}
        handleClose={handleCloseDialog}
        handleDeleteClick={handleCancel}
      />
    </DashboardContainer>
  )
}

const getSubtext = (appointment: AppointmentResponse) => {
  if (appointment.location.meetingProvider === 'GOOGLE_MEET' || appointment.location.meetingProvider === 'ZOOM') {
    return appointment.onlineMeetingLink ?? ''
  }

  if (appointment.location.meetingProvider === 'IN_PERSON') {
    return appointment.address ?? appointment.location.defaultAddress ?? ''
  }

  return ''
}

const showButton = (appointment: AppointmentResponse) => {
  if (appointment.location.meetingProvider === 'GOOGLE_MEET' || appointment.location.meetingProvider === 'ZOOM') {
    return getSubtext(appointment) !== ''
  }

  return false
}

const getPaymentProvider = (appointment: AppointmentResponse, t: TFunction) => {
  const payment = appointment.payments?.find(payment => payment.status === "PAID")
  if (!payment) {
    return ""
  }

  const paymentType = t("PaymentType")

  if (payment.provider === "MONTONIO") {
    if (payment.subtype === "CARD") {
      return `${paymentType}: ${t("Card")}`
    }

    if (payment.subtype === "APPLE_PAY") {
      return `${paymentType}: Apple Pay`
    }

    if (payment.subtype === "GOOGLE_PAY") {
      return `${paymentType}: Google Pay`
    }

    return `${paymentType}: ${t("Bank")}`
  }

  if (payment.provider === "PAYPAL") {
    return `${paymentType}: PayPal`
  }

  return ""
}

interface ConfirmCancelDialogProps {
  open: boolean;
  handleClose: () => void;
  handleDeleteClick: () => void;
}

export const ConfirmCancelDialog = ({open, handleClose, handleDeleteClick}: ConfirmCancelDialogProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('translation', {keyPrefix: 'AppointmentViewPage'})
  return (
    <Dialog
      open={open}
      maxWidth='md'
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '472px',
          margin: isMobile ? '16px' : 'auto',
          padding: '2rem 1.5rem 1.5rem 1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          borderRadius: '1rem',
        },
      }}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h3'>
          {t('Cancel appointment')}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseRounded/>
        </IconButton>
      </Box>
      <Typography variant='body'>
        {t('Are you sure you want to cancel this appointment?')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <LengvaButton
          title={t('No, don\'t cancel')}
          color='secondary'
          onClick={handleClose}
        />
        <LengvaButton
          title={t('Yes, cancel')}
          color='primary'
          onClick={handleDeleteClick}
        />
      </Box>
    </Dialog>
  )
}

export default AppointmentViewPage
