import { Button, styled } from "@mui/material"
import { type Auth } from "_api/Auth"
import { createApi } from "_utils/ApiCreator"
import { useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export default function Testing() {
  const location = useLocation()
  const navigate = useNavigate()

  const queryParameters = new URLSearchParams(location.search)
  const code = queryParameters.get("code")
  const authAPI = useRef(createApi('auth') as Auth)

  useEffect(() => {
    login()
  }, [])

  const login = async () => {
    if (code) {
      await authAPI.current.loginMicrosoft({code})
    }
  }

  const onLogin = () => {
    const redirectUri = "http%3A%2F%2Flocalhost%3A4000%2Ftesting"
    const clientId = "b4413f61-2fb0-414e-a325-89f9c7daae3b"
    const scopes = "offline_access%20user.read%20mail.read%20calendars.readwrite"
    const responseType = "code"
    const responseMode = "query"
    // must use href to access external url
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&response_mode=${responseMode}&scope=${scopes}`
  }

  return (
    <MicrosoftButton variant='outlined' onClick={() => onLogin()}>
      <img
        src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/320px-Microsoft_logo.svg.png'
        alt='Microsoft'
        width='24'
        height='24'
        style={{ marginRight: "8px" }}
      />
      Prisijunkite su Microsoft 365
    </MicrosoftButton>
  )
}

const MicrosoftButton = styled(Button)({
  padding: "12px 24px",
  fontSize: "1.25rem",
  textTransform: "none",
  borderRadius: 24,
  "&:hover": {
    background: "#f5f5f5",
    cursor: "pointer",
  },
})
