/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AppointmentClientResponse,
  AppointmentCreateRequest,
  AppointmentResponse,
  AppointmentUpdateRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Appointments<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags appointment-controller
   * @name FindById
   * @request GET:/api/v1/user/appointments/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<AppointmentClientResponse, any>({
      path: `/api/v1/user/appointments/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-controller
   * @name Update
   * @request PUT:/api/v1/user/appointments/{id}
   */
  update = (id: number, data: AppointmentUpdateRequest, params: RequestParams = {}) =>
    this.request<AppointmentClientResponse, any>({
      path: `/api/v1/user/appointments/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-controller
   * @name Delete
   * @request DELETE:/api/v1/user/appointments/{id}
   */
  delete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/user/appointments/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-controller
   * @name Cancel
   * @request PUT:/api/v1/user/appointments/{id}/cancel
   */
  cancel = (id: number, params: RequestParams = {}) =>
    this.request<AppointmentClientResponse, any>({
      path: `/api/v1/user/appointments/${id}/cancel`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-specialist-controller
   * @name UpdateSpecialistAppointment
   * @request PUT:/api/v1/specialist/appointments/{id}
   */
  updateSpecialistAppointment = (id: number, data: AppointmentUpdateRequest, params: RequestParams = {}) =>
    this.request<AppointmentResponse, any>({
      path: `/api/v1/specialist/appointments/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-controller
   * @name Create
   * @request POST:/api/v1/user/appointments
   */
  create = (data: AppointmentCreateRequest, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v1/user/appointments`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-controller
   * @name CreateManual
   * @request POST:/api/v1/user/appointments/manual
   */
  createManual = (data: AppointmentCreateRequest, params: RequestParams = {}) =>
    this.request<AppointmentClientResponse, any>({
      path: `/api/v1/user/appointments/manual`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-controller
   * @name CreateFree
   * @request POST:/api/v1/user/appointments/free
   */
  createFree = (data: AppointmentCreateRequest, params: RequestParams = {}) =>
    this.request<AppointmentClientResponse, any>({
      path: `/api/v1/user/appointments/free`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-specialist-controller
   * @name FindAllBySpecialistId
   * @request GET:/api/v1/specialist/appointments
   */
  findAllBySpecialistId = (params: RequestParams = {}) =>
    this.request<AppointmentResponse[], any>({
      path: `/api/v1/specialist/appointments`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-specialist-controller
   * @name CreateSpecialistAppointment
   * @request POST:/api/v1/specialist/appointments
   */
  createSpecialistAppointment = (data: AppointmentCreateRequest, params: RequestParams = {}) =>
    this.request<AppointmentResponse, any>({
      path: `/api/v1/specialist/appointments`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-controller
   * @name FindAllByPatientId
   * @request GET:/api/v1/user/appointments/patient
   */
  findAllByPatientId = (params: RequestParams = {}) =>
    this.request<AppointmentClientResponse[], any>({
      path: `/api/v1/user/appointments/patient`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-controller
   * @name FindClientAllFilteredBy
   * @request GET:/api/v1/user/appointments/filter
   */
  findClientAllFilteredBy = (
    query?: {
      /** @format int64 */
      specialistId?: number;
      /** @format int64 */
      serviceId?: number;
      /** @format int64 */
      locationId?: number;
      status?: "CREATED" | "OCCURRED" | "NOT_OCCURRED" | "CANCELLED";
      statusNotEquals?: "CREATED" | "OCCURRED" | "NOT_OCCURRED" | "CANCELLED";
      /** @format date-time */
      dateFrom?: string;
      /** @format date-time */
      dateTo?: string;
      paymentStatus?: "PENDING" | "PAID" | "VOIDED" | "PARTIALLY_REFUNDED" | "REFUNDED" | "ABANDONED" | "AUTHORIZED";
      searchString?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<AppointmentClientResponse[], any>({
      path: `/api/v1/user/appointments/filter`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-specialist-controller
   * @name FindAppointmentsToday
   * @request GET:/api/v1/specialist/appointments/today
   */
  findAppointmentsToday = (params: RequestParams = {}) =>
    this.request<AppointmentResponse[], any>({
      path: `/api/v1/specialist/appointments/today`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags appointment-specialist-controller
   * @name FindAllFilteredBy
   * @request GET:/api/v1/specialist/appointments/filter
   */
  findAllFilteredBy = (
    query?: {
      /** @format int64 */
      patientId?: number;
      /** @format int64 */
      serviceId?: number;
      /** @format int64 */
      locationId?: number;
      status?: "CREATED" | "OCCURRED" | "NOT_OCCURRED" | "CANCELLED";
      statusNotEquals?: "CREATED" | "OCCURRED" | "NOT_OCCURRED" | "CANCELLED";
      /** @format date-time */
      dateFrom?: string;
      /** @format date-time */
      dateTo?: string;
      paymentStatus?: "PENDING" | "PAID" | "VOIDED" | "PARTIALLY_REFUNDED" | "REFUNDED" | "ABANDONED" | "AUTHORIZED";
      searchString?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<AppointmentResponse[], any>({
      path: `/api/v1/specialist/appointments/filter`,
      method: "GET",
      query: query,
      ...params,
    });
}
