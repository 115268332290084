import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material"
import { type Account } from "_api/Account"
import { type ClientCreateRequest, type AppointmentResponse, type ClientResponse } from "_api/data-contracts"
import ArrowNarrowLeftIcon from '_assets/arrow-narrow-left.svg'
import PlusBlueIcon from '_assets/plusBlue.svg'
import LengvaButton from "_components/LengvaButton"
import LengvaDatePicker from "_components/LengvaDatePicker"
import LengvaInput from "_components/LengvaInput"
import LengvaSelect from "_components/LengvaSelect"
import LengvaTable, { type ColumnConfig } from "_components/LengvaTable"
import LengvaTableBadge from "_components/LengvaTableBadge"
import { DashboardContainer, DashboardSectionContainer, DashboardTitleContainer } from "_pages/admin/appointments/AppointmentListPage"
import { createApi } from "_utils/ApiCreator"
import { convertTime } from "_utils/ObjectUtils"
import dayjs from "dayjs"
import { Formik } from "formik"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from 'yup'
import RedCrossIcon from '_assets/red-cross.svg'
import ItemDetailRow from "_components/ItemDetailRow"
import CheckIcon from '_assets/check.svg'
import { toast } from "react-toastify"
import { type Auth } from "_api/Auth"
import { type TFunction } from "i18next"

const ClientViewPage = () => {
  const { id } = useParams<{ id: string }>()

  const [client, setClient] = useState<ClientResponse | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const accountAPI = useRef(createApi('account') as Account)
  const authAPI = useRef(createApi('auth') as Auth)
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage' })
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })

  const tabs = [
    t("General Information"),
    t("Appointments"),
  ]
  const [currentTab, setCurrentTab] = useState<string>(t('General Information'))

  useEffect(() => {
    getClient()
  // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    setCurrentTab(tabs[(currentTab === "General Information" || currentTab === "Bendra informacija") ? 0 : 1])
  }, [t])

  const getClient = async () => {
    if (!id) {
      return
    }

    setIsLoading(true)
    const client = await accountAPI.current.findClientById(Number(id))
    setClient(client)
    setIsLoading(false)
  }

  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 600px)')

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab)
  }

  const handleSubmit = async (values: ClientViewFormValues) => {
    const name = values.name.split(' ')[0]
    const lastName = name.at(-1)
    if (!lastName) {
      return
    }

    const clientUpdateInfoRequest: ClientCreateRequest = {
      name,
      lastName,
      email: values.email,
      phoneNumber: values.phonePrefix + values.phoneNumber,
    }

    await accountAPI.current.updateClientInfo(Number(id), clientUpdateInfoRequest)
    toast.success(t('Client info updated successfully'))
    getClient()
  }

  const handleSendConfirmationEmail = async () => {
    if (!id) {
      return
    }

    await authAPI.current.sendEmailConfirmationToClient(Number(id))
    toast.success(t('Confirmation email sent'))
  }

  const pastAppointments = mapAppointmentsToClientAppointments(client?.appointments ?? [], true, tServices.t)
  const futureAppointments = mapAppointmentsToClientAppointments(client?.appointments ?? [], false, tServices.t)

  return (
    <Formik
      enableReinitialize
      initialValues={client ? getInitialValues(client) : initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <DashboardContainer>
          <DashboardTitleContainer
            sx={{
              marginBottom: '0.5rem',
            }}
          >
            <LengvaButton
              title={t('Back')}
              color='secondary'
              icon={ArrowNarrowLeftIcon}
              onClick={() => navigate(-1)}
            />
          </DashboardTitleContainer>
          <DashboardSectionContainer
            sx={{
              gap: 0,
              padding: 0,
              borderRadius: '12px 12px 0 0',
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              padding='1rem 2rem'
            >
              <Box
                display='flex'
                gap='1.5rem'
              >
                <Button
                  color='inherit'
                  sx={{
                    borderRadius: '12px 12px 0 0',
                    marginBottom: '-1px',
                    borderBottom: currentTab === tabs[0] ? '2px solid #3872E1' : '2px solid transparent',
                    transition: 'ease-in-out 0.3s',
                  }}
                  onClick={() => handleTabChange(tabs[0])}
                >
                  <Typography
                    variant='button'
                    sx={{
                      color: currentTab === tabs[0] ? 'primary.main' : 'text.light',
                    }}
                  >
                    {tabs[0]}
                  </Typography>
                </Button>
                <Button
                  color='inherit'
                  sx={{
                    borderRadius: '12px 12px 0 0',
                    marginBottom: '-1px',
                    borderBottom: currentTab === tabs[1] ? '2px solid #3872E1' : '2px solid transparent',
                    transition: 'ease-in-out 0.3s',
                  }}
                  onClick={() => handleTabChange(tabs[1])}
                >
                  <Typography
                    variant='button'
                    sx={{
                      color: currentTab === tabs[1] ? 'primary.main' : 'text.light',
                    }}
                  >
                    {tabs[1]}
                  </Typography>
                  <LengvaTableBadge
                    textColor={currentTab === tabs[1] ? 'primary.main' : 'text.light'}
                    value={client?.totalAppointments ?? 0}
                  />
                </Button>
              </Box>
              <Divider
                sx={{
                  width: "calc(100% + 4rem)",
                  marginLeft: "-2rem",
                }}
              />
            </Box>
            {currentTab === tabs[0] && (
              <DashboardSectionContainer
                sx={{
                  padding: isMobile ? '1rem' : '2rem',
                  gap: isMobile ? '1rem' : '2.5rem',
                }}
              >
                <LengvaInput
                  title={t('Name')}
                  label='name'
                />
                <Box
                  display='flex'
                  gap='1rem'
                  alignItems='flex-end'
                  flexDirection={isMobile ? 'column' : 'row'}
                >
                  <LengvaInput
                    containerStyle={{
                      flex: 1,
                    }}
                    title={t('Email')}
                    label='email'
                  />
                  <Box
                    display='flex'
                    gap='0.5rem'
                    alignItems='flex-end'
                    flex='1'
                    width='100%'
                  >
                    <LengvaSelect
                      title={t('Phone Number')}
                      label='phonePrefix'
                      containerStyle={{
                        flex: 1,
                        maxWidth: '138px',
                      }}
                      optionsMain={[
                        {
                          label: '+371',
                          value: '+371',
                        },
                        {
                          label: '+370',
                          value: '+370',
                        },
                      ]}
                    />
                    <LengvaInput
                      label='phoneNumber'
                      containerStyle={{
                        flex: 2,
                      }}
                    />
                  </Box>
                </Box>
                <LengvaDatePicker
                  title={t('Birth Date')}
                  label='birthDate'
                  onChange={async newDate => formik.setFieldValue('birthDate', dayjs(newDate).toDate())}
                />
                <LengvaInput
                  multiline
                  disabled
                  title={t('Notes')}
                  label='notes'
                  placeholder='Add notes'
                  minRows={8}
                  maxRows={12}
                />
                {client && !client.isEmailConfirmed && (
                  <ItemDetailRow
                    title=''
                    text={t('Email not confirmed')}
                    textIcon={client?.isEmailConfirmed ? CheckIcon : RedCrossIcon}
                    // subtext={appointment.payments?.map(payment => payment.status).includes("PAID") ? 'Mokėjimo būdas: Swedbank' : ""}
                    borderColor={client?.isEmailConfirmed ? "#E9E9EB" : "error.main"}
                    secondaryButtonText={t('Resend confirmation email')}
                    secondaryButtonOnClick={async () => handleSendConfirmationEmail()}
                  />
                )}
              </DashboardSectionContainer>
            )}
            {currentTab === tabs[1] && (
              <>
                <DashboardSectionContainer
                  sx={{
                    padding: isMobile ? '1rem' : '2rem',
                  }}
                >
                  <DashboardTitleContainer>
                    <Typography variant='h4' fontSize={18}>
                      Ateinantys
                    </Typography>
                    <LengvaButton
                      title={t('New')}
                      color='secondary'
                      icon={PlusBlueIcon}
                      onClick={() => navigate(`/admin/appointments/create`)}
                    />
                  </DashboardTitleContainer>
                  <Box
                    sx={{
                      border: '1px solid #E9E9EB',
                      borderRadius: '12px',
                    }}
                  >
                    {futureAppointments.length > 0 && (
                      <LengvaTable
                        isViewOnly
                        isLoading={isLoading}
                        showFilters={false}
                        showColumnConfig={false}
                        showPagination={false}
                        title='Rezervacijos'
                        columns={createClientAppointmentConfig()}
                        viewMore={(appointment: any) => navigate(`/admin/appointments/${appointment.id}`)}
                        data={futureAppointments}
                        filter={[]}
                        rowType='checkbox'
                        allow={["add", "edit", "delete"]} itemCreated={async (item: any) => {
                          // console.log(item)
                        }} itemUpdated={async (item: any) => {
                          // console.log(item)
                        }}
                        itemDeleted={async (item: any) => {
                          // console.log(item)
                        }}/>
                    )}
                    {futureAppointments.length === 0 && (
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='100%'
                        padding='2rem'
                      >
                        <Typography variant='h4' fontSize={18}>
                          {t('No Reservations')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </DashboardSectionContainer>
                <DashboardSectionContainer
                  sx={{
                    padding: '2rem',
                    paddingTop: '1rem',
                  }}
                >
                  <DashboardTitleContainer>
                    <Typography variant='h4' fontSize={18}>
                      Praėję
                    </Typography>
                  </DashboardTitleContainer>
                  <Box
                    sx={{
                      border: '1px solid #E9E9EB',
                      borderRadius: '12px',
                    }}
                  >
                    { pastAppointments.length > 0 && (
                      <LengvaTable
                        isViewOnly
                        isLoading={isLoading}
                        showFilters={false}
                        showColumnConfig={false}
                        showPagination={false}
                        title='Rezervacijos'
                        columns={createClientAppointmentConfig()}
                        viewMore={(appointment: any) => navigate(`/admin/appointments/${appointment.id}`)}
                        data={pastAppointments}
                        filter={[]}
                        rowType='checkbox'
                        allow={["add", "edit", "delete"]} itemCreated={async (item: any) => {
                          // console.log(item)
                        }} itemUpdated={async (item: any) => {
                          // console.log(item)
                        }}
                        itemDeleted={async (item: any) => {
                          // console.log(item)
                        }}/>
                    )}
                    { pastAppointments.length === 0 && (
                      <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        height='100%'
                        padding='2rem'
                      >
                        <Typography variant='h4' fontSize={18}>
                          {t('No Reservations')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </DashboardSectionContainer>
              </>
            )}
            <Divider
              sx={{
                width: "100%",
              }}
            />
          </DashboardSectionContainer>
          <Box
            display='flex'
            justifyContent='flex-end'
            borderRadius='0 0 12px 12px'
            padding='1.5rem'
            marginTop='-1rem'
            sx={{
              backgroundColor: '#FFFFFF',
            }}
          >
            <LengvaButton
              title={t('Save')}
              color='primary'
              onClick={() => formik.handleSubmit()}
            />
          </Box>
        </DashboardContainer>
      )}
    </Formik>
  )
}

interface ClientViewFormValues {
  name: string;
  email: string;
  phonePrefix: string;
  phoneNumber: string;
  birthDate: Date | null;
  notes: string;
}

const initialValues: ClientViewFormValues = {
  name: "",
  email: "",
  phonePrefix: "",
  phoneNumber: "",
  birthDate: null,
  notes: "",
}

const getInitialValues = (client: ClientResponse): ClientViewFormValues => ({
  name: `${client.name} ${client.lastName}`,
  email: client.email,
  phonePrefix: client.phoneNumber?.slice(0, 4) ?? "",
  phoneNumber: client.phoneNumber?.slice(4) ?? "",
  birthDate: null,
  notes: client.notes ?? "",
})

const validationSchema = yup.object({
  name: yup.string().required('Privalomas laukas'),
  email: yup.string().email('Neteisingas el. pašto formatas').required('Privalomas laukas'),
  phoneNumber: yup.string().required('Privalomas laukas'),
  phonePrefix: yup.string().required('Privalomas laukas'),
  notes: yup.string(),
})

const createClientAppointmentConfig = () => {
  const config: ColumnConfig[] = [
    {
      property: "date",
      property2: "startTime",
      label: "Date",
      type: "string",
      hideOnCreate: true,
      orderId: 1,
    },
    {
      property: "service",
      property2: "serviceLength",
      label: "Service",
      type: "string",
      orderId: 2,
    },
    {
      property: "paymentStatus",
      label: "Payment Status",
      type: "label",
      hideOnCreate: true,
      orderId: 5,
    },
  ]

  return config
}

const mapAppointmentsToClientAppointments = (appointments: AppointmentResponse[], isPastTable: boolean, tServices: TFunction) => {
  const filteredAppointments = isPastTable ? appointments.filter(appointment => new Date(appointment.startDate) < new Date()) : appointments.filter(appointment => new Date(appointment.startDate) > new Date())

  const clientAppointments = filteredAppointments
    .map(appointment => ({
      id: appointment.id,
      date: new Date(appointment.startDate).toLocaleDateString('lt-LT', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      startTime: new Date(appointment.startDate).toLocaleTimeString('lt-LT', { hour: '2-digit', minute: '2-digit' }) + " - "
      + new Date(appointment.endDate).toLocaleTimeString('lt-LT', { hour: '2-digit', minute: '2-digit' }),
      service: tServices(`Services.${appointment.service.serviceDescription.name}`),
      serviceLength: convertTime(appointment.service.duration),
      paymentStatus: {
        label: appointment.payments?.map(payment => payment.status).includes("PAID") ? "Atliktas" : "Neatliktas",
        variant: appointment.payments?.map(payment => payment.status).includes("PAID") ? "success" : "error",
        beforeText: `${appointment.service.price}€`,
      },
    }))

  return clientAppointments
}

export default ClientViewPage
