import { ChevronLeftRounded, ChevronRightRounded, ExpandMoreRounded } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  SwipeableDrawer,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  type MenuProps,
  type SwipeableDrawerProps,
} from "@mui/material"
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { type Schedules } from "_api/Schedules"
import { type Services } from "_api/Services"
import { type ScheduleCreateRequest, type ServiceResponse } from "_api/data-contracts"
import ArrowNarrowRight from "_assets/arrow-narrow-right.svg"
import CalendarActiveIcon from "_assets/calendar-active.svg"
import CalendarIcon from "_assets/calendar-big.svg"
import ChevronLeftIcon from "_assets/chevronLeft.svg"
import CloseBlackIcon from "_assets/closeBlack.svg"
import RefreshActiveIcon from "_assets/refresh-active.svg"
import RefreshIcon from "_assets/refresh.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaIcon from "_components/LengvaIcon"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import { mapAvailableDaysToScheduleEntries } from "_pages/admin/schedule/EditScheduleDialog"
import ScheduleAvailabilityForm, { type AvailableDay, type ScheduleAvailabilityFormValues, type TimeInterval } from "_pages/admin/schedule/ScheduleAvailabilityForm"
import ScheduleDetailsForm, { LocationContainer, type ScheduleDetailsFormValues } from "_pages/admin/schedule/ScheduleDetailsForm"
import { createApi } from "_utils/ApiCreator"
import { convertTime } from "_utils/ObjectUtils"
import dayjs from "dayjs"
import { type FormikHelpers, type FormikProps } from "formik"
import i18next from "i18next"
import moment from "moment"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Calendar, Views, momentLocalizer } from "react-big-calendar"
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { useTranslation } from "react-i18next"
import '../calendar/CalendarPage.css'

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchSchedules?: () => Promise<void>;
}

export default function AddScheduleDialog({
  open,
  handleClose,
  refetchSchedules,
}: Props) {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [activeMobileStep, setActiveMobileStep] = useState<number>(0)
  const [selectedServices, setSelectedServices] = useState<number[]>([])
  const [isScheduleTypeRepeating, setIsScheduleTypeRepeating] = useState<boolean | null>(true)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [myEvents, setMyEvents] = useState<DayEvent[]>([])
  const [eventId, setEventId] = useState<number>(0)
  const [selectedDate, setSelectedDate] = useState<Date >(moment().toDate())
  const [datePickerDrawerOpen, setDatePickerDrawerOpen] = useState<boolean>(false)
  const [services, setServices] = useState<ServiceResponse[]>([])
  const [datePickerMenuAnchorElement, setDatePickerMenuAnchorElement] = useState<null | HTMLElement>(null)
  const [availabilityFormValues, setAvailabilityFormValues] = useState<ScheduleAvailabilityFormValues | undefined>()
  const [detailsFormValues, setDetailsFormValues] = useState<ScheduleDetailsFormValues | undefined>()
  const [formKey, setFormKey] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isMobile = useMediaQuery(`(max-width: 600px)`)
  const scheduleAPI = useRef(createApi('schedule') as Schedules)
  const serviceAPI = useRef(createApi('service') as Services)
  const datePickerMenuOpen = Boolean(datePickerMenuAnchorElement)
  const submitAvailabilityButtonRef = useRef<HTMLButtonElement | null>(null)
  const submitDetailsButtonRef = useRef<HTMLButtonElement | null>(null)
  const resetDetailsButtonRef = useRef<HTMLButtonElement | null>(null)
  const resetAvailabilityButtonRef = useRef<HTMLButtonElement | null>(null)
  const { t } = useTranslation('translation', {keyPrefix: 'AddScheduleDialog'})
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })

  useEffect(() => {
    if (availabilityFormValues && detailsFormValues) {
      handleScheduleSubmit()
    }
  // eslint-disable-next-line
  }, [availabilityFormValues, detailsFormValues])

  const handleAvailabilityFormSubmit = async (values: ScheduleAvailabilityFormValues, formikHelpers: FormikHelpers<ScheduleAvailabilityFormValues>) => {
    const errors = await formikHelpers.validateForm()
    if (Object.keys(errors).length > 0) {
      console.log('errors', errors)
      return
    }

    setAvailabilityFormValues(values)
    formikHelpers.resetForm()
    // resetAvailabilityButtonRef.current?.click()
    // resetDetailsButtonRef.current?.click()
  }

  const handleDetailsFormSubmit = async (values: ScheduleDetailsFormValues, formikHelpers: FormikHelpers<ScheduleDetailsFormValues>) => {
    const errors = await formikHelpers.validateForm()
    if (Object.keys(errors).length > 0) {
      return
    }

    setDetailsFormValues(values)
    setActiveStep(activeStep + 1)
  }

  const handleDetailsFormReset = (formik?: FormikProps<ScheduleDetailsFormValues>) => {
    resetDetailsButtonRef.current?.click()
    formik?.setFieldValue('locations', [])
    formik?.setFieldValue('availableDays', initialAvailableDays)
    setDetailsFormValues(undefined)
    // setFormKey(formKey + 1)
  }

  const handleAvailabilityFormReset = (formik?: FormikProps<ScheduleAvailabilityFormValues>) => {
    resetAvailabilityButtonRef.current?.click()
    formik?.setFieldValue('repeatFrequency', "")
    formik?.setFieldValue('maxDaysInAdvance', "")
    formik?.setFieldValue('maxBookingsPerDay', "")
    formik?.setFieldValue('availableDays', initialAvailableDays)
    setAvailabilityFormValues(undefined)
    setMyEvents([])
  }

  const getServices = async () => {
    setIsLoading(true)
    const response = await serviceAPI.current.findAllMyServices()
    setServices(response)
    const serviceIds = response.map(service => service.id)
    setSelectedServices(serviceIds)
    setIsLoading(false)
  }

  useEffect(() => {
    getServices()
  }, [])

  // useEffect(() => {
  //   if (isScheduleTypeRepeating === null) {
  //     setTimeout(() => {
  //       setDrawerOpen(true)
  //     }, 300)
  //   }
  // }, [isScheduleTypeRepeating])

  moment.locale('lt', {
    week: {
      dow: 1,
    },
  })
  moment.locale('lt')
  const localizer = momentLocalizer(moment)
  const calendarRef = useRef(null)
  const DnDCalendar = withDragAndDrop(Calendar)

  const toggleDatePickerDrawer = () => {
    setDatePickerDrawerOpen(!datePickerDrawerOpen)
  }

  const handleGoBackClick = () => {
    if (isMobile && activeStep === 0) {
      toggleDrawer()
      return
    }

    if (activeStep === 0) {
      handleDialogClose()
      handleDetailsFormReset()
    }

    if (activeStep !== 0) {
      setActiveStep(activeStep - 1)
    }

    setActiveMobileStep(activeMobileStep - 1)
  }

  const handleNextButtonClick = () => {
    if (selectedServices.length === 0 || isScheduleTypeRepeating === null) {
      return
    }

    if (activeStep === 1) {
      submitDetailsButtonRef.current?.click()
      return
    }

    if (activeStep === 2) {
      submitAvailabilityButtonRef.current?.click()
      return
    }

    if (activeStep === 2 && !isScheduleTypeRepeating) {
      handleScheduleSubmit()
    }

    setActiveStep(activeStep + 1)
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const handleServiceClick = (serviceId: number) => {
    if (selectedServices.includes(serviceId)) {
      setSelectedServices(previous => previous.filter(item => item !== serviceId))
    } else {
      setSelectedServices(previous => [...previous, serviceId])
    }
  }

  const handleSelectScheduleType = (isRepeating: boolean) => {
    setIsScheduleTypeRepeating(isRepeating)
    setDrawerOpen(false)
  }

  const { components, defaultDate } = useMemo(
    () => ({
      components: {
        eventWrapper(props: any) {
          const newProps = {
            ...props,
            style: {
              ...props.style,
            },
            children: {
              ...props.children,
              props: {
                ...props.children.props,
                style: {
                  ...props.children.props.style,
                },
              },
            },
          }
          return (
            <div {...newProps}>
              {newProps.children}
            </div>
          )
        },
      },
      defaultDate: new Date(),
      views: (Object.keys(Views) as Array<keyof typeof Views>).map(k => Views[k]),
      formats: {
        timeGutterFormat: (date: any, culture: any, localizer: any) =>
          localizer.format(date, 'HH:mm', culture),
      },
    }),
    [],
  )

  const handleSelectSlot = useCallback(
    ({ start, end }: any) => {
      setMyEvents(previous => [...previous, { id: eventId, start, end, isResizable: true }])
      setEventId(eventId + 1)
    },
    [setMyEvents, eventId, setEventId],
  )

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }: any) => {
      setMyEvents((previous: any) => {
        const existing = previous.find((ev: any) => ev.id === event.id) ?? {}
        const filtered = previous.filter((ev: any) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end }]
      })
    },
    [setMyEvents],
  )

  const resizeEvent = useCallback(
    ({ event, start, end }: any) => {
      setMyEvents((previous: any) => {
        const existing = previous.find((ev: any) => ev.id === event.id) ?? {}
        const filtered = previous.filter((ev: any) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end }]
      })
    },
    [setMyEvents],
  )

  const selectEvent = useCallback(
    (event: any) => {
      setMyEvents((previous: any) => {
        const filtered = previous.filter((ev: any) => ev.id !== event.id)
        return [...filtered]
      })
    },
    [setMyEvents],
  )

  const handleNextDay = () => {
    setSelectedDate(moment(selectedDate).add(1, 'day').toDate())
  }

  const handlePreviousDay = () => {
    setSelectedDate(moment(selectedDate).subtract(1, 'day').toDate())
  }

  const minDate = moment(selectedDate).hours(9).minutes(0).toDate()
  const maxDate = moment(selectedDate).hours(19).minutes(0).toDate()

  const handleScheduleSubmit = async () => {
    console.log('handleScheduleSubmit')
    if (!detailsFormValues || (!availabilityFormValues)) {
      return
    }

    if (isScheduleTypeRepeating && availabilityFormValues) {
      const scheduleCreateRequest: ScheduleCreateRequest = {
        name: detailsFormValues.scheduleName,
        description: detailsFormValues.description,
        type: 'REPEATING',
        repeatFrequency: "WEEKLY",
        maxDaysInAdvance: availabilityFormValues.maxDaysInAdvance,
        maxBookingsPerDay: availabilityFormValues.maxBookingsPerDay,
        scheduleEntries: mapAvailableDaysToScheduleEntries(availabilityFormValues.availableDays),
        serviceIds: selectedServices,
        locationIds: detailsFormValues.locations.map(location => location.id),
      }

      await scheduleAPI.current.create(scheduleCreateRequest)
    } else {
      const TimeIntervalMap = new Map<string, TimeInterval[]>()
      moment.locale('en')
      myEvents.forEach(event => {
        const timeInterval: TimeInterval = {
          start: event.start,
          end: event.end,
        }

        if (TimeIntervalMap.has(moment(event.start).format('dddd'))) {
          const timeIntervals = TimeIntervalMap.get(moment(event.start).format('dddd'))
          timeIntervals?.push(timeInterval)
          TimeIntervalMap.set(moment(event.start).format('dddd'), timeIntervals ?? [])
        } else {
          TimeIntervalMap.set(moment(event.start).format('dddd'), [timeInterval])
        }
      })

      const availableDays: AvailableDay[] = []
      TimeIntervalMap.forEach((timeIntervals, day) => {
        availableDays.push({
          day,
          isActive: true,
          timeIntervals,
        })
      })

      console.log(selectedServices)
      const scheduleCreateRequest: ScheduleCreateRequest = {
        name: detailsFormValues.scheduleName,
        description: detailsFormValues.description,
        type: 'ONE_TIME',
        repeatFrequency: "WEEKLY",
        scheduleEntries: mapAvailableDaysToScheduleEntries(availableDays),
        serviceIds: selectedServices,
        locationIds: detailsFormValues.locations.map(location => location.id),
      }

      await scheduleAPI.current.create(scheduleCreateRequest)
      moment.locale('lt')
    }

    refetchSchedules?.()
    handleDialogClose()
    handleDetailsFormReset()
  }

  const handleDialogClose = () => {
    setActiveStep(0)
    setActiveMobileStep(0)
    setSelectedServices([])
    setIsScheduleTypeRepeating(null)
    setAvailabilityFormValues(undefined)
    setDetailsFormValues(undefined)
    setMyEvents([])
    handleDetailsFormReset()
    handleAvailabilityFormReset()
    resetAvailabilityButtonRef.current?.click()
    resetDetailsButtonRef.current?.click()
    handleClose()
  }

  return (
    <Dialog
      keepMounted
      fullWidth
      open={open}
      maxWidth='md'
      PaperProps={{
        sx: {
          borderRadius: isMobile ? "0px" : "24px",
          height: "100%",
          minWidth: isMobile ? "100%" : 'md',
          minHeight: isMobile ? "100%" : 'none',
        },
      }}
    >
      <StyledDialogTitle>
        <LengvaIcon
          variant='v3'
          icon={ChevronLeftIcon}
          onClick={handleGoBackClick}
        />
        <Typography variant='body' fontWeight={600}>{t('Add time')}</Typography>
        <LengvaIcon
          variant='v3'
          icon={CloseBlackIcon}
          onClick={handleDialogClose}/>
      </StyledDialogTitle>
      {!isLoading && (
        <DialogContent sx={{ padding: 0 }}>
          <Box sx={{
            display: "flex",
            padding: isMobile ? '1rem' : "1.5rem",
            paddingBottom: "1.5rem",
            flexDirection: "column",
            gap: "24px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          >
            {activeStep === 0 && (
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              >
                {/* {!isMobile && (
                  <ScheduleTypeSelect
                    isScheduleTypeRepeating={isScheduleTypeRepeating}
                    handleSelectScheduleType={handleSelectScheduleType}
                  />
                )} */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                  }}
                >
                  <Typography variant='h5' color='text.secondary'>{t('For which services would you like to create the schedule?')}</Typography>
                  <Typography variant='body' color='text.secondary'>{t('Choose from the list below')}</Typography>
                  {services.map((service: ServiceResponse) => (
                    <LocationContainer
                      key={service.id}
                      sx={{
                        padding: "24px 16px",
                        borderRadius: "12px",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        alignSelf: "stretch",
                        borderColor: selectedServices.includes(service.id) ? "#3872E1" : "#E9E9EB",
                      }}
                      onClick={() => handleServiceClick(service.id)}
                    >
                      <Checkbox
                        checked={selectedServices.includes(service.id)}
                        sx={{
                          color: "#3872E1",
                          '&.Mui-checked': {
                            color: "#3872E1",
                          },
                        }}/>
                      <Box>
                        <Typography variant='h5' color='text.secondary'>
                          {tServices.t(`Services.${service.serviceDescription.name}`)}
                        </Typography>
                        <Typography
                          variant='h6'
                          color='text.tertiary'
                        >{`${service.price}€ • ${convertTime(service.duration)}`}
                        </Typography>
                      </Box>
                    </LocationContainer>
                  ))}
                </Box>
              </Box>
            )}
            {activeStep === 1 && (
              <ScheduleDetailsForm
                // key={formKey}
                submitRef={submitDetailsButtonRef}
                resetRef={resetDetailsButtonRef}
                values={detailsFormValues}
                handleSubmit={handleDetailsFormSubmit}
                handleReset={handleDetailsFormReset}
              />
            )}
            {activeStep === 2 && isScheduleTypeRepeating && (
              <ScheduleAvailabilityForm
                values={availabilityFormValues}
                submitRef={submitAvailabilityButtonRef}
                handleSubmit={handleAvailabilityFormSubmit}
                resetRef={resetAvailabilityButtonRef}
                handleReset={handleAvailabilityFormReset}
              />
            )}
            {activeStep === 2 && !isScheduleTypeRepeating && (
              <>
                <Typography variant='body'>{t('Select a time slot')}</Typography>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  marginRight='auto'
                  gap='2rem'
                  flex={2}
                  width='100%'
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                      cursor: 'pointer',
                    }}
                    onClick={event => {
                      setDatePickerMenuAnchorElement(event.currentTarget)
                      setDatePickerDrawerOpen(true)
                    }}
                  >
                    <Typography
                      variant='body'
                      textTransform='capitalize'
                    >
                      {moment(selectedDate).locale(i18next.language).format('ddd, DD MMM YYYY')}
                    </Typography>
                    <IconButton
                      onClick={event => {
                        setDatePickerMenuAnchorElement(event.currentTarget)
                        setDatePickerDrawerOpen(true)
                      }}
                    >
                      <ExpandMoreRounded/>
                    </IconButton>
                  </Box>
                  <Box
                    display='flex'
                    gap='12px'
                    flex={1}
                    justifyContent='flex-end'
                    alignItems='center'
                    marginLeft='24px'
                    width='100%'
                  >
                    <IconButton
                      sx={{
                        padding: '12px',
                        backgroundColor: '#F5F5F5',
                        borderRadius: '12px',
                        height: '44px',
                        width: '44px',
                      }}
                      onClick={handlePreviousDay}
                    >
                      <ChevronLeftRounded
                        sx={{
                          color: '#585C65',
                        }}
                      />
                    </IconButton>
                    <IconButton
                      sx={{
                        padding: '12px',
                        backgroundColor: '#F5F5F5',
                        borderRadius: '12px',
                        height: '44px',
                        width: '44px',
                      }}
                      onClick={handleNextDay}
                    >
                      <ChevronRightRounded/>
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "700px",
                    width: "100%",
                    gap: '2rem',
                  }}
                  className='height600'
                >
                  <DnDCalendar
                    ref={calendarRef}
                    popup
                    showMultiDayTimes
                    resizable
                    selectable
                    step={15}
                    timeslots={4}
                    style={{
                      width: "100%",
                    }}
                    localizer={localizer}
                    components={components}
                    min={minDate}
                    max={maxDate}
                    date={selectedDate}
                    toolbar={false}
                    defaultDate={defaultDate}
                    defaultView='day'
                    views={['day']}
                    events={myEvents}
                    onSelectSlot={handleSelectSlot}
                    onEventDrop={moveEvent}
                    onEventResize={resizeEvent}
                    onSelectEvent={selectEvent}
                  />
                  {!isMobile && (
                    <Menu
                      open={datePickerMenuOpen}
                      anchorEl={datePickerMenuAnchorElement}
                      onClose={() => setDatePickerMenuAnchorElement(null)}
                    >
                      <CalendarStaticDatePicker
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        handleClose={() => setDatePickerMenuAnchorElement(null)}
                      />
                    </Menu>
                  )}
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
      )}
      {isLoading && <LengvaLoadingSpinner/>}
      <DialogActions
        sx={{
          display: "flex",
          padding: isMobile ? '0.75rem' : "1.5rem",
          alignItems: "center",
          gap: "24px",
          alignSelf: "stretch",
          borderTop: "1px solid #EBEBEB",
        }}
      >
        <Box sx={{ marginLeft: 'auto', display: "flex", gap: "12px" }}>
          <LengvaButton
            endIcon={activeStep === 2 ? undefined : ArrowNarrowRight}
            title={activeStep === 2 ? t('Create schedule') : t('Next')}
            onClick={handleNextButtonClick}
          />
        </Box>
      </DialogActions>
      {isMobile && (
        <>
          <DialogDrawer
            drawerOpen={drawerOpen}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
          >
            <StyledDialogTitle
              sx={{
                padding: "0px 0px 24px 0px",
                borderBottom: 'none',
              }}
            >
              <Typography variant='h4'>{t('Add time')}</Typography>
              <LengvaIcon variant='v3' icon={CloseBlackIcon} onClick={toggleDrawer}/>
            </StyledDialogTitle>
            {/* <ScheduleTypeSelect
              isScheduleTypeRepeating={isScheduleTypeRepeating}
              handleSelectScheduleType={handleSelectScheduleType}
            /> */}
          </DialogDrawer>
          <DialogDrawer
            drawerOpen={datePickerDrawerOpen}
            onClose={toggleDatePickerDrawer}
            onOpen={toggleDatePickerDrawer}
          >
            <CalendarStaticDatePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              handleClose={() => setDatePickerDrawerOpen(false)}
            />
          </DialogDrawer>
        </>
      )}
    </Dialog>
  )
}

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

const initialAvailableDays: AvailableDay[] = weekdays.map(weekday => ({
  id: undefined,
  day: weekday,
  isActive: false,
  timeIntervals: [],
}))

interface DayEvent {
  id: number;
  start: Date;
  end: Date;
  isResizable: boolean;
}

interface DialogDrawerProps extends SwipeableDrawerProps {
  drawerOpen: boolean;
  children: any;
}

interface ScheduleTypeSelectProps {
  isScheduleTypeRepeating: boolean | null;
  handleSelectScheduleType: (isRepeating: boolean) => void;
}

const ScheduleTypeSelect = ({isScheduleTypeRepeating, handleSelectScheduleType}: ScheduleTypeSelectProps) => {
  const { t } = useTranslation('translation', {keyPrefix: 'AddScheduleDialog'})

  return (
    <Box sx={{display: "flex", flexDirection: "column", gap: "16px", alignSelf: "stretch", width: '100%'}}>
      <Typography variant='body' color='text.secondary'>{t('Choose schedule type')}</Typography>
      <Box>
        <Box sx={{display: "flex", alignItems: "flex-start", alignSelf: "stretch", gap: "24px"}}>
          <SelectTypeItem
            sx={{
              border: isScheduleTypeRepeating ? "1px solid #3872E1" : "1px solid #E9E9EB",
            }}
            onClick={() => {
              handleSelectScheduleType(true)
            }}
          >
            <img src={isScheduleTypeRepeating ? (RefreshActiveIcon as any) : (RefreshIcon as any)} alt='' width='64px' height='64px'/>
            <Typography variant='h5' color='text.secondary'>{t('Repeating')}</Typography>
          </SelectTypeItem>
          <SelectTypeItem
            sx={{
              border: isScheduleTypeRepeating === false ? "1px solid #3872E1" : "1px solid #E9E9EB",
            }}
            onClick={() => handleSelectScheduleType(false)}
          >
            <img src={isScheduleTypeRepeating === false ? (CalendarActiveIcon as any) : (CalendarIcon as any)} alt='' width='64px' height='64px'/>
            <Typography variant='h5' color='text.secondary'>{t('One time')}</Typography>
          </SelectTypeItem>
        </Box>
      </Box>
    </Box>
  )
}

interface CalendarStaticDatePickerProps {
  selectedDate: Date;
  handleClose: () => void;
  setSelectedDate: (date: Date) => void;
}

const CalendarStaticDatePicker = ({selectedDate, handleClose, setSelectedDate}: CalendarStaticDatePickerProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <StaticDatePicker
      defaultValue={dayjs(selectedDate)}
      slotProps={{
        actionBar: {
          onCancel() {
            handleClose()
          },
        } as any,
      }}
      sx={{
        width: "100%",
        "& .MuiDayCalendar-header": {
          width: "100%",
          justifyContent: "space-between",
        },
        "& .MuiDayCalendar-weekContainer": {
          justifyContent: "space-between",
        },
        "& .Mui-selected": {
          backgroundColor: "#3872E1 !important",
          "&:hover": {
            backgroundColor: "#3872E1",
          },
        },
      }}
      onAccept={date => {
        if (date) {
          setSelectedDate(date.toDate())
        }

        handleClose()
      }}
    />
  </LocalizationProvider>
)

export const DialogDrawer = ({drawerOpen, children, ...other}: DialogDrawerProps) => {
  const iOS
  = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
  return (
    <SwipeableDrawer
      {...other}
      keepMounted
      anchor='bottom'
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        zIndex: 1301,
      }}
      open={drawerOpen}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(2px)",
          },
        },
      }}
      PaperProps={{
        sx: {
          padding: "1.5rem",
          paddingBottom: "1.5rem",
          boxSizing: "border-box",
          borderRadius: "24px 24px 0 0",
          transition: "0.3s",
          width: "100%",
        },
      }}
    >
      {children}
    </SwipeableDrawer>
  )
}

const StyledDialogTitle = styled(DialogTitle)({
  display: "flex",
  padding: "1.5rem",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  alignSelf: "stretch",
  borderBottom: "1px solid #E9E9EB",
})

const SelectTypeItem = styled(Box)({
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
  flex: "1 0 0",
  borderRadius: "16px",
  transition: "0.3s",
  cursor: "pointer",
})

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.05)",
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}))
