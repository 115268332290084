/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { DayOffResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Dayoff<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags day-off-controller
   * @name FindAll
   * @request GET:/api/v1/specialist/dayoff
   */
  findAll = (params: RequestParams = {}) =>
    this.request<DayOffResponse[], any>({
      path: `/api/v1/specialist/dayoff`,
      method: "GET",
      ...params,
    });
}
