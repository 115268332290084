import { Box, Typography, useMediaQuery } from "@mui/material"
import LengvaSmallLogo from "_assets/lengva-small-logo.svg"
import { Outlet, useSearchParams } from "react-router-dom"

export default function PublicLayout() {
  const platform = useSearchParams()[0].get('platform')
  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Outlet/>
      {platform && (
        <PoweredByLengvaFooterContainer/>
      )}
    </Box>
  )
}

export const PoweredByLengvaFooter = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
      padding: "0 0.5rem",
    }}
  >
    <Typography variant='body'>
      Powered by
    </Typography>
    <img
      style={{
        maxWidth: "100px",
      }}
      src={LengvaSmallLogo as any}
      alt='powered by lengva logo'
    />
  </Box>
)

export const PoweredByLengvaFooterContainer = () => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height={isMobile ? 50 : 100}
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0 }}
    >
      <Box
        sx={{
          maxWidth: 720,
          width: "100%",
        }}
      >
        <PoweredByLengvaFooter/>
      </Box>
    </Box>
  )
}
