import { KeyboardArrowDown, MenuOutlined } from "@mui/icons-material"
import { Avatar, Box, Hidden, Typography, styled, useMediaQuery } from "@mui/material"
import ChevronDownIcon from '_assets/chevronDown.svg'
import LengvaLogo from "_assets/lengva-logo.svg"
import Notification from '_assets/notification-icon.svg'
import SearchIcon from "_assets/search.svg"
import LengvaProfileMenu from "_components/LengvaProfileMenu"
import { SidebarIconSpace } from "_components/LengvaSidebar"
import useStore from "_state/useStore"
import { Formik, type FormikProps } from "formik"
import { useState } from "react"
import { ReactCountryFlag } from "react-country-flag"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface LengvaNavbarProps {
  isAdmin: boolean;
  openDrawer: boolean;
  keyPrefix: string;
  homePageLink: string;
  setOpenDrawer: (openDrawer: boolean) => void;
  handleLanguageChange: () => void;
}

const LengvaNavbar = ({ isAdmin, openDrawer, keyPrefix, homePageLink, setOpenDrawer, handleLanguageChange }: LengvaNavbarProps) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorElement)
  const account = useStore(state => state.account)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorElement(null)
  }

  const { i18n } = useTranslation('translation', { keyPrefix })
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Formik
      initialValues={{}}
      onSubmit={values => console.log("on submit:", values)}
    >
      {(formik: FormikProps<any>) => (
        <TopBar style={{ zIndex: '1199' }}>
          <Hidden mdUp>
            <MenuIcon onClick={() => setOpenDrawer(!openDrawer)} />
            {!isMobile && (
              <Box sx={{ padding: "5px 20px 0 30px" }}>
                <Link to={homePageLink}>
                  <img src={LengvaLogo as any} style={{ width: "106px" }} />
                </Link>
              </Box>
            )}
          </Hidden>

          <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <Hidden mdUp>
              <SearchStyledIcon />
            </Hidden>
            <Box sx={{ display: 'flex', margin: '0 16px 0 0', '&:hover': { cursor: 'pointer' } }}>
              <ReactCountryFlag
                svg
                style={{ width: "30px", height: "20px" }}
                countryCode={i18n.language === "en" ? "GB" : i18n.language.toUpperCase()}
                cdnSuffix='svg'
                title={i18n.language.toUpperCase()}
                onClick={handleLanguageChange}
              />
            </Box>

            <UserBox
              sx={{
                cursor: 'pointer',
              }}
              onClick={handleMenuClick}
            >
              <Avatar
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              />

              <Hidden mdDown>
                <Box flexDirection={"column"} display="flex" alignItems={"start"}>
                  <SidebarIconSpace>
                    <Typography variant='button'>
                      {account?.name}
                    </Typography>

                  </SidebarIconSpace>
                </Box>


              </Hidden>
              <img
                src={ChevronDownIcon as any}
                style={{
                  marginLeft: '32px',
                }}
              />
            </UserBox>
          </Box>
          <LengvaProfileMenu
            anchorEl={anchorElement}
            open={open}
            handleClose={handleMenuClose}
          />
        </TopBar>
      )}
    </Formik>
  )
}

export const TopBar = styled(Box)(({ theme }) => ({
  padding: "1rem 40px",
  borderBottom: "1px solid #EBEBEB",
  display: "flex",
  alignItems: "center",
  background: "rgba(255, 255, 255, 0.8)",
  backdropFilter: "blur(12px)",
  zIndex: 1251,
  [theme.breakpoints.down('md')]: {
    padding: "1rem",
  },
}))

export const NotificationIcon = styled('img')({
  width: "24px",
  height: "24px",
  marginRight: "1rem",
  cursor: "pointer",
})

export const UserBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  borderRadius: "12px",
  background: "#F5F5F5",
  color: "text.primary",
  fontWeight: "700",
  padding: "0.5rem 1rem",
})

export const ArrowIcon = styled(KeyboardArrowDown)({
  width: "26px",
  height: "26px",
  marginLeft: "0.5rem",
  cursor: "pointer",
})

export const MenuIcon = styled(MenuOutlined)({
  width: "26px",
  height: "26px",
  marginLeft: "0.5rem",
  cursor: "pointer",
})

export const SearchStyledIcon = styled('img')({
  width: "22px",
  height: "22px",
  marginRight: "1rem",
  cursor: "pointer",
})

SearchStyledIcon.defaultProps = {
  src: SearchIcon as any,
}

NotificationIcon.defaultProps = {
  src: Notification as any,
}
export default LengvaNavbar
