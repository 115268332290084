import { type Account } from "_api/Account"
import { type Appointments } from "_api/Appointments"
import { type Services } from "_api/Services"
import { type AccountPublicResponse, type AppointmentCreateRequest, type AppointmentResponse, type AppointmentUpdateRequest, type ServiceResponse } from "_api/data-contracts"
import ArrowNarrowLeftIcon from "_assets/arrow-narrow-left.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import AppointmentCreateEditForm, { type AppointmentFormValues } from "_pages/admin/appointments/AppointmentCreateEditForm"
import { DashboardContainer, DashboardTitleContainer } from "_pages/admin/appointments/AppointmentListPage"
import { createApi } from "_utils/ApiCreator"
import { getDate } from "_utils/ObjectUtils"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

interface Props {
  isEdit?: boolean;
}

const AppointmentCreateEditPage = ({isEdit}: Props) => {
  const { id } = useParams<{ id: string }>()

  const [appointment, setAppointment] = useState<AppointmentResponse | undefined>()
  const [clients, setClients] = useState<AccountPublicResponse[]>([])
  const [services, setServices] = useState<ServiceResponse[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const appointmentAPI = useRef(createApi('appointment') as Appointments)
  const accountAPI = useRef(createApi('account') as Account)
  const serviceAPI = useRef(createApi('service') as Services)
  const { t } = useTranslation('translation', { keyPrefix: 'AppointmentCreateEdit'})

  useEffect(() => {
    getData()
  // eslint-disable-next-line
  }, [])

  const getData = async () => {
    setIsLoading(true)
    await getAppointment()
    await getClients()
    await getServices()
    setIsLoading(false)
  }

  const getAppointment = async () => {
    if (!id) {
      return
    }

    const response = await appointmentAPI.current.findById(Number(id))
    setAppointment(response)
  }

  const getClients = async () => {
    const clients = await accountAPI.current.findAllClientsBySpecialist()
    setClients(clients)
  }

  const getServices = async () => {
    const services = await serviceAPI.current.findAllMyServices()
    setServices(services)
  }

  const handleSubmit = async (values: AppointmentFormValues) => {
    if (!values.service || !values.client || !values.location || !values.startTime || !values.endTime) {
      return
    }

    if (isEdit && appointment) {
      const updateAppointment: AppointmentUpdateRequest = {
        id: appointment.id,
        patientId: values.client,
        startDate: getDate(values.date ?? values.startTime, values.startTime).toISOString(),
        endDate: getDate(values.date ?? values.endTime, values.endTime).toISOString(),
        serviceId: values.service,
        locationId: values.location,
        notes: values.notes,
        address: values.locationAddress,
        sendNotifications: values.sendNotifications,
      }

      await appointmentAPI.current.updateSpecialistAppointment(appointment?.id, updateAppointment)
      navigate(-1)
      return
    }

    const newAppointment: AppointmentCreateRequest = {
      patientId: values.client,
      startDate: getDate(values.date ?? values.startTime, values.startTime).toISOString(),
      endDate: getDate(values.date ?? values.startTime, values.startTime).toISOString(),
      serviceId: values.service,
      locationId: values.location,
      notes: values.notes,
      address: values.locationAddress,
      sendNotifications: values.sendNotifications,
    }

    await appointmentAPI.current.createSpecialistAppointment(newAppointment)
    navigate(-1)
  }

  if (isLoading) {
    return (
      <LengvaLoadingSpinner/>
    )
  }

  return (
    <DashboardContainer>
      <DashboardTitleContainer
        sx={{
          marginBottom: '0.5rem',
        }}
      >
        <LengvaButton
          title={t('Back')}
          color='secondary'
          icon={ArrowNarrowLeftIcon}
          onClick={() => navigate(-1)}
        />
      </DashboardTitleContainer>
      {((isEdit && appointment) ?? !isEdit) && (
        <AppointmentCreateEditForm
          isEdit={isEdit}
          clients={clients}
          services={services}
          appointment={appointment}
          handleSubmit={handleSubmit}
        />
      )}
      {!appointment && isEdit && (
        <LengvaLoadingSpinner/>
      )}
    </DashboardContainer>
  )
}

export default AppointmentCreateEditPage
