
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function Footer() {
  const { t } = useTranslation('translation', {keyPrefix: "LoginPage"})
  return (
    <Box textAlign='center' sx={{ marginTop: "auto" }} boxShadow={3} py={1} px={1.5}>
      {t("Footer")}&nbsp;
      <a href='mailto: labas@lengva.tech'>
        <Typography variant='hyperlink'>
          labas@lengva.tech
        </Typography>
      </a>
    </Box>
  )
}
