import { Box, Typography, styled, useMediaQuery } from "@mui/material"
import { type Services } from "_api/Services"
import { type ServiceCreateRequest, type ServiceResponse, type ServiceUpdateRequest } from "_api/data-contracts"
import EditIcon from '_assets/edit-black.svg'
import PlusIcon from '_assets/plus.svg'
import TrashIcon from '_assets/trash.svg'
import LengvaButton from "_components/LengvaButton"
import LengvaDeleteConfirm from "_components/LengvaDeleteConfirm"
import LengvaIcon from "_components/LengvaIcon"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import { DashboardContainer, DashboardTitleContainer } from "_pages/admin/SpecialistDashboard"
import ServiceDialog from "_pages/admin/services/ServiceDialog"
import { type ServiceFormValues } from "_pages/admin/services/ServiceForm"
import { createApi } from "_utils/ApiCreator"
import { convertTime, itemColorArray } from "_utils/ObjectUtils"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

const ServicePage = () => {
  const [services, setServices] = useState<ServiceResponse[]>([])
  const [activeService, setActiveService] = useState<ServiceResponse | null>(null)
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { t } = useTranslation('translation', { keyPrefix: 'ServicePage' })
  const isMobile = useMediaQuery('(max-width: 600px)')
  const serviceAPI = useRef(createApi('service') as Services)

  useEffect(() => {
    getServices()
  }, [])

  const getServices = async () => {
    setIsLoading(true)
    const response = await serviceAPI.current.findAllMyServices()
    setServices(response)
    setIsLoading(false)
  }

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true)
  }

  const handleAddDialogClose = () => {
    setAddDialogOpen(false)
  }

  const handleEdit = (service: ServiceResponse) => {
    setActiveService(service)
    setEditDialogOpen(true)
  }

  const handleEditDialogClose = () => {
    setActiveService(null)
    setEditDialogOpen(false)
  }

  const handleDeleteDialogOpen = (service: ServiceResponse) => {
    setActiveService(service)
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  const handleDelete = async (service: ServiceResponse) => {
    await serviceAPI.current.delete(service.id)
    setActiveService(null)
    getServices()
  }

  const handleSubmit = async (values: ServiceFormValues) => {
    if (values.price === undefined || values.price === null || !values.duration || !values.serviceDescriptionId) {
      return
    }

    const durationInMinutes = (values.duration.getHours() * 60) + values.duration.getMinutes()

    if (activeService) {
      const serviceUpdateRequest: ServiceUpdateRequest = {
        id: activeService.id,
        price: values.price,
        duration: durationInMinutes,
        bufferTimeBefore: values.bufferTimeBefore,
        bufferTimeAfter: values.bufferTimeAfter,
        serviceDescriptionId: values.serviceDescriptionId,
      }

      await serviceAPI.current.update(activeService.id, serviceUpdateRequest)
      getServices()
      handleEditDialogClose()
      return
    }

    const serviceCreateRequest: ServiceCreateRequest = {
      price: values.price,
      duration: durationInMinutes,
      bufferTimeBefore: values.bufferTimeBefore,
      bufferTimeAfter: values.bufferTimeAfter,
      serviceDescriptionId: values.serviceDescriptionId,
    }

    await serviceAPI.current.create(serviceCreateRequest)
    getServices()
    handleAddDialogClose()
  }

  return (
    <DashboardContainer
      sx={{
        paddingTop: '0',
      }}
    >

      <DashboardTitleContainer
        sx={{
          flexWrap: 'wrap',
          marginBottom: '2rem',
          gap: '1rem',
        }}
      >
        <Typography variant='h2'>
          {t("Services")}
        </Typography>
        <Box
          display='flex'
          gap='1rem'
          flexWrap='wrap'
        >
          <LengvaButton
            title={isMobile ? t('New') : t('New Service')}
            color='primary'
            icon={PlusIcon}
            onClick={handleAddDialogOpen}
          />

        </Box>
      </DashboardTitleContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          padding: isMobile ? '0px' : '1.5rem',
          boxSizing: 'border-box',
        }}
      >
        {!isLoading && services.map((service, index) => (
          <ServiceItem
            key={service.id}
            service={service}
            sidecolor={itemColorArray[index % itemColorArray.length]}
            handleEdit={handleEdit}
            handleDelete={handleDeleteDialogOpen}
          />
        ))}
        {isLoading && <LengvaLoadingSpinner/>}
      </Box>
      {activeService && (
        <ServiceDialog
          open={editDialogOpen}
          service={activeService}
          handleClose={handleEditDialogClose}
          handleSubmit={handleSubmit}
          handleDelete={() => handleDeleteDialogOpen(activeService)}
        />
      )}
      <ServiceDialog
        open={addDialogOpen}
        handleSubmit={handleSubmit}
        handleClose={handleAddDialogClose}
      />
      {activeService && (

        <LengvaDeleteConfirm
          open={deleteDialogOpen}
          handleClose={handleDeleteDialogClose}
          handleDelete={async () => handleDelete(activeService)}
          objectName='Service'/>
      )}
    </DashboardContainer>
  )
}

interface LocationItemProps {
  sidecolor: string;
  service: ServiceResponse;
  handleEdit: (service: ServiceResponse) => void;
  handleDelete: (service: ServiceResponse) => void;
}

const ServiceItem = ({service, sidecolor, handleEdit, handleDelete}: LocationItemProps) => {
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })
  const isMobile = useMediaQuery('(max-width: 600px)')
  return (
    <ServiceItemContainer
      sidecolor={sidecolor}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
          }}
        >
          <Typography variant='h4'>
            {tServices.t(`Services.${service.serviceDescription.name}`)}
          </Typography>
          {service.duration && (
            <Typography variant='body'>
              {service.price}€ • {convertTime(service.duration)}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: '0 1 96px',
          gap: '1rem',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
        }}
      >
        <LengvaIcon
          icon={EditIcon}
          onClick={() => handleEdit(service)}
        />
        <LengvaIcon
          icon={TrashIcon}
          onClick={() => handleDelete(service)}
        />
      </Box>

    </ServiceItemContainer>
  )
}

const ServiceItemContainer = styled(Box)(({sidecolor}: {sidecolor: string}) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isMobile ? '0.75rem 1rem' : '1.5rem 2rem',
    paddingLeft: '2rem',
    boxSizing: 'border-box',
    borderRadius: '0.75rem',
    width: '100%',
    backgroundImage: `linear-gradient(90deg, ${sidecolor} 12px, #FFFFFF 12px)`,
    border: '1px solid #E9E9EB',
    overflowX: 'auto',
    gap: '1rem',
  }
})
export default ServicePage
