import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, IconButton, InputBase, Paper, Tooltip, Typography } from "@mui/material"
import { useField, useFormikContext } from "formik"
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined'
import { useState } from "react"

interface Props {
  startIcon?: any;
  endIcon?: any;
  title?: string;
  label: string;
  supportText?: string;
  size?: "small";
  startIconOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  endIconOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  onChange?: (newValue: string) => void;
  value?: string;
  placeholder?: string;
  type?: "text" | "password" | 'number';
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
  tooltip?: string;
}

export default function LengvaInput({
  startIcon,
  endIcon,
  title,
  label,
  supportText,
  size,
  startIconOnClick,
  endIconOnClick,
  onChange,
  value,
  placeholder,
  type,
  multiline,
  minRows,
  maxRows,
  containerStyle,
  disabled,
  tooltip,
}: Props) {
  const [field, meta] = useField(label)
  const formikContext = useFormikContext()

  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const paperStyle = {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    borderRadius: "12px",
    margin: "0px",
    border: "1px solid var(--border-border-secondary, #9B9DA3)",
    color: "#787878",
    alignSelf: "stretch",
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: "100%", gap: "4px", ...containerStyle }}>
      {title && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '8px',
          }}
        >
          <Typography
            variant='h6'
            color='text.secondary'
            style={{ fontWeight: '600' }}
          >
            {title}
          </Typography>

          {tooltip && (
            <Tooltip
              title={tooltip}
            >
              <HelpOutlinedIcon
                sx={{
                  color: '#9B9DA3',
                  fontSize: '1rem',
                }}
              />
            </Tooltip>
          )}
        </Box>
      )}
      <Paper
        variant='outlined'
        component='form'
        style={{
          ...paperStyle,
          padding: size === "small" ? "8px 16px" : "12px 16px",
        }}
        sx={{
          "&:hover": {
            border: disabled ? '1px solid #9B9DA3 !important' : "1px solid #3872E1 !important",
            cursor: "pointer",
          },
          "&& :focus": {
            outline: "none",
            color: "#202632",
          },
        }}
      >
        {startIcon && (
          <IconButton
            aria-label='menu'
            style={{
              padding: 0,
              height: "24px",
            }}
            onClick={startIconOnClick}
          >
            <img src={startIcon} alt=''/>
          </IconButton>
        )}
        <InputBase
          {...field}
          value={field.value}
          disabled={disabled}
          sx={{ flex: 1, padding: '0!important' }}
          placeholder={placeholder}
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          type={type === 'password' ? (showPassword ? "text" : "password") : type}
          inputProps={{ "aria-label": "" }}
          style={{
            height: multiline ? "none" : "24px",
            margin: 0,
            color: "#787878",
          }}
          onKeyDown={event => {
            if (event.key === "Enter") {
              event.preventDefault()
              formikContext.submitForm()
            }
          }}
        />

        {(endIcon || type === 'password') && (
          <IconButton
            aria-label='menu'
            style={{
              height: "20px",
              width: "20px",
              padding: 0,
            }}
            onClick={endIconOnClick}
          >
            {type === 'password' && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {showPassword ? (
                  <VisibilityOff
                    onClick={toggleShowPassword}
                  />
                ) : (
                  <Visibility onClick={toggleShowPassword}/>
                )}
              </>
            )}
            {type !== 'password' && (
              <img
                src={endIcon}
                alt=''
                style={{
                  height: "20px",
                  width: "20px",
                  padding: 0,
                }}
              />
            )}
          </IconButton>
        )}
      </Paper>
      {supportText && (
        <Typography variant='h6' color='text.tertiary'> {supportText} </Typography>
      )}
      {meta.touched && meta.error && (
        <Typography variant='h6' color='#EB0E0E'>
          {meta.error}
        </Typography>
      )}
    </Box>
  )
}
