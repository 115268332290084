import { Autocomplete, Avatar, Box, Paper, TextField, Typography } from "@mui/material"
import { type SelectOption } from "_components/LengvaSelect"
import { useField } from "formik"
import { useMemo } from "react"
import CheckBlueIcon from "../_assets/checkBlue.svg"
import ChevronDown from '../_assets/chevronDown.svg'
import FilledCloseIcon from '../_assets/filled-close-icon.svg'

interface Props {
  label: string;
  options: SelectOption[];
  title?: string;
  placeholder?: string;
  multiple?: boolean;
  supportText?: string;
}

const LengvaAutocomplete = ({label, options, title, placeholder, multiple, supportText}: Props) => {
  const [field, meta, helper] = useField(label)

  const selectedValue = useMemo(
    () => options.find(option => option.value === field.value) ?? null,
    [field.value, options],
  )

  return (
    <Box style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "4px" }}
    >
      <Typography variant='body2' color='text.secondary' fontWeight={600}>
        {title}
      </Typography>
      <Autocomplete
        options={options}
        value={selectedValue}
        multiple={multiple}
        getOptionLabel={option => option.label}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 3px 9px 0px rgba(0, 0, 0, 0.14)",
              padding: "0px 8px",
            }}
          >
            {children}
          </Paper>
        )}
        renderInput={parameters => (
          <TextField
            {...parameters}
            placeholder={placeholder}
            InputProps={{
              sx: {
                borderRadius: "12px",
                padding: "12px 16px",
                "&.MuiInputBase-input": {
                  padding: "0px",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #9F9F9F",
                },
                "&.MuiOutlinedInput-root": {
                  padding: "13px 16px !important",
                  ".MuiAutocomplete-input": {
                    height: "24px",
                    padding: "0px",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "23.8px",
                    color: '#202632',
                  },
                },
              },
              inputProps: {
                sx: {
                  padding: "0px",
                },
                ...parameters.inputProps,
              },
              ...parameters.InputProps,
              endAdornment: (
                <>
                  {field.value && (
                    <img
                      src={FilledCloseIcon as any}
                      style={{ cursor: "pointer" }}
                      alt='close-icon'
                      onClick={async () => {
                        helper.setValue('')
                        parameters.inputProps.onChange?.({ target: { value: '' } } as any)
                      }}/>
                  )}
                  {!field.value && (
                    <img src={ChevronDown as any} alt='arrow-down'/>
                  )}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box
            component='li' {...props}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              padding: "11px 16px !important",
              height: "54px",
              borderRadius: "12px",
              boxSizing: "border-box",
              backgroundColor: "#FFFFFF !important",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#F4F4F5 !important",
              },
            }}
          >
            {option.icon && (
              <img
                src={option.icon}
                style={{
                  width: "28px",
                  height: "28px",
                }}
                alt=''
              />
            )}
            {option.avatarSrc && (
              <Avatar
                src={option.avatarSrc}
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              />
            )}
            <Typography variant='body' color='text.secondary' fontWeight={600}>
              {option.label}
            </Typography>
            {field.value === option.value && (
              <img
                src={CheckBlueIcon as any}
                alt=''
                style={{
                  marginLeft: 'auto',
                }}/>
            )}
          </Box>
        )}
        onChange={(event, newValue) => {
          if (!newValue) {
            helper.setValue('')
            return
          }

          if (Array.isArray(newValue)) {
            helper.setValue(newValue.map((option: any) => option.value))
            return
          }

          helper.setValue(newValue.value)
        }}
      />
      {supportText && (
        <Typography variant='h6' color='text.tertiary'>
          {supportText}
        </Typography>
      )}
      {meta.touched && meta.error && (
        <Typography variant='h6' color='#EB0E0E'>
          {meta.error}
        </Typography>
      )}
    </Box>
  )
}

export default LengvaAutocomplete
