import { Box, Typography, useMediaQuery } from "@mui/material"
import { type Account } from "_api/Account"
import { type Appointments } from "_api/Appointments"
import { type AccountPublicResponse, type AppointmentCreateRequest, type AppointmentResponse, type ClientResponse, type ServiceResponse } from "_api/data-contracts"
import CheckGreen from '_assets/check.svg'
import PendingIcon from '_assets/pending-icon.svg'
import PlusIcon from '_assets/plus.svg'
import FailedIcon from '_assets/red-cross.svg'
import LengvaButton from "_components/LengvaButton"
import LengvaTable, { type ColumnConfig } from "_components/LengvaTable"
import { type FilterItem, type FilterValues } from "_components/LengvaTableFilterModal"
import { type AppointmentFormValues } from "_pages/admin/appointments/AppointmentCreateEditForm"
import { DashboardContainer, DashboardSectionContainer, DashboardTitleContainer } from "_pages/admin/appointments/AppointmentListPage"
import { createApi } from "_utils/ApiCreator"
import AutoSubmitToken from "_utils/AutoSubmitToken"
import { getDate } from "_utils/ObjectUtils"
import { Formik } from "formik"
import { type TFunction } from "i18next"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const ClientAppointmentsPage = () => {
  const [appointments, setAppointments] = useState<AppointmentResponse[]>([])
  const [filterConfig, setFilterConfig] = useState<FilterItem[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [specialists, setSpecialists] = useState<AccountPublicResponse[]>([])

  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const appointmentAPI = useRef(createApi("appointment") as Appointments)
  const accountAPI = useRef(createApi("account") as Account)
  const { t } = useTranslation('translation', {keyPrefix: "ClientAppointmentsPage"})

  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })

  const getAppointments = async () => {
    setIsLoading(true)
    const response = await appointmentAPI.current.findClientAllFilteredBy({})
    setAppointments(response)
    setFilterConfig(getClientAppointmentFilterConfig(response, t, tServices.t))

    setIsLoading(false)
  }

  const getSpecialists = async () => {
    const response = await accountAPI.current.findAllSpecialists()
    setSpecialists(response)
  }

  const handleDialogOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    getAppointments()
    getSpecialists()
  // eslint-disable-next-line
  }, [])

  const handleFilterSubmit = async (values: AppointmentClientFilterValues) => {
    const query = {
      specialistId: values.specialist,
      serviceId: values.service,
      locationId: values.location,
      status: values.status,
      dateFrom: values.dateFrom ? moment(values.dateFrom).hour(0).minute(0).second(0).toISOString() : undefined,
      dateTo: values.dateTo ? moment(values.dateTo).hour(23).minute(59).second(59).toISOString() : undefined,
      paymentStatus: values.paymentStatus,
      searchString: values.searchValue,
    }

    const filteredAppointments = await appointmentAPI.current.findClientAllFilteredBy(query)
    setAppointments(filteredAppointments)
  }

  const handleCreateAppointment = async (values: AppointmentFormValues) => {
    if (!values.service || !values.specialist || !values.location || !values.startTime || !values.endTime) {
      return
    }

    const newAppointment: AppointmentCreateRequest = {
      specialistId: values.specialist,
      startDate: getDate(values.date ?? values.startTime, values.startTime).toISOString(),
      endDate: getDate(values.date ?? values.startTime, values.startTime).toISOString(),
      serviceId: values.service,
      locationId: values.location,
      notes: values.notes,
      address: values.locationAddress,
      sendNotifications: values.sendNotifications,
    }

    await appointmentAPI.current.create(newAppointment)
    handleClose()
    getAppointments()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFilterSubmit}
    >
      {formik => (
        <DashboardContainer>
          <DashboardTitleContainer
            sx={{
              flexWrap: 'wrap',
              marginBottom: '2rem',
              gap: '1rem',
            }}
          >
            <Typography variant='h2'>
              {t('Appointments')}
            </Typography>

            {/* <LengvaButton
              title={isMobile ? t('New') : t('New appointment')}
              color='primary'
              icon={PlusIcon}
              onClick={handleDialogOpen}
            /> */}
            <DashboardSectionContainer
              sx={{
                backgroundColor: '#FAFAFA',
              }}
            >
              <Box
                sx={{
                  width: 'calc(100% + 3rem)',
                  marginLeft: '-1.5rem',
                }}
              >
                {!isLoading && (
                  <LengvaTable
                    isViewOnly
                    isHeaderTransparent
                    isLoading={isLoading}
                    columns={createClientAppointmentConfig()}
                    viewMore={(appointment: any) => navigate(`/appointments/${appointment.id}`)}
                    data={mapAppointmentsToTableData(appointments, tServices.t, t)}
                    filter={filterConfig}
                    emptyStateText={t('No Appointments')}
                    allow={["add", "edit", "delete"]}
                    onFilterValueClear={(property: string) => {
                      formik.setFieldValue(property, undefined)
                      formik.handleSubmit() // should the form be instantly submitted here?
                    }} onAllFilterValueClear={() => {
                      formik.setValues(initialValues)
                      formik.handleSubmit() // should the form be instantly submitted here?
                    }}
                    onFilterSubmit={formik.handleSubmit}
                  />
                )}
              </Box>
            </DashboardSectionContainer>
          </DashboardTitleContainer>
          <AutoSubmitToken
            handleSubmit={formik.handleSubmit}
            dependantValue={formik.values.searchValue}
            delay={1500}
          />
          {/* TOOD: add appointment create/edit dialog when payment method selection is implemented */}
          {/* <AppointmentCreateEditDialog
            isClientView
            open={open}
            handleClose={handleClose}
            specialists={specialists}
            handleSubmit={handleCreateAppointment}
          /> */}
        </DashboardContainer>
      )}
    </Formik>
  )
}

interface AppointmentTableValue {
  id: number;
  date: string;
  time: string;
  service: string;
  location: string;
  paymentStatus: {
    label: string;
    variant: string;
    beforeText: string;
  };
}

const mapAppointmentsToTableData = (appointments: AppointmentResponse[], tServices: TFunction, t: TFunction): AppointmentTableValue[] => appointments.map(appointment => {
  const paidAppointment = appointment.payments?.find(payment => payment.status === "PAID")
  const payments = appointment.payments ?? []
  const tableValue: AppointmentTableValue = {
    id: appointment.id,
    time: `${moment(appointment.startDate).format('HH:mm')} - ${moment(appointment.endDate).format('HH:mm')}`,
    date: moment(appointment.startDate).format('YYYY.MM.DD'),
    service: tServices(`Services.${appointment.service.serviceDescription.name}`) ?? '',
    location: appointment.location.name ?? '',
    paymentStatus: {
      label: appointment.payments?.map(payment => payment.status).includes("PAID") ? t("Paid") : t("Pending"),
      variant: appointment.payments?.map(payment => payment.status).includes("PAID") ? 'success' : 'warning',
      beforeText: `${paidAppointment?.amount ?? payments.length > 0 ? payments[0].amount : appointment.service.price}€`,
      // beforeText: `${appointment.service.price === 0 ? appointment.service.price : appointment.service.price + 1.5}€`,

    },
  }

  return tableValue
})

export const createClientAppointmentConfig = () => {
  const config: ColumnConfig[] = [
    {
      property: "date",
      property2: "time",
      label: "Date",
      type: "string",
      hideOnCreate: true,
      orderId: 1,
    },
    {
      property: "service",
      label: "Service",
      type: "string",
      orderId: 3,
    },
    {
      property: "location",
      label: "Location",
      type: "string",
      hideOnCreate: true,
      orderId: 4,
    },
    {
      property: "paymentStatus",
      label: "Status",
      type: "label",
      hideOnCreate: true,
      orderId: 5,
    },
  ]

  return config
}

interface AppointmentClientFilterValues {
  specialist?: number;
  service?: number;
  location?: number;
  status?: "CREATED" | "OCCURRED" | "NOT_OCCURRED" | "CANCELLED" ;
  dateFrom?: Date;
  dateTo?: Date;
  paymentStatus?: "PENDING" | "PAID" | "VOIDED" | "PARTIALLY_REFUNDED" | "REFUNDED" | "ABANDONED" | "AUTHORIZED";
  searchValue?: string;
}

const initialValues: AppointmentClientFilterValues = {
  specialist: undefined,
  service: undefined,
  location: undefined,
  status: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  paymentStatus: undefined,
  searchValue: undefined,
}

const getClientAppointmentFilterConfig = (appointments: AppointmentResponse[], t: TFunction, tServices: TFunction) => {
  const services = Array.from(new Map(appointments.map(appointment => [appointment.service.id, appointment.service])).values())
  const specialists = Array.from(new Map(appointments.map(appointment => [appointment.specialist.id, appointment.specialist])).values())

  const serviceOptions: FilterValues[] = services.map((service: ServiceResponse) => ({
    title: tServices(`Services.${service.serviceDescription.name}`),
    value: service.id.toString(),
    isActive: false,
  }))

  const paymentStatusOptions: FilterValues[] = [
    {
      title: t("Paid"),
      value: "PAID",
      isActive: false,
      status: "success",
      icon: CheckGreen,
    },
    {
      title: t("Pending"),
      value: "PENDING",
      isActive: false,
      status: "warning",
      icon: PendingIcon,
    },
    {
      title: t("Not paid"),
      value: "ABANDONED",
      isActive: false,
      status: "error",
      icon: FailedIcon,
    },
  ]

  const appointmentStatusOptions: FilterValues[] = [
    {
      title: t("Happened"),
      value: "OCCURRED",
      isActive: false,
      status: "success",
    },
    {
      title: t("Pending"),
      value: "CREATED",
      isActive: false,
      status: "warning",
    },
    {
      title: t("Cancelled"),
      value: "CANCELLED",
      isActive: false,
      status: "error",
    },
  ]

  const specialistOptions: FilterValues[] = specialists.map((client: ClientResponse) => ({
    title: `${client.name} ${client.lastName}`,
    value: client.id.toString(),
    isActive: false,
  }))

  const appointmentFilterConfig: FilterItem[] = [
    {
      property: "dateFrom",
      property2: "dateTo",
      label: t("Date"),
      type: "dateRange",
      showFastFilter: true,
      values: [],
    },
    {
      property: "specialist",
      label: t("Specialist"),
      type: "avatar",
      values: specialistOptions,
    },
    {
      property: "service",
      label: t("Service"),
      type: "chip",
      values: serviceOptions,
    },
    {
      property: "status",
      label: t("Status"),
      type: "status",
      values: appointmentStatusOptions,
    },
    {
      property: "paymentStatus",
      label: t("Payment Status"),
      type: "status",
      values: paymentStatusOptions,
    },
  ]

  return appointmentFilterConfig
}

export default ClientAppointmentsPage
