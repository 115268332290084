
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material"
import ChevronLeftIcon from "_assets/chevronLeft.svg"
import CloseBlackIcon from "_assets/closeBlack.svg"
import PlusIcon from "_assets/plus.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaIcon from "_components/LengvaIcon"
import LengvaInput from "_components/LengvaInput"
import LengvaSelect from "_components/LengvaSelect"
import LengvaSelectItem from "_components/LengvaSelectItem"
import { type ServiceConfig } from "_components/LengvaTableCreateEditModal"
import { useState } from "react"

interface Props {
  services: ServiceConfig[];
  open: boolean;
  handleClose: () => void;
  openEdit?: boolean;
  serviceKey?: number;
}

export default function LengvaSpecialistAddServiceModal({
  services,
  open,
  handleClose,
  openEdit,
  serviceKey,
}: Props) {
  const [openServiceDetails, setOpenServiceDetails] = useState<boolean>(
    openEdit ?? false,
  )
  const [openServiceKey, setOpenServiceKey] = useState<number>(serviceKey ?? 0)

  const isMobile = useMediaQuery('(max-width: 600px)')

  const handleServiceClick = (key: number) => {
    setOpenServiceDetails(true)
    setOpenServiceKey(key)
  }

  const handleChevronLeftClick = () => {
    if (openEdit) {
      handleClose()
    }

    if (!openServiceDetails) {
      handleClose()
    }

    setOpenServiceDetails(false)
  }

  return (
    <Dialog
      keepMounted
      fullWidth
      open={open}
      maxWidth='md'
      PaperProps={{
        sx: {
          borderRadius: isMobile ? "0px" : "24px",
          height: "100%",
          minWidth: isMobile ? "100%" : 'md',
          minHeight: isMobile ? "100%" : 'none',
        },
      }}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          padding: "32px 32px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          alignSelf: "stretch",
          borderBottom: "1px solid #EBEBEB",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            alignSelf: "stretch",
          }}
        >
          <LengvaIcon
            variant='v3'
            icon={ChevronLeftIcon}
            onClick={handleChevronLeftClick}
          />
          <Typography variant='h4'>Pridėti paslaugą</Typography>
          <LengvaIcon
            variant='v3'
            icon={CloseBlackIcon}
            onClick={handleClose}
          />
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          padding: "24px !important",
          flexDirection: "column",
          gap: "16px",
          overflow: "auto",
        }}
      >
        {!openServiceDetails && (
          <>
            <Typography variant='body' color='text.secondary'>
              Pasirinkite veiklą iš sąrašo.
            </Typography>

            {services.map((service, key) => (
              <Box key={service.id} onClick={() => handleServiceClick(key)}>
                <LengvaSelectItem
                  title={service.title}
                  supportText={service.supportTitleText}
                />
              </Box>
            ))}
            <Typography variant='body' color='text.secondary'>
              Sąraše nerandate paslaugos?
            </Typography>
            <Box
              key={services.length}
              onClick={() => handleServiceClick(services.length)}
            >
              <LengvaSelectItem title='Pridėkite naują paslaugą'/>
            </Box>
          </>
        )}

        {openServiceDetails && (
          <>
            <Typography variant='body' color='text.secondary'>
              Pridėkite informaciją apie paslaugą.
            </Typography>
            <LengvaSelect
              label={`services.${openServiceKey}.type`}
              title='Paslaugos tipas'
              optionsMain={[]}
            />
            <LengvaInput
              label={`services.${openServiceKey}.title`}
              title='Paslaugos pavadinimas'
            />
            <LengvaInput
              title='Kaina'
              label={`services.${openServiceKey}.price`}
            />
            <LengvaSelect
              label={`services.${openServiceKey}.duration`}
              optionsMain={[
                { label: "1h", value: "1h" },
                { label: "2h", value: "2h" },
              ]}
              title='Trukmė'
            />
            <Box sx={{ display: "flex", gap: "16px" }}>
              <LengvaSelect
                label={`services.${openServiceKey}.bufferTimeBefore`}
                title='Reikalingas laikas prieš paslaugą'
                optionsMain={[
                  { label: "10 min", value: "10 min" },
                  { label: "5 min", value: "5 min" },
                  { label: "0 min", value: "0 min" },
                ]}
              />
              <LengvaSelect
                label={`services.${openServiceKey}.bufferTimeAfter`}
                title='Reikalingas laikas po paslaugos'
                optionsMain={[
                  { label: "10 min", value: "10 min" },
                  { label: "5 min", value: "5 min" },
                  { label: "0 min", value: "0 min" },
                ]}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          padding: "32px",
          alignItems: "center",
          gap: "24px",
          alignSelf: "stretch",
          borderTop: "1px solid #EBEBEB",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        { openEdit && openServiceDetails && (
          <LengvaButton
            fullWidth
            title='Saugoti'
            onClick={handleClose}/>
        )}
        {!openEdit && openServiceDetails && (
          <LengvaButton
            fullWidth
            icon={PlusIcon}
            title='Pridėti'
            onClick={handleClose}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}
