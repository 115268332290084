/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ServiceDescriptionCreateRequest,
  ServiceDescriptionResponse,
  ServiceDescriptionUpdateRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ServiceDescriptions<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags service-description-controller
   * @name FindById
   * @request GET:/api/v1/specialist/service-descriptions/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<ServiceDescriptionResponse, any>({
      path: `/api/v1/specialist/service-descriptions/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags service-description-controller
   * @name Update
   * @request PUT:/api/v1/specialist/service-descriptions/{id}
   */
  update = (id: number, data: ServiceDescriptionUpdateRequest, params: RequestParams = {}) =>
    this.request<ServiceDescriptionResponse, any>({
      path: `/api/v1/specialist/service-descriptions/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags service-description-controller
   * @name Delete
   * @request DELETE:/api/v1/specialist/service-descriptions/{id}
   */
  delete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/specialist/service-descriptions/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags service-description-controller
   * @name FindAll
   * @request GET:/api/v1/specialist/service-descriptions
   */
  findAll = (params: RequestParams = {}) =>
    this.request<ServiceDescriptionResponse[], any>({
      path: `/api/v1/specialist/service-descriptions`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags service-description-controller
   * @name Create
   * @request POST:/api/v1/specialist/service-descriptions
   */
  create = (data: ServiceDescriptionCreateRequest, params: RequestParams = {}) =>
    this.request<ServiceDescriptionResponse, any>({
      path: `/api/v1/specialist/service-descriptions`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
