import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from "@mui/icons-material"
import { Box, styled, useMediaQuery } from "@mui/material"
import { DateCalendar, DayCalendarSkeleton, LocalizationProvider, PickersDay, type PickersDayProps } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import BlueCircle from "_assets/blue-circle.svg"
import GrayCircle from "_assets/gray-circle.svg"
import AddIcon from "_assets/plus.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaDatePicker from "_components/LengvaDatePicker"
import { useField, useFormikContext } from "formik"
import i18next from "i18next"
import moment, { type Moment } from "moment"
import { useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  startLabel: string;
  endLabel: string;
  handleAddClick: () => void;
}

const LengvaCalendarRangePicker = ({startLabel, endLabel, handleAddClick}: Props) => {
  const [startField, startMeta] = useField(startLabel)
  const [endField, endMeta] = useField(endLabel)
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const { t } = useTranslation('translation', { keyPrefix: 'ScheduleDetailsForm'})

  const formikContext = useFormikContext()
  moment.locale('en', {
    week: {
      dow: 1,
    },
  })
  moment.locale('en')

  const isMobile = useMediaQuery('(max-width: 600px)')

  const handleStartChange = (newValue: any) => {
    if (endDate && newValue?.isAfter(endDate)) {
      setEndDate(null)
      formikContext.setFieldValue(endLabel, null)
      // endField.onChange(null)
    }

    setStartDate(newValue)
    formikContext.setFieldValue(startLabel, newValue)
    // startField.onChange(newValue)
  }

  const handleEndChange = (newValue: any) => {
    setEndDate(newValue)
    formikContext.setFieldValue(endLabel, newValue)
    // endField.onChange(newValue)
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={i18next.language}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: '1.5rem',
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: '1rem',
          }}
        >
          <LengvaDatePicker
            label={startLabel}
            maxDate={endDate?.toDate()}
            onChange={handleStartChange}
          />
          <LengvaDatePicker
            label={endLabel}
            minDate={startDate?.toDate()}
            onChange={handleEndChange}
          />
          <Box
            sx={{
              flex: isMobile ? '0 0 20px' : '1 1 auto',
            }}
          >
            <LengvaButton
              icon={AddIcon}
              title={isMobile ? undefined : t("Add")}
              sx={{
                padding: isMobile ? '15px 0.5rem' : '13.75px 20px',
              }}
              onClick={handleAddClick}
            />
          </Box>
        </Box>
        <StyledCalendar
          openTo='day'
          dayOfWeekFormatter={(day, date: any) => date.locale(i18next.language).format("ddd")}
          views={['day']}
          renderLoading={() => <DayCalendarSkeleton/>}
          slots={{
            leftArrowIcon: KeyboardArrowLeftRounded,
            rightArrowIcon: KeyboardArrowRightRounded,
            day: Day as any,
          }}
          slotProps={{
            day: ownerState =>
              ({
                start: startDate,
                end: endDate,
              } as any),
          }}
          onChange={(newValue: any) => {
            if (startDate && endDate) {
              handleStartChange(newValue)
              handleEndChange(null)
              return
            }

            if (!startDate) {
              handleStartChange(newValue)
              return
            }

            if (startDate && !endDate) {
              if (newValue.isBefore(startDate) || newValue.isSame(startDate)) {
                handleStartChange(newValue)
                return
              }

              handleEndChange(newValue)
            }
          }}
        />
      </Box>
    </LocalizationProvider>
  )
}

const StyledCalendar = styled(DateCalendar)({
  width: "100%",
  height: "100%",
  maxHeight: "100%",
  borderRadius: "12px",
  padding: "0.75rem",
  boxSizing: "border-box",
  "&.MuiDateCalendar-root": {
    padding: "1rem",
    gap: "1rem",
  },
  "& .MuiPickersFadeTransitionGroup-root": {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#585C65",
  },
  "& .MuiPickersArrowSwitcher-root": {
    gap: "0.75rem",
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
  },
  "& .MuiPickersArrowSwitcher-spacer": {
    width: "16px",
  },
  "& .MuiPickersArrowSwitcher-button": {
    padding: "12px",
    borderRadius: "12px",
    border: '1px solid #E9E9EB',
    backgroundColor: "#F5F5F5",
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiDayCalendar-slideTransition": {
    minHeight: "260px",
  },
  "& .MuiPickersCalendarHeader-root": {
    padding: "0px",
  },
  "& .MuiPickersDay-root.Mui-selected": {
    fontWeight: "600",
  },
  "& .MuiPickersDay-today": {
    border: "none !important",
  },
  "& .MuiDayCalendar-header": {
    width: "100%",
    justifyContent: "space-around",
  },
  "& .MuiDayCalendar-weekContainer": {
    justifyContent: "space-between",
  },
})

interface CustomPickerDayProps extends PickersDayProps<Moment> {
  start: Moment | null;
  end: Moment | null;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop => prop !== 'start' && prop !== 'end',
})<CustomPickerDayProps>(({ day, start, end }) => ({
  height: '40px',
  borderRadius: 0,
  width: '100%',
  backgroundColor: 'transparent !important',
  "&:hover, &:focus, &:visited": {
    background: day.isSame(start) || day.isSame(end) ? (`url(${BlueCircle as any}) center no-repeat linear-gradient(${day.isSame(start) ? '270' : '90'}deg, #EBEBEB 50%, transparent 50%) !important`) : `url(${GrayCircle as any}) center no-repeat`,
  },
  "&:visited": {
    backgroundColor: 'transparent',
  },
  "&.Mui-selected": {
    backgroundColor: '#3872E1 !important',
    color: '#ffffff !important',
  },
  ...(start && day.isSame(start) && {
    background: `url(${BlueCircle as any}) center no-repeat` + (end ? `, linear-gradient(270deg, #EBEBEB 50%, transparent 50%)` : ''),
    backgroundSize: 'object-fit',
    color: '#ffffff',
    transition: '0.1s ease-out',
    "&:hover, &:focus": {
      backgroundColor: 'transparent',
    },
  }),
  ...(end && day.isSame(end) && {
    background: `url(${BlueCircle as any}) center no-repeat` + (start ? `, linear-gradient(90deg, #EBEBEB 50%, transparent 50%)` : ''),
    color: '#ffffff',
    transition: 'background-color 0.1s ease-out',
  }),
  ...(start && end && day.isBetween(start, end) && {
    backgroundColor: '#EBEBEB !important',
    width: '100%',

  }),
})) as React.ComponentType<CustomPickerDayProps>

function Day(
  props: PickersDayProps<Moment> & {
    start: Moment | null;
    end: Moment | null;
  },
) {
  const { day, start, end, ...other } = props

  return (
    <CustomPickersDay
      {...other}
      disableMargin
      day={day}
      start={start}
      end={end}
      sx={{
        "&:hover, &:active": {
          background: day.isSame(start) || day.isSame(end) ? (`url(${BlueCircle as any}) center no-repeat linear-gradient(${day.isSame(start) ? '270' : '90'}deg, #EBEBEB 50%, transparent 50%) !important`) : `url(${GrayCircle as any}) center no-repeat`,
        },
        "&:active": {
          backgroundColor: day.isSame(start) || day.isSame(end) ? 'transparent' : `url(${GrayCircle as any}) center no-repeat`,
        },
      }}
      selected={false}
    />
  )
}

export default LengvaCalendarRangePicker
