/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AccountAdminResponse,
  AccountPublicResponse,
  AccountResponse,
  AccountUpdateRequest,
  ClientCreateRequest,
  ClientResponse,
  PasswordUpdateRequest,
  RoleResponse,
  UpdateAccountInfoRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Account<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags account-user-controller
   * @name UpdateAccountInfo
   * @request PUT:/api/v1/user/account
   */
  updateAccountInfo = (data: UpdateAccountInfoRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/user/account`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-user-controller
   * @name UpdatePassword
   * @request PUT:/api/v1/user/account/password
   */
  updatePassword = (data: PasswordUpdateRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/user/account/password`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name FindClientById
   * @request GET:/api/v1/specialist/account/clients/{id}
   */
  findClientById = (id: number, params: RequestParams = {}) =>
    this.request<ClientResponse, any>({
      path: `/api/v1/specialist/account/clients/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name UpdateClientInfo
   * @request PUT:/api/v1/specialist/account/clients/{id}
   */
  updateClientInfo = (id: number, data: ClientCreateRequest, params: RequestParams = {}) =>
    this.request<ClientResponse, any>({
      path: `/api/v1/specialist/account/clients/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name FindById
   * @summary Find an account by id
   * @request GET:/api/v1/admin/account/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<AccountAdminResponse, any>({
      path: `/api/v1/admin/account/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name Update
   * @summary Update an account
   * @request PUT:/api/v1/admin/account/{id}
   */
  update = (id: number, data: AccountUpdateRequest, params: RequestParams = {}) =>
    this.request<AccountResponse, any>({
      path: `/api/v1/admin/account/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name Delete
   * @summary Delete an account by id
   * @request DELETE:/api/v1/admin/account/{id}
   */
  delete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/admin/account/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name UpdateStrapiAccountId
   * @summary Update Strapi profile accountId field
   * @request PUT:/api/v1/admin/account/strapi/{id}
   */
  updateStrapiAccountId = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/admin/account/strapi/${id}`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name FindAll
   * @summary Find all users
   * @request GET:/api/v1/admin/account
   */
  findAll = (params: RequestParams = {}) =>
    this.request<AccountAdminResponse[], any>({
      path: `/api/v1/admin/account`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name Create
   * @summary Create an account
   * @request POST:/api/v1/admin/account
   */
  create = (data: AccountUpdateRequest, params: RequestParams = {}) =>
    this.request<AccountResponse, any>({
      path: `/api/v1/admin/account`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-user-controller
   * @name FindAllSpecialistsByPatient
   * @request GET:/api/v1/user/account/specialists
   */
  findAllSpecialistsByPatient = (params: RequestParams = {}) =>
    this.request<AccountPublicResponse[], any>({
      path: `/api/v1/user/account/specialists`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name FindAllClientsBySpecialist
   * @request GET:/api/v1/specialist/account/clients
   */
  findAllClientsBySpecialist = (params: RequestParams = {}) =>
    this.request<ClientResponse[], any>({
      path: `/api/v1/specialist/account/clients`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name SearchClients
   * @request GET:/api/v1/specialist/account/clients/search
   */
  searchClients = (
    query: {
      searchString: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ClientResponse[], any>({
      path: `/api/v1/specialist/account/clients/search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name CountNewClientsFromTheLastMonth
   * @request GET:/api/v1/specialist/account/clients/last-month
   */
  countNewClientsFromTheLastMonth = (params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v1/specialist/account/clients/last-month`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-public-controller
   * @name FindSpecialistById
   * @summary Find a specialist by id
   * @request GET:/api/v1/public/account/{id}
   */
  findSpecialistById = (id: number, params: RequestParams = {}) =>
    this.request<AccountPublicResponse, any>({
      path: `/api/v1/public/account/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-public-controller
   * @name FindAllSpecialists
   * @request GET:/api/v1/public/account/specialists
   */
  findAllSpecialists = (params: RequestParams = {}) =>
    this.request<AccountPublicResponse[], any>({
      path: `/api/v1/public/account/specialists`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account
   * @name GetAllRoles
   * @summary Get all roles
   * @request GET:/api/v1/admin/account/roles
   */
  getAllRoles = (params: RequestParams = {}) =>
    this.request<RoleResponse[], any>({
      path: `/api/v1/admin/account/roles`,
      method: "GET",
      ...params,
    });
}
