import { Box, Typography, styled, useMediaQuery } from "@mui/material"
import { type Locations } from "_api/Locations"
import { type LocationCreateRequest, type LocationResponse, type LocationUpdateRequest } from "_api/data-contracts"
import EditIcon from '_assets/edit-black.svg'
import PlusIcon from '_assets/plus.svg'
import TrashIcon from '_assets/trash.svg'
import LengvaButton from "_components/LengvaButton"
import LengvaDeleteConfirm from "_components/LengvaDeleteConfirm"
import LengvaIcon from "_components/LengvaIcon"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import { DashboardContainer, DashboardTitleContainer } from "_pages/admin/SpecialistDashboard"
import LocationDialog from "_pages/admin/locations/LocationDialog"
import { type LocationFormValues } from "_pages/admin/locations/LocationForm"
import { createApi } from "_utils/ApiCreator"
import { MeetingProvider, getLocationName, itemColorArray, locationIconMap } from "_utils/ObjectUtils"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

const LocationPage = () => {
  const [locations, setLocations] = useState<LocationResponse[]>([])
  const [activeLocation, setActiveLocation] = useState<LocationResponse | null>(null)
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { t } = useTranslation('translation', { keyPrefix: 'LocationPage' })
  const isMobile = useMediaQuery('(max-width: 600px)')
  const locationAPI = useRef(createApi('location') as Locations)

  const getLocations = async () => {
    setIsLoading(true)
    const response = await locationAPI.current.findAllMyLocations()
    setLocations(response)
    setIsLoading(false)
  }

  useEffect(() => {
    getLocations()
  }, [])

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true)
  }

  const handleAddDialogClose = () => {
    setAddDialogOpen(false)
  }

  const handleEdit = (location: LocationResponse) => {
    setActiveLocation(location)
    setEditDialogOpen(true)
  }

  const handleEditDialogClose = () => {
    setActiveLocation(null)
    setEditDialogOpen(false)
  }

  const handleDeleteDialogOpen = (location: LocationResponse) => {
    setActiveLocation(location)
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  const handleDelete = async (location: LocationResponse) => {
    await locationAPI.current.delete(location.id)
    setActiveLocation(null)
    getLocations()
  }

  const handleSubmit = async (values: LocationFormValues) => {
    if (values.meetingProvider === "") {
      return
    }

    if (activeLocation) {
      const locationUpdateRequests: LocationUpdateRequest = {
        id: activeLocation.id,
        name: values.name,
        defaultAddress: values.meetingProvider === MeetingProvider.IN_PERSON || values.meetingProvider === MeetingProvider.CALL ? values.defaultAddress : "",
        meetingProvider: values.meetingProvider,
      }

      await locationAPI.current.update(activeLocation.id, locationUpdateRequests)
      handleEditDialogClose()
      getLocations()
      return
    }

    const isInPersonOrCall = values.meetingProvider === MeetingProvider.IN_PERSON || values.meetingProvider === MeetingProvider.CALL

    const locationCreateRequests: LocationCreateRequest = {
      name: isInPersonOrCall ? values.name : "",
      defaultAddress: isInPersonOrCall ? values.defaultAddress : "",
      meetingProvider: values.meetingProvider,
    }

    await locationAPI.current.create(locationCreateRequests)
    handleAddDialogClose()
    getLocations()
  }

  return (
    <DashboardContainer
      sx={{
        paddingTop: '0',
      }}
    >

      <DashboardTitleContainer
        sx={{
          flexWrap: 'wrap',
          marginBottom: '2rem',
          gap: '1rem',
        }}
      >
        <Typography variant='h2'>
          {t("Locations")}
        </Typography>
        <Box
          display='flex'
          gap='1rem'
          flexWrap='wrap'
        >
          <LengvaButton
            title={isMobile ? t('New') : t('New location')}
            color='primary'
            icon={PlusIcon}
            onClick={handleAddDialogOpen}
          />

        </Box>
      </DashboardTitleContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          padding: isMobile ? '0px' : '1.5rem',
          boxSizing: 'border-box',
        }}
      >
        {!isLoading && locations.map((location, index) => (
          <LocationItem
            key={location.id}
            location={location}
            sideColor={itemColorArray[index % itemColorArray.length]}
            handleEdit={handleEdit}
            handleDelete={handleDeleteDialogOpen}
          />
        ))}
        {isLoading && <LengvaLoadingSpinner/>}
      </Box>
      {activeLocation && (
        <LocationDialog
          open={editDialogOpen}
          location={activeLocation}
          handleClose={handleEditDialogClose}
          handleSubmit={handleSubmit}
          handleDelete={() => handleDeleteDialogOpen(activeLocation)}
          existingLocations={locations.filter(location => location.id !== activeLocation.id)}
        />
      )}
      <LocationDialog
        open={addDialogOpen}
        handleSubmit={handleSubmit}
        handleClose={handleAddDialogClose}
        existingLocations={locations}
      />
      {activeLocation && (

        <LengvaDeleteConfirm
          open={deleteDialogOpen}
          handleClose={handleDeleteDialogClose}
          handleDelete={async () => handleDelete(activeLocation)}
          objectName='Location'
        />
      )}
    </DashboardContainer>
  )
}

interface LocationItemProps {
  sideColor: string;
  location: LocationResponse;
  handleEdit: (location: LocationResponse) => void;
  handleDelete: (location: LocationResponse) => void;
}

const LocationItem = ({location, sideColor, handleEdit, handleDelete}: LocationItemProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LocationPage' })
  const isMobile = useMediaQuery('(max-width: 600px)')
  return (
    <LocationItemContainer
      sideColor={sideColor}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >

        <img
          src={locationIconMap.get(location.meetingProvider)}
          style={{
            height: '1.5rem',
            width: '1.5rem',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
          }}
        >
          <Typography variant='h4'>
            {getLocationName(t, location)}
          </Typography>
          {location.defaultAddress && (
            <Typography variant='body'>
              {location.defaultAddress}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: '0 1 96px',
          gap: '1rem',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
        }}
      >
        <LengvaIcon
          icon={EditIcon}
          onClick={() => handleEdit(location)}
        />
        <LengvaIcon
          icon={TrashIcon}
          onClick={() => handleDelete(location)}
        />
      </Box>

    </LocationItemContainer>
  )
}

const LocationItemContainer = styled(Box)(({sideColor}: {sideColor: string}) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isMobile ? '0.75rem 1rem' : '1.5rem 2rem',
    paddingLeft: '2rem',
    boxSizing: 'border-box',
    borderRadius: '0.75rem',
    width: '100%',
    backgroundImage: `linear-gradient(90deg, ${sideColor} 12px, #FFFFFF 12px)`,
    border: '1px solid #E9E9EB',
    overflowX: 'auto',
    gap: '1rem',
  }
})

export default LocationPage
