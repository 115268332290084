import { Box } from "@mui/material"
import { type AppointmentResponse } from "_api/data-contracts"
import { WeekHeader, type CalendarEvent, CalendarViewSelector } from "_pages/admin/calendar/CalendarPage"
import moment from "moment"
import { useEffect, useMemo, useRef, useState } from "react"
import { Calendar, Views, momentLocalizer, type View } from "react-big-calendar"
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import '../_pages/admin/calendar/CalendarPage.css'

interface LengvaCompactCalendarProps {
  appointments: AppointmentResponse[];
}

const LengvaCompactCalendar = ({appointments}: LengvaCompactCalendarProps) => {
  const [myEvents, setMyEvents] = useState<CalendarEvent[]>([])
  const [selectedView, setSelectedView] = useState<View>('day')
  const [selectedDate, setSelectedDate] = useState<Date >(moment().toDate())

  useEffect(() => {
    mapAppointmentsToEvents(appointments)
  }, [appointments])

  const mapAppointmentsToEvents = (appointments: AppointmentResponse[]) => {
    const events: CalendarEvent[] = appointments.map(appointment => ({
      id: appointment.id,
      title: appointment.patient.name ?? "Pacientas",
      start: moment(appointment.startDate).toDate(),
      end: moment(appointment.endDate).toDate(),
      isResizable: true,
      isDraggable: true,
    }))

    setMyEvents(events)
  }

  moment.locale('lt', {
    week: {
      dow: 1,
    },
  })
  moment.locale('lt')
  const localizer = momentLocalizer(moment)
  const calendarRef = useRef(null)
  const DnDCalendar = withDragAndDrop(Calendar)

  const { components, defaultDate, views, formats } = useMemo(
    () => ({
      components: {
        week: {
          header: WeekHeader,
        },
        eventWrapper(props: any) {
          // Create clone of the component but with the new props
          const newProps = {
            ...props,
            style: {
              ...props.style,
            },
            children: {
              ...props.children,
              props: {
                ...props.children.props,
                style: {
                  ...props.children.props.style,
                },
              },
            },

          }
          return (
            <div {...newProps}>
              {newProps.children}
            </div>
          )
        },
      },
      defaultDate: new Date(),
      views: (Object.keys(Views) as Array<keyof typeof Views>).map(k => Views[k]),
      formats: {
        timeGutterFormat: (date: any, culture: any, localizer: any) =>
          localizer.format(date, 'HH:mm', culture),
      },
    }),
    [],
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        overflow: "auto",
        height: "100%",
      }}
    >
      <CalendarViewSelector
        selectedView={selectedView}
        setSelectedView={setSelectedView}

      />
      <Box
        sx={{
          minHeight: "150%",
        }}
      >
        <DnDCalendar
          ref={calendarRef}
          popup
          showMultiDayTimes
          resizable
          selectable
          style={{
            width: "100%",
          }}
          draggableAccessor={(event: any) => (event).isDraggable}
          defaultView='week'
          localizer={localizer}
          components={components}
          toolbar={false}
          defaultDate={defaultDate}
          events={myEvents}
          formats={formats}
          date={selectedDate}
          view={selectedView}
          min={moment(selectedDate).hours(9).minutes(0).toDate()}
          max={moment(selectedDate).hours(18).minutes(0).toDate()}
          // eventPropGetter={eventPropGetter}
          // dayPropGetter={dayPropGetter}
          step={15}
          views={views}
          timeslots={4}
        />
      </Box>
    </Box>
  )
}

export default LengvaCompactCalendar
