import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, MenuItem, Typography, styled, useMediaQuery } from "@mui/material"
import AppTheme from "AppTheme"
import { type Locations } from "_api/Locations"
import { type LocationResponse } from "_api/data-contracts"
import CloseXCircleIcon from "_assets/closeXCircle.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaIcon from "_components/LengvaIcon"
import LengvaInput from "_components/LengvaInput"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import { type SelectOption } from "_components/LengvaSelect"
import { DialogDrawer, StyledMenu } from "_pages/admin/schedule/AddScheduleDialog"
import { createApi } from "_utils/ApiCreator"
import { MeetingProvider, getLocationName, locationIconMap } from "_utils/ObjectUtils"
import { FieldArray, Form, Formik, type FormikHelpers, type FormikProps } from "formik"
import { type TFunction } from "i18next"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from 'yup'

interface Props {
  values?: ScheduleDetailsFormValues;
  hideTitle?: boolean;
  handleSubmit: (values: ScheduleDetailsFormValues, formikHelpers: FormikHelpers<ScheduleDetailsFormValues>) => void;
  handleReset?: (formik?: FormikProps<ScheduleDetailsFormValues>) => void;
  submitRef?: any;
  resetRef?: any;
}

const ScheduleDetailsForm = ({values, handleSubmit, handleReset, resetRef, hideTitle, submitRef}: Props) => {
  const [locationDrawerOpen, setLocationDrawerOpen] = useState<LocationState>(initialLocationState)
  const [locationCollapseExpanded, setLocationCollapseExpanded] = useState<LocationState>(initialLocationState)
  const [locations, setLocations] = useState<LocationResponse[]>([])
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([])
  const [selectDrawerOpen, setSelectDrawerOpen] = useState<boolean>(false)
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const menuOpen = Boolean(anchorElement)

  const locationAPI = useRef(createApi('location') as Locations)
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('translation', {keyPrefix: 'ScheduleDetailsForm'})

  const getLocations = async () => {
    setIsLoading(true)
    const response = await locationAPI.current.findAllMyLocations()
    setLocations(response)
    const options: SelectOption[] = response.map(location => ({
      value: location.meetingProvider,
      value2: location.id,
      label: getLocationName(t, location),
      icon: locationIconMap.get(location.meetingProvider),
    }))
    setLocationOptions(options)
    setIsLoading(false)
  }

  useEffect(() => {
    getLocations()
  }, [])

  const toggleLocationDrawer = (index: number, forceOpen?: boolean) => {
    setLocationDrawerOpen({
      open: forceOpen ?? !locationDrawerOpen.open,
      index,
    })
  }

  const handleCloseLocationDrawer = () => {
    setLocationDrawerOpen({
      open: false,
      index: null,
    })
  }

  const toggleSelectDrawer = () => {
    setSelectDrawerOpen(!selectDrawerOpen)
  }

  const toggleLocationCollapse = (index: number, forceOpen?: boolean) => {
    if (locationCollapseExpanded.open && locationCollapseExpanded.index === index) {
      setLocationCollapseExpanded({
        open: forceOpen ?? !locationCollapseExpanded.open,
        index,
      })
    } else {
      setLocationCollapseExpanded({
        open: true,
        index,
      })
    }
  }

  const handleLocationClick = (event: React.MouseEvent<HTMLElement>, locationIndex: number) => {
    if (isMobile) {
      setSelectDrawerOpen(true)
      return
    }

    if (anchorElement) {
      setAnchorElement(null)
    }

    if (!anchorElement) {
      setAnchorElement(event.currentTarget)
    }
  }

  const handleLocationChoose = (selectedLocation: SelectOption, formik: FormikProps<ScheduleDetailsFormValues>) => {
    setAnchorElement(null)

    if (selectedLocation !== undefined) {
      const locationsValues = formik.values.locations
      const location = locations.find(location => location.id === selectedLocation.value2)

      const newLocation = {
        id: selectedLocation.value2,
        type: selectedLocation.value,
        address: location?.defaultAddress,
        locationName: location?.name,
      }

      locationsValues.push(newLocation)
      const index = locationsValues.length - 1
      formik.setFieldValue('locations', locationsValues)

      if (selectedLocation.value === MeetingProvider.IN_PERSON) {
        setTimeout(() => {
          if (isMobile) {
            toggleLocationDrawer(index)
          } else {
            toggleLocationCollapse(index, true)
          }
        }, 200)
      }
    }
  }

  if (isLoading) {
    return <LengvaLoadingSpinner/>
  }

  return (
    <Formik
      enableReinitialize={false}
      initialValues={values ?? initialValues}
      validationSchema={getValidationSchema(t)}
      onSubmit={handleSubmit}
      onReset={formik => {
        setLocationCollapseExpanded(initialLocationState)
        setLocationDrawerOpen(initialLocationState)
        formik.locations = []
      }}
    >
      {(formik: FormikProps<ScheduleDetailsFormValues>) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {!hideTitle && (

            <Typography
              variant='h5'
              color='text.secondary'
              sx={{
                paddingBottom: "1rem",
              }}
            >
              {t('Add schedule details')}
            </Typography>

          )}
          <LengvaInput label='scheduleName' title={t('Schedule name')}/>
          <LengvaInput multiline minRows={8} label='description' title={t('Description')}/>
          <Box sx={{padding: "16px 0", borderTop: "1px solid #EBEBEB"}}>
            <Typography
              variant='h6'
              color='text.tertiary'
              sx={{
                paddingBottom: "1rem",
              }}
            >
              {t('Locations')}
            </Typography>
            <FieldArray
              name='locations'
              render={(arrayHelpers: any) => (
                <Box sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "16px",
                  alignSelf: "stretch",
                  flexWrap: "wrap",
                }}
                >
                  {formik.values.locations.length > 0 && formik.values.locations.map((location: LocationType, index: number) => (
                    <LocationContainer
                      key={location.id ?? index}
                      sx={{
                        flexDirection: "column",
                        paddingBottom: location?.type === MeetingProvider.IN_PERSON
                          && locationCollapseExpanded.open
                          && locationCollapseExpanded.index === index
                          && !isMobile ? "16px" : "9px",
                      }}
                    >
                      <StyledAccordion
                        expanded={locationCollapseExpanded.open
                          && locationCollapseExpanded.index === index
                          && !isMobile && location?.type === MeetingProvider.IN_PERSON}
                        onClick={(event: any) => {
                          if (!location?.type) {
                            handleLocationClick(event, index)
                          }

                          if (location?.type === MeetingProvider.IN_PERSON) {
                            toggleLocationDrawer(index)
                          }
                        }}
                      >
                        <StyledAccordionSummary
                          onClick={() => {
                            toggleLocationCollapse(index)
                          }}
                        >
                          <img src={locationOptions.find(option => option.value === location?.type)?.icon ?? ""} alt=''/>
                          <Typography variant='h5' color={AppTheme.palette.text.secondary}>
                            {getLocationName(t, locations.find(locationResponse => locationResponse.id === location.id)) }
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}
                          >
                            {location?.type && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <LengvaIcon
                                  variant='v3'
                                  icon={CloseXCircleIcon}
                                  onClick={() => {
                                    if (location?.type === MeetingProvider.IN_PERSON) {
                                      handleCloseLocationDrawer()
                                    }

                                    arrayHelpers.remove(index)
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        </StyledAccordionSummary>
                        {!isMobile && (
                          <AccordionDetails
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "1rem",
                              padding: "0px",
                            }}
                          >
                            {location?.type === MeetingProvider.IN_PERSON && (
                              <>
                                <LengvaInput
                                  disabled
                                  label={`locations[${index}].locationName`}
                                  title={t('Name')}
                                />
                                <LengvaInput
                                  disabled
                                  label={`locations[${index}].address`}
                                  title={t('Address')}
                                />
                              </>
                            )}
                            {/* {(location?.type === MeetingProvider.GOOGLE_MEET || location?.type === MeetingProvider.ZOOM) && (
                              <LengvaInput
                                disabled
                                label={`locations[${index}].meetingLink`}
                                title={t('Link')}
                              />
                            )} */}
                          </AccordionDetails>
                        )}
                      </StyledAccordion>
                      {isMobile && (
                        <DialogDrawer
                          drawerOpen={locationDrawerOpen.open && locationDrawerOpen.index === index}
                          onClose={() => handleCloseLocationDrawer()}
                          onOpen={() => toggleLocationDrawer(index)}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: '1.5rem',
                            }}
                          >
                            {location?.type === MeetingProvider.IN_PERSON && (
                              <>
                                <LengvaInput
                                  disabled
                                  label={`locations[${index}].locationName`}
                                  title={t('Name')}
                                />
                                <LengvaInput
                                  disabled
                                  label={`locations[${index}].address`}
                                  title={t('Address')}
                                />
                                <LengvaButton
                                  fullWidth
                                  disabled={!formik.values.locations[index].address || !formik.values.locations[index].locationName}
                                  title={t('Add')}
                                  onClick={() => {
                                    toggleLocationDrawer(index)
                                  }}
                                />
                              </>
                            )}
                            {/* {(location?.type === MeetingProvider.GOOGLE_MEET || location?.type === MeetingProvider.ZOOM) && (
                              <>
                                <LengvaInput
                                  label={`locations[${index}].meetingLink`}
                                  title={t('Link')}
                                />
                                <LengvaButton
                                  fullWidth
                                  disabled={!formik.values.locations[index].meetingLink}
                                  title={t('Add')}
                                  onClick={() => {
                                    toggleLocationDrawer(index)
                                  }}
                                />
                              </>
                            )} */}
                          </Box>
                        </DialogDrawer>
                      )}
                    </LocationContainer>
                  ))}
                  {formik.values.locations.length > 0 && (
                    <LocationContainer
                      key={formik.values.locations.length}
                      onClick={event => handleLocationClick(event, formik.values.locations.length)}
                    >
                      <Typography variant='h5' color='text.secondary'>
                        {`${t('Location')} ${formik.values.locations.length + 1} (${t('Optional')})`}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto"}}>
                        <Typography variant='button' color='primary.main' lineHeight='37px'> + {t('Add')}</Typography>
                      </Box>
                    </LocationContainer>
                  )}
                  {formik.values.locations.length === 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: '0.5rem',
                        width: "100%",
                      }}
                    >
                      <LocationContainer
                        key={0}
                        onClick={event => (handleLocationClick(event, 0))}
                      >
                        <Typography variant='h5' color='text.secondary'>{t('Location')} 1</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto"}}>
                          <Typography variant='button' color='primary.main' lineHeight='37px'> + {t('Add')}</Typography>
                        </Box>
                      </LocationContainer>
                      {formik.getFieldMeta('locations').touched && formik.getFieldMeta('locations').error && (
                        <Typography variant='h6' color='#EB0E0E'>
                          {formik.getFieldMeta('locations').error}
                        </Typography>
                      )}
                    </Box>
                  )}
                  {!isMobile && (
                    <StyledMenu
                      id='demo-customized-menu'
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorElement}
                      open={menuOpen}
                      onClose={() => setAnchorElement(null)}
                    >
                      {locationOptions.map(option => (
                        <MenuItem
                          key={option.value}
                          disableRipple
                          onClick={() => {
                            handleLocationChoose(option, formik)
                          }}
                        >
                          <img src={option.icon} alt='' width='27px' height='27px'/>
                          {option.label}
                        </MenuItem>
                      ))}
                    </StyledMenu>
                  )}
                  {isMobile && (
                    <DialogDrawer
                      drawerOpen={selectDrawerOpen}
                      onClose={toggleSelectDrawer}
                      onOpen={toggleSelectDrawer}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant='h3'>{t('Add location')}</Typography>
                        <LengvaIcon
                          variant='v3'
                          icon={CloseXCircleIcon}
                          onClick={toggleSelectDrawer}
                        />
                      </Box>
                      {locationOptions.map((option, index) => (
                        <Box
                          key={option.value}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "12px",
                          }}
                        >
                          <MenuItem
                            key={option.value}
                            disableRipple
                            sx={{
                              marginTop: "12px",
                            }} onClick={() => {
                              handleLocationChoose(option, formik)
                              toggleSelectDrawer()
                            }}
                          >
                            <img src={option.icon} alt='' width='27px' height='27px'/>
                            {option.label}
                          </MenuItem>
                          {(index !== locationOptions.length - 1) && (
                            <Divider
                              sx={{
                                width: "100%",
                                margin: '0px !important',
                              }}
                            />
                          )}
                        </Box>
                      ))}
                    </DialogDrawer>
                  )}
                </Box>
              )}
            />
          </Box>
          <button ref={submitRef} type='submit' style={{display: 'none'}}/>
          <button
            ref={resetRef}
            type='reset'
            style={{display: 'none'}}
            onClick={() => {
              formik.setFieldValue('locations', [], false)
              handleReset?.(formik)
              formik.resetForm()
              formik.handleReset()
            }}/>
        </Form>
      )}
    </Formik>
  )
}

export interface LocationType {
  id: number;
  type: MeetingProvider;
  locationName?: string;
  address?: string;
  meetingLink?: string;
}

export interface LocationState {
  open: boolean;
  index: number | null;
}

export interface ScheduleDetailsFormValues {
  scheduleName: string;
  description: string;
  locations: LocationType[];
}

const initialLocationState: LocationState = {
  open: false,
  index: null,
}

const initialValues: ScheduleDetailsFormValues = {
  scheduleName: '',
  description: '',
  locations: [],
}

const getValidationSchema = (t: TFunction) => yup.object({
  scheduleName: yup.string().required(t('ValidationErrors.ScheduleNameRequired')),
  description: yup.string(),
  locations: yup.array().min(1, t('ValidationErrors.MinOneLocation')),
})

export const LocationContainer = styled(Box)({
  display: "flex",
  padding: "9px 16px",
  gap: "12px",
  flexGrow: 1,
  justifyContent: "space-between",
  alignItems: "center",
  flex: "1 1 auto",
  borderRadius: "12px",
  border: "1px solid #E9E9EB",
  "&: hover": {
    background: "#E9E9EB",
    cursor: "pointer",
    transition: "0.3s",
  },
})

const StyledAccordion = styled(Accordion)({
  display: "flex",
  padding: "0px",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: "transparent",
  boxShadow: "none",
  "& .MuiCollapse-root": {
    width: "100%",
  },
  "& .MuiAccordionSummary-root": {
    minHeight: "0px !important",
  },
})

const StyledAccordionSummary = styled(AccordionSummary)({
  "& .MuiAccordionSummary-content": {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    width: "100%",
    margin: "0px !important",
  },
  width: "100%",
  padding: "0px",
})

export default ScheduleDetailsForm
