import { Box, Checkbox, Divider, Typography } from "@mui/material"
import { type Auth } from "_api/Auth"
import { type Locations } from "_api/Locations"
import { type Schedules } from "_api/Schedules"
import { type ServiceDescriptions } from "_api/ServiceDescriptions"
import { type Services } from "_api/Services"
import { type LocationCreateRequest, type MeResponse, type RegisterConfirmationRequest, type ScheduleCreateRequest, type ScheduleEntryCreateRequest, type ServiceCreateRequest, type ServiceDescriptionResponse } from "_api/data-contracts"
import ArrowNarrowLeft from "_assets/arrow-narrow-left.svg"
import ArrowNarrowRight from "_assets/arrow-narrow-right.svg"
import LengvaLogo from "_assets/lengva-logo.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaInput from "_components/LengvaInput"
import LengvaSelect from "_components/LengvaSelect"
import LengvaStepper from "_components/LengvaStepper"
import LengvaTimePicker from "_components/LengvaTimePicker"
import { FormContainer, RegisterPageContainer, RegisterPageInnerContainer } from "_pages/public/auth/RegisterLoginPage"
import useStore from "_state/useStore"
import { createApi } from "_utils/ApiCreator"
import AutoSubmitToken from "_utils/AutoSubmitToken"
import { MeetingProvider, WeekDayEnum, locationMeetingProviderSelectOptions } from "_utils/ObjectUtils"
import { Formik, type FormikProps } from "formik"
import { type TFunction } from "i18next"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"

const SpecialistConfirmRegistrationPage = () => {
  const {getMe, account} = useStore()
  const [stage, setStage] = useState<number>(0)
  const [weekDayChecked, setWeekDayChecked] = useState<WeekDay[]>(weekDays)
  const [serviceDescriptions, setServiceDescriptions] = useState<ServiceDescriptionResponse[]>([])

  const authAPI = useRef(createApi("auth") as Auth)
  const serviceDescriptionAPI = useRef(createApi("serviceDescription") as ServiceDescriptions)
  const serviceAPI = useRef(createApi("service") as Services)
  const locationAPI = useRef(createApi("location") as Locations)
  const scheduleAPI = useRef(createApi("schedule") as Schedules)
  const { t } = useTranslation("translation", { keyPrefix: "SpecialistConfirmRegistrationPage" })
  const steps = [t('YourInformation'), t('AddYourFirstService')]
  const navigate = useNavigate()

  useEffect(() => {
    getServiceDescriptions()
  }, [])

  const getServiceDescriptions = async () => {
    const response = await serviceDescriptionAPI.current.findAll()
    setServiceDescriptions(response)
  }

  const setNextStage = () => {
    if (stage !== steps.length - 1) {
      setStage(stage + 1)
    }
  }

  const setPreviousStage = () => {
    if (stage !== 0) {
      setStage(stage - 1)
    }
  }

  const setWeekDayCheckedValue = (value: number, formik: any) => {
    const newWeekDayChecked = weekDayChecked.map(day => {
      if (day.value === value) {
        return {
          ...day,
          isChecked: !day.isChecked,
        }
      }

      return day
    })

    setWeekDayChecked(newWeekDayChecked)
    formik.setFieldValue("availableDays", newWeekDayChecked.filter(day => day.isChecked).map(day => day.value))
  }

  const handleSubmit = async (values: FormValues) => {
    if (stage === 0) {
      setNextStage()
      return
    }

    const confirmationRequest: RegisterConfirmationRequest = {
      name: values.name,
      lastName: values.lastName,
      phoneNumber: `${values.phoneNumberPrefix}${values.phoneNumber}`,
    }

    if (!values.duration || !values.meetingProvider) {
      return
    }

    const durationInMinutes = (values.duration.getHours() * 60) + values.duration.getMinutes()

    const createServiceRequest: ServiceCreateRequest = {
      price: Number(values.price),
      duration: durationInMinutes,
      serviceDescriptionId: Number(values.serviceType),
    }

    await serviceAPI.current.create(createServiceRequest)

    const locationCreateRequest: LocationCreateRequest = {
      name: values.locationName,
      defaultAddress: values.defaultAddress,
      meetingProvider: values.meetingProvider,
    }

    await locationAPI.current.create(locationCreateRequest)

    await authAPI.current.registerConfirmation(confirmationRequest)
    await getMe()
    window.location.href = "/admin"
  }

  const serviceDescriptionOptions = getServiceDescriptionOptions(serviceDescriptions, t)

  return (
    <RegisterPageContainer
      sx={{
        paddingBottom: "6rem",
      }}
    >
      <RegisterPageInnerContainer>
        <img
          style={{
            maxWidth: "162px",
          }}
          src={LengvaLogo as any}
          alt='icon'
        />
        <LengvaStepper
          steps={steps}
          activeStep={stage}
        />
        <Formik
          initialValues={getInitialValues(account)}
          validationSchema={validationSchemaArray(t)[stage]}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<any>) => (
            <>
              {stage === 0 && (
                <FormContainer>
                  <LengvaInput title={t('Name')} label='name'/>
                  <LengvaInput title={t('Lastname')} label='lastName'/>
                  <Box
                    width='100%'
                    display='flex'
                    gap='1rem'
                    sx={{alignItems: 'end'}}
                  >
                    <Box
                      width='100%'
                      maxWidth={148}
                    >
                      <LengvaSelect
                        label='phoneNumberPrefix'
                        title={t('PhoneNumber')}
                        optionsMain={phoneNumberCodes}
                      />
                    </Box>
                    <LengvaInput label='phoneNumber'/>
                  </Box>
                  {/* TODO: Enable when SMS confirmation is implemented */}
                  {/* <LengvaButton
                    sx={{
                      width: "100%",
                    }}
                    title={t('ConfirmWithSMS')}
                  /> */}
                </FormContainer>
              )}
              {stage === 1 && (
                <FormContainer>
                  <Typography variant='h5' fontSize={18} alignSelf='flex-start'>
                    {t('WhatServiceDoYouWantToProvide')}
                  </Typography>
                  <LengvaSelect
                    title={t('ServiceType')}
                    label='serviceType'
                    optionsMain={serviceDescriptionOptions}
                  />
                  <Box
                    display='flex'
                    gap='1rem'
                    width='100%'
                    sx={{alignItems: 'end'}}
                  >
                    <LengvaInput title={t('Price')} label='price'/>
                    <LengvaTimePicker
                      title={t('Duration')}
                      label='duration'
                    />
                  </Box>
                  <Divider
                    sx={{
                      borderWidth: "1px",
                      width: "100%",
                      borderColor: "#E9E9EB",
                    }}
                  />
                  <Typography variant='h5' fontSize={18} alignSelf='flex-start'>
                    {t('WhereWillYouProvideThisService')}
                  </Typography>
                  <LengvaSelect
                    label='meetingProvider'
                    title={t('MeetingProvider')}
                    optionsMain={locationMeetingProviderSelectOptions}
                  />
                  <AutoSubmitToken
                    dependantValue={formik.values.meetingProvider}
                    handleSubmit={() => {
                      if (formik.values.meetingProvider === MeetingProvider.CALL) {
                        formik.setFieldValue("defaultAddress", `${formik.values.phoneNumberPrefix}${formik.values.phoneNumber}`)
                      } else {
                        formik.setFieldValue("defaultAddress", "")
                        formik.setFieldValue("locationName", "")
                      }
                    }}
                  />
                  {formik.values.meetingProvider === MeetingProvider.IN_PERSON && (
                    <LengvaInput
                      title={t('LocationName')}
                      label='locationName'
                    />
                  )}
                  {(formik.values.meetingProvider === MeetingProvider.IN_PERSON || formik.values.meetingProvider === MeetingProvider.CALL) && (
                    <LengvaInput
                      title={formik.values.meetingProvider === MeetingProvider.IN_PERSON ? t('Address') : t('PhoneNumber')}
                      label='defaultAddress'
                    />
                  )}
                  {/* <Divider
                    sx={{
                      borderWidth: "1px",
                      width: "100%",
                      borderColor: "#E9E9EB",
                    }}
                  />
                  <Typography variant='h5' fontSize={18} alignSelf='flex-start'>
                    {t('WhenCanYouProvideThisService')}
                  </Typography>
                  <Box
                    display='flex'
                    gap='1rem'
                    alignItems='center'
                    width='100%'
                  >
                    <LengvaTimePicker label='startTime'/>
                    -
                    <LengvaTimePicker label='endTime'/>
                  </Box> */}
                  {/* <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                    border='1px solid #EBEBEB'
                    borderRadius='12px'
                    sx={{
                      overflow: "scroll",
                      minWidth: "100%",
                    }}
                  >
                    {weekDays.map((day, index) => (
                      <Box
                        key={day.value}
                        display='flex'
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='center'
                          padding='0rem 1.5rem 0.75rem 1.5rem'
                          gap='7px'
                          width='44px'
                        >
                          <Checkbox
                            checked={weekDayChecked[index].isChecked}
                            onChange={() => setWeekDayCheckedValue(day.value, formik)}
                          />
                          <Typography variant='body'>
                            {day.label}
                          </Typography>
                        </Box>
                        {index !== weekDays.length - 1 && (
                          <Divider
                            flexItem
                            orientation='vertical'
                            sx={{
                              borderWidth: "1px",
                              borderColor: "#E9E9EB",
                              borderRight: "none",
                              height: "61px",
                              margin: "auto",
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                  {formik.errors.availableDays && (
                    <Typography variant='body' alignSelf='flex-start' color='error.main'>
                      {formik.getFieldMeta("availableDays").error}
                    </Typography>
                  )}
                  <Typography variant='body' alignSelf='flex-start' color='text.secondary'>
                    🤗  {t('DontWorryYouCanChangeThisInformationLater')}
                  </Typography> */}
                </FormContainer>
              )}
              {stage === 0 && (
                <Box
                  width='100%'
                  display='flex'
                  justifyContent='flex-end'
                >
                  <LengvaButton
                    title='Toliau'
                    sx={{
                      maxWidth: "116px",
                      gap: "0.5rem",
                    }}
                    endIcon={ArrowNarrowRight}
                    onClick={() => formik.handleSubmit()}
                  />
                </Box>
              )}
              {stage !== 0 && (
                <Box
                  width='100%'
                  display='flex'
                  justifyContent='space-between'
                >
                  <LengvaButton
                    title={t('Back')}
                    color='secondary'
                    sx={{
                      maxWidth: "116px",
                      gap: "0.5rem",
                    }}
                    icon={ArrowNarrowLeft}
                    onClick={setPreviousStage}
                  />
                  <LengvaButton
                    title={t('CreateAccount')}
                    onClick={() => formik.handleSubmit()}
                  />
                </Box>
              )}
            </>
          )}
        </Formik>
      </RegisterPageInnerContainer>
    </RegisterPageContainer>
  )
}

interface FormValues {
  name: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberPrefix: string;
  serviceType: string;
  price: string;
  duration: Date | null;
  meetingProvider: MeetingProvider | "";
  defaultAddress: string;
  locationName: string;
  startTime: Date | null;
  endTime: Date | null;
  availableDays: number[];
}

const getInitialValues = (account: MeResponse | undefined): FormValues => ({
  name: account?.name ?? "",
  lastName: account?.lastName ?? "",
  phoneNumberPrefix: account?.phoneNumber ? account?.phoneNumber.slice(0, 4) : "",
  phoneNumber: account?.phoneNumber ? account?.phoneNumber.slice(4) : "",
  serviceType: "",
  price: "",
  duration: null,
  meetingProvider: "",
  defaultAddress: "",
  locationName: "",
  startTime: null,
  endTime: null,
  availableDays: [],
})

export const phoneNumberCodes = [
  {
    value: "+370",
    label: "+370",
  },
]

const validationScemaOne = (t: TFunction) => yup.object({
  name: yup.string().required(t("ValidationErrors.RequiredField")),
  lastName: yup.string().required(t("ValidationErrors.RequiredField")),
  phoneNumberPrefix: yup.string().required(t("ValidationErrors.RequiredField")).oneOf(phoneNumberCodes.map(code => code.value), t("ValidationErrors.ChoosePhoneCode")),
  phoneNumber: yup.number().required(t("ValidationErrors.RequiredField"))
    .min(10_000_000, t("ValidationErrors.WrongPhoneNumber"))
    .max(99_999_999, t("ValidationErrors.WrongPhoneNumber"))
    .typeError(t("ValidationErrors.WrongPhoneNumber")),
})

const validationSchemaPageTwo = (t: TFunction) => yup.object({
  serviceType: yup.string().required(t("ValidationErrors.RequiredField")),
  price: yup.string().required(t("ValidationErrors.RequiredField")),
  duration: yup.date().required(t("ValidationErrors.RequiredField")),
  meetingProvider: yup.string().required(t("ValidationErrors.RequiredField")),
})

const validationSchemaArray = (t: TFunction) => [
  validationScemaOne(t),
  validationSchemaPageTwo(t),
]

const getServiceDescriptionOptions = (serviceDescriptions: ServiceDescriptionResponse[], t: TFunction) => serviceDescriptions.map(serviceDescription => ({
  value: serviceDescription.id,
  label: t(`Services.${serviceDescription.name}`),
}))

interface WeekDay {
  value: number;
  label: string;
  isChecked: boolean;
}

const weekDays: WeekDay[] = [
  {
    value: 0,
    label: "Mon",
    isChecked: false,
  },
  {
    value: 1,
    label: "Tue",
    isChecked: false,

  },
  {
    value: 2,
    label: "Wed",
    isChecked: false,

  },
  {
    value: 3,
    label: "Thu",
    isChecked: false,

  },
  {
    value: 4,
    label: "Fri",
    isChecked: false,

  },
  {
    value: 5,
    label: "Sat",
    isChecked: false,

  },
  {
    value: 6,
    label: "Sun",
    isChecked: false,

  },

]

export default SpecialistConfirmRegistrationPage
