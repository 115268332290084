import { CloseRounded } from "@mui/icons-material"
import { Box, Dialog, IconButton, Typography, useMediaQuery } from "@mui/material"
import LengvaButton from "_components/LengvaButton"
import { useTranslation } from "react-i18next"

interface Props {
  open: boolean;
  objectName: string;
  handleClose: () => void;
  handleDelete: () => void;
}

const LengvaDeleteConfirm = ({open, objectName, handleClose, handleDelete}: Props) => {
  const { t } = useTranslation('translation', {keyPrefix: 'DeleteConfirm'})
  const isMobile = useMediaQuery('(max-width: 768px)')

  const handleDeleteClick = () => {
    handleDelete()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      maxWidth='md'
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '472px',
          margin: isMobile ? '16px' : 'auto',
          padding: '2rem 1.5rem 1.5rem 1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          borderRadius: '1rem',
        },
      }}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h3'>
          {t('Delete')} {t(objectName)}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseRounded/>
        </IconButton>
      </Box>
      <Typography variant='body'>
        {t('Are you sure you want to delete')} {t(objectName)}?<br/>
        {t('This will delete all data')}

      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <LengvaButton
          title={t('Cancel')}
          color='secondary'
          onClick={handleClose}
        />
        <LengvaButton
          title={t('Confirm')}
          color='primary'
          onClick={handleDeleteClick}
        />
      </Box>
    </Dialog>
  )
}

export default LengvaDeleteConfirm
