import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import { type AvailableTime } from "_api/data-contracts"
import CheckCircleBlue from "_assets/check-circle-blue.svg"
import CheckCircleBrown from "_assets/check-circle-brown.svg"
import { useField } from "formik"
import moment from "moment"
import { useSearchParams } from "react-router-dom"

interface Props {
  label: string;
  options: AvailableTime[];
  containerStyle?: React.CSSProperties;
}

const LengvaTimeSelect = ({label, options, containerStyle}: Props) => {
  const platform = useSearchParams()[0].get('platform')
  const mainColor = platform === 'mind' ? "#75451D" : "#3872E1"

  const [field, meta, helper] = useField(label)
  const handleSelect = (value: AvailableTime) => {
    helper.setValue(value)
    field.onChange({ target: { name: label, value }})
  }

  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='1rem'
      width='100%'
      padding={isMobile ? '0' : '1rem'}
      border={isMobile ? '' : '1px solid #E9E9EB'}
      borderRadius='12px'
      boxSizing='border-box'
      style={containerStyle}
    >

      {options.map((option, index) => (
        <Button
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          sx={{
            padding: '13px 20px',
            borderRadius: "12px",
            background: "transparent",
            border: option === field.value ? `1px solid ${mainColor}` : "1px solid #E9E9EB",
            "&:hover": {
              background: "transparent",
              border: `1px solid ${mainColor}`,
            },
          }}
          onClick={() => handleSelect(option)}
        >
          <Typography variant='body' color={mainColor}>
            {moment(option.startTime).format('HH:mm')} - {moment(option.endTime).format('HH:mm')}
          </Typography>
          {option === field.value && (
            <Box
              sx={{
                position: "absolute",
                right: "6px",
                top: "6px",
              }}
            >
              <img src={platform === "mind" ? CheckCircleBrown : CheckCircleBlue as any} alt=''/>
            </Box>
          )}
        </Button>
      ))}
      {meta.touched && meta.error && (
        <Typography variant='h6' color='#EB0E0E'>
          {(meta.error as any).startTime}
        </Typography>
      )}
    </Box>
  )
}

export default LengvaTimeSelect
