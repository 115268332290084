import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import useAuthStore from "_state/useStore"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"

interface Props {
  access: string[];
  children: any;
}

export default function RequireAuth(props: Props) {
  const authStore = useAuthStore()
  const { t } = useTranslation('translation', { keyPrefix: "AdminPage" })

  useEffect(() => {
    const init = async () => {
      await authStore.getMe()
    }

    init()
  }, [])

  const alreadyLogin = () => authStore.account && (window.location.pathname.includes('/login') || (window.location.pathname.includes('/register') && !window.location.pathname.includes('confirm')))
  const alreadyActive = () => authStore.account?.isActive && (window.location.pathname === '/register/confirm' || window.location.pathname === '/admin/register/specialist/confirm')
  const userShouldActivateEmail = () => authStore.account && !authStore.account.isEmailConfirmed && !window.location.pathname.includes('/login')
  const userShouldActivateAccount = () => !authStore?.account?.isActive && (!window.location.pathname.includes('/register/confirm') && window.location.pathname !== '/admin/register/specialist/confirm')
  const newAppointmentWithoutAccount = () => !authStore.account && window.location.pathname.includes('/appointment/new')
  console.log("tick: ", authStore);

  // eslint-disable-next-line
  if (alreadyLogin() || alreadyActive()) {
    console.log("logined or active", authStore);
    if (authStore.isSpecialist) {
      return <Navigate replace to={'/admin'} />
    }
    if (authStore.isUser) {
      return <Navigate replace to={'/appointments'} />
    }
    if (authStore.isAdmin)
      return <Navigate replace to={"/admin/accounts"} />
  }

  if (props.access.length === 0) {
    return props.children
  }

  if (!authStore.isStateReady) {
    return <LengvaLoadingSpinner />
  }

  if (newAppointmentWithoutAccount()) {
    const splitPathName = window.location.pathname.split('/')
    const specialistId = splitPathName[splitPathName.length - 1] // eslint-disable-line 
    return <Navigate replace to={`/register/appointment/${specialistId}`} />
  }

  if (userShouldActivateEmail()) {
    const emailConfirmationBanner = {
      open: true,
      severity: "warning",
      title: t("EmailNotConfirmed"),
      message: t("CheckEmailAndConfirm"),
    }
    window.localStorage.setItem('BANNER_STATE', JSON.stringify(emailConfirmationBanner))
  } else {
    window.localStorage.setItem('BANNER_STATE', JSON.stringify({}));
  }

  if (userShouldActivateAccount()) {
    return authStore.isSpecialist ? <Navigate replace to='/admin/register/specialist/confirm' /> : <Navigate replace to='/register/confirm' />
  }

  if (props.access.includes("SPECIALIST") && authStore.isSpecialist) {
    return props.children
  }

  if (props.access.includes("USER") && authStore.isUser) {
    return props.children
  }

  if (props.access.includes("MANAGER") && authStore.isManager) {
    return props.children
  }

  if (props.access.includes("ADMIN") && authStore.isAdmin) {
    return props.children
  }

  return <Navigate replace to='/login' />
}

export enum RoleNames {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  SPECIALIST = "SPECIALIST",
  USER = "USER",
}
