import { CheckOutlined, ChevronRight, CloseRounded } from "@mui/icons-material"
import {
  Box,
  Hidden,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material"
import { type Account } from "_api/Account"
import { type Appointments } from "_api/Appointments"
import { type Services } from "_api/Services"
import {
  type AppointmentCreateRequest,
  type AppointmentResponse,
  type ClientResponse,
  type ServiceResponse,
} from "_api/data-contracts"
import ArrowNarrowRight from "_assets/arrow-narrow-right-blue.svg"
import BagIcon from "_assets/bag-icon-blue.svg"
import PlusIcon from "_assets/plus.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaCompactCalendar from "_components/LengvaCompactCalendar"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import AppointmentCreateEditDialog from "_pages/admin/appointments/AppointmentCreateEditDialog"
import { type AppointmentFormValues } from "_pages/admin/appointments/AppointmentCreateEditForm"
import useStore from "_state/useStore"
import { createApi } from "_utils/ApiCreator"
import { convertTime, getDate, locationIconMap } from "_utils/ObjectUtils"
import { replace } from "lodash"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

function SpecialistDashboard() {
  const [appointmentsToday, setAppointmentsToday] = useState<AppointmentResponse[]>([])
  const [clients, setClients] = useState<ClientResponse[]>([])
  const [newClients, setNewClients] = useState<number>(0)
  const [services, setServices] = useState<ServiceResponse[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { t } = useTranslation("translation", {
    keyPrefix: "SpecialistDashboard",
  })
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })
  const navigate = useNavigate()
  const { account } = useStore()
  const isMobile = useMediaQuery("(max-width: 600px)")
  const appointmentAPI = useRef(createApi("appointment") as Appointments)
  const accountAPI = useRef(createApi("account") as Account)
  const serviceAPI = useRef(createApi("service") as Services)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setIsLoading(true)
    await getAppointmentsToday()
    await getAllClients()
    await getNewClients()
    await getAllServices()
    setIsLoading(false)
  }

  const getAppointmentsToday = async () => {
    const response = await appointmentAPI.current.findAppointmentsToday()
    setAppointmentsToday(response)
  }

  const getAllClients = async () => {
    const response = await accountAPI.current.findAllClientsBySpecialist()
    setClients(response)
  }

  const getNewClients = async () => {
    const response = await accountAPI.current.countNewClientsFromTheLastMonth()
    setNewClients(response)
  }

  const getAllServices = async () => {
    const response = await serviceAPI.current.findAllMyServices()
    setServices(response)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (isLoading) {
    return (
      <LengvaLoadingSpinner/>
    )
  }

  const handleCreateAppointment = async (values: AppointmentFormValues) => {
    if (!values.service || !values.client || !values.location || !values.startTime || !values.endTime) {
      return
    }

    const newAppointment: AppointmentCreateRequest = {
      patientId: values.client,
      startDate: getDate(values.date ?? values.startTime, values.startTime).toISOString(),
      endDate: getDate(values.date ?? values.endTime, values.endTime).toISOString(),
      serviceId: values.service,
      locationId: values.location,
      notes: values.notes,
      address: values.locationAddress,
      sendNotifications: values.sendNotifications,
    }

    await appointmentAPI.current.createSpecialistAppointment(newAppointment)
    toast.success(t("AppointmentCreateSuccess"))
    handleClose()
  }

  return (
    <DashboardContainer>
      <DashboardTitleContainer
        sx={{
          marginBottom: "2rem",
        }}
      >
        <Typography variant='h2'>{t("Hello")}, {account?.name} 👋</Typography>

        {!isMobile && (
          <LengvaButton
            icon={PlusIcon}
            title={t("New appointment")}
            color='primary'
            onClick={handleOpen}
          />
        )}
      </DashboardTitleContainer>
      <DashboardContainer
        sx={{
          flexDirection: "row",
          gap: "1rem",
          padding: "0",
          flexWrap: "wrap",
        }}
      >
        <DashboardColumnContainer
          sx={{
            flex: 2,
          }}
        >
          <DashboardSectionContainer
            sx={{
              flexWrap: "wrap",
            }}
          >
            <DashboardTitleContainer>
              <Typography variant={isMobile ? "h4" : "h3"}>
                {t(`Today's appointments`)}
              </Typography>
              {!isMobile && (
                <LengvaButton
                  color='inherit'
                  title={t("Show all")}
                  endIcon={ArrowNarrowRight}
                  onClick={() => navigate("/admin/appointments")}
                />
              )}
              {isMobile && (
                <LengvaButton
                  endIcon={ArrowNarrowRight}
                  color='inherit'
                  onClick={() => navigate("/admin/appointments")}
                />
              )}
            </DashboardTitleContainer>
            <DashboardSectionContainer
              sx={{
                flexDirection: "row",
                padding: "0",
                gap: isMobile ? "0.75rem" : "1rem",
                justifyContent: "space-between",
              }}
            >
              <PillContainer
                isMobile={isMobile}
                sx={{
                  flex: 1,
                }}
              >
                <Typography variant={isMobile ? 'h4' : 'h3'}>
                  {appointmentsToday.filter(appointment => appointment.location.meetingProvider === 'IN_PERSON').length}
                </Typography>
                <Typography variant='body2'>{t("In person")}</Typography>
              </PillContainer>
              <PillContainer
                isMobile={isMobile}
                sx={{
                  flex: 1,
                }}
              >
                <Typography variant={isMobile ? 'h4' : 'h3'}>
                  {appointmentsToday.filter(appointment => appointment.location.meetingProvider === 'CALL').length}
                </Typography>
                <Typography variant='body2'>{t("Phone calls")}</Typography>
              </PillContainer>
              <PillContainer
                isMobile={isMobile}
                sx={{
                  flex: 1,
                }}
              >
                <Typography variant={isMobile ? 'h4' : 'h3'}>
                  {appointmentsToday.filter(appointment => appointment.location.meetingProvider === 'ZOOM' || appointment.location.meetingProvider === 'GOOGLE_MEET').length}
                </Typography>
                <Typography variant='body2'>{t("Online")}</Typography>
              </PillContainer>
            </DashboardSectionContainer>
            <AppointmentTable appointments={mapAppointmentsToTableValues(appointmentsToday)}/>
          </DashboardSectionContainer>
          <Hidden mdUp>
            <DashboardSectionContainer>
              <DashboardTitleContainer>
                <Typography variant='h3'>{t("Calendar")}</Typography>
                <Hidden mdDown>
                  <LengvaButton
                    color='inherit'
                    title={t("Show full")}
                    onClick={() => navigate("/admin/calendar")}
                  />
                </Hidden>
                <Hidden mdUp>
                  <LengvaButton
                    color='inherit'
                    icon={ArrowNarrowRight}
                    onClick={() => navigate("/admin/calendar")}
                  />
                </Hidden>
              </DashboardTitleContainer>
              <LengvaCompactCalendar
                appointments={appointmentsToday}
              />
            </DashboardSectionContainer>
          </Hidden>
          <Hidden mdDown>
            <DashboardSectionContainer>
              <DashboardTitleContainer>
                <Typography variant='h3'>{t("Clients")}</Typography>
                <LengvaButton
                  color='inherit'
                  title={t("Show all")}
                  onClick={() => navigate("/admin/clients")}
                />
              </DashboardTitleContainer>
              <DashboardSectionContainer
                sx={{
                  flexDirection: "row",
                  padding: "0",
                }}
              >
                <PillContainer
                  isMobile={isMobile}
                  sx={{
                    flex: 1,
                    width: "100%",
                  }}
                >
                  <Typography variant='h3'>{newClients}</Typography>
                  <Typography variant='body' fontSize={14}>
                    {t("New this month")}
                  </Typography>
                </PillContainer>
                <PillContainer
                  isMobile={isMobile}
                  sx={{
                    flex: 1,
                    width: "100%",
                  }}
                >
                  <Typography variant='h3'>{clients.length}</Typography>
                  <Typography variant='body' fontSize={14}>
                    {t("All clients")}
                  </Typography>
                </PillContainer>
              </DashboardSectionContainer>

              <Box
                width='100%'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                border='1px solid #EBEBEB'
                borderRadius='12px'
              >
                {clients.slice(0, 5).map(client => (
                  <Box
                    key={client.email}
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        backgroundColor: "#FAFAFA",
                      },
                    }}
                    onClick={() => navigate(`/admin/clients/${client.id}`)}
                  >
                    <Typography
                      variant='body'
                      fontSize={16}
                      flex='1'
                      color='text.secondary'
                      sx={{
                        padding: "24px 16px 24px 24px",
                      }}
                    >
                      {client.name}
                    </Typography>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      gap='16px'
                      flex='1'
                    >
                      <Typography
                        variant='body'
                        fontSize={16}
                        color='text.secondary'
                        sx={{
                          padding: "24px 16px 24px 24px",
                        }}
                      >
                        {client.email}
                      </Typography>
                      <IconButton
                        sx={{
                          margin: "18px 16px 18px 8px",
                          marginLeft: "auto",
                        }}
                      >
                        <ChevronRight/>
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            </DashboardSectionContainer>
          </Hidden>
        </DashboardColumnContainer>
        <Hidden mdDown>
          <DashboardColumnContainer
            sx={{
              flex: 1,
            }}
          >
            <DashboardSectionContainer>
              <DashboardTitleContainer>
                <Typography variant={isMobile ? "h4" : "h3"}>
                  {t("Calendar")}
                </Typography>
                <Hidden mdDown>
                  <LengvaButton
                    color='inherit'
                    title={t("Show full")}
                    onClick={() => navigate("/admin/calendar")}
                  />
                </Hidden>
                <Hidden mdUp>
                  <LengvaButton
                    color='inherit'
                    icon={ArrowNarrowRight}
                    onClick={() => navigate("/admin/calendar")}
                  />
                </Hidden>
              </DashboardTitleContainer>
              <LengvaCompactCalendar
                appointments={appointmentsToday}
              />
            </DashboardSectionContainer>
            {/* TODO: Implement after revenue is implemented */}
            {/* <DashboardSectionContainer>
              <DashboardTitleContainer>
                <Typography variant='h3'>
                  {t('Revenue')}
                </Typography>
                <LengvaButton
                  color='inherit'
                  title={t('Show more')}
                />
              </DashboardTitleContainer>
              <DashboardSectionContainer
                sx={{
                  flexDirection: 'row',
                  padding: '0',
                }}
              >
                <PillContainer
                  isMobile={isMobile}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography variant='h3'>
                    2,925 €
                  </Typography>
                  <Box
                    display='flex'
                    gap='8px'
                    alignItems='center'
                  >
                    <ColoredBox color='#9ADDD5'/>
                    <Typography variant='body' fontSize={14}>
                      {t('Last month')}
                    </Typography>
                  </Box>
                </PillContainer>
                <PillContainer
                  isMobile={isMobile}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography variant='h3'>
                    2,250 €
                  </Typography>
                  <Box
                    display='flex'
                    gap='8px'
                    alignItems='center'
                  >
                    <ColoredBox color='#BCB6FD'/>
                    <Typography variant='body' fontSize={14}>
                      {t('This month')}
                    </Typography>
                  </Box>
                </PillContainer>
              </DashboardSectionContainer>

              <VictoryChart
                domainPadding={24}
                height={228}
                width={332}
                style={{
                  parent: {
                    border: '1px solid #E9E9EB',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '12px',
                  },

                }}
                domain={{ x: [0.6, 2.5] }}
              >
                <VictoryBar
                  barWidth={68}
                  style={{ data: { fill: '#9ADDD5' } }}
                  cornerRadius={6}
                  data={[{
                    name: 'Jul',
                    sum: 2925,
                  }]}
                  x='name'
                  y='sum'
                />
                <VictoryBar
                  barWidth={68}
                  style={{ data: { fill: '#BCB6FD' } }}
                  cornerRadius={6}
                  data={[{
                    name: 'Aug',
                    sum: 2250,
                  }]}
                  x='name'
                  y='sum'
                />
              </VictoryChart>
            </DashboardSectionContainer> */}

            <DashboardSectionContainer>
              <DashboardTitleContainer>
                <Typography variant='h3'>{t("Services")}</Typography>
              </DashboardTitleContainer>
              {services.map(service => (
                <PillContainer
                  key={service.id}
                  isMobile={isMobile}
                  alignItems='center'
                  sx={{
                    gap: "24px",
                    flexDirection: "row",
                    width: "100%",
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: "#FAFAFA",
                    },
                  }}
                  onClick={() => navigate(`/admin/services`, {replace: true})}
                >
                  <IconPill src={BagIcon as any}/>
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap='8px'
                    alignSelf='flex-start'
                  >
                    <Typography
                      variant='body'
                      fontWeight={600}
                      color='text.secondary'
                    >
                      {tServices.t(`Services.${service.serviceDescription.name}`)}
                    </Typography>
                    <Typography variant='body' color='text.tertiary'>
                      {service.price ? `${service.price}€ • ` : ""}{" "}
                      {convertTime(service.duration)}
                    </Typography>
                  </Box>
                  <IconButton
                    sx={{
                      marginLeft: "auto",
                    }}
                  >
                    <ChevronRight/>
                  </IconButton>
                </PillContainer>
              ))}
            </DashboardSectionContainer>
          </DashboardColumnContainer>
        </Hidden>
      </DashboardContainer>
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            bottom: "0.75rem",
            right: "0.75rem",
            zIndex: 100,
          }}
        >
          <LengvaButton
            title='Appointment'
            icon={PlusIcon}
            onClick={handleOpen}
          />
        </Box>
      )}
      <AppointmentCreateEditDialog
        open={open}
        handleClose={handleClose}
        showStickyFooter={!isMobile}
        showSubmitButton={isMobile}
        clients={clients}
        services={services}
        dialogTitle={t("NewAppointment")}
        handleSubmit={handleCreateAppointment}
      />
    </DashboardContainer>
  )
}

interface AppointmentTableValue {
  id: number;
  meetingType: string;
  meetingTime: string;
  service: string;
  client: string;
  paymentStatus: "paid" | "unpaid" | "partiallyPaid";
}

const mapAppointmentsToTableValues = (
  appointments: AppointmentResponse[],
): AppointmentTableValue[] =>
  appointments.map(appointment => ({
    id: appointment.id,
    meetingType: appointment.location.meetingProvider,
    meetingTime:
      moment(appointment.startDate).format("HH:mm")
      + " - "
      + moment(appointment.endDate).format("HH:mm"),
    service: appointment.service.serviceDescription.name,
    client: `${appointment.patient.name} ${appointment.patient.lastName}`,
    paymentStatus: appointment.payments
      ?.map(payment => payment.status)
      .includes("PAID")
      ? "paid"
      : "unpaid",
  }))

export const DashboardContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "center",
  boxSizing: "border-box",
}))

export const DashboardTitleContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "0.75rem",
}))

export const DashboardSectionContainer = styled(Box)(() => {
  const isMobile = useMediaQuery("(max-width: 600px)")
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: isMobile ? "0.75rem" : "1.5rem",
    boxSizing: "border-box",
    gap: "1rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
  }
})

export const DashboardColumnContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "1rem",
}))

export const PillContainer = styled(Box)(
  ({ isMobile }: { isMobile: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    padding: isMobile ? "12px" : "20px",
    boxSizing: "border-box",
    gap: "4px",
    border: "1px solid #E9E9EB",
    borderRadius: "12px",
  }),
)

export const ColoredBox = styled(Box)(({ color }: { color: string }) => ({
  borderRadius: "4px",
  width: "12px",
  height: "12px",
  background: color,
  marginTop: "3px",
  minWidth: "12px",
}))

export const IconPill = styled("img")(() => ({
  width: "20px",
  height: "20px",
  padding: "0.75rem",
  borderRadius: "12px",
  background: "#F5F8FD",
}))

function AppointmentTable({
  appointments,
}: {
  appointments: AppointmentTableValue[];
}) {
  const navigate = useNavigate()
  const { t } = useTranslation("translation", {
    keyPrefix: "SpecialistDashboard",
  })
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })
  return (
    <Box
      sx={{
        border: "1px solid #E9E9EB",
        borderRadius: "12px",
        overflowX: "auto",
        width: "100%",
      }}
    >
      {appointments.length === 0 && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100%'
          padding='2rem'
          width='100%'
          boxSizing='border-box'
        >
          <Typography variant='h4' fontSize={18} align='center'>
            {t("No Reservations Today")}
          </Typography>
        </Box>
      )}
      {appointments.length > 0 && (
        <Table
          sx={{
            overflow: "scroll",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "none",
              }}
            >
              <TableCell/>
              <TableCell align='left'>{t("Time")}</TableCell>
              <TableCell align='left'>{t("Services")}</TableCell>
              <TableCell align='left'>{t("Client")}</TableCell>
              <TableCell align='left'>{t("Payment")}</TableCell>
              <TableCell align='left'/>
            </TableRow>
          </TableHead>
          {appointments.slice(0, 5).map(appointment => (
            <TableRow
              key={appointment.meetingTime}
              sx={{
                cursor: "pointer",
                ":hover": {
                  backgroundColor: "#FAFAFA",
                },
                "*": {
                  border: "none !important",
                },
              }}
              onClick={() => navigate(`/admin/appointments/${appointment.id}`)}
            >
              <TableCell
                align='right'
                sx={{
                  borderBottom: "none",
                  paddingRight: "8px",
                }}
              >
                <IconPill src={locationIconMap.get(appointment.meetingType)}/>
              </TableCell>
              <TableCell align='left'>{appointment.meetingTime}</TableCell>
              <TableCell align='left'>{tServices.t(`Services.${appointment.service}`)}</TableCell>
              <TableCell align='left'>{appointment.client}</TableCell>
              <TableCell align='left'>
                {appointment.paymentStatus === "paid" ? (
                  <CheckOutlined sx={{ color: "#108037" }}/>
                ) : (
                  <CloseRounded
                    sx={{
                      color: "#EB0E0E",
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <IconButton>
                  <ChevronRight/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      )}
    </Box>
  )
}

export default SpecialistDashboard
