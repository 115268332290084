import { ThemeProvider } from '@emotion/react'
import { BusinessOutlined, PlaceOutlined, PlaceRounded } from '@mui/icons-material'
import AccountTreeIcon from "@mui/icons-material/AccountTree"
import { GoogleOAuthProvider } from '@react-oauth/google'
import AppTheme from 'AppTheme'
import AppointmentsActiveIcon from '_assets/appointments-active-icon.svg'
import AppointmentsIcon from '_assets/appointments-icon.svg'
import CalendarActiveIcon from '_assets/calendar-active-icon.svg'
import CalendarIcon from '_assets/calendar.svg'
import ClientsActiveIcon from '_assets/clients-active-icon.svg'
import ClientsIcon from '_assets/clients-icon.svg'
import ClockActiveIcon from '_assets/clock-active-icon.svg'
import ClockIcon from '_assets/clock-icon.svg'
import DashboardActiveIcon from '_assets/dashboard-active-icon.svg'
import DashboardIcon from '_assets/dashboard-icon.svg'
import PaymentsActiveIcon from '_assets/payments-active-icon.svg'
import PaymentsIcon from '_assets/payments-icon.svg'
import AdminPage from '_pages/admin/AdminPage'
import SpecialistDashboard from '_pages/admin/SpecialistDashboard'
import AppointmentCreateEditPage from '_pages/admin/appointments/AppointmentCreateEditPage'
import AppointmentListPage from '_pages/admin/appointments/AppointmentListPage'
import AppointmentViewPage from '_pages/admin/appointments/AppointmentViewPage'
import AccountPage from '_pages/superAdmin/AccountPage'
import CalendarPage from '_pages/admin/calendar/CalendarPage'
import ClientPage from '_pages/admin/clients/ClientPage'
import ClientViewPage from '_pages/admin/clients/ClientViewPage'
import CreateClientPage from '_pages/admin/clients/CreateClientPage'
import LocationPage from '_pages/admin/locations/LocationPage'
import SchedulePage from '_pages/admin/schedule/SchedulePage'
import ServicePage from '_pages/admin/services/ServicePage'
import CreateEditSpecialistPage from '_pages/admin/specialists/CreateEditSpecialistPage'
import Page404 from '_pages/public/404'
import ClientPaymentsPage from '_pages/public/ClientPaymentsPage'
import PaymentProcessPage from '_pages/public/PaymentProcess'
import ProfilePage from '_pages/public/ProfilePage'
import PublicLayout from '_pages/public/PublicLayout'
import Testing from '_pages/public/Testing'
import UserLayout from "_pages/public/UserLayout"
import ClientAppointmentsPage from '_pages/public/appointments/ClientAppointmentsPage'
import MagicLoginHandler from '_pages/public/auth/MagicLoginHandler'
import PasswordResetPage from '_pages/public/auth/PasswordResetPage'
import RegisterLoginPage from '_pages/public/auth/RegisterLoginPage'
import SpecialistConfirmRegistrationPage from '_pages/public/auth/SpecialistConfirmRegistrationPage'
import UserConfirmRegistrationPage from '_pages/public/auth/UserConfirmRegistrationPage'
import RequireAuth from '_utils/RequireAuth'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import AccountCreateEditPage from '_pages/superAdmin/AccountCreateEditPage'
import 'react-toastify/dist/ReactToastify.css'
import './i18n'
import './index.css'

const clientId = '384777777579-e4kqpbvh0nhijbkv1gvvmt6ge7k2maio.apps.googleusercontent.com'

const root = createRoot(document.querySelector("#root")!)
root.render(
  <>
    <App/>
    <ToastContainer
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      position='top-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      rtl={false}
      theme='dark'/>
  </>)

function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ThemeProvider theme={AppTheme}>
        <BrowserRouter>
          <Routes>
            {layouts?.map((layout: PathGroup, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Route key={i} path={layout.path} element={layout?.layout}>
                {layout.paths?.map((component: PathItem) => (
                  <Route
                    key={component.path}
                    path={component.path}
                    element={
                      <RequireAuth access={component.access}>
                        {component.element}
                      </RequireAuth>
                    }
                  />
                ))}
              </Route>
            ))}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </GoogleOAuthProvider>
  )
}

export const layouts: PathGroup[] = [
  {
    layout: <PublicLayout/>,
    path: "",
    paths: [
      {
        path: "/",
        element: <Navigate replace to='/login'/>,
        access: [],
      },
      {
        path: "register/specialist",
        element: <RegisterLoginPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: [],
      },
      {
        path: "login/specialist",
        element: <RegisterLoginPage isLoginPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: [],
      },
      {
        path: "register",
        element: <RegisterLoginPage isUserPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: [],
      },
      {
        path: "login",
        element: <RegisterLoginPage isLoginPage isUserPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: [],
      },
      {
        path: "forgot-password",
        element: <RegisterLoginPage isForgotPasswordPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: [],
      },
      {
        path: "login/appointment/:specialistId",
        element: <RegisterLoginPage isLoginPage isUserPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: [],
      },
      {
        path: "register/appointment/:specialistId",
        element: <RegisterLoginPage isUserPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: [],
      },
      {
        path: "login/magic",
        element: <MagicLoginHandler/>,
        access: [],
      },
      {
        path: "login/magic/password",
        element: <MagicLoginHandler isPasswordCreation/>,
        access: [],
      },
      {
        path: "*",
        element: <Page404/>,
        access: [],
      },
      {
        path: "password-reset/new",
        element: <PasswordResetPage isCreate/>,
        access: [],
      },
      {
        path: "password-reset",
        element: <PasswordResetPage isForgot/>,
        access: [],
      },
      {
        path: "register/confirm/:specialistId",
        element: <UserConfirmRegistrationPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: ["USER"],
      },
      {
        path: "register/confirm",
        element: <UserConfirmRegistrationPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: ["USER"],
      },
      {
        path: "register/specialist/confirm",
        element: <SpecialistConfirmRegistrationPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: ["SPECIALIST"],
      },
      {
        path: "testing",
        element: <Testing/>,
        access: [],
      },
    ],
  },
  {
    layout: <UserLayout/>,
    path: "",
    paths: [

      // does it belong here?
      // maybe create other group?
      {
        path: "/appointment/new/:specialistId",
        element: <UserConfirmRegistrationPage isAppointmentRegistration/>,
        menuIcon: <AccountTreeIcon/>,
        access: ["USER"],
      },
      {
        path: "appointments",
        element: <ClientAppointmentsPage/>,
        menuIcon: <img src={DashboardIcon as any} alt='Dashboard'/>,
        activeMenuIcon: <img src={DashboardActiveIcon as any} alt='Dashboard'/>,
        menuLabel: "Appointments",
        access: ["USER"],
      },
      {
        path: "appointments/:id",
        element: <AppointmentViewPage isClient/>,
        access: ["USER"],
      },
      {
        path: "payments",
        element: <ClientPaymentsPage/>,
        menuIcon: <img src={PaymentsIcon as any} alt='Payments'/>,
        activeMenuIcon: <img src={PaymentsActiveIcon as any} alt='Payments'/>,
        menuLabel: "Payments",
        access: ["USER"],
      },
      {
        path: 'profile',
        element: <ProfilePage/>,
        access: ["USER"],
      },
      {
        path: "register/confirm/:specialistId",
        element: <UserConfirmRegistrationPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: ["USER"],
      },
      {
        path: "register/confirm",
        element: <UserConfirmRegistrationPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: ["USER"],
      },
      {
        path: "payment-process/:appointmentId",
        element: <PaymentProcessPage/>,
        access: ["USER"],
      },
      {
        path: "payment-process",
        element: <PaymentProcessPage/>,
        access: ["USER"],
      },
    ],
  },
  {
    layout: <AdminPage/>,
    path: "admin",
    paths: [
      {
        path: "",
        element: <SpecialistDashboard/>,
        menuLabel: "Dashboard",
        menuIcon: <img src={DashboardIcon as any} alt='Dashboard'/>,
        activeMenuIcon: <img src={DashboardActiveIcon as any} alt='Dashboard'/>,
        access: ["SPECIALIST"],
      },
      {
        path: "appointments",
        element: <AppointmentListPage/>,
        menuLabel: "Appointments",
        menuIcon: <img src={AppointmentsIcon as any} alt='Appointments'/>,
        activeMenuIcon: <img src={AppointmentsActiveIcon as any} alt='Appointments'/>,
        access: ["SPECIALIST"],
      },
      {
        path: "appointments/:id",
        element: <AppointmentViewPage/>,
        access: ["SPECIALIST"],
      },
      {
        path: "appointments/:id/edit/",
        element: <AppointmentCreateEditPage isEdit/>,
        access: ["SPECIALIST"],
      },
      {
        path: "appointments/create",
        element: <AppointmentCreateEditPage/>,
        access: ["SPECIALIST"],
      },
      {
        path: "calendar",
        element: <CalendarPage/>,
        menuLabel: "Calendar",
        menuIcon: <img src={CalendarIcon as any} alt='Calendar'/>,
        activeMenuIcon: <img src={CalendarActiveIcon as any} alt='Calendar'/>,
        access: ["SPECIALIST"],
      },
      {
        path: "clients",
        element: <ClientPage/>,
        menuLabel: "Clients",
        menuIcon: <img src={ClientsIcon as any} alt='Clients'/>,
        activeMenuIcon: <img src={ClientsActiveIcon as any} alt='Clients'/>,
        access: ["SPECIALIST"],
      },
      {
        path: "clients/:id",
        element: <ClientViewPage/>,
        access: ["SPECIALIST"],
      },
      {
        path: "schedules",
        element: <SchedulePage/>,
        menuLabel: "Schedules",
        menuIcon: <img src={ClockIcon as any} alt='Schedules'/>,
        activeMenuIcon: <img src={ClockActiveIcon as any} alt='Schedules'/>,
        access: ["SPECIALIST"],
      },
      {
        path: "locations",
        element: <LocationPage/>,
        menuLabel: "Locations",
        menuIcon: <PlaceOutlined/>,
        activeMenuIcon: <PlaceRounded
          sx={{
            color: "primary.main",
          }}
        />,
        access: ["SPECIALIST"],
      },
      {
        path: "services",
        element: <ServicePage/>,
        menuLabel: "Services",
        menuIcon: <BusinessOutlined/>,
        activeMenuIcon: <BusinessOutlined/>,
        access: ["SPECIALIST"],
      },
      {
        path: "register/specialist/confirm",
        element: <SpecialistConfirmRegistrationPage/>,
        menuIcon: <AccountTreeIcon/>,
        access: ["SPECIALIST"],
      },
      {
        path: "clients/create",
        element: <CreateClientPage/>,
        access: ["SPECIALIST"],
      },
      // {
      //   path: "revenue",
      //   element: <StatisticPage/>,
      //   menuLabel: "Statistics",
      //   menuIcon: <MonetizationOnOutlinedIcon/>,
      //   access: ["SPECIALIST"],
      // },
      {
        path: "accounts",
        element: <AccountPage/>,
        menuLabel: "Users",
        menuIcon: <img src={ClientsIcon as any} alt='Users'/>,
        access: ["ADMIN"],

      },
      {
        path: "accounts/manage/new",
        element: <AccountCreateEditPage/>,
        access: ["ADMIN"],
      },
      {
        path: "accounts/manage/:id",
        element: <AccountCreateEditPage/>,
        access: ["ADMIN"],
      },
      {
        path: "accounts/new",
        element: <CreateEditSpecialistPage/>,
        access: ["SPECIALIST"],
      },
      {
        path: "accounts/:id",
        element: <CreateEditSpecialistPage/>,
        access: ["SPECIALIST"],
      },
      {
        path: "profile",
        element: <ProfilePage/>,
        access: ["SPECIALIST"],
      },
      // {
      //   path: "notifications",
      //   element: <NotificationList/>,
      //   menuLabel: "Notifications",
      //   menuIcon: <AccountTreeIcon/>,
      //   access: ["SPECIALIST"],
      // },
      // {
      //   path: "payments",
      //   element: <PaymentList/>,
      //   menuLabel: "Payments",
      //   menuIcon: <AccountTreeIcon/>,
      //   access: ["SPECIALIST"],
      // },
      // {
      //   path: "payments/:paymentProcessorId",
      //   element: <PaymentInformationPage/>,
      //   menuIcon: <AccountTreeIcon/>,
      //   access: ["SPECIALIST"],
      // },
      // {
      //   path: "services",
      //   element: <ServiceDescriptionPage/>,
      //   menuLabel: "Services",
      //   menuIcon: <AccountTreeIcon/>,
      //   access: ["SPECIALIST"],
      // },
    ],
  },
]

interface PathGroup {
  layout: JSX.Element;
  path: string;
  paths: PathItem[];
}

export interface PathItem {
  path: string;
  element: JSX.Element;
  menuLabel?: string;
  menuIcon?: JSX.Element;
  activeMenuIcon?: JSX.Element;
  access: string[];
}

export const getAllPublicMenuItems = () => layouts[1].paths.filter(path => path.menuIcon && path.menuLabel)
export const getAllAdminMenuItems = () => layouts[2].paths.filter(path => path.menuIcon && path.menuLabel)
