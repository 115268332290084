import { Box, Typography } from "@mui/material"
import React from "react"
import ChevronRightIcon from "../_assets/chevronRight.svg"

interface Props {
  readonly title: string;
  readonly supportText?: string;
  readonly onClick?: void;
}

export default function LengvaSelectItem({
  title,
  supportText,
  onClick,
}: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        background: "#FAFAFA",
        borderRadius: "24px",
        padding: "24px",
        "&: hover": {
          background: "#E9E9EB",
          cursor: "pointer",
          transition: "0.3s",
        },
      }}
      onClick={() => onClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          borderRadius: "24px",
          width: "100%",
        }}
      >
        <Typography variant='h5' color='text.secondary'>
          {title}
        </Typography>
        {supportText && (
          <Typography variant='h6' color='text.tertiary'>
            {supportText}
          </Typography>
        )}
      </Box>
      <img src={ChevronRightIcon as any} alt=''/>
    </Box>
  )
}
