import {
  Box,
  Divider,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material"
import { DialogDrawer } from "_pages/admin/schedule/AddScheduleDialog"
import { useField, useFormikContext } from "formik"
import { useState } from "react"
import CheckBlueIcon from "../_assets/checkBlue.svg"
import ChevronDownBlue from '../_assets/chevronDown.svg'
import LengvaIcon from "./LengvaIcon"

interface Props {
  title?: string;
  label: string;
  placeholder?: string;
  optionsMain: SelectOption[];
  icon?: any;
  size?: "small" | "large";
  width?: number;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
  multiple?: boolean;
}

export default function LengvaSelect({
  title,
  label,
  placeholder,
  optionsMain,
  icon,
  size,
  width,
  containerStyle,
  disabled,
  multiple,
}: Props) {
  const [field, meta] = useField(label)
  const formikContext = useFormikContext()

  const [open, setOpen] = useState<boolean>(false)

  const isMobile = useMediaQuery("(max-width: 600px)")

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const selectStyle = {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    gap: "12px",
    borderRadius: "12px",
    padding: size === "small" ? "7px 16px" : "12px 16px",
    minWidth: width ? width + "px" : "",
    border: "1px solid #9F9F9F",
  }

  const getIcon = () => {
    const foundIcon = optionsMain.find(option => option.value === field.value)?.icon
    return foundIcon ?? icon
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: "100%", gap: "4px", ...containerStyle }}>
      <FormControl
        disabled={disabled}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: "4px",
        }}
        variant='standard'
      >
        <Typography variant='h5' color='text.secondary' sx={{ fontSize: "14px" }}>
          {title}
        </Typography>
        {!isMobile && (
          <Select
            {...field}
            multiple={multiple}
            value={field.value ?? placeholder}
            IconComponent={() => <img src={ChevronDownBlue as any} alt=''/>}
            MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: "12px",
                  boxShadow: "0px 3px 9px 0px rgba(0, 0, 0, 0.14)",
                  "& .MuiList-root": {
                    padding: "8px",
                    boxSizing: "border-box",
                  },
                },
              },
            }}
            slotProps={{
              input: {
                enterKeyHint: "enter",
              },
            }}
            id='demo-customized-select-native'
            open={open && !disabled}
            disabled={disabled}
            defaultValue={placeholder}
            input={<BootstrapInput/>}
            renderValue={(label: any) => (
              <Box style={{ display: "flex", alignItems: "center", marginRight: '12px' }}>
                {!field.value && icon && <img src={icon} alt=''/>}
                {getIcon() && (
                  <img src={field.value && getIcon()} style={{ paddingRight: "12px" }} alt=''/>
                )}
                {field.value
                  ? optionsMain.find(option => option.value === field.value)?.label
                  : placeholder}
              </Box>
            )}
            style={{ ...selectStyle }}
            sx={{ ...selectSx }}
            onChange={event => {
              field.onChange(event)
            }}
            onClick={handleOpen}
            onClose={handleClose}
            onBlur={handleClose}
          >
            {optionsMain?.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ padding: option.label2 ? "6px 16px" : "11px 16px" }}
                style={{ backgroundColor: "inherit" }}
              >
                {option.icon && <LengvaIcon icon={option.icon}/>}
                <Typography
                  color='text.secondary'
                  style={{
                    ...selectTypography,
                  }}
                >
                  {option.label}
                  {option.label2 && (
                    <Typography
                      style={{
                        ...selectTypography,
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#787878",
                      }}
                    >
                      {option.label2}
                    </Typography>
                  )}
                </Typography>
                {field.value === option.value && (
                  <img src={CheckBlueIcon as any} alt=''/>
                )}

              </MenuItem>
            ))}
          </Select>
        )}
        {isMobile && (
          <>
            <Box
              sx={{
                ...selectStyle,
                height: "24px",
                "&:hover": {
                  cursor: "pointer",
                  border: "1px solid #3872E1",
                },
                justifyContent: (field.value || placeholder) ? "space-between" : "flex-end",
              }}
              onClick={handleOpen}
            >
              {field.value && (
                <Box
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {getIcon() && (
                    <img
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      src={field.value && getIcon()} alt=''/>
                  )}
                  <Typography
                    variant='body'
                    color='text.primary'
                  >
                    {optionsMain.find(option => option.value === field.value)?.label}
                  </Typography>
                </Box>
              )}
              {!field.value && placeholder && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: "12px",
                  }}
                >
                  <Typography
                    color='text.secondary'
                    style={{
                      ...selectTypography,
                      color: "#9F9F9F",
                    }}
                  >
                    {placeholder}
                  </Typography>
                  {icon && <img src={icon} alt=''/>}
                </Box>
              )}
              <img src={ChevronDownBlue as any} alt=''/>
            </Box>
            <DialogDrawer
              drawerOpen={open}
              onClose={handleClose}
              onOpen={handleOpen}
            >
              {optionsMain?.map((option, index) => (
                <div
                  key={option.value}
                >
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      padding: option.label2
                        ? "6px 0px"
                        : "11px 0px",
                      boxSizing: "border-box",
                    }}
                    style={{ backgroundColor: "inherit" }}
                    onClick={async () => {
                      formikContext.setFieldValue(label, option.value)
                      handleClose()
                    }}
                  >
                    {option.icon && <LengvaIcon icon={option.icon}/>}
                    <Typography
                      color='text.secondary'
                      style={{
                        ...selectTypography,
                      }}
                    >
                      {option.label}
                      {option.label2 && (
                        <Typography
                          style={{
                            ...selectTypography,
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "20px",
                            color: "#787878",
                          }}
                        >
                          {option.label2}
                        </Typography>
                      )}
                    </Typography>
                    {field.value === option.value && (
                      <img
                        src={CheckBlueIcon as any}
                        alt=''
                        style={{
                          marginRight: '12px',
                        }}
                      />
                    )}
                  </MenuItem>
                  {index !== optionsMain.length - 1 && (
                    <Divider/>
                  )}
                </div>
              ))}
            </DialogDrawer>
          </>
        )}
      </FormControl>
      {meta.touched && meta.error && (
        <Typography variant='h6' color='#EB0E0E'>
          {meta.error}
        </Typography>
      )}
    </Box>
  )
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    color: "text.primary",
    lineHeight: "24px",
    position: "relative",
    width: "100%",
    fontSize: 16,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    backgroundColor: "inherit",
    padding: "0px !important",
    height: "24px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))

export interface SelectOption {
  value: any;
  value2?: any;
  label: string;
  icon?: any;
  avatarSrc?: any;
  label2?: string;
}

const selectTypography = {
  fontFamily: "Open Sans",
  fontSize: "16px",
  fontStyle: "32px",
  fontWeight: "600",
  lineHeight: "32px",
  width: "100%",
}

const selectSx = {
  backgroundColor: "#FFFFFF !important",
  "&:focus": {
    backgroundColor: "#FFFFFF !important",
  },
  "&:hover": {
    cursor: "pointer",
    border: "1px solid #3872E1 !important",
  },
  "& .MuiInputBase-input": {
    backgroundColor: "#FFFFFF !important",
  },
}
