import { Box, useMediaQuery } from "@mui/material"
import { type ServiceDescriptions } from "_api/ServiceDescriptions"
import { type ServiceDescriptionResponse, type ServiceResponse } from "_api/data-contracts"
import LengvaButton from "_components/LengvaButton"
import LengvaInput from "_components/LengvaInput"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import LengvaSelect, { type SelectOption } from "_components/LengvaSelect"
import LengvaTimePicker from "_components/LengvaTimePicker"
import { createApi } from "_utils/ApiCreator"
import { Form, Formik } from "formik"
import { Services, type TFunction } from "i18next"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from 'yup'

interface Props {
  service?: ServiceResponse;
  handleSubmit: (values: ServiceFormValues) => void;
  handleDelete?: () => void;
}

const ServiceForm = ({service, handleSubmit, handleDelete}: Props) => {
  const [serviceDescriptions, setServiceDescriptions] = useState<ServiceDescriptionResponse[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { t } = useTranslation('translation', { keyPrefix: 'ServiceForm' })
  const isMobile = useMediaQuery('(max-width: 600px)')
  const serviceDescriptionAPI = useRef(createApi('serviceDescription') as ServiceDescriptions)

  useEffect(() => {
    getServiceDescriptions()
  }, [])

  const getServiceDescriptions = async () => {
    setIsLoading(true)
    const response = await serviceDescriptionAPI.current.findAll()
    setServiceDescriptions(response)
    setIsLoading(false)
  }

  if (isLoading) {
    return <LengvaLoadingSpinner/>
  }

  return (
    <Formik
      initialValues={getInitialServiceFormValues(service)}
      validationSchema={getValidationSchema(t)}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
          }}
        >
          <LengvaSelect
            title={t('Service')}
            label='serviceDescriptionId'
            optionsMain={getServiceDescriptionSelectOptions(serviceDescriptions, t)}
          />
          <LengvaInput
            title={t('Price')}
            label='price'
            type='number'
          />
          <LengvaTimePicker
            title={t('Duration')}
            label='duration'
          />

          {/* <Box
            sx={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            <LengvaInput
              title={t('Buffer Time Before')}
              label='bufferTimeBefore'
              type='number'
            />
            <LengvaInput
              title={t('Buffer Time After')}
              label='bufferTimeAfter'
              type='number'
            />
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: handleDelete ? 'space-between' : 'flex-end',
              alignItems: 'center',
              paddingTop: '0.5rem',
              position: isMobile ? 'fixed' : 'static',
              bottom: 16,
              width: isMobile ? "calc(100% - 48px)" : '100%',
            }}
          >
            {handleDelete && (
              <LengvaButton
                title={t('Delete')}
                color='secondary'
                onClick={handleDelete}
              />
            )}

            <LengvaButton
              title={t('Save')}
              type='submit'
              disabled={!formik.isValid || formik.isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const getServiceDescriptionSelectOptions = (serviceDescriptions: ServiceDescriptionResponse[], t: TFunction): SelectOption[] => serviceDescriptions.map(serviceDescription => ({
  value: serviceDescription.id,
  label: t(`Services.${serviceDescription.name}`),
}))

export interface ServiceFormValues {
  serviceDescriptionId: number | null;
  price: number | null;
  duration: Date | null;
  bufferTimeBefore: number;
  bufferTimeAfter: number;
}

const initialValues: ServiceFormValues = {
  serviceDescriptionId: null,
  price: null,
  duration: null,
  bufferTimeBefore: 0,
  bufferTimeAfter: 0,
}

const getInitialServiceFormValues = (service?: ServiceResponse): ServiceFormValues => {
  if (!service) {
    return initialValues
  }

  const durationInDate = new Date()
  durationInDate.setHours(0, service.duration, 0, 0)

  return {
    serviceDescriptionId: service.serviceDescription.id,
    price: service.price,
    duration: durationInDate,
    bufferTimeBefore: service.bufferTimeBefore ?? 0,
    bufferTimeAfter: service.bufferTimeAfter ?? 0,
  }
}

const durationSelectOptions: SelectOption[] = [
  {
    value: 15,
    label: '15 min',
  },
  {
    value: 30,
    label: '30 min',
  },
  {
    value: 45,
    label: '45 min',
  },
  {
    value: 60,
    label: '1 h',
  },
  {
    value: 75,
    label: '1 h 15 min',
  },
  {
    value: 90,
    label: '1 h 30 min',
  },
  {
    value: 105,
    label: '1 h 45 min',
  },
  {
    value: 120,
    label: '2 h',
  },
]

const getValidationSchema = (t: TFunction) => {
  const minDate = moment().hour(0).minute(0).toDate()

  const validationSchema = yup.object().shape({
    serviceDescriptionId: yup.number().required(t('ValidationErrors.ServiceDescriptionRequired')),
    price: yup.number().required(t('ValidationErrors.PriceRequired')).min(0, t('ValidationErrors.PriceMin')),
    duration: yup.date().required(t('ValidationErrors.DurationRequired')).min(minDate, t('ValidationErrors.DurationMin')),
    bufferTimeBefore: yup.number().typeError(t('ValidationErrors.NumberTyperError')).min(0, t('ValidationErrors.BufferTimeMin')),
    bufferTimeAfter: yup.number().typeError(t('ValidationErrors.NumberTyperError')).min(0, t('ValidationErrors.BufferTimeMin')),
  })

  return validationSchema
}

export default ServiceForm
