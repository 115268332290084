import { Box, Divider, FormControl, Typography } from "@mui/material"
import { type ColumnConfig, LengvaSwitch } from "_components/LengvaTable"
import { useEffect, useState } from "react"
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import CloseBlackIcon from "../_assets/closeBlack.svg"
import MenuIcon from "../_assets/menu.svg"
import LengvaButton from "./LengvaButton"
import LengvaIcon from "./LengvaIcon"

interface Props {
  columns: ColumnConfig[];
  onClose: Function;
  onChange: Function;
  onClearConfig: any;
}

export default function LengvaTableColumnConfigModal({
  columns,
  onClose,
  onChange,
  onClearConfig,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'LengvaTable' })

  const allColumnsBoxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
    alignSelf: "stretch",
    width: "100%",
  }

  const mainBoxStyle = {
    display: "flex",
    width: "100%",
    maxWidth: "600px",
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px",
    gap: "24px",
    overflowY: "auto",
    overflowX: "hidden",
  }

  const columnBoxStyle = {
    display: "flex",
    padding: "24px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    background: "#FAFAFA",
    borderRadius: "12px",
    width: "100% !important",
    boxSizing: "border-box",
  }

  const titleBoxStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
  }

  const endBoxSx = {
    bgColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxSizing: "border-box",
    gap: "24px",
    alignSelf: "stretch",
    marginTop: "auto",
    position: "fixed",
    bottom: "16px",
    width: "calc(100% - 48px)",
    maxWidth: "552px",

  }

  const reorder = (
    list: ColumnConfig[],
    startIndex: number,
    endIndex: number,
  ): ColumnConfig[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    result.forEach((column, index) => (column.orderId = index + 1))

    return result
  }

  const [items, setItems] = useState(columns)

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    const columns: ColumnConfig[] = reorder(
      items,
      result.source.index,
      result.destination.index,
    )
    setItems(columns)
  }

  const onSwitchToggle = (index: number) => {
    setItems(
      items.map(item => {
        const toggledItem = item
        if (item.orderId === index + 1) {
          toggledItem.hideOnTable = !item.hideOnTable
        }

        return toggledItem
      }),
    )
  }

  const handleConfigClear = () => {
    onClearConfig()
    onClose()
  }

  const handleConfigAccept = () => {
    onChange(items)
    onClose()
  }

  useEffect(() => {
    setItems(columns)
  }, [columns])

  return (
    <Box sx={{ ...mainBoxStyle }}>
      <Box sx={{ ...titleBoxStyle }}>
        <Typography variant='h4' color='#081F17'>
          {t('ColumnsSettings.ColumnsSettings')}
        </Typography>
        <LengvaIcon icon={CloseBlackIcon} variant='v3' onClick={onClose}/>
      </Box>
      <Typography variant='body' color='#505352'>
        {t("ColumnsSettings.ColumnsSettingsDescription")}
      </Typography>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided: any, snapshot: any) => (
            <Box ref={provided.innerRef} sx={{ ...allColumnsBoxStyle }}>
              {items.filter(item => item.type !== 'button').map((item: ColumnConfig, index: any) => (
                <Draggable
                  key={item.property}
                  draggableId={item.property}
                  index={index}
                >
                  {(provided: any, snapshot: any) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{ ...columnBoxStyle }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <img src={MenuIcon as any} alt=''/>
                        <Typography variant='h5'>{t(item.label)}</Typography>
                      </Box>
                      <FormControl>
                        <LengvaSwitch
                          checked={!item.hideOnTable}
                          onChange={() => {
                            onSwitchToggle(index)
                          }}
                        />
                      </FormControl>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box
        sx={{ ...endBoxSx}}
      >
        <Divider
          sx={{
            width: "calc(100% + 48px)",
            marginLeft: "-24px",
            height: "1px",
            backgroundColor: "#E9E9EB",
            alignSelf: "stretch",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          <LengvaButton
            title={t('ColumnsSettings.Reset')}
            color='secondary'
            onClick={handleConfigClear}
          />
          <LengvaButton title={t("ColumnsSettings.Submit")} onClick={handleConfigAccept}/>
        </Box>
      </Box>
    </Box>
  )
}
