import { Box, Button, Checkbox, Divider, Typography, useMediaQuery } from "@mui/material"
import { type Appointments } from "_api/Appointments"
import { type Services } from "_api/Services"
import { type AccountPublicResponse, type AppointmentResponse, type ServiceResponse } from "_api/data-contracts"
import ArrowLeftIcon from '_assets/arrow-narrow-left.svg'
import ArrowRightIcon from '_assets/arrow-narrow-right.svg'
import CalendarIcon from "_assets/calendar.svg"
import MenuIcon from '_assets/menu.svg'
import TrashIcon from '_assets/trash-blue.svg'
import LengvaAutocomplete from "_components/LengvaAutocomplete"
import LengvaButton from "_components/LengvaButton"
import LengvaDatePicker from "_components/LengvaDatePicker"
import LengvaDeleteConfirm from "_components/LengvaDeleteConfirm"
import LengvaInput from "_components/LengvaInput"
import LengvaSelect, { type SelectOption } from "_components/LengvaSelect"
import LengvaStepper from "_components/LengvaStepper"
import LengvaTimePicker from "_components/LengvaTimePicker"
import { DashboardSectionContainer } from "_pages/admin/appointments/AppointmentListPage"
import { ConfirmCancelDialog } from "_pages/admin/appointments/AppointmentViewPage"
import { type CalendarEvent } from "_pages/admin/calendar/CalendarPage"
import { createApi } from "_utils/ApiCreator"
import AutoSubmitToken from "_utils/AutoSubmitToken"
import { getServiceLocationOptionMap } from "_utils/ObjectUtils"
import dayjs from "dayjs"
import { Form, Formik, type FormikHelpers } from "formik"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import * as yup from 'yup'

interface Props {
  isEdit?: boolean;
  isClientView?: boolean;
  appointment?: AppointmentResponse;
  clients?: AccountPublicResponse[];
  specialists?: AccountPublicResponse[];
  services?: ServiceResponse[];
  newEvent?: CalendarEvent;
  showSubmitButton?: boolean;
  showStickyFooter?: boolean;
  handleSubmit: (values: any) => void;
  handleCancel?: () => void;
}

const AppointmentCreateEditForm = ({ isEdit, appointment, isClientView, clients, specialists, services, newEvent, showSubmitButton = true, showStickyFooter, handleSubmit, handleCancel}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'AppointmentCreateEditForm' })
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })
  const [repeatAppointmentOpen, setRepeatAppointmentOpen] = useState<boolean>(false)
  const [repeatAppointmentValue, setRepeatAppointmentValue] = useState<string>("never")
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false)
  const [specialistServiceOptions, setSpecialistServiceOptions] = useState<SelectOption[]>([])
  const [locationOptionsMap, setLocationOptionsMap] = useState<Map<number, SelectOption[]>>(services ? getServiceLocationOptionMap(services, t) : new Map())

  const toggleRepeatAppointmentOpen = () => {
    setRepeatAppointmentOpen(!repeatAppointmentOpen)
  }

  const appointmentAPI = useRef(createApi('appointment') as Appointments)
  const serviceAPI = useRef(createApi('service') as Services)

  const clientOptions: SelectOption[] = clients?.map(client => ({
    value: client.id ?? 0,
    label: `${client.name} ${client.lastName}`,
  })) ?? []

  const specialistOptions: SelectOption[] = specialists?.map(specialist => ({
    value: specialist.id ?? 0,
    label: `${specialist.name} ${specialist.lastName}`,
  })) ?? []

  const serviceOptions: SelectOption[] = services?.map(service => ({
    value: service.id ?? 0,
    label: service.serviceDescription ? tServices.t(`Services.${service.serviceDescription.name}`) : '',
  })) ?? []

  const shouldEditFormBreak = useMediaQuery('(max-width: 1080px)')
  const isMobile = useMediaQuery('(max-width: 600px)')
  const tabs = [
    t('General information'),
    t('Date and time'),
  ]
  const handleTabChange = (tab: number) => {
    setCurrentTab(tab)
  }

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  const handleCancelDialogOpen = () => {
    setCancelDialogOpen(true)
  }

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false)
  }

  const handleSpecialistChange = async (specialistId?: number) => {
    if (specialistId) {
      const response = await serviceAPI.current.findAllBySpecialistId(specialistId)

      const serviceOptions = response.map(service => ({
        value: service.id,
        label: service.serviceDescription.name,
      }))

      setSpecialistServiceOptions(serviceOptions)

      const newLocationMap = getServiceLocationOptionMap(response, t)
      console.log(newLocationMap)
      setLocationOptionsMap(newLocationMap)
    }
  }

  const handleDeleteAppointment = () => {
    if (!appointment) {
      return
    }

    appointmentAPI.current.delete(appointment.id)
    setDeleteDialogOpen(false)
    window.location.reload()
  }

  const onSubmit = async (values: AppointmentFormValues) => {
    if (isMobile && !isEdit && currentTab === 0) {
      setCurrentTab(1)
      return
    }

    handleSubmit(values)
  }

  return (
    <DashboardSectionContainer
      sx={{
        padding: '1rem',
        paddingBottom: isMobile ? '0rem' : (showStickyFooter ? "0" : '2rem'),
      }}
    >
      <Formik
        enableReinitialize
        initialValues={appointment ? getInitialValues(appointment) : (newEvent ? getInitialEventValues(newEvent) : initialValues)}
        validationSchema={isMobile ? (currentTab === 0 ? getFirstStepValidationSchema(isClientView) : getSecondStepValidationSchema()) : getValidationSchema(isClientView)}
        onSubmit={onSubmit}
      >
        {formik => (
          <Form>
            <DashboardSectionContainer
              sx={{
                gap: shouldEditFormBreak ? '2rem' : '1rem',
                padding: '0px',
                flexDirection: shouldEditFormBreak ? 'column' : 'row',
                paddingBottom: (isMobile && !showStickyFooter) ? '6rem' : '2rem',
              }}
            >
              <DashboardSectionContainer
                sx={{
                  flex: 1,
                  padding: '0px',
                  height: '100%',
                }}
              >
                {isMobile && isEdit && (
                  <Box
                    display='flex'
                    flexDirection='column'
                  >
                    <Box
                      display='flex'
                      gap='1.5rem'
                      justifyContent='center'
                    >
                      <Button
                        color='inherit'
                        sx={{
                          borderRadius: '12px 12px 0 0',
                          marginBottom: '-1px',
                          borderBottom: currentTab === 0 ? '2px solid #3872E1' : '2px solid transparent',
                          transition: 'ease-in-out 0.3s',
                          padding: '16px 12px',
                        }}
                        onClick={() => handleTabChange(0)}
                      >
                        <Typography
                          variant='button'
                          sx={{
                            color: currentTab === 0 ? 'primary.main' : 'text.light',
                          }}
                        >
                          {tabs[0]}
                        </Typography>
                      </Button>
                      <Button
                        color='inherit'
                        sx={{
                          borderRadius: '12px 12px 0 0',
                          marginBottom: '-1px',
                          borderBottom: currentTab === 1 ? '2px solid #3872E1' : '2px solid transparent',
                          transition: 'ease-in-out 0.3s',
                          padding: '16px 12px',
                        }}
                        onClick={() => handleTabChange(1)}
                      >
                        <Typography
                          variant='button'
                          sx={{
                            color: currentTab === 1 ? 'primary.main' : 'text.light',
                          }}
                        >
                          {tabs[1]}
                        </Typography>
                      </Button>
                    </Box>
                    <Divider
                      sx={{
                        width: "calc(100% + 4rem)",
                        marginLeft: "-2rem",
                      }}
                    />
                  </Box>
                )}

                {isMobile && !isEdit && (
                  <LengvaStepper
                    hideText
                    steps={tabs}
                    activeStep={currentTab}
                  />
                )}
                {!isMobile && (
                  <Box
                    display='flex'
                    gap='0.75rem'
                    alignItems='center'
                  >
                    <img
                      src={MenuIcon as any} alt='' style={{
                        width: '28px',
                        height: '28px',
                      }}/>
                    <Typography variant='body' fontWeight={600}>
                      {t("General information")}
                    </Typography>
                  </Box>
                )}
                {(!isMobile || (isMobile && currentTab) === 0) && (

                  <DashboardSectionContainer
                    sx={{
                      border: isMobile ? 'none' : '1px solid #E9E9EB',
                      padding: isMobile ? '0' : '1rem',
                    }}
                  >
                    {!isClientView && (
                      <LengvaAutocomplete
                        label='client'
                        title={t("Client")}
                        options={clientOptions}
                      />

                    )}
                    {isClientView && (
                      <>
                        <LengvaAutocomplete
                          label='service'
                          title={t("Service")}
                          options={specialistServiceOptions}
                        />
                        <AutoSubmitToken
                          dependantValue={formik.values.specialist}
                          handleSubmit={async () => handleSpecialistChange(formik.values.specialist)}
                        />
                      </>
                    )}
                    <LengvaSelect
                      label='service'
                      title={t("Service")}
                      optionsMain={isClientView ? (specialistServiceOptions.length > 0 ? specialistServiceOptions : [{
                        value: null,
                        label: 'Pasirinkite specialistą',
                      }]) : serviceOptions}
                    />
                    <AutoSubmitToken
                      dependantValue={formik.values.service}
                      handleSubmit={async () => {
                        if (formik.values.startTime) {
                          const service = services?.find(service => service.id === formik.values.service)
                          if (service) {
                            formik.setFieldValue('endTime', dayjs(formik.values.startTime).add(service.duration, 'minute').toDate())
                          }
                        }
                      }}
                    />
                    <LengvaSelect
                      label='location'
                      title={t("Location")}
                      optionsMain={locationOptionsMap.get(formik.values.service ?? 0) ?? [{
                        value: null,
                        label: 'Pasirinkite paslaugą',
                      }]}
                    />
                    <LengvaInput
                      multiline
                      title={t("Notes")}
                      label='notes'
                      minRows={8}
                      maxRows={16}
                      tooltip={t("NotesTooltip")}
                    />
                    <Box
                      display='flex'
                      alignItems='center'
                    >
                      <Checkbox
                        checked={formik.values.sendNotifications}
                        color='primary'
                        name='sendNotifications'
                        onChange={async () => formik.setFieldValue('sendNotifications', !formik.values.sendNotifications)}
                      />
                      <Typography variant='body'>
                        {t("Send me notifications")}
                      </Typography>
                    </Box>
                  </DashboardSectionContainer>
                )}
              </DashboardSectionContainer>
              {(!isMobile || (isMobile && currentTab) === 1) && (
                <DashboardSectionContainer
                  sx={{
                    flex: 1,
                    padding: '0px',
                  }}
                >
                  {!isMobile && (
                    <Box
                      display='flex'
                      gap='0.75rem'
                      alignItems='center'
                    >
                      <img
                        src={CalendarIcon as any} alt='' style={{
                          width: '28px',
                          height: '28px',
                        }}/>
                      <Typography variant='body' fontWeight={600}>
                        {t("Date and time")}
                      </Typography>
                    </Box>
                  )}
                  <DashboardSectionContainer
                    sx={{
                      border: isMobile ? 'none' : '1px solid #E9E9EB',
                      padding: isMobile ? '0' : '1rem',
                    }}
                  >
                    <LengvaDatePicker
                      title={t("Date")}
                      label='date'
                      onChange={async newDate => formik.setFieldValue('date', dayjs(newDate).toDate())}
                    />
                    <Box>
                      <Typography variant='body' fontSize={14} fontWeight={600} color='text.secondary'>
                        {t("Time")}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'space-between',
                          gap: '1rem',
                        }}
                      >
                        <LengvaTimePicker isClockInterface label='startTime'/>
                        <Typography variant='body' alignSelf='center'>
                          {t("to")}
                        </Typography>
                        <LengvaTimePicker isClockInterface label='endTime'/>
                        <AutoSubmitToken
                          dependantValue={formik.values.startTime}
                          handleSubmit={async () => {
                            const startTime = dayjs(formik.values.startTime)
                            const service = services?.find(service => service.id === formik.values.service)
                            if (service) {
                              formik.setFieldValue('endTime', startTime.add(service.duration, 'minute').toDate())
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    {/* TODO: sugalvojus kaip daryt atokmentuot */}
                    {/* <Divider
                    sx={{
                      paddingBottom: '1.5rem',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '0.75rem',
                    }}
                  >
                    <Typography variant='body'>
                      Kartoti susitikimą
                    </Typography>
                    <LengvaSwitch
                      checked={repeatAppointmentOpen}
                      onChange={toggleRepeatAppointmentOpen}
                    />
                  </Box>
                  {repeatAppointmentOpen && (
                    <Box
                      paddingTop='0.5rem'
                      display='flex'
                      flexDirection='column'
                      gap='1.5rem'
                    >
                      <LengvaSelect
                        title='Kartoti'
                        label='repeatType'
                        optionsMain={repeatAppointmentTypeOptions}
                      />
                      <RadioGroup
                        name='repeat-appointment'
                        value={repeatAppointmentValue}
                        defaultValue='never'
                        sx={{
                          gap: '1rem',
                        }}
                        onChange={event => {
                          setRepeatAppointmentValue(event.target.value)
                          if (event.target.value === "afterCertainNumber") {
                            // TODO: fix this
                            formik.setFieldValue('repeatTimes', 1)
                            repeatAppointmentTypeOptions.push({
                              value: 'custom',
                              label: 'Custom',
                            })
                            formik.setFieldValue('repeatType', 'custom')
                          } else if (repeatAppointmentTypeOptions.some(option => option.value === 'custom')) {
                            repeatAppointmentTypeOptions.pop()
                            formik.setFieldValue('repeatType', '')
                          }
                        }}
                      >
                        <Typography variant='body' color='text.secondary'>
                          Baigsis
                        </Typography>
                        <FormControlLabel value='never' control={<Radio/>} label='Niekada'/>
                        <FormControlLabel value='afterDate' control={<Radio/>} label='Po nustatytos datos'/>
                        {repeatAppointmentValue === 'afterDate' && (
                          <LengvaDatePicker
                            label='afterDate'
                            onChange={async newDate => formik.setFieldValue('afterDate', dayjs(newDate).format('YYYY.MM.DD'))}
                          />
                        )}
                        <FormControlLabel value='afterCertainNumber' control={<Radio/>} label='Po nustatyto susitikimų skaičiaus'/>
                        {repeatAppointmentValue === 'afterCertainNumber' && (
                          <LengvaInput
                            label='apointmentCount'
                            type='number'
                          />
                        )}
                      </RadioGroup>
                    </Box>
                  )}
                  */}
                  </DashboardSectionContainer>
                </DashboardSectionContainer>
              )}
            </DashboardSectionContainer>
            {showSubmitButton && (
              <StaticMobileButtonFooter
                activeStep={currentTab}
                formik={formik}
                setActiveStep={handleTabChange}
                isEdit={isEdit}
                handleDeleteClick={handleDeleteClick}
              />
            )}
            {showStickyFooter && !showSubmitButton && (
              <Box
                sx={{
                  display: 'flex',
                  paddingBottom: '1rem',
                  justifyContent: handleCancel ? 'space-between' : 'flex-end',
                  width: '100%',
                  position: 'sticky',
                  backgroundColor: 'transparent',
                  bottom: '0',
                }}
              >
                {handleCancel && (
                  <LengvaButton
                    title={t('Cancel')}
                    color='inherit'
                    icon={TrashIcon}
                    onClick={handleCancelDialogOpen}
                  />
                )}
                <LengvaButton
                  title={t('Save')}
                  type='submit'
                  disabled={formik.isSubmitting || !formik.isValid}
                />
              </Box>
            )}
            <LengvaDeleteConfirm
              open={deleteDialogOpen}
              handleClose={handleDeleteDialogClose}
              handleDelete={handleDeleteAppointment}
              objectName='Appointment'
            />
            {handleCancel && (
              <ConfirmCancelDialog
                open={cancelDialogOpen}
                handleClose={handleCloseCancelDialog}
                handleDeleteClick={handleCancel}
              />
            )}
          </Form>
        )}
      </Formik>
    </DashboardSectionContainer>
  )
}

export const StaticMobileButtonFooter = ({ isEdit, activeStep, formik, setActiveStep, handleDeleteClick}:
{
  activeStep: number;
  formik: FormikHelpers<AppointmentFormValues>;
  setActiveStep: (step: number) => void;
  handleDeleteClick: () => void;
  isEdit?: boolean;

}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'AppointmentCreateEditForm' })
  const isMobile = useMediaQuery('(max-width: 600px)')
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '100%',
        padding: isMobile ? '1rem' : 'none',
        paddingTop: isMobile ? 'none' : '1.5rem',
        position: isMobile ? 'fixed' : 'none',
        bottom: isMobile ? '0' : 'none',
        right: '0',
        backgroundColor: '#FFFFFF',
        gap: isMobile ? '1rem' : '1.5rem',
      }}
    >
      <Divider
        sx={{
          width: 'calc(100% + 2rem)',
          marginRight: isMobile ? '-1rem' : '-1rem',
          marginTop: isMobile ? '-1rem' : 'none',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          justifyContent: isMobile && (activeStep === 1 || isEdit) ? 'space-between' : 'flex-end',
          width: `calc(100% - 32px)`,
        }}
      >
        {isMobile && !isEdit && activeStep === 1 && (
          <LengvaButton
            title={t('Back')}
            color='secondary'
            icon={ArrowLeftIcon}
            onClick={() => activeStep === 1 && setActiveStep(0)}
          />
        )}
        {isMobile && isEdit && (
          <LengvaButton
            title={t('Delete')}
            color='inherit'
            icon={TrashIcon}
            onClick={handleDeleteClick}
          />
        )}
        <LengvaButton
          title={isEdit ? t('Save') : (isMobile && activeStep === 0 ? t('Next') : t('Create'))}
          endIcon={isEdit ? undefined : (isMobile && activeStep === 0 ? ArrowRightIcon : undefined)}
          color='primary'
          onClick={async () => formik.submitForm()}
        />
      </Box>
    </Box>
  )
}

// const repeatAppointmentTypeOptions: SelectOption[] = [
//   {
//     value: 'everyWeek',
//     label: 'Kiekvieną savaitę',
//   },
//   {
//     value: 'everyMonth',
//     label: 'Kiekvieną mėnesį',
//   },
// ]

export interface AppointmentFormValues {
  client?: number;
  specialist?: number;
  service?: number;
  location?: number;
  locationName: string;
  locationAddress: string;
  notes: string;
  sendNotifications: boolean;
  date?: Date;
  startTime?: Date;
  endTime?: Date;
  repeat: boolean;
  repeatType?: string;
  repeatTimes?: number;
  repeatUntil?: Date;
  repeatDoesNotEnd?: boolean;
}

const initialValues: AppointmentFormValues = {
  client: undefined,
  specialist: undefined,
  service: undefined,
  location: undefined,
  locationName: '',
  locationAddress: '',
  notes: '',
  sendNotifications: false,
  date: undefined,
  startTime: undefined,
  endTime: undefined,
  repeat: false,
  repeatType: undefined,
  repeatTimes: undefined,
  repeatUntil: undefined,
  repeatDoesNotEnd: undefined,
}

const getInitialValues = (appointment: AppointmentResponse): AppointmentFormValues => ({
  client: appointment.patient.id,
  specialist: appointment.specialist?.id,
  service: appointment.service.id,
  location: appointment.location.id,
  locationName: appointment.location.name,
  locationAddress: appointment.address ?? appointment.location.defaultAddress ?? "",
  notes: appointment.notes ?? "",
  sendNotifications: appointment.sendNotifications ?? false,
  date: new Date(appointment.startDate),
  startTime: new Date(appointment.startDate),
  endTime: new Date(appointment.endDate),
  repeat: false,
  repeatType: undefined,
  repeatTimes: undefined,
  repeatUntil: undefined,
  repeatDoesNotEnd: undefined,
})

const getInitialEventValues = (event: CalendarEvent): AppointmentFormValues => ({
  client: undefined,
  specialist: undefined,
  service: undefined,
  location: undefined,
  locationName: "",
  locationAddress: '',
  notes: "",
  sendNotifications: false,
  date: new Date(event.start),
  startTime: new Date(event.start),
  endTime: new Date(event.end),
  repeat: false,
  repeatType: undefined,
  repeatTimes: undefined,
  repeatUntil: undefined,
  repeatDoesNotEnd: undefined,
})

const getValidationSchema = (isClientView?: boolean) => {
  const validationSchema = yup.object({
    client: yup.number().when('isClientView', (client, schema) => {
      if (!isClientView) {
        return yup.number().required('Klientas yra privalomas')
      }

      return yup.number()
    }),
    specialist: yup.number().when('isClientView', (specialist, schema) => {
      if (isClientView) {
        return yup.number().required('Specialistas yra privalomas')
      }

      return yup.number()
    }),
    service: yup.string().required('Paslauga yra privaloma'),
    location: yup.string().required('Susitikimo vieta yra privaloma'),
    locationName: yup.string(),
    locationAddress: yup.string(),
    notes: yup.string(),
    sendNotifications: yup.boolean(),
    date: yup.date().required('Data yra privaloma'),
    startTime: yup.string().required('Pradžios laikas yra privalomas'),
    endTime: yup.string().required('Pabaigos laikas yra privalomas'),
    repeat: yup.boolean(),
    repeatType: yup.string(),
    repeatTimes: yup.number(),
    repeatUntil: yup.date(),
    repeatDoesNotEnd: yup.boolean(),
  })

  return validationSchema
}

const getFirstStepValidationSchema = (isClientView?: boolean) => {
  const validationSchema = yup.object({
    client: yup.number().when('isClientView', (client, schema) => {
      if (!isClientView) {
        return yup.number().required('Klientas yra privalomas')
      }

      return yup.number()
    }),
    specialist: yup.number().when('isClientView', (specialist, schema) => {
      if (isClientView) {
        return yup.number().required('Specialistas yra privalomas')
      }

      return yup.number()
    }),
    service: yup.string().required('Paslauga yra privaloma'),
    location: yup.string().required('Susitikimo vieta yra privaloma'),
  })

  return validationSchema
}

const getSecondStepValidationSchema = () => {
  const validationSchema = yup.object({
    date: yup.date().required('Data yra privaloma'),
    startTime: yup.string().required('Pradžios laikas yra privalomas'),
    endTime: yup.string().required('Pabaigos laikas yra privalomas'),
  })

  return validationSchema
}

export default AppointmentCreateEditForm
