/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "./http-client";

export class Change<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags active-role-controller
   * @name Logoff
   * @request POST:/api/v1/user/change
   */
  logoff = (
    query: {
      role: "ADMIN" | "MANAGER" | "SPECIALIST" | "USER";
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v1/user/change`,
      method: "POST",
      query: query,
      ...params,
    });
}
