/* eslint-disable no-unsafe-optional-chaining */
import { CheckRounded, ClearRounded, HourglassEmptyRounded } from "@mui/icons-material"
import { Avatar, Box, Typography, useMediaQuery } from "@mui/material"
import { type Appointments } from "_api/Appointments"
import { type Payment } from "_api/Payment"
import { type AppointmentResponse, type LocationResponse } from "_api/data-contracts"
import LengvaLogo from "_assets/lengva-logo.svg"
import PlusIcon from "_assets/plus.svg"
import PlusBlueIcon from "_assets/plusBlue.svg"
import PlusBrownIcon from "_assets/plusBrown.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import { RegisterPageContainer, RegisterPageInnerContainer, getButtonVariant } from "_pages/public/auth/RegisterLoginPage"
import { AppointmentCard } from "_pages/public/auth/UserConfirmRegistrationPage"
import { createApi } from "_utils/ApiCreator"
import { locationIconMap } from "_utils/ObjectUtils"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

interface PaymentProcessPageProps {
  multipleAppointments?: boolean;
}

export default function PaymentProcessPage({multipleAppointments}: PaymentProcessPageProps) {
  const { appointmentId } = useParams<{ appointmentId: string }>()
  const platform = useSearchParams()[0].get('platform')

  const [appointment, setAppointment] = useState<AppointmentResponse | null>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPaymentStatusLoading, setIsPaymentStatusLoading] = useState<boolean>(true)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const isMobile = useMediaQuery('(max-width: 600px)')
  const navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'PaymentProcessPage' })
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })
  const appointmentAPI = useRef(createApi("appointment") as Appointments)
  const paymentAPI = useRef(createApi("payment") as Payment)

  useEffect(() => {
    getAppointment()
    // eslint-disable-next-line
}, [])

  // poll payment status every second for 60 seconds
  useEffect(() => {
    const startTime = moment()
    const interval = setInterval(async () => {
      if (isSuccess) {
        clearInterval(interval)
        return
      }

      if (moment().diff(startTime, 'seconds') >= 60) {
        setIsPaymentStatusLoading(false)
        clearInterval(interval)
        return
      }

      if (!appointment) {
        return
      }

      const payment = appointment.payments ? appointment.payments[0] : null

      if (!payment) {
        return
      }

      const response = await paymentAPI.current.isPaymentSuccess(payment.id)
      if (response === "SUCCESS") {
        setIsSuccess(true)
        setIsPaymentStatusLoading(false)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [appointment])

  const getAppointment = async () => {
    if (!appointmentId) {
      return
    }

    setIsLoading(true)
    const appointment = await appointmentAPI.current.findById(Number(appointmentId))
    setIsLoading(false)

    setAppointment(appointment)
  }

  const handleAddToGoogleCalendarClick = () => {
    if (!appointment) {
      return
    }

    const startDate = moment(appointment.startDate).format('YYYYMMDDTHHmmssZ')
    const endDate = moment(appointment.endDate).format('YYYYMMDDTHHmmssZ')

    const googleCalendarUrl
    = encodeURI(['https://calendar.google.com/calendar/render',
      '?action=TEMPLATE',
      `&text=${t('MeetingWith')} ${appointment.specialist.name} ${appointment.specialist.lastName}`,
      `&dates=${(startDate)}/${endDate}`,
      `&details=${tServices.t(`Services.${appointment.service.serviceDescription.name}`)}`,
      `&location=${getEventLocation(appointment, appointment.location)}`,
      `&sf=true&output=xml`].join(''))

    window.open(googleCalendarUrl, '_blank')
  }

  const handleAddToIcalClick = () => {
    if (!appointment) {
      return
    }

    const startDate = moment.utc(appointment.startDate).format('YYYYMMDDTHHmmss') + 'Z'
    const endDate = moment.utc(appointment.endDate).format('YYYYMMDDTHHmmss') + 'Z'

    const icalContent
      = 'BEGIN:VCALENDAR\n'
      + 'VERSION:2.0\n'
      + 'BEGIN:VEVENT\n'
      + `UID:${appointment.id}\n`
      + `DTSTART:${startDate}\n`
      + `DTEND:${endDate}\n`
      + `SUMMARY:${t('MeetingWith')} ${appointment.specialist.name} ${appointment.specialist.lastName}\n`
      + `DESCRIPTION:${tServices.t(`Services.${appointment.service.serviceDescription.name}`)}\n`
      + `LOCATION:${getEventLocation(appointment, appointment.location)}\n`
      + 'END:VEVENT\n'
      + 'END:VCALENDAR'

    const blob = new Blob([icalContent], { type: 'text/calendar;charset=utf-8' })

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'event.ics'

    document.body.append(link)
    link.click()
    link.remove()
  }

  if (isLoading) {
    return <LengvaLoadingSpinner/>
  }

  return (
    <RegisterPageContainer
      sx={{
        padding: '1rem',
        paddingTop: isMobile ? '2rem' : '4rem',
        gap: isMobile ? '1rem' : '2rem',
      }}
    >
      <RegisterPageInnerContainer>
        <img
          style={{
            maxWidth: "162px",
            cursor: 'pointer',
          }}
          src={LengvaLogo as any}
          alt='logo'
          onClick={() => navigate('/')}

        />
        {!isLoading && appointment?.specialist && (
          <Box
            display='flex'
            alignItems='center'
            gap='12px'
          >
            <Typography variant={isMobile ? 'body2' : 'body'}>
              {t('Appointment reservation with')}
            </Typography>
            <Box
              display='flex'
              alignItems='center'
              gap='8px'
            >
              <Avatar
              // TODO: add profile picture
                src=''
                alt='specialist'
                sx={{
                  width: isMobile ? '24px !important' : "32px",
                  height: isMobile ? '24px !important' : "32px",
                }}
              />
              <Typography variant={isMobile ? 'body2' : 'body'}>
                {appointment.specialist.name + ' ' + appointment.specialist.lastName?.charAt(0) + '.'}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1.5rem",
            width: "100%",
            padding: "2rem",
            boxSizing: "border-box",
            borderRadius: "24px",
            background: "#FFFFFF",
            boxShadow: "0px 3px 17px 0px rgba(0, 0, 0, 0.14)",
          }}
        >
          <Box
            padding='4rem 0'
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap='1.5rem'
            width='100%'
          >
            {!isPaymentStatusLoading && isSuccess && (
              <CheckRounded
                sx={{
                  width: '2rem',
                  height: '2rem',
                  color: "#108037",
                  padding: '1rem',
                  borderRadius: '50%',
                  border: '1px solid #108037',
                }}
              />
            )}
            {!isPaymentStatusLoading && !isSuccess && (
              <HourglassEmptyRounded
                sx={{
                  width: '2rem',
                  height: '2rem',
                  color: "#3872E1",
                  padding: '1rem',
                  borderRadius: '50%',
                  border: '1px solid #3872E1',
                }}
              />
            )}
            {isPaymentStatusLoading && (
              <LengvaLoadingSpinner/>
            )}
            <Box
              display='flex'
              flexDirection='column'
              gap='2rem'
              width='100%'
              alignItems='center'
            >
              <Box
                display='flex'
                flexDirection='column'
                gap='0.5rem'
                alignItems='center'
                width='100%'
              >
                {!isPaymentStatusLoading && isSuccess && (
                  <>
                    <Typography variant='h4' align='center'>
                      {multipleAppointments ? t('Success multiple') : t('Success')}
                    </Typography>
                    <Typography variant='body' align='center'>
                      {t('You will receive a confirmation email.')}
                    </Typography>
                  </>
                )}
                {!isPaymentStatusLoading && !isSuccess && (
                  <>
                    <Typography variant='h4' align='center'>
                      {t('Waiting')}
                    </Typography>
                    <Typography variant='body' align='center'>
                      {t('WeAreProcessingYourPaymentPleaseWaitForConfirmationEmail')}
                    </Typography>
                  </>
                )}
              </Box>
              {!isPaymentStatusLoading && appointment && !multipleAppointments && (
                <AppointmentCard
                  isMobile={isMobile}
                  serviceName={tServices.t(`Services.${appointment.service.serviceDescription.name}`)}
                  meetingPlace={appointment.location.name}
                  locationIcon={locationIconMap.get(appointment.location.meetingProvider)}
                  meetingDate={new Date(appointment.startDate)}
                  meetingStartTime={new Date(appointment.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false})}
                  meetingEndTime={new Date(appointment.endDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false})}
                  containerStyle={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    borderRadius: '24px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '24px',
                    padding: '12px 0px',
                    width: '100%',
                  }}
                />
              )}
              {isPaymentStatusLoading && (
                <Typography variant='body' align='center'>
                  {t('PleaseWaitWhileWeProcessYourPayment')}
                </Typography>
              )}
              {isSuccess && (
                <>
                  <LengvaButton
                    title={t("AddToGoogleCalendar")}
                    icon={platform === "mind" ? PlusBrownIcon : PlusBlueIcon}
                    color={getButtonVariant("secondary", platform)}
                    sx={{
                      maxWidth: "210px",
                    }}
                    onClick={handleAddToGoogleCalendarClick}
                  />
                  <LengvaButton
                    title={t("AddToICal")}
                    icon={PlusIcon}
                    color={getButtonVariant("primary", platform)}
                    sx={{
                      maxWidth: "210px",
                    }}
                    onClick={handleAddToIcalClick}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </RegisterPageInnerContainer>
      {!isPaymentStatusLoading && (
        <LengvaButton
          fullWidth
          title={t("BackToHome")}
          sx={{
            maxWidth: "720px",
          }}
          color={getButtonVariant("primary", platform)}
          onClick={() => navigate('/appointments')}
        />
      )}
    </RegisterPageContainer>
  )
}

const getEventLocation = (appointment: AppointmentResponse, location: LocationResponse) => {
  if (location.meetingProvider === 'ZOOM' || location.meetingProvider === 'GOOGLE_MEET') {
    return appointment.onlineMeetingLink ?? location.meetingProvider === 'ZOOM' ? 'https://zoom.us/' : 'https://meet.google.com/'
  }

  if (location.meetingProvider === 'CALL') {
    return location.defaultAddress
  }

  if (location.meetingProvider === 'IN_PERSON') {
    return `${location.name} ${location.defaultAddress}`
  }

  return ''
}
