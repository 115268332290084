import { Box, Chip, Divider, Typography } from "@mui/material"
import LengvaDatePicker from "_components/LengvaDatePicker"
import LengvaFilterChipGroup from "_components/LengvaFilterChip"
import { useFormikContext } from "formik"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { type TFunction } from "i18next"
import CloseBlackIcon from "../_assets/closeBlack.svg"
import LengvaButton from "./LengvaButton"
import LengvaIcon from "./LengvaIcon"
import LengvaSelect from "./LengvaSelect"

export interface FilterItem {
  property: string;
  property2?: string;
  label: string;
  type: "select" | "dateRange" | "status" | "chip" | "avatar" ;
  values: FilterValues[];
  fastFilter?: boolean;
  icon?: any;
  multiple?: boolean;
  showFastFilter?: boolean;
}

export interface FilterValues {
  value: string;
  title: string;
  icon?: any;
  status?: "success" | "warning" | "error";
  isActive: boolean;
}

interface Props {
  config: FilterItem[];
  onClose: Function;
  onChange: Function;
  onClearFilterItem: Function;
  onClearAllFilters: Function;
  onSubmit?: Function;
}

const titleBoxSx = {
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  marginBottom: "24px",
  boxSizing: "border-box",
  alignSelf: "stretch",
  width: "100%",
}

const mainBoxSx = {
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  overflow: "auto",
  width: "100%",
  maxWidth: "600px",
  boxSizing: "border-box",
}

const chipsBoxSx = {
  display: "flex",
  gap: "12px",
  alignSelf: "stretch",
  flexWrap: "wrap",
}
const endBoxSx = {
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  backgroundColor: "#FFFFFF",
  // boxShadow: "0px -4px 12px 0px rgba(0, 0, 0, 0.10)",
  bgColor: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  position: "fixed",
  bottom: "0",
  gap: "16px",
  paddingBottom: "16px",
  width: "calc(100% - 48px)",
  maxWidth: "552px",
}

export default function LengvaTableFilterModal({
  config,
  onClose,
  onChange,
  onClearFilterItem,
  onClearAllFilters,
  onSubmit,
}: Props) {
  // const { t } = useTranslation('translations', {keyPrefix: 'LengvaTableFilterModal'})
  const formikContext = useFormikContext()
  const { t } = useTranslation('translation', {keyPrefix: 'LengvaTable'})

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "auto",
        padding: "24px",
        boxSizing: "border-box",
        marginBottom: "80px",
      }}
    >
      <Box sx={{ ...titleBoxSx }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          <Typography variant='h3' color='#081F17'>
            {t(`TableFilter.Filters`)}
          </Typography>
          <LengvaIcon icon={CloseBlackIcon} variant='v3' onClick={onClose}/>
        </Box>
      </Box>
      <Box sx={{ ...mainBoxSx }}>
        {config.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <>
            {option.type === "select" && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  alignSelf: "stretch",
                  width: "100%",
                }}
              >
                <FilterTitleContainer
                  title={option.label}
                  t={t}
                  onClear={() => {
                    onClearFilterItem(option.property)
                  }}
                />
                <LengvaSelect
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  // multiple
                  label={option.property}
                  optionsMain={option.values.map(value => ({
                    value: value.value,
                    label: value.title,
                    icon: value.icon ? value.icon : undefined,
                  }))}
                  placeholder={t("TableFilter.SelectPlaceholder")}
                  // value={option.values.find(value => value.isActive)?.value}
                  icon={option.icon}
                />
              </Box>
            )}
            {option.type === "dateRange" && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  alignSelf: "stretch",
                  width: "100%",
                }}
              >
                <FilterTitleContainer
                  title={option.label}
                  t={t}
                  onClear={() => {
                    onClearFilterItem(option.property)
                    onClearFilterItem(option.property2)
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <LengvaDatePicker label={option.property}/>
                  <Typography variant='body' alignSelf='center'>
                    {t("TableFilter.To")}
                  </Typography>
                  <LengvaDatePicker label={option.property2 ?? ""}/>
                </Box>
                {option.showFastFilter && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      paddingTop: "4px",
                    }}
                  >
                    <Typography variant='body2' color='#505352'>
                      {t(`TableFilter.QuickFilters`)}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "stretch",
                        width: "100%",
                        gap: "8px",
                      }}
                    >
                      <Chip
                        label={t('TableFilter.ThisMonth')}
                        // label='This month'
                        color={moment((formikContext.values as any)[option.property]).isSame(moment.utc().startOf('month'), 'day') ? "secondary" : "primary"}
                        sx={{
                          padding: "8px 16px",
                        }}
                        onClick={() => {
                          formikContext.setFieldValue(option.property, moment.utc().startOf('month').toDate())
                          if (option.property2) {
                            formikContext.setFieldValue(option.property2, moment.utc().endOf('month').toDate())
                          }
                        }}
                      />
                      <Chip
                        label={t('TableFilter.LastMonth')}
                        // label='Last month'
                        color={option.property2 ? (moment((formikContext.values as any)[option.property2]).isSame(moment.utc().startOf('month'), 'day') ? "secondary" : "primary") : "primary"}
                        sx={{
                          padding: "8px 16px",
                        }}
                        onClick={() => {
                          formikContext.setFieldValue(option.property, moment.utc().subtract(1, 'months').startOf('month').toDate())
                          if (option.property2) {
                            formikContext.setFieldValue(option.property2, moment.utc().subtract(1, 'months').endOf('month').toDate())
                          }
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {(option.type === "status" || option.type === "avatar" || option.type === "chip") && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <FilterTitleContainer
                  title={option.label}
                  t={t}
                  onClear={() => {
                    onClearFilterItem(option.property)
                  }}
                />
                <LengvaFilterChipGroup
                  key={option.property}
                  type={option.type === "chip" ? "text" : option.type}
                  multiple={option.multiple}
                  label={option.property}
                  options={option.values}
                />
              </Box>
            )}
          </>
        ))}
      </Box>
      <Box
        sx={{ ...endBoxSx}}
      >
        <Divider
          sx={{
            width: "calc(100% + 48px)",
            marginLeft: "-24px",
            height: "1px",
            backgroundColor: "#E9E9EB",
            alignSelf: "stretch",
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          <LengvaButton
            title={t('TableFilter.ClearAllFilters')}
            color='inherit'
            onClick={onClearAllFilters}
          />
          <LengvaButton
            type='submit'
            title={t('TableFilter.Submit')} onClick={() => {
              onSubmit?.()
              onClose()
            }}/>
        </Box>
      </Box>
    </Box>
  )
}

interface FilterTitleContainerProps {
  title: string;
  onClear: () => void;
  t: TFunction;
}

const FilterTitleContainer = ({title, onClear, t}: FilterTitleContainerProps) => (
  <Box
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      alignSelf: "stretch",
    }}
  >
    <Typography variant='h5' color='#505352'>
      {t(title)}
    </Typography>
    <Box
      sx={{ "&:hover": { cursor: "pointer" } }}
      onClick={onClear}
    >
      <Typography
        variant='button'
        color='#3872E1'
        onClick={onClear}
      >
        {t("TableFilter.ClearFilter")}
      </Typography>
    </Box>
  </Box>
)
