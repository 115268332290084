import { Box, Divider, Hidden, IconButton, Typography, styled, useMediaQuery } from "@mui/material"
import { type Schedules } from "_api/Schedules"
import { type Services } from "_api/Services"
import { type ServiceResponse, type ScheduleResponse } from "_api/data-contracts"
import ArrowNarrowLeft from "_assets/arrow-narrow-left.svg"
import ArrowNarrowRight from "_assets/arrow-narrow-right.svg"
import EditIcon from "_assets/edit.svg"
import FlagLt from "_assets/flagLt.png"
import PlusIcon from "_assets/plus.svg"
import PlusIconBlue from "_assets/plusBlue.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaCalendarRangePicker from "_components/LengvaCalendarRangePicker"
import LengvaIcon from "_components/LengvaIcon"
import LengvaInput from "_components/LengvaInput"
import LengvaModalStepper from "_components/LengvaModalStepper"
import LengvaSelect from "_components/LengvaSelect"
import LengvaStepper from "_components/LengvaStepper"
import { type ScheduleConfig, type ServiceConfig } from "_components/LengvaTableCreateEditModal"
import { createScheduleTestData, createServiceTestData, modalStepConfig } from "_pages/superAdmin/AccountPage"
import LengvaSpecialistAddServiceModal from "_pages/admin/specialists/LengvaSpecialistAddServiceModal"
import AddScheduleDialog from "_pages/admin/schedule/AddScheduleDialog"
import EditScheduleDialog from "_pages/admin/schedule/EditScheduleDialog"
import { createApi } from "_utils/ApiCreator"
import { Formik } from "formik"
import TrashIcon from "_assets/trash-blue.svg"
import { useEffect, useRef, useState } from "react"
import LengvaDeleteConfirm from "_components/LengvaDeleteConfirm"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import { useTranslation } from "react-i18next"

const CreateEditSpecialistPage = () => {
  const [editServiceKey, setEditServiceKey] = useState<number>()
  const [steps, setSteps] = useState(modalStepConfig)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [openEditService, setOpenEditService] = useState<boolean>(false)
  const [addScheduleModalOpen, setAddScheduleModalOpen] = useState<boolean>(false)
  const [addServiceModalOpen, setAddServiceModalOpen] = useState<boolean>(false)
  const [editScheduleDialogOpen, setEditScheduleDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleResponse | null>(null)
  const [services, setServices] = useState<ServiceResponse[]>([])
  const [schedules, setSchedules] = useState<ScheduleResponse[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const scheduleAPI = useRef(createApi('schedule') as Schedules)
  const serviceAPI = useRef(createApi('service') as Services)
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })
  const { t } = useTranslation("translation", {
    keyPrefix: "CreateEditSpecialistPage",
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setIsLoading(true)
    await getSchedules()
    await getServices()
    setIsLoading(false)
  }

  const getSchedules = async () => {
    const response = await scheduleAPI.current.findAllBySpecialist()
    setSchedules(response)
  }

  const getServices = async () => {
    const response = await serviceAPI.current.findAllMyServices()
    setServices(response)
  }

  const handleServiceEditClick = (key: number) => {
    setEditServiceKey(key)
    setOpenEditService(true)
    setAddServiceModalOpen(true)
  }

  const handleServiceModalClose = () => {
    setAddServiceModalOpen(false)
    setOpenEditService(false)
  }

  const handleEditServiceDialogOpen = (schedule: ScheduleResponse) => {
    setEditScheduleDialogOpen(true)
    setSelectedSchedule(schedule)
  }

  const handleEditServiceDialogClose = () => {
    setEditScheduleDialogOpen(false)
    setSelectedSchedule(null)
  }

  const handleDelete = async (schedule: ScheduleResponse) => {
    if (!schedule.id) {
      return
    }

    await scheduleAPI.current.delete(schedule.id)
    handleDeleteDialogClose()
    getSchedules()
  }

  const handleDeleteDialogOpen = (schedule: ScheduleResponse) => {
    setSelectedSchedule(schedule)
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setSelectedSchedule(null)
    setDeleteDialogOpen(false)
  }

  const isMobile = useMediaQuery('(max-width: 600px)')
  const getActiveStep = () => {
    if (isMobile) {
      return {
        id: activeStep + 1,
        status: "active",
        title: mobileStepperSteps[activeStep],
      }
    }

    return steps.find(step => step.status === "active")
  }

  const handleNextStep = () => {
    if (isMobile && activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1)
    }

    const currentStep = getActiveStep()
    if (currentStep) {
      const nextStep = steps.find(step => step.id === currentStep.id + 1)
      if (nextStep) {
        currentStep.status = "done"
        nextStep.status = "active"
      }

      setSteps([...steps])
    }
  }

  const handleSkipStep = () => {
    if (isMobile) {
      setActiveStep(activeStep + 1)
    }

    const currentStep = getActiveStep()
    if (currentStep) {
      const nextStep = steps.find(step => step.id === currentStep.id + 1)
      if (nextStep) {
        currentStep.status = "inactive"
        nextStep.status = "active"
      }
    }

    setSteps([...steps])
  }

  const handlePreviousStep = () => {
    if (isMobile && activeStep !== 0) {
      setActiveStep(activeStep - 1)
    }

    const currentStep = getActiveStep()
    if (currentStep) {
      const previousStep = steps.find(step => step.id === currentStep.id - 1)
      if (previousStep) {
        currentStep.status = "inactive"
        previousStep.status = "active"
      }
    }

    setSteps([...steps])
  }

  if (isLoading) {
    return <LengvaLoadingSpinner/>
  }

  return (
    <Formik
      initialValues={{
        services,
        schedules,
      }}
      onSubmit={values => console.log("on submit:", values)}
    >
      {(formik: any) => (
        <>
          <Box
            sx={{
              width: "100%",
              height: 'calc(100% - 5rem)',
              display: "flex",
              gap: '2.5rem',
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Hidden smDown>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  gap: '28px',

                }}
              >
                {steps.map((step, index) => (
                  <LengvaModalStepper
                    key={step.id}
                    title={t(`Steps.${step.title}`)}
                    status={step.status}
                    index={index}
                  />
                ))}
              </Box>
            </Hidden>
            <Hidden smUp>
              <LengvaStepper
                steps={mobileStepperSteps}
                activeStep={activeStep}
              />
            </Hidden>
            <Divider
              flexItem orientation='vertical'
              sx={{
                marginTop: '-2rem',

              }}
            />
            {getActiveStep()?.id === 1 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  flex: 4.5,
                }}
              >
                <LengvaInput title={t('Name')} label='specialist.name'/>
                <LengvaInput title={t("Last Name")} label='specialist.surname'/>
                <LengvaInput
                  label='specialist.username'
                  title={t("Username")}
                  supportText={t("UsernameSupportText")}
                />
                <LengvaInput title={t("Email")} label='specialist.email'/>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "8px",
                  }}
                >
                  <Box sx={{ flex: "0 0 138px" }}>
                    <LengvaSelect
                      label='specialist.phoneStart'
                      icon={FlagLt}
                      placeholder='+370'
                      title={t("PhoneNumber")}
                      optionsMain={[{ value: "+370", label: "+370" }]}
                    />
                  </Box>
                  <LengvaInput label='specialist.phoneEnd'/>
                </Box>
                <LengvaInput
                  title={t("Info")}
                  label='specialist.info'
                  placeholder={t("InfoPlaceholder")}
                />
              </Box>
            )}
            {getActiveStep()?.id === 2 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  flex: 4.5,
                }}
              >
                {services.map((service, key) => (
                  <ItemContainer
                    key={service.id}
                    onClick={() => handleServiceEditClick(key)}
                  >
                    <Box>
                      <Typography variant='h5' color='text.secondary'>
                        {tServices.t(`Services.${service.serviceDescription.name}`)}
                      </Typography>
                      <Typography
                        variant='h6'
                        color='text.tertiary'
                      >{`${service.price}€ • ${service.duration}`}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        sx={{
                          padding: "0px",
                        }}
                      >
                        <LengvaIcon icon={EditIcon}/>
                      </IconButton>
                    </Box>
                  </ItemContainer>
                ))}
                <LengvaButton
                  title={t("AddMore")}
                  color='secondary'
                  onClick={() => setAddServiceModalOpen(true)}
                />
              </Box>
            )}
            {getActiveStep()?.id === 3 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: schedules.length === 0 ? "center" : "flex-start",
                  height: "100%",
                  width: "100%",
                  padding: "0 40px 0 0",
                  gap: "24px",
                  flex: 4.5,
                }}
              >
                {schedules.length === 0 && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "50px",
                          background: "#F5F5F5",
                          borderRadius: "100px",
                        }}
                      >
                        🗓️
                      </Typography>
                      <Typography variant='h5' color='text.secondary'>
                        {t("EmptyScheduleMessage")}
                      </Typography>
                    </Box>
                    <LengvaButton
                      title={t("AddNewTime")}
                      icon={PlusIcon}
                      onClick={() => setAddScheduleModalOpen(true)}
                    />
                    <LengvaButton
                      title={t("Edit")}
                      icon={PlusIcon}
                      onClick={handleEditServiceDialogOpen}
                    />
                  </>
                )}
                {schedules.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    {schedules.map((schedule, key) => (
                      <ItemContainer
                        key={schedule.id}
                        sx={{
                          width: "100%",
                          "&:hover": {
                            cursor: schedule.type === "REPEATING" ? "pointer" : "default",
                          },

                        }}
                        onClick={() => {
                          if (schedule.type === "REPEATING") {
                            handleEditServiceDialogOpen(schedule)
                          }
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <Typography
                            variant='body'
                            fontWeight={600}
                          >
                            {schedule.name}
                          </Typography>
                          <Typography variant='body' fontSize={14} color='text.secondary'>
                            {schedule.type === "REPEATING" ? t("Repeating") : t("OneTime")} • {schedule.serviceNames?.map(serviceName => tServices.t(`Services.${serviceName}`)).join(' • ')} • {schedule.locations?.map(location => location.name).join(' • ')}
                          </Typography>
                        </Box>
                        {schedule.type === "REPEATING" && (
                          <IconButton
                            sx={{
                              padding: "0px",
                            }}
                          >
                            <LengvaIcon icon={EditIcon}/>
                          </IconButton>
                        )}
                        {schedule.type === "ONE_TIME" && (
                          <IconButton
                            sx={{
                              padding: "0px",
                            }}
                            onClick={() => handleDeleteDialogOpen(schedule)}
                          >
                            <LengvaIcon icon={TrashIcon}/>
                          </IconButton>
                        )}
                        <LengvaDeleteConfirm
                          open={deleteDialogOpen}
                          handleClose={handleDeleteDialogClose}
                          objectName='Schedule'
                          handleDelete={async () => handleDelete(schedule)}
                        />

                      </ItemContainer>
                    ))}
                    <LengvaButton
                      fullWidth
                      title={t("AddMore")}
                      icon={PlusIconBlue}
                      color='secondary'
                      onClick={() => setAddScheduleModalOpen(true)}
                    />
                  </Box>
                )}
              </Box>
            )}
            {getActiveStep()?.id === 4 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.5rem",
                  flex: 4.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <Typography variant='body' fontWeight={600}>
                    {t("SelectUnavailability")}
                  </Typography>
                  <Typography variant='body' fontSize={14}>
                    {t("SelectUnavailabilitySupportText")}
                  </Typography>
                </Box>
                <LengvaCalendarRangePicker
                  handleAddClick={() => console.log("add")}
                  startLabel='startTime'
                  endLabel='endTime'
                />
              </Box>
            )}
            {addServiceModalOpen && (
              <LengvaSpecialistAddServiceModal
                open={addServiceModalOpen}
                services={createServiceTestData()}
                handleClose={handleServiceModalClose}
                openEdit={openEditService}
                serviceKey={editServiceKey}
              />
            )}
            {addScheduleModalOpen && (
              <AddScheduleDialog
                open={addScheduleModalOpen}
                handleClose={() => setAddScheduleModalOpen(false)}
                refetchSchedules={getSchedules}
              />
            )}
            {editScheduleDialogOpen && selectedSchedule && (
              <EditScheduleDialog
                open={editScheduleDialogOpen}
                schedule={selectedSchedule}
                handleClose={handleEditServiceDialogClose}
                refetchSchedules={getSchedules}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              width: '100%',
              position: 'sticky',
              bottom: 0,
              marginTop: '82px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <Divider
              sx={{
                width: 'calc(100% + 5rem)',
                marginLeft: '-2.5rem',

              }}
            />
            <Box
              sx={{
                paddingTop: '2rem',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >

              <LengvaButton
                title={t("Back")}
                color='secondary'
                icon={ArrowNarrowLeft}
                onClick={handlePreviousStep}
              />
              <Box
                sx={{
                  display: 'flex',
                  gap: '1.5rem',

                }}
              >
                <LengvaButton
                  title={t("Skip")}
                  color='inherit'
                  onClick={handleSkipStep}
                />
                <LengvaButton
                  title={t("Next")}
                  endIcon={ArrowNarrowRight}
                  onClick={handleNextStep}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Formik>
  )
}

const ItemContainer = styled(Box)({
  display: "flex",
  padding: "24px 16px",
  justifyContent: "space-between",
  borderRadius: "12px",
  border: '1px solid #E9E9EB',
  alignItems: "center",
  "&: hover": {
    background: "#E9E9EB",
    cursor: "pointer",
    transition: "0.3s",
  },
})

const mobileStepperSteps = [
  "PersonalDetails",
  "Services",
  "Schedules",
  "DaysOff",
]

export default CreateEditSpecialistPage

