import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Account } from '_api/Account'
import LengvaAutocomplete from '_components/LengvaAutocomplete'
import LengvaButton from '_components/LengvaButton'
import LengvaInput from '_components/LengvaInput'
import LengvaSelect, { SelectOption } from '_components/LengvaSelect'
import { createApi } from '_utils/ApiCreator'
import { phoneNumberPrefixOptions } from "_utils/ObjectUtils"
import { Form, Formik, useField } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  roles?: SelectOption[];
}

export default function ProfilePublicDataForm({roles}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'ProfilePage' })
  const accountAPI = useRef(createApi("account") as Account)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [invoiceField, invoiceMeta] = useField("invoiceDetails")
  const [rolesField, rolesMeta] = useField("roles")

  return (
    <ProfileFormStyle>
      <LengvaInput
        label='name'
        title={t('Name')}
      />
      <LengvaInput
        label='lastName'
        title={t('LastName')}
      />
      <LengvaInput
        label='email'
        title={t('Email')}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: '0.5rem',
        }}
      >
        <Box
          sx={{
            maxWidth: '167px',
          }}
        >
          <LengvaSelect
            label='phoneNumberPrefix'
            title={t('PhoneNumber')}
            optionsMain={phoneNumberPrefixOptions}
          />
        </Box>
        <LengvaInput
          label='phoneNumber'
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <LengvaButton
          title={t('Save')}
          color='primary'
          type='submit'
        />
      </Box>
      {roles
      && <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '0.5rem',
            flexWrap: "wrap",
          }}
        >
          {rolesField.value.map((role: any, index: number) => (
            <LengvaSelect
              key={index}
              label={`roles.${index}`}
              title={t('Roles')}
              width={264}
              optionsMain={roles}
            />
          ))}
          <LengvaSelect
            key={rolesField.value.length}
            label={`roles.${rolesField.value.length}`}
            title={t('Roles')}
            width={264}
            optionsMain={roles}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '0.5rem',
          }}
        >
          <Box
            sx={{
              maxWidth: '167px',
            }}
          >
            <LengvaSelect
              label='invoiceDetails.workplaceType'
              title={t('WorkplaceType')}
              optionsMain={activityType(t)}
            />
          </Box>
          {invoiceField.value?.workplaceType === "COMPANY"
          && <LengvaInput
            label='invoiceDetails.companyName'
            title={t("CompanyName")}
          />}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '0.5rem',
          }}
        >
          <LengvaInput
            label='invoiceDetails.iban'
            title={t("IBAN")}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '0.5rem',
          }}
        >
          <LengvaInput
            label='invoiceDetails.address'
            title={t("Address")}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '0.5rem',
          }}
        >
          <LengvaInput
            label='invoiceDetails.companyCode'
            title={t("CompanyCode")}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '0.5rem',
          }}
        >
          <LengvaInput
            label='invoiceDetails.vatCode'
            title={t("vatCode")}
          />
        </Box>
      </>}
    </ProfileFormStyle>
  )
}

const activityType = (t: any): SelectOption[] => [
  {
    value: "SELF_EMPLOYED",
    label: "SELF_EMPLOYED",
  },
  {
    value: "COMPANY",
    label: "COMPANY",
  },
]

const ProfileFormStyle = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  width: '100%',
})
