import { useFormikContext } from "formik"
import { useEffect, useState } from "react"

interface AutoSubmitTokenProps {
  dependantValue: any;
  handleSubmit: (values: any) => void;
  delay?: number;
}

const AutoSubmitToken = ({dependantValue, delay, handleSubmit}: AutoSubmitTokenProps) => {
  const [delayReady, setDelayReady] = useState(false)
  const formikContext = useFormikContext()

  useEffect(() => {
    if (delay) {
      setDelayReady(false)
      setTimeout(() => {
        setDelayReady(true)
        // handleSubmit(formikContext.values)
      }, delay)
    } else {
      handleSubmit(formikContext.values)
    }
  // eslint-disable-next-line
  }, [dependantValue])

  useEffect(() => {
    if (delayReady) {
      handleSubmit(formikContext.values)
    }

  // eslint-disable-next-line
  }, [delayReady])

  return null
}

export default AutoSubmitToken
