/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LocationCreateRequest, LocationResponse, LocationUpdateRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Locations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags location
   * @name Update
   * @request PUT:/api/v1/specialist/locations/{id}
   */
  update = (id: number, data: LocationUpdateRequest, params: RequestParams = {}) =>
    this.request<LocationResponse, any>({
      path: `/api/v1/specialist/locations/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags location
   * @name Delete
   * @request DELETE:/api/v1/specialist/locations/{id}
   */
  delete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/specialist/locations/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags location
   * @name UpdateMany
   * @request PUT:/api/v1/specialist/locations/updateMany
   */
  updateMany = (data: LocationUpdateRequest[], params: RequestParams = {}) =>
    this.request<LocationResponse[], any>({
      path: `/api/v1/specialist/locations/updateMany`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags location
   * @name FindAllMyLocations
   * @request GET:/api/v1/specialist/locations
   */
  findAllMyLocations = (params: RequestParams = {}) =>
    this.request<LocationResponse[], any>({
      path: `/api/v1/specialist/locations`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags location
   * @name Create
   * @request POST:/api/v1/specialist/locations
   */
  create = (data: LocationCreateRequest, params: RequestParams = {}) =>
    this.request<LocationResponse, any>({
      path: `/api/v1/specialist/locations`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags location
   * @name CreateMany
   * @request POST:/api/v1/specialist/locations/createMany
   */
  createMany = (data: LocationCreateRequest[], params: RequestParams = {}) =>
    this.request<LocationResponse[], any>({
      path: `/api/v1/specialist/locations/createMany`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags location
   * @name FindById
   * @request GET:/api/v1/user/locations/{id}
   */
  findById = (id: number, params: RequestParams = {}) =>
    this.request<LocationResponse, any>({
      path: `/api/v1/user/locations/${id}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags location
   * @name GetLocationsByAccountId
   * @request GET:/api/v1/user/locations/{accountId}
   */
  getLocationsByAccountId = (accountId: number, params: RequestParams = {}) =>
    this.request<LocationResponse[], any>({
      path: `/api/v1/user/locations/${accountId}`,
      method: "GET",
      ...params,
    });
}
