import { Box, Divider, Typography, useMediaQuery } from "@mui/material"
import { type Auth } from "_api/Auth"
import { type ClientCreateRequest } from "_api/data-contracts"
import LengvaButton from "_components/LengvaButton"
import LengvaInput from "_components/LengvaInput"
import LengvaSelect from "_components/LengvaSelect"
import { DashboardContainer, DashboardTitleContainer } from "_pages/admin/SpecialistDashboard"
import { FormContainer } from "_pages/public/auth/RegisterLoginPage"
import { phoneNumberCodes } from "_pages/public/auth/SpecialistConfirmRegistrationPage"
import { createApi } from "_utils/ApiCreator"
import { Form, Formik, type FormikHelpers } from "formik"
import { type TFunction } from "i18next"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import * as yup from 'yup'

const CreateClientPage = () => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('translation', { keyPrefix: 'CreateClientPage' })
  const authAPI = useRef(createApi("auth") as Auth)

  const handleSubmit = async (values: CreateClientFormValues, formik: FormikHelpers<CreateClientFormValues>) => {
    const clientCreateRequest: ClientCreateRequest = {
      name: values.name,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumberPrefix + values.phoneNumber,
    }

    await authAPI.current.createClient(clientCreateRequest)
    formik.resetForm()
    toast.success(t('Client created successfully, they will receive an email with a link to confirm their account'))
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "2rem",
      }}
    >
      <DashboardContainer
        sx={{
          maxWidth: '1400px',
        }}
      >
        <DashboardTitleContainer
          sx={{
            marginBottom: "2rem",
          }}
        >
          <Typography variant='h2'>
            {t('New Client')}
          </Typography>
        </DashboardTitleContainer>
        <DashboardContainer>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={getValidationSchema(t)}
            onSubmit={handleSubmit}
          >
            {formik => (
              <FormContainer>
                <LengvaInput
                  label='name'
                  title={t('Name')}
                />
                <LengvaInput
                  label='lastName'
                  title={t('Last Name')}
                />
                <LengvaInput
                  label='email'
                  title={t('Email')}
                />
                <Box
                  width='100%'
                  display='flex'
                  gap='1rem'
                  sx={{alignItems: 'end'}}
                >
                  <Box
                    width='100%'
                    maxWidth={168}
                  >
                    <LengvaSelect
                      label='phoneNumberPrefix'
                      title={t('Phone Number')}
                      optionsMain={phoneNumberCodes}
                    />
                  </Box>
                  <LengvaInput label='phoneNumber'/>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: `calc(100% + ${isMobile ? '2rem' : '4rem'})`,
                    marginBottom: isMobile ? '0' : "-0.5rem",
                    padding: isMobile ? "0rem 1rem" : "0rem 1.5rem",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    boxSizing: "border-box",
                    gap: isMobile ? "1rem" : "1.5rem",
                  }}
                >
                  <Divider
                    sx={{
                      width: 'calc(100% + 3rem)',
                      marginLeft: isMobile ? '-1rem' : '-1.5rem',
                      height: "1px",
                      backgroundColor: "#E9E9EB",
                      alignSelf: "stretch",
                    }}
                  />

                  <LengvaButton
                    title={t('Create')}
                    sx={{
                      width: isMobile ? '100%' : 'auto',
                      padding: isMobile ? '13px 20px 13px 17px' : '13px 2.5rem',
                    }}
                    color='primary'
                    onClick={() => formik.handleSubmit()}

                  />
                </Box>
              </FormContainer>
            )}
          </Formik>
        </DashboardContainer>
      </DashboardContainer>
    </Box>
  )
}

const getValidationSchema = (t: TFunction) => yup.object({
  name: yup.string()
    .required(t('Required')),
  lastName: yup.string()
    .required(t('Required')),
  email: yup.string()
    .email(t('Invalid email address'))
    .required(t('Required')),
  phoneNumberPrefix: yup.string().required(t('Required')),
  phoneNumber: yup.number()
    .required(t('Required'))
    // NOTE: This is a temporary solution, only suitable for Lithuanian numbers, maybe we should use a library to validate phone numbers
    .min(10_000_000, t('Invalid phone number'))
    .max(99_999_999, t('Invalid phone number'))
    .typeError(t('Invalid phone number')),
})

interface CreateClientFormValues {
  name: string;
  lastName: string;
  email: string;
  phoneNumberPrefix: string;
  phoneNumber: string;
}

const initialValues: CreateClientFormValues = {
  name: '',
  lastName: '',
  email: '',
  phoneNumberPrefix: '',
  phoneNumber: '',
}

export default CreateClientPage

