import { Box, Hidden, Typography, styled, useMediaQuery } from "@mui/material"
import { type Schedules } from '_api/Schedules'
import { type Services } from "_api/Services"
import { type ScheduleResponse, type ServiceResponse } from '_api/data-contracts'
import CopyIcon from '_assets/copy.svg'
import DownloadIcon from '_assets/download.svg'
import EditIcon from '_assets/edit-black.svg'
import PlusIcon from '_assets/plus.svg'
import UploadIcon from '_assets/upload.svg'
import LengvaButton from "_components/LengvaButton"
import LengvaIcon from '_components/LengvaIcon'
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import { DashboardContainer, DashboardTitleContainer } from "_pages/admin/SpecialistDashboard"
import AddScheduleDialog from "_pages/admin/schedule/AddScheduleDialog"
import EditScheduleDialog from "_pages/admin/schedule/EditScheduleDialog"
import { createApi } from '_utils/ApiCreator'
import { getLocationName, itemColorArray } from "_utils/ObjectUtils"
import { Formik, type FormikProps } from "formik"
import { type TFunction } from "i18next"
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next"

export default function SchedulePage() {
  const [schedules, setSchedules] = useState<ScheduleResponse[]>([])
  const [services, setServices] = useState<ServiceResponse[]>([])
  const [activeSchedule, setActiveSchedule] = useState<ScheduleResponse | null>(null)
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [addScheduleDialogOpen, setAddScheduleDialogOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isMobile = useMediaQuery('(max-width: 600px)')
  const scheduleAPI = useRef(createApi('schedule') as Schedules)
  const serviceAPI = useRef(createApi('service') as Services)
  const { t } = useTranslation('translation', { keyPrefix: 'SchedulesPage' })

  useEffect(() => {
    getData()
  }, [])

  const handleDialogOpen = (schedule: ScheduleResponse) => {
    setActiveSchedule(schedule)
    setEditDialogOpen(true)
  }

  const handleAddDialogOpen = () => {
    setAddScheduleDialogOpen(true)
  }

  const handleAddDialogClose = () => {
    setAddScheduleDialogOpen(false)
  }

  const handleDialogClose = () => {
    setActiveSchedule(null)
    setEditDialogOpen(false)
  }

  const getData = async () => {
    setIsLoading(true)
    await getSchedules()
    await getServices()
    setIsLoading(false)
  }

  const getSchedules = async () => {
    const response = await scheduleAPI.current.findAllBySpecialist()
    setSchedules(response)
  }

  const getServices = async () => {
    const response = await serviceAPI.current.findAllMyServices()
    setServices(response)
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={values => console.log("on submit:", values)}
    >
      {(formik: FormikProps<any>) => (
        <DashboardContainer
          sx={{
            paddingTop: '0',
          }}
        >
          <DashboardTitleContainer
            sx={{
              flexWrap: 'wrap',
              marginBottom: '2rem',
              gap: '1rem',
            }}
          >
            <Typography variant='h2'>
              {t("Schedules")}
            </Typography>
            <Box
              display='flex'
              gap='1rem'
              flexWrap='wrap'
            >
              {!isLoading && schedules.length === 0 && (
                <LengvaButton
                  title={isMobile ? t('New') : t('New schedule')}
                  color='primary'
                  icon={PlusIcon}
                  onClick={handleAddDialogOpen}
                />
              )}
              {/* <Hidden mdDown>
                <LengvaButton
                  title={t('Download')}
                  color='secondary'
                  icon={DownloadIcon}
                />
                <LengvaButton
                  title={t('Import')}
                  color='secondary'
                  icon={UploadIcon}
                />
              </Hidden> */}
            </Box>
          </DashboardTitleContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              padding: isMobile ? '0px' : '1.5rem',
              boxSizing: 'border-box',
            }}
          >
            {!isLoading && schedules.map((schedule, index) => (
              <ScheduleItem
                key={schedule.id}
                schedule={schedule}
                services={services}
                handleEdit={handleDialogOpen}
                sideColor={itemColorArray[index % itemColorArray.length]}
              />
            ))}
            {isLoading && <LengvaLoadingSpinner/>}
            {!isLoading && schedules.length === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '3rem 1rem',
                  boxSizing: 'border-box',
                  border: '1px solid #E9E9EB',
                  borderRadius: '0.75rem',
                  backgroundColor: '#FFFFFF',
                }}
              >
                <Typography
                  variant='h3'
                  align='center'
                >
                  {t('NoSchedules')}
                </Typography>
              </Box>
            )}
          </Box>
          {activeSchedule && (
            <EditScheduleDialog
              open={editDialogOpen}
              handleClose={handleDialogClose}
              schedule={activeSchedule}
              refetchSchedules={getSchedules}
            />
          )}
          {addScheduleDialogOpen && (
            <AddScheduleDialog
              open={addScheduleDialogOpen}
              handleClose={handleAddDialogClose}
              refetchSchedules={getSchedules}
            />
          )}
        </DashboardContainer>
      )}
    </Formik>
  )
}

interface ScheduleItemProps {
  sideColor: string;
  schedule: ScheduleResponse;
  services: ServiceResponse[];
  handleEdit: (schedule: ScheduleResponse) => void;
}

const ScheduleItem = ({sideColor, schedule, services, handleEdit}: ScheduleItemProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'SchedulesPage' })
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })
  const isMobile = useMediaQuery('(max-width: 600px)')
  return (
    <ScheduleItemContainer
      sideColor={sideColor}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '0.5rem',
            width: '100%',
          }}
        >
          <Typography
            variant='body'
            fontWeight={600}
          >
            {schedule.name}
          </Typography>
          <Typography
            variant='body'
            fontSize={14}
          >
            {schedule.type === "REPEATING" ? t("Repeating") : t("One time")} • {getServiceNames(schedule, services, tServices.t)} • {schedule.locations?.map(location => getLocationName(t, location)).join(' • ')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          {/* <LengvaIcon
            icon={ShareIcon}Ç
            onClick={() => console.log('share')}
          /> */}
          {/* <LengvaIcon
            icon={CopyIcon}
            onClick={() => console.log('copy')}
          /> */}
          <LengvaIcon
            icon={EditIcon}
            onClick={() => handleEdit(schedule)}
          />
        </Box>
      </Box>
    </ScheduleItemContainer>
  )
}

const getServiceNames = (schedule: ScheduleResponse, services: ServiceResponse[], t: TFunction) => {
  if (services.length === schedule.serviceNames?.length) {
    return t('AllServices')
  }

  return schedule.serviceNames?.map(serviceName => t(`Services.${serviceName}`)).join(' • ')
}

const ScheduleItemContainer = styled(Box)(({sideColor}: {sideColor: string}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '1.5rem 2rem',
  boxSizing: 'border-box',
  borderRadius: '0.75rem',
  width: '100%',
  backgroundImage: `linear-gradient(90deg, ${sideColor} 12px, #FFFFFF 12px)`,
  border: '1px solid #E9E9EB',
}))
