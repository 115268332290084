import i18next, { use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

const options = {
  order: ['cookie'],
  lookupCookie: 'i18next',
  caches: ['cookie'],
  excludeCacheFor: ['cimode'],
  htmlTag: document.documentElement,
  cookieOptions: { path: '/' },
}

i18next.use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    debug: false,
    detection: options,
    fallbackLng: 'en',
  })
