import { Box, Checkbox, Divider, MenuItem, Typography, useMediaQuery } from "@mui/material"
import CloseBlackIcon from "_assets/closeBlack.svg"
import DuplicateIcon from "_assets/duplicate.svg"
import PlusGrayIcon from "_assets/plus-gray.svg"
import TrashIcon from "_assets/trash.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaIcon from "_components/LengvaIcon"
import LengvaInput from "_components/LengvaInput"
import LengvaSelect from "_components/LengvaSelect"
import { LengvaSwitch } from "_components/LengvaTable"
import LengvaTimePicker from "_components/LengvaTimePicker"
import { DialogDrawer, StyledMenu } from "_pages/admin/schedule/AddScheduleDialog"
import { Form, Formik, type FormikHelpers, type FormikProps } from "formik"
import { type TFunction } from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from 'yup'

interface Props {
  values?: ScheduleAvailabilityFormValues;
  hideTitle?: boolean;
  handleSubmit: (values: ScheduleAvailabilityFormValues, formikHelpers: FormikHelpers<ScheduleAvailabilityFormValues>) => void;
  submitRef?: any;
  resetRef?: any;
  handleReset?: (formik: FormikProps<ScheduleAvailabilityFormValues>) => void;
}

const ScheduleAvailabilityForm = ({values, handleSubmit, hideTitle, submitRef, resetRef, handleReset}: Props) => {
  const [copyAnchorElement, setCopyAnchorElement] = useState<HTMLElement | null>(null)
  const [copyMenuData, setCopyMenuData] = useState<CopyMenuData[]>(copyMenuInitialData)
  const [copyDrawerOpen, setCopyDrawerOpen] = useState<boolean>(false)
  const copyMenuOpen = Boolean(copyAnchorElement)
  const { t } = useTranslation('translation', { keyPrefix: 'ScheduleDetailsForm' })

  const toggleDrawer = () => {
    if (copyDrawerOpen) {
      setCopyMenuData(copyMenuInitialData)
    }

    setCopyDrawerOpen(!copyDrawerOpen)
  }

  const isMobile = useMediaQuery('(max-width: 600px)')
  const isTablet = useMediaQuery('(max-width: 960px)')

  const handleCopyClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    const newCopyMenuData = copyMenuData.map((weekDay, weekDayIndex) => {
      if (weekDayIndex === index) {
        return { ...weekDay, isCurrent: true}
      }

      return weekDay
    })

    setCopyMenuData(newCopyMenuData)
    setCopyAnchorElement(event.currentTarget)
    if (isMobile) {
      setCopyDrawerOpen(true)
    }
  }

  const handleCopyMenuClose = () => {
    setCopyAnchorElement(null)
    setCopyMenuData(copyMenuInitialData)
  }

  const handleCopyMenuSelect = (index: number) => {
    const newCopyMenuData = copyMenuData.map((day, dayIndex) => {
      if (dayIndex === index) {
        return { ...day, isActive: !day.isActive}
      }

      return day
    })

    setCopyMenuData(newCopyMenuData)
  }

  const handleDuplicateTime = (timeIntervals: TimeInterval[], formik: FormikProps<ScheduleAvailabilityFormValues>) => {
    const newWeekDays = formik.values.availableDays.map((weekDay, weekDayIndex) => {
      copyMenuData.map((day, dayIndex) => {
        if (dayIndex === weekDayIndex && day.isActive) {
          weekDay.timeIntervals = timeIntervals.map(timeInterval => ({...timeInterval, id: undefined}))
          weekDay.isActive = true
          return weekDay
        }

        return weekDay
      })

      return weekDay
    })
    handleCopyMenuClose()
    toggleDrawer()

    formik.setFieldValue('availableDays', newWeekDays)
  }

  const handleTimeDelete = (weekDayIndex: number, timeIntervalIndex: number, formik: FormikProps<ScheduleAvailabilityFormValues>) => {
    const newWeekDays = formik.values.availableDays.map((weekDay, index) => {
      if (index === weekDayIndex) {
        const newTimeIntervals = weekDay.timeIntervals.filter((timeInterval, timeIndex) => timeIndex !== timeIntervalIndex)
        const isActive = newTimeIntervals.length > 0

        return { ...weekDay, timeIntervals: newTimeIntervals, isActive }
      }

      return weekDay
    })

    formik.setFieldValue('availableDays', newWeekDays)
  }

  const handleAddTime = (weekDayIndex: number, formik: FormikProps<ScheduleAvailabilityFormValues>) => {
    const newWeekDays = formik.values.availableDays.map((weekDay, index) => {
      if (index === weekDayIndex) {
        weekDay.isActive = true
        weekDay.timeIntervals.push({start: null, end: null})
        return weekDay
      }

      return weekDay
    })

    formik.setFieldValue('availableDays', newWeekDays)
  }

  const handleCheckboxClick = (weekDayIndex: number, formik: FormikProps<ScheduleAvailabilityFormValues>) => {
    const updatedWeekDays = formik.values.availableDays.map((weekDay, index) => {
      if (weekDayIndex === index) {
        if (!weekDay.isActive && weekDay.timeIntervals.length === 0) {
          handleAddTime(index, formik)
          return weekDay
        }

        if (weekDay.isActive) {
          weekDay.timeIntervals = []
          return { ...weekDay, isActive: !weekDay.isActive}
        }

        return { ...weekDay, isActive: !weekDay.isActive}
      }

      return weekDay
    })

    formik.setFieldValue('availableDays', updatedWeekDays)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values ?? initialValues}
      validationSchema={getValidationSchema(t)}
      onSubmit={handleSubmit}
    >
      {(formik: FormikProps<ScheduleAvailabilityFormValues>) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
          }}
        >
          {!hideTitle && (
            <Typography variant='h5' color='text.secondary'>{t("SelectAvailability")}</Typography>
          )}
          {/* <Box sx={{ display: "flex", width: isMobile ? "100%" : "50%"}}>
            <LengvaSelect label='repeatFrequency' title={t('RepeatEvery')} optionsMain={repeatOptions.map(option => ({...option, label: t(option.label)}))}/>
          </Box> */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            {formik.values.availableDays.map((weekDay, index) => (
              <Box
                key={weekDay.day}
                sx={{
                  display: "flex",
                  padding: "12px 16px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "16px",
                  alignSelf: "stretch",
                  borderRadius: "12px",
                  border: '1px solid #E9E9EB',
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "24px", alignSelf: "stretch", flexWrap: 'wrap' }}>
                  <Box sx={{ display: "flex", alignItems: "center", width: "165px", order: 0}}>
                    <Checkbox
                      sx={{
                        color: "#3872E1",
                        '&.Mui-checked': {
                          color: "#3872E1",
                        },
                      }}
                      checked={weekDay.isActive}
                      onClick={() => handleCheckboxClick(index, formik)}
                    />
                    <Typography variant='body' color='text.primary'>{t(`Weekdays.${weekDay.day}`)}</Typography>
                  </Box>
                  {(weekDay.timeIntervals.length === 0 || !weekDay.isActive) && (
                    <>
                      <Typography variant='body' color='text.tertiary'>{t("Unavailable")}</Typography>
                      <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
                        <LengvaIcon icon={PlusGrayIcon} onClick={() => handleAddTime(index, formik)}/>
                      </Box>
                    </>
                  )}
                  {weekDay.timeIntervals.length > 0 && weekDay.isActive && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "24px",
                          flexShrink: "0",
                          maxWidth: isTablet ? "calc(100% - 68px)" : "321px",
                          order: isTablet ? 2 : 1,
                          minWidth: isTablet ? "100%" : "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <LengvaTimePicker label={`availableDays[${index}].timeIntervals[0].start`}/>
                          -
                          <LengvaTimePicker label={`availableDays[${index}].timeIntervals[0].end`}/>
                        </Box>
                        {isTablet && (
                          <Box
                            sx={{
                              marginLeft: "auto",
                            }}
                          >
                            <LengvaIcon icon={TrashIcon} onClick={() => handleTimeDelete(index, 0, formik)}/>
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "auto",
                          display: "flex",
                          alignItems: "center",
                          gap: '0.5rem',
                          order: 1,
                        }}
                      >
                        {!isTablet && (
                          <LengvaIcon icon={TrashIcon} onClick={() => handleTimeDelete(index, 0, formik)}/>
                        )}
                        {/* // TODO: implement copy later */}
                        {/* <LengvaIcon
                          icon={DuplicateIcon}
                          aria-controls={`copy-menu-${index}`}
                          onClick={(event: any) => handleCopyClick(event, index)}/> */}
                        <LengvaIcon icon={PlusGrayIcon} onClick={() => handleAddTime(index, formik)}/>
                      </Box>
                    </>)}
                </Box>
                {weekDay.isActive && weekDay.timeIntervals.map((time, timeIndex) => (
                  timeIndex !== 0 && (
                  // eslint-disable-next-line react/no-array-index-key
                    <Box key={timeIndex} sx={{ display: "flex", alignItems: "center", gap: "24px", width: "100%" }}>
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        flexShrink: "0",
                        maxWidth: isTablet ? 'calc(100% - 68px)' : '321px',
                        marginLeft: isTablet ? 'none' : '189px',
                      }}
                      >
                        <LengvaTimePicker label={`availableDays[${index}].timeIntervals[${timeIndex}].start`}/>
                        -
                        <LengvaTimePicker label={`availableDays[${index}].timeIntervals[${timeIndex}].end`}/>
                      </Box>
                      <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center", gap: "16px" }}>
                        <Box sx={{ display: "flex" }}>
                          <LengvaIcon icon={TrashIcon} onClick={() => handleTimeDelete(index, timeIndex, formik)}/>
                        </Box>
                      </Box>
                      {!isMobile && (
                        <StyledMenu
                          keepMounted
                          open={copyMenuOpen}
                          anchorEl={copyAnchorElement}
                          MenuListProps={{
                            'aria-labelledby': 'copy-button',
                            sx: {
                              width: "212px",
                            },
                          }}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          onClose={handleCopyMenuClose}
                        >
                          <CopyMenuContent
                            copyMenuData={copyMenuData}
                            handleCopyMenuSelect={handleCopyMenuSelect}
                            handleDuplicateTime={handleDuplicateTime}
                            formik={formik}
                            index={index}
                          />
                        </StyledMenu>
                      )}
                      {isMobile && (
                        <DialogDrawer
                          drawerOpen={copyDrawerOpen}
                          onClose={toggleDrawer}
                          onOpen={toggleDrawer}
                        >
                          <CopyMenuContent
                            copyMenuData={copyMenuData}
                            handleCopyMenuSelect={handleCopyMenuSelect}
                            handleDuplicateTime={handleDuplicateTime}
                            formik={formik}
                            index={index}
                            toggleDrawer={toggleDrawer}
                          />
                        </DialogDrawer>
                      )}
                    </Box>
                  )))}
              </Box>
            ))}
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            width: isMobile ? '100%' : '50%',
          }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", alignSelf: "stretch"}}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignSelf: "stretch", alignItems: "center"}}>
                <Typography variant='h6' color='text.secondary'>{t("RegistrationFutureLimitInDays")}</Typography>
                <LengvaSwitch
                  checked={formik.values.isMaxDaysInAdvanceActive}
                  onChange={async () => formik.setFieldValue('isMaxDaysInAdvanceActive', !formik.values.isMaxDaysInAdvanceActive)}/>
              </Box>
              {formik.values.isMaxDaysInAdvanceActive && (
                <LengvaInput label='maxDaysInAdvance'/>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", alignSelf: "stretch"}}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignSelf: "stretch", alignItems: "center"}}>
                <Typography variant='h6' color='text.secondary'>{t('RegistrationsPerDayLimit')}</Typography>
                <LengvaSwitch
                  name='isMaxBookingsPerDayActive'
                  checked={formik.values.isMaxBookingsPerDayActive}
                  onChange={async () => (formik.setFieldValue(`isMaxBookingsPerDayActive`, !formik.values.isMaxBookingsPerDayActive))}/>
              </Box>
              {formik.values.isMaxBookingsPerDayActive && (
                <LengvaInput label='maxBookingsPerDay'/>
              )}
            </Box>
          </Box>
          <button
            ref={submitRef} type='submit'
            style={{ display: "none" }}
            onClick={() => formik.handleSubmit()}/>
          <button
            ref={resetRef}
            type='reset'
            style={{display: 'none'}}
            onClick={() => {
              formik.setFieldValue('locations', [], false)
              handleReset?.(formik)
              formik.resetForm()
              formik.handleReset()
            }}/>
        </Form>
      )}
    </Formik>
  )
}

export interface ScheduleAvailabilityFormValues {
  isMaxBookingsPerDayActive: boolean;
  maxBookingsPerDay: number;
  isMaxDaysInAdvanceActive: boolean;
  maxDaysInAdvance: number;
  availableDays: AvailableDay[];

}

export interface AvailableDay {
  day: string;
  isActive: boolean;
  timeIntervals: TimeInterval[];
}

export interface TimeInterval {
  id?: number;
  start: Date | null;
  end: Date | null;
}

export const weekDaysTestData = [
  {day: "Monday", isActive: false, timeIntervals: []},
  {day: "Tuesday", isActive: false, timeIntervals: []},
  {day: "Wednesday", isActive: false, timeIntervals: []},
  {day: "Thursday", isActive: false, timeIntervals: []},
  {day: "Friday", isActive: false, timeIntervals: []},
  {day: "Saturday", isActive: false, timeIntervals: []},
  {day: "Sunday", isActive: false, timeIntervals: []},
]

interface CopyMenuData {
  day: string;
  isActive: boolean;
  isCurrent: boolean;
}

const copyMenuInitialData: CopyMenuData[] = [
  {day: "Monday", isActive: false, isCurrent: false},
  {day: "Tuesday", isActive: false, isCurrent: false},
  {day: "Wednesday", isActive: false, isCurrent: false},
  {day: "Thursday", isActive: false, isCurrent: false},
  {day: "Friday", isActive: false, isCurrent: false},
  {day: "Saturday", isActive: false, isCurrent: false},
  {day: "Sunday", isActive: false, isCurrent: false},
]

const initialValues: ScheduleAvailabilityFormValues = {
  isMaxBookingsPerDayActive: false,
  maxBookingsPerDay: 0,
  isMaxDaysInAdvanceActive: false,
  maxDaysInAdvance: 0,
  availableDays: [
    {day: "Monday", isActive: false, timeIntervals: []},
    {day: "Tuesday", isActive: false, timeIntervals: []},
    {day: "Wednesday", isActive: false, timeIntervals: []},
    {day: "Thursday", isActive: false, timeIntervals: []},
    {day: "Friday", isActive: false, timeIntervals: []},
    {day: "Saturday", isActive: false, timeIntervals: []},
    {day: "Sunday", isActive: false, timeIntervals: []},
  ],
}

const getInitialValues = (values: ScheduleAvailabilityFormValues | undefined) => {
  console.log('getInitialValues')
  if (!values) {
    const initialValue: ScheduleAvailabilityFormValues = {
      isMaxBookingsPerDayActive: false,
      maxBookingsPerDay: 0,
      isMaxDaysInAdvanceActive: false,
      maxDaysInAdvance: 0,
      // Do not change this to weekDaysTestData, it will not work
      availableDays: weekDaysTestData,
    }
    return initialValue
  }

  return values
}

const getValidationSchema = (t: TFunction) => yup.object({
  isMaxBookingsPerDayActive: yup.boolean(),
  maxBookingsPerDay: yup.number().typeError(t('ValidationErrors.EnterRegistrationsNumber')),
  isMaxDaysInAdvanceActive: yup.boolean(),
  maxDaysInAdvance: yup.number().typeError(t('ValidationErrors.EnterDays')),
  availableDays: yup.array().of(yup.object({
    day: yup.string(),
    isActive: yup.boolean(),
    timeIntervals: yup.array().of(yup.object({
      start: yup.date().required(t('ValidationErrors.SelectTime')).typeError(t('ValidationErrors.SelectTime')),
      end: yup.date().required(t('ValidationErrors.SelectTime')).typeError(t('ValidationErrors.SelectTime')).min(yup.ref('start'), t('ValidationErrors.EndTimeLaterThanStart')),
    })),
  })),
})

const repeatOptions = [
  {value: "WEEKLY", label: "1week"},
  {value: "MONTHLY", label: "1month"},
]

interface CopyMenuContentProps {
  copyMenuData: CopyMenuData[];
  handleCopyMenuSelect: (index: number) => void;
  handleDuplicateTime: (timeIntervals: TimeInterval[], formik: FormikProps<ScheduleAvailabilityFormValues>) => void;
  formik: FormikProps<ScheduleAvailabilityFormValues>;
  index: number;
  toggleDrawer?: () => void;
}

const CopyMenuContent = ({copyMenuData, handleCopyMenuSelect, handleDuplicateTime, formik, index, toggleDrawer}: CopyMenuContentProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  return (
    <>
      <MenuItem
        disableRipple
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingBottom: isMobile ? "24px" : "12px",
          "&:hover": {
            backgroundColor: "transparent !important",
            cursor: "default",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant={isMobile ? 'h3' : 'body'}
            color='text.secondary'
            fontWeight={600}
            sx={{
              boxSizing: "border-box",
            }}
          >
            Copy times to
          </Typography>
          {isMobile && (
            <LengvaIcon variant='v3' icon={CloseBlackIcon} onClick={toggleDrawer}/>
          )}
        </Box>
        {!isMobile && (
          <Divider
            sx={{
              borderColor: "#E9E9EB",
              width: "calc(100% + 32px)",
              marginLeft: "-16px",
            }}
          />
        )}
      </MenuItem>
      {copyMenuData.map((weekDay, index) => (
        <MenuItem
          key={weekDay.day}
          disableRipple={weekDay.isCurrent}
          sx={{
            display: "flex",
            padding: "11px 16px",
            borderRadius: "0x",
            "&:hover": {
              backgroundColor: weekDay.isCurrent ? "transparent !important" : "#EBEBEB",
              cursor: weekDay.isCurrent ? "default" : "pointer",
            },
          }}
          onClick={() => handleCopyMenuSelect(index)}
        >
          <Checkbox
            disabled={weekDay.isCurrent}
            checked={weekDay.isActive || weekDay.isCurrent}
            sx={{
              padding: "7px",
              color: "#3872E1",
              "& .MuiSvgIcon-root": {
                margin: "0px !important",
                color: "#3872E1 !important",
                opacity: weekDay.isCurrent ? "0.3" : "1",
              },

            }}
            onChange={() => {
              handleCopyMenuSelect(index)
            }}
          />
          <Typography variant='body' fontWeight={600}>{weekDay.day}</Typography>
        </MenuItem>
      ))}
      <MenuItem
        disableRipple
        disableTouchRipple
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: isMobile ? "24px" : "12px",
          alignItems: "flex-start",
          "&:hover": {
            backgroundColor: "transparent !important",
            cursor: "default",
          },
        }}
      >
        {!isMobile && (
          <Divider
            sx={{
              borderColor: "#E9E9EB",
              width: "calc(100% + 32px)",
              marginLeft: "-16px",
            }}
          />
        )}
        <LengvaButton
          fullWidth
          title='Pritaikyti'
          onClick={() => {
            handleDuplicateTime(formik.values.availableDays[index].timeIntervals, formik)
          }}
        />
      </MenuItem>
    </>
  )
}

export default ScheduleAvailabilityForm
