import {
  type LocationResponse,
  type ScheduleResponse,
  type ServiceResponse,
} from "_api/data-contracts"
import CitadeleLogo from "_assets/citadele-logo.png"
import GoogleMeetsLogo from "_assets/google-meets-logo.png"
import KredaLogo from "_assets/kreda-logo.png"
import LkuLogo from "_assets/lku-logo.png"
import LocationMarkerLogo from "_assets/location.svg"
import LuminorLogo from "_assets/luminor-logo.png"
import MedicinosBankasLogo from "_assets/medicinos-bankas-logo.png"
import PhoneLogo from "_assets/phone.svg"
import SebLogo from "_assets/seb-logo.png"
import SiauliuBankasLogo from "_assets/siauliu-bankas-logo.png"
import SwedbankLogo from "_assets/swedbank-logo.png"
import ZoomLogo from "_assets/zoomApp.png"
import { type SelectOption } from "_components/LengvaSelect"
import { type TFunction } from "i18next"
import moment from "moment-timezone"
import { ReactCountryFlag } from "react-country-flag"

export const getServiceLocationMap = (
  services: ServiceResponse[],
): Map<number, LocationResponse[]> => {
  const locationServiceMap = new Map<number, LocationResponse[]>()

  services.forEach(service => {
    service.schedules?.forEach((schedule: ScheduleResponse) => {
      if (!schedule.locations) {
        return
      }

      const currentLocations = locationServiceMap.get(service.id)
      if (!currentLocations) {
        locationServiceMap.set(service.id, schedule.locations)
        return
      }

      schedule.locations.forEach((location: LocationResponse) => {
        if (
          !currentLocations.some(
            (currentLocation: LocationResponse) =>
              currentLocation.id === location.id,
          )
        ) {
          currentLocations.push(location)
        }
      })

      locationServiceMap.set(service.id, currentLocations)
    })
  })
  return locationServiceMap
}

export const getServiceLocationOptionMap = (
  services: ServiceResponse[],
  t: TFunction,
): Map<number, SelectOption[]> => {
  const locationOptionServiceMap = new Map<number, SelectOption[]>()

  services.forEach(service => {
    service.schedules?.forEach((schedule: ScheduleResponse) => {
      if (!schedule.locations) {
        return
      }

      const locationOptions: SelectOption[] = schedule.locations.map(
        (location: LocationResponse) => ({
          label: getLocationName(t, location),
          value: location.id ?? "",
          icon: locationIconMap.get(location.meetingProvider) ?? null,
        }),
      )

      if (locationOptions) {
        const currentLocationOptions = locationOptionServiceMap.get(
          service.id,
        )

        if (currentLocationOptions) {
          locationOptions.forEach((locationOption: SelectOption) => {
            if (
              !currentLocationOptions.some(
                currentLocationOption =>
                  currentLocationOption.value
									=== locationOption.value,
              )
            ) {
              currentLocationOptions.push(locationOption)
            }
          })

          locationOptionServiceMap.set(
            service.id,
            currentLocationOptions,
          )
        } else {
          locationOptionServiceMap.set(service.id, locationOptions)
        }
      }
    })
  })

  return locationOptionServiceMap
}

export const getLocationName = (t: TFunction, location?: LocationResponse) => {
  if (!location) {
    return ''
  }

  if (location.meetingProvider === MeetingProvider.GOOGLE_MEET) {
    return 'Google Meet'
  }

  if (location.meetingProvider === MeetingProvider.ZOOM) {
    return 'Zoom'
  }

  if (location.meetingProvider === MeetingProvider.CALL) {
    return t('Phone call')
  }

  if (location.name) {
    return location.name
  }

  if (location.defaultAddress) {
    return location.defaultAddress
  }

  return ''
}

export const setDateHours = (date: Date, hours: number, minutes?: number): Date => {
  const newDate = moment(date).tz('Europe/Vilnius').toDate()
  newDate.setHours(hours)
  newDate.setMinutes(minutes ?? 0)
  newDate.setSeconds(0)

  return newDate
}

export const getDateFromTimeString = (time: string, baseDate: Date): Date => {
  const newDate = moment(time).tz('Europe/Vilnius')
  newDate.year(baseDate.getFullYear())
  newDate.month(baseDate.getMonth())
  newDate.date(baseDate.getDate())

  return newDate.toDate()
}

export enum MeetingProvider {
  GOOGLE_MEET = "GOOGLE_MEET",
  ZOOM = "ZOOM",
  CALL = "CALL",
  IN_PERSON = "IN_PERSON",
}

export const locationMeetingProviderSelectOptions: SelectOption[] = [
  {
    label: "Google Meet",
    value: MeetingProvider.GOOGLE_MEET,
    icon: GoogleMeetsLogo,
  },
  {
    label: "Zoom",
    value: MeetingProvider.ZOOM,
    icon: ZoomLogo,
  },
  {
    label: "Phone",
    value: MeetingProvider.CALL,
    icon: PhoneLogo,
  },
  {
    label: "In Person",
    value: MeetingProvider.IN_PERSON,
    icon: LocationMarkerLogo,
  },
]

export const getLocationMeetingProviderSelectOptions = (t: TFunction, locations?: LocationResponse[]): SelectOption[] => {
  const locationOptions: SelectOption[] = [
    {
      label: "Google Meet",
      value: MeetingProvider.GOOGLE_MEET,
      icon: GoogleMeetsLogo,
    },
    {
      label: "Zoom",
      value: MeetingProvider.ZOOM,
      icon: ZoomLogo,
    },
    {
      label: t('Phone'),
      value: MeetingProvider.CALL,
      icon: PhoneLogo,
    },
    {
      label: t('InPerson'),
      value: MeetingProvider.IN_PERSON,
      icon: LocationMarkerLogo,
    },
  ]

  if (locations) {
    return locationOptions.filter(
      option =>
        !locations.some(location =>
          location.meetingProvider === option.value
           && option.value !== MeetingProvider.IN_PERSON
           && option.value !== MeetingProvider.CALL),
    )
  }

  return locationOptions
}

export const locationIconMap = new Map<string, string>([
  [MeetingProvider.GOOGLE_MEET, GoogleMeetsLogo],
  [MeetingProvider.ZOOM, ZoomLogo],
  [MeetingProvider.CALL, PhoneLogo],
  [MeetingProvider.IN_PERSON, LocationMarkerLogo],
])

export const convertTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return `${hours}h ${mins}min`
}

export const getDate = (date: Date, time: Date): Date => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  const hours = time.getHours()
  const minutes = time.getMinutes()

  return new Date(year, month, day, hours, minutes)
}

export const itemColorArray = ["#9ADDD5", "#C3D5F6", "#A19AFC", "#F79A9A"]

export const bankLogos = [
  SwedbankLogo,
  SebLogo,
  LuminorLogo,
  CitadeleLogo,
  SiauliuBankasLogo,
  MedicinosBankasLogo,
  LkuLogo,
  KredaLogo,
]

export enum PaymentMethod {
  paymentInitiation = "paymentInitiation",
  cardPayments = "cardPayments",
  paypal = "paypal",
}

export enum PaymentProvider {
  PAYPAL = "PAYPAL",
  MONTONIO = "MONTONIO",
}

export enum PaymentSubtype {
  CARD = "CARD",
  BANK = "BANK",
  APPLE_PAY = "APPLE_PAY",
  GOOGLE_PAY = "GOOGLE_PAY",
}

export enum WeekDayEnum {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export const phoneNumberPrefixOptions: SelectOption[] = [
  {
    value: "+370",
    label: "+370",
  },
]

export const getRoleName = (role: "ADMIN" | "MANAGER" | "SPECIALIST" | "USER" | undefined, t: TFunction) => {
  switch (role) {
    case "ADMIN": {
      return t("Admin")
    }

    case "MANAGER": {
      return t("Manager")
    }

    case "SPECIALIST": {
      return t("Specialist")
    }

    case "USER": {
      return t("User")
    }

    default: {
      return ""
    }
  }
}
