import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from "@mui/icons-material"
import { DateCalendar, DayCalendarSkeleton, LocalizationProvider, PickersDay, type PickersDayProps } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { useField } from "formik"
import moment, { type Moment } from "moment"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

interface Props {
  label: string;
  highlightedDays: Date[];
  fetchAvailableDays?: (date: Date) => void;
}

const LengvaCalendarPicker = ({label, highlightedDays, fetchAvailableDays}: Props) => {
  const platform = useSearchParams()[0].get('platform')
  moment.locale('en', {
    week: {
      dow: 1,
    },
  })
  moment.locale('en')

  const [field, meta, helper] = useField(label)
  const [value, setValue] = useState<Moment | null>(moment(field.value))
  const [mainColor, setMainColor] = useState<string>('#3872E1')

  useEffect(() => {
    if (platform === 'mind') {
      setMainColor("#75451D")
    }
  }, [platform])

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
    >
      <DateCalendar
        disablePast
        value={value}
        dayOfWeekFormatter={(day, date) => date.format("ddd")}
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          border: '1px solid #E9E9EB',
          borderRadius: "12px",
          padding: "0.75rem",
          boxSizing: "border-box",
          "&.MuiDateCalendar-root": {
            padding: "1rem",
            gap: "1rem",
          },
          "& .MuiPickersFadeTransitionGroup-root": {
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
            color: mainColor,
          },
          "& .MuiPickersArrowSwitcher-root": {
            gap: "0.75rem",
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            },
          },
          "& .MuiPickersArrowSwitcher-spacer": {
            width: "16px",
          },
          "& .MuiPickersCalendarHeader-label": {
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#202632",
          },
          "& .MuiPickersArrowSwitcher-button": {
            padding: "12px",
            borderRadius: "12px",
            border: '1px solid #E9E9EB',
            backgroundColor: "#F5F5F5",
            "&.Mui-disabled": {
              backgroundColor: "transparent",
              "& .MuiSvgIcon-root": {
                color: mainColor,
                opacity: 0.3,
              },
            },
          },
          "& .MuiPickersCalendarHeader-root": {
            padding: "0px",
          },
          "& .MuiPickersDay-root.Mui-selected": {
            backgroundColor: mainColor,
            fontWeight: 600,
            "&:hover": {
              backgroundColor: mainColor,
            },
            "&:focus": {
              backgroundColor: mainColor,
            },
          },
          "& .MuiPickersDay-today": {
            border: "none !important",
          },
          "& .MuiDayCalendar-header": {
            width: "100%",
            justifyContent: "space-between",
          },
          "& .MuiDayCalendar-weekContainer": {
            justifyContent: "space-between",
          },
        }}
        views={['day']}
        renderLoading={() => <DayCalendarSkeleton/>}
        slots={{
          day: AvailableDays,
          leftArrowIcon: KeyboardArrowLeftRounded,
          rightArrowIcon: KeyboardArrowRightRounded,
        }}
        slotProps={{
          day: {
            highlightedDays,
            mainColor,
          } as any,
        }}
        onMonthChange={date => {
          if (fetchAvailableDays) {
            fetchAvailableDays(date.toDate())
          }
        }}
        onChange={value => {
          field.onChange({ target: { name: label, value: value?.toDate()}})
          setValue(value)
        }}
      />
    </LocalizationProvider>
  )
}

const AvailableDays = (props: PickersDayProps<Moment> & { highlightedDays?: Date[] } & { mainColor?: string }) => {
  const { highlightedDays = [], mainColor, day, outsideCurrentMonth, ...other } = props

  const isAvailable
        = !props.outsideCurrentMonth && highlightedDays.some(d => moment(d).isSame(day, 'day'))

  return (
    <PickersDay
      {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}
      disabled={!isAvailable}
      sx={{
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontStyle: "normal",
        lineHeight: "20px",
        background: isAvailable ? "#F4F4F5" : "inherit",
        color: isAvailable ? mainColor : "#757980",
        fontWeight: isAvailable ? 600 : 400,
      }}
    />
  )
}

export default LengvaCalendarPicker
