/* eslint-disable new-cap */
import { Avatar, Box, Divider, FormControl, FormControlLabel, Hidden, Radio, RadioGroup, Typography, styled, useMediaQuery } from "@mui/material"
import { type Account } from "_api/Account"
import { type Appointments } from "_api/Appointments"
import { type Auth } from "_api/Auth"
import { type AvailableTimes } from "_api/AvailableTimes"
import { type Services } from "_api/Services"
import { type AccountPublicResponse, type AppointmentCreateRequest, type AvailableTimesRequest, type AvailableTimesResponse, type MeResponse, type RegisterConfirmationRequest, type ServiceResponse } from "_api/data-contracts"
import ApplePayLogo from "_assets/apple-pay-logo.png"
import ArrowNarrowLeft from "_assets/arrow-narrow-left.svg"
import ArrowNarrowLeftBrown from "_assets/arrow-narrow-left-brown.svg"
import ArrowNarrowRight from "_assets/arrow-narrow-right.svg"
import CalendarIcon from "_assets/calendar.svg"
import GlobeIcon from "_assets/globe.svg"
import LengvaLogo from "_assets/lengva-logo.svg"
import PayPalLogo from "_assets/paypal-logo.png"
import VisaMastercardLogo from "_assets/visa-mastercard-logo.png"
import LengvaButton from "_components/LengvaButton"
import LengvaCalendarPicker from "_components/LengvaCalendarPicker"
import LengvaInput from "_components/LengvaInput"
import LengvaSelect, { type SelectOption } from "_components/LengvaSelect"
import LengvaStepper from "_components/LengvaStepper"
import LengvaTimeSelect from "_components/LengvaTimeSelect"
import { FormContainer, RegisterPageContainer, RegisterPageInnerContainer, getButtonVariant } from "_pages/public/auth/RegisterLoginPage"
import { phoneNumberCodes } from "_pages/public/auth/SpecialistConfirmRegistrationPage"
import useStore from "_state/useStore"
import { createApi } from "_utils/ApiCreator"
import AutoSubmitToken from "_utils/AutoSubmitToken"
import { bankLogos, getServiceLocationOptionMap } from "_utils/ObjectUtils"
import { Formik, type FormikHelpers } from "formik"
import { type TFunction } from "i18next"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import * as yup from "yup"

interface UserConfirmRegistrationPageProps {
  isAppointmentRegistration?: boolean;
}

const UserConfirmRegistrationPage = ({ isAppointmentRegistration }: UserConfirmRegistrationPageProps) => {
  const { specialistId } = useParams<{specialistId: string}>()
  const platform = useSearchParams()[0].get('platform')
  const { getMe, account } = useStore()
  const { t } = useTranslation('translation', { keyPrefix: 'UserConfirmRegistrationPage' })
  const steps = isAppointmentRegistration ? appointmentBookingSteps(t) : defaultSteps(t)
  const validationSchemaArray = isAppointmentRegistration ? validationSchemaAppointment(t) : validationSchemaArrayRegister(t)

  const [stage, setStage] = useState<number>(0)
  const [currentStep, setCurrentStep] = useState<string>(steps[0])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>("")
  const [specialist, setSpecialist] = useState<AccountPublicResponse | null>()
  const [services, setServices] = useState<ServiceResponse[]>([])
  const [availableTimes, setAvailableTimes] = useState<AvailableTimesResponse[]>([])
  const [lastFetchedDate, setLastFetchedDate] = useState<Date>(moment().toDate())
  const [isFreeService, setIsFreeService] = useState<boolean>(false)

  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const authAPI = useRef(createApi("auth") as Auth)
  const accountAPI = useRef(createApi("account") as Account)
  const serviceAPI = useRef(createApi("service") as Services)
  const appointmentAPI = useRef(createApi("appointment") as Appointments)
  const availableTimeAPI = useRef(createApi("availableTime") as AvailableTimes)
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })

  useEffect(() => {
    getSpecialist()
    getServices()
  // eslint-disable-next-line
  }, [])

  const getSpecialist = async () => {
    if (!specialistId) {
      return
    }

    const specialistResponse = await accountAPI.current.findSpecialistById(Number(specialistId))
    setSpecialist(specialistResponse)
  }

  const getServices = async () => {
    if (!specialistId) {
      return
    }

    const servicesResponse = await serviceAPI.current.findAllBySpecialistId(Number(specialistId))
    setServices(servicesResponse)
  }

  const getAvailableTimes = async (locationId: number, serviceId: number, dateFrom: Date, dateTo: Date) => {
    if (!specialistId) {
      return
    }

    if (moment(dateTo).isBefore(lastFetchedDate)) {
      return
    }

    const fromDate = moment(dateFrom).isBefore(lastFetchedDate) ? lastFetchedDate : dateFrom

    const availableTimeRequest: AvailableTimesRequest = {
      specialistId: Number(specialistId),
      locationId,
      serviceId,
      dateFrom: fromDate.toISOString(),
      dateTo: dateTo.toISOString(),
    }

    const response = await availableTimeAPI.current.findAvailableTimes(availableTimeRequest)
    if (availableTimes.length === 0) {
      setAvailableTimes(response)
    } else {
      setAvailableTimes([...availableTimes, ...response])
    }

    setLastFetchedDate(dateTo)
  }

  const setNextStage = (formikHelpers: FormikHelpers<FormValues>) => {
    if (stage !== steps.length - 1) {
      setCurrentStep(steps[stage + 1])
      setStage(stage + 1)
    }
  }

  const setPreviousStage = () => {
    if (stage !== 0) {
      setCurrentStep(steps[stage - 1])
      setStage(stage - 1)
    }
  }

  const handleSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    if (!specialistId && stage === 0 && !isAppointmentRegistration) {
      const confirmationRequest: RegisterConfirmationRequest = {
        name: values.name,
        lastName: values.lastName,
        phoneNumber: `${values.phoneNumberPrefix}${values.phoneNumber}`,
      }

      await authAPI.current.registerConfirmation(confirmationRequest)
      await getMe()
      navigate("/appointments")
      window.location.href = "/appointments"
    }

    // eslint-disable-next-line
    if ((((stage === 0 && isAppointmentRegistration) || (stage === 1 && !isAppointmentRegistration))) && specialistId && values.service && values.meetingPlace) {
      const dateFrom = moment().add(1, 'days').toDate()
      const dateTo = moment().add(2, 'months').toDate()
      getAvailableTimes(Number(values.meetingPlace), Number(values.service), dateFrom, dateTo)
    }

    if (currentStep !== steps.at(-1) && (!isFreeService || stage !== steps.length - 2)) {
      setNextStage(formikHelpers)
    }

    if ((stage === steps.length - 1) || (stage === steps.length - 2 && isFreeService)) {
      if (!isAppointmentRegistration) {
        const confirmationRequest: RegisterConfirmationRequest = {
          name: values.name,
          lastName: values.lastName,
          phoneNumber: `${values.phoneNumberPrefix}${values.phoneNumber}`,
        }

        await authAPI.current.registerConfirmation(confirmationRequest)
      }

      if (!specialistId || !values.meetingDate || !values.service || !values.meetingPlace || !values.meetingTime.startTime || !values.meetingTime.endTime) {
        return
      }

      const appointmentCreateRequest: AppointmentCreateRequest = {
        specialistId: Number(specialistId),
        startDate: new Date(values.meetingTime.startTime).toISOString() ?? "",
        endDate: new Date(values.meetingTime.endTime).toISOString() ?? "",
        serviceId: Number(values.service),
        locationId: Number(values.meetingPlace),
        paymentMethod: values.paymentMethod as any, // TODO: nzn kaip kitaip
      }

      if (isFreeService) {
        const response = await appointmentAPI.current.createFree(appointmentCreateRequest)
        navigate(`/payment-process/${response.id}`)
        return
      }

      const paymentUrl = await appointmentAPI.current.create(appointmentCreateRequest)
      window.location.href = paymentUrl
    }
  }

  const serviceOptions: SelectOption[] = services.map((service: ServiceResponse) => ({
    label: service.serviceDescription?.name ? tServices.t(`Services.${service.serviceDescription?.name}`) : "",
    value: service.id ?? 0,
  }))

  const locationOptionServiceMap = getServiceLocationOptionMap(services, t)

  return (
    <RegisterPageContainer>
      <RegisterPageInnerContainer>
        {/* {!isAppointmentRegistration && (
          <img
            style={{
              maxWidth: "162px",
            }}
            src={LengvaLogo as any}
            alt='logo'
          />
        )} */}
        {specialist && (
          <Box
            display='flex'
            alignItems='center'
            gap='12px'
          >
            <Typography variant={isMobile ? 'body2' : 'body'}>
              {t("Meeting reservation with")}
            </Typography>
            <Box
              display='flex'
              alignItems='center'
              gap='8px'
            >
              <Avatar
              // TODO: add profile picture
                alt='specialist' src=''
                sx={{
                  width: isMobile ? '24px !important' : "32px",
                  height: isMobile ? '24px !important' : "32px",

                }}
              />
              <Typography variant={isMobile ? 'body2' : 'body'}>
                {specialist?.name}
              </Typography>
            </Box>
          </Box>
        )}
        {stage !== 4 && specialistId && (
          <LengvaStepper
            steps={steps}
            activeStep={stage}
            colorActiveOverride={platform === "mind" ? "primary.mind" : undefined}
            colorInactiveOverride={platform === "mind" ? "rgba(117, 69, 29, .15)" : undefined}
          />
        )}
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={getInitialValues(account)}
          validationSchema={validationSchemaArray[stage]}
          onSubmit={handleSubmit}
        >
          {(formik: any) => (
            <>
              {stage !== steps.length - 1 && (
                <FormContainer>
                  {stage === steps.length - 4 && (
                    <>
                      <LengvaInput label='name' title={t("Name")}/>
                      <LengvaInput label='lastName' title={t("LastName")}/>
                      <Box
                        width='100%'
                        display='flex'
                        gap='1rem'
                        sx={{alignItems: 'end'}}
                      >
                        <Box
                          width='100%'
                          maxWidth={148}
                        >
                          <LengvaSelect
                            label='phoneNumberPrefix'
                            title={t("PhoneNumber")}
                            optionsMain={phoneNumberCodes}
                          />
                        </Box>
                        <LengvaInput label='phoneNumber'/>
                      </Box>
                      {/* TODO: Enable when SMS confirmation is implemented */}
                      {/* <LengvaButton
                        sx={{
                          width: "100%",
                        }}
                        title={t("ConfirmViaSMS")}
                      /> */}
                    </>
                  )}
                  {stage === steps.length - 3 && (
                    <>
                      <LengvaSelect
                        label='service'
                        title={t("Service")}
                        optionsMain={serviceOptions}
                      />
                      <AutoSubmitToken
                        dependantValue={formik.values.service}
                        handleSubmit={() => {
                          const service = services.find(service => service.id === Number(formik.values.service))
                          if (service?.price === 0) {
                            setIsFreeService(true)
                            formik.setFieldValue('paymentMethod', undefined)
                          } else {
                            setIsFreeService(false)
                          }

                          setLastFetchedDate(moment().toDate())
                          setAvailableTimes([])
                          formik.setFieldValue('meetingDate', null)
                        }}
                      />
                      <LengvaSelect
                        label='meetingPlace'
                        title={t("MeetingPlace")}
                        optionsMain={locationOptionServiceMap.get(Number(formik.values.service)) ?? []}
                      />
                    </>
                  )}
                  {stage === steps.length - 2 && (
                    <>
                      <Box
                        width='100%'
                        display='flex'
                        gap='1.5rem'
                      >
                        {((isMobile && !formik.values.meetingDate) || !isMobile) && (
                          <>
                            <LengvaCalendarPicker
                              label='meetingDate'
                              highlightedDays={availableTimes.map(availableTime => moment(availableTime.date).toDate())}
                              fetchAvailableDays={(date: Date) => {
                                getAvailableTimes(Number(formik.values.meetingPlace), Number(formik.values.service), moment(date).toDate(), moment(date).add(2, 'months').toDate())
                              }}
                            />
                            <AutoSubmitToken
                              dependantValue={formik.values.meetingDate}
                              handleSubmit={() => {
                                formik.setFieldValue('meetingTime.startTime', null)
                                formik.setFieldValue('meetingTime.endTime', null)
                              }}
                            />
                          </>
                        )}
                        {formik.values.meetingDate && !isMobile && (
                          <Box
                            width='100%'
                            maxWidth='40%'
                            sx={{
                              overflow: 'auto',
                              maxHeight: '500px',
                            }}
                          >
                            <LengvaTimeSelect
                              options={getAvailableTimesForDate(availableTimes, formik.values.meetingDate)}
                              label='meetingTime'
                              containerStyle={{
                                maxHeight: '384px',
                                overflow: 'auto',
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                      {isMobile && formik.values.meetingDate && (
                        <Box
                          width='100%'
                          display='flex'
                          alignItems='center'
                          boxSizing='border-box'
                          padding='0.75rem 1rem'
                          border='1px solid #9F9F9F'
                          borderRadius='12px'
                          justifyContent='space-between'
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            formik.setFieldValue('meetingDate', null)
                            formik.setFieldValue('meetingTime', null)
                          }}
                        >
                          <Typography variant='body'>
                            {moment(formik.values.meetingDate).format("YYYY.MM.DD")}
                          </Typography>
                          <img
                            src={CalendarIcon as any}
                            alt='calendar-icon'
                          />
                        </Box>
                      )}
                      <Box
                        width='100%'
                        display='flex'
                        gap='0.75rem'
                        alignItems='center'
                        boxSizing='border-box'
                      >
                        <img
                          src={GlobeIcon as any}
                          alt='globe-icon'
                        />
                        <Typography variant='body' fontSize={14} color='text.secondary'>
                          {`Time zone: ${Intl.DateTimeFormat().resolvedOptions().timeZone} (${moment().format("hh:mm")})`}
                        </Typography>
                      </Box>
                      {isMobile && formik.values.meetingDate && (
                        <LengvaTimeSelect
                          options={getAvailableTimesForDate(availableTimes, formik.values.meetingDate)}
                          label='meetingTime'
                        />
                      )}
                    </>
                  )}
                </FormContainer>
              )}
              {stage === steps.length - 1 && (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='1rem'
                >
                  <Hidden smDown>
                    <AppointmentCard
                      serviceName={serviceOptions.find(option => option.value === formik.values.service)?.label ?? ""}
                      meetingPlace={locationOptionServiceMap.get(Number(formik.values.service))?.find(option => option.value === formik.values.meetingPlace)?.label ?? ""}
                      meetingDate={formik.values.meetingDate}
                      meetingStartTime={moment(formik.values.meetingTime.startTime).format("HH:mm")}
                      meetingEndTime={moment(formik.values.meetingTime.endTime).format("HH:mm")}
                      containerStyle={{
                        display: 'flex',
                        boxShadow: '0px 3px 17px 0px rgba(0, 0, 0, 0.14)',
                        borderRadius: '24px',
                        padding: '24px 32px',
                        gap: '32px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      locationIcon={locationOptionServiceMap.get(Number(formik.values.service))?.find(option => option.value === formik.values.meetingPlace)?.icon ?? ""}
                    />
                  </Hidden>
                  <FormContainer>
                    <Typography variant='h5' alignSelf='flex-start' fontSize={18}>
                      {t("Payment methods")}
                    </Typography>
                    <RadioGroupPaymentButtons
                      selectedValue={selectedPaymentMethod}
                      setSelectedValue={(value: string) => {
                        setSelectedPaymentMethod(value)
                        formik.setFieldValue('paymentMethod', value)
                      }}
                    />
                  </FormContainer>
                </Box>
              )}
              {stage !== steps.length && (
                <Box
                  width='100%'
                  display='flex'
                  justifyContent={stage === 0 ? 'flex-end' : 'space-between'}
                >
                  {stage !== 0 && (
                    <LengvaButton
                      title={t("Back")}
                      color={getButtonVariant("secondary", platform)}
                      sx={{
                        maxWidth: "116px",
                        gap: "0.5rem",
                      }}
                      icon={platform === "mind" ? ArrowNarrowLeftBrown : ArrowNarrowLeft}
                      onClick={setPreviousStage}
                    />
                  )}
                  <LengvaButton
                    title={stage === steps.length - 1 ? t('Pay now') : t('Next')}
                    sx={{
                      maxWidth: "116px",
                      gap: "0.5rem",
                    }}
                    color={getButtonVariant("primary", platform)}
                    endIcon={stage === steps.length - 1 ? "" : ArrowNarrowRight}
                    onClick={() => formik.handleSubmit()}
                  />
                </Box>
              )}
            </>
          )}
        </Formik>
      </RegisterPageInnerContainer>
    </RegisterPageContainer>
  )
}

const getAvailableTimesForDate = (availableTimes: AvailableTimesResponse[], date: Date) => {
  const availableTimesForDate = availableTimes.find(availableTime => moment(availableTime.date).isSame(date, 'day'))
  return availableTimesForDate?.availableTimes ?? []
}

const LabelBox = styled(Box)(({ selected, mainColor }: {selected: boolean; mainColor: string}) => ({
  width: '100%',
  display: 'flex',
  border: '1px solid ' + (selected ? mainColor : '#E9E9EB'),
  padding: '14px 24px',
  boxSizing: 'border-box',
  borderRadius: '12px',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    borderColor: mainColor,
  },
}))

const LogoContainer = styled(Box)({
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  flexWrap: 'wrap',
})

const getMeetingTimes = (meetingDate: Date, meetingTime: string) => {
  const [startingTime, endingTime] = meetingTime.split(" - ")
  return {
    startingTime: new Date(meetingDate.getFullYear(), meetingDate.getMonth(), meetingDate.getDate(), Number(startingTime.split(":")[0]), Number(startingTime.split(":")[1])),
    endingTime: new Date(meetingDate.getFullYear(), meetingDate.getMonth(), meetingDate.getDate(), Number(endingTime.split(":")[0]), Number(endingTime.split(":")[1])),
  }
}

interface FormValues {
  name: string;
  lastName: string;
  phoneNumberPrefix: string;
  phoneNumber: string;
  service: string;
  meetingPlace: string;
  meetingDate: Date | null;
  meetingTime: {
    startTime: Date | null;
    endTime: Date | null;
  };
  paymentMethod?: string;
}

const getInitialValues = (account: MeResponse | undefined): FormValues => ({
  name: account?.name ?? "",
  lastName: account?.lastName ?? "",
  phoneNumberPrefix: account?.phoneNumber ? account.phoneNumber.slice(0, 4) : "",
  phoneNumber: account?.phoneNumber ? account.phoneNumber.slice(4) : "",
  service: "",
  meetingPlace: "",
  meetingDate: null,
  meetingTime: {
    startTime: null,
    endTime: null,
  },
  paymentMethod: "",
})

const validationSchemaPageOne = (t: TFunction) => yup.object({
  name: yup.string().required(t("ValidationErrors.RequiredField")),
  lastName: yup.string().required(t("ValidationErrors.RequiredField")),
  phoneNumberPrefix: yup.string().required(t("ValidationErrors.RequiredField")).oneOf(phoneNumberCodes.map(code => code.value), t("ValidationErrors.ChoosePhoneCode")),
  phoneNumber: yup.number().required(t("ValidationErrors.RequiredField"))
    .typeError(t("ValidationErrors.WrongPhoneNumber"))
    .min(10_000_000, t("ValidationErrors.WrongPhoneNumber"))
    .max(99_999_999, t("ValidationErrors.WrongPhoneNumber")),
})

const validationSchemaPageTwo = (t: TFunction) => yup.object({
  service: yup.string().required(t("ValidationErrors.RequiredField")),
  meetingPlace: yup.string().required(t("ValidationErrors.RequiredField")),
})

const validationSchemaPageThree = (t: TFunction) => yup.object({
  meetingDate: yup.string().required(t("ValidationErrors.RequiredField")),
  meetingTime: yup.object().shape({
    startTime: yup.date().required(t("ValidationErrors.RequiredField")),
    endTime: yup.date().required(t("ValidationErrors.RequiredField")),
  }),
})

const validationSchemaPageFour = (t: TFunction) => yup.object({
  paymentMethod: yup.string().required(t("ValidationErrors.RequiredField")),
})

const validationSchemaArrayRegister = (t: TFunction) => [validationSchemaPageOne(t), validationSchemaPageTwo(t), validationSchemaPageThree(t), validationSchemaPageFour(t)]
const validationSchemaAppointment = (t: TFunction) => [validationSchemaPageTwo(t), validationSchemaPageThree(t), validationSchemaPageFour(t)]

const defaultSteps = (t: TFunction): string[] => [t("YourInfo"), t("ChooseLocationAndType"), t("ChooseDateAndTime"), t("Payment")]
const appointmentBookingSteps = (t: TFunction): string[] => [t("ChooseLocationAndType"), t("ChooseDateAndTime"), t("Payment")]

interface AppointmentCardProps {
  serviceName: string;
  meetingPlace: string;
  meetingDate: Date;
  meetingStartTime: string;
  meetingEndTime: string;
  containerStyle?: any;
  isMobile?: boolean;
  locationIcon?: string;
}

export const AppointmentCard = ({ serviceName, meetingPlace, meetingDate, meetingStartTime, meetingEndTime, containerStyle, isMobile, locationIcon }: AppointmentCardProps) => (
  <Box
    sx={containerStyle}
  >
    <Typography variant='body' align='center'>
      {serviceName}
    </Typography>
    <Divider
      orientation={isMobile ? 'horizontal' : 'vertical'}
      sx={{
        borderColor: "#E9E9EB",
        height: isMobile ? "1px" : "44px",
        width: isMobile ? "60%" : "1px",
      }}
    />
    <Box
      display='flex'
      alignItems='center'
      gap='0.75rem'
    >
      <img src={locationIcon} alt=''/>
      <Typography variant='body' color='text.primary' align='center'>
        {meetingPlace}
      </Typography>
    </Box>
    <Divider
      orientation={isMobile ? 'horizontal' : 'vertical'}
      sx={{
        borderColor: "#E9E9EB",
        height: isMobile ? "1px" : "44px",
        width: isMobile ? "60%" : "1px",
      }}
    />
    <Box
      display='flex'
      alignItems='center'
      gap='12px'
    >
      <img src={CalendarIcon as any} alt=''/>
      <Box>
        <Typography variant='body' color='text.secondary'>
          {moment(meetingDate).format("YYYY-MM-DD")},{" "}
        </Typography>
        <Typography variant='body'>
          {meetingStartTime} - {meetingEndTime}
        </Typography>
      </Box>
    </Box>
  </Box>
)

interface RadioGroupPaymentButtonsProps {
  selectedValue: string;
  setSelectedValue: (value: string) => void;
}

const bankItems = [
  {
    value: "paymentInitiation",
    logos: bankLogos,
  },
  {
    value: "paypal",
    logos: [PayPalLogo],
  },
  {
    value: "cardPayments",
    logos: [VisaMastercardLogo, ApplePayLogo],
  },
]

export const RadioGroupPaymentButtons = ({selectedValue, setSelectedValue}: RadioGroupPaymentButtonsProps) => {
  const platform = useSearchParams()[0].get('platform')
  const mainColor = platform === 'mind' ? "#75451D" : "#3872E1"
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value)
  }

  return (
    <Box width='100%'>
      <FormControl component='div' sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <RadioGroup
          aria-labelledby='radio-buttons-group-label'
          name='radio-buttons-group'
          value={selectedValue}
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', width: '100%' }}
          onChange={handleChange}
        >
          {bankItems.map(item => (
            <LabelBox
              key={item.value}
              mainColor={mainColor}
              selected={selectedValue === item.value}
              onClick={() => setSelectedValue(item.value)}
            >
              <LogoContainer
                sx={{
                  gap: item.value === "cardPayments" ? "16px" : "6px",
                }}
              >
                {item.logos.map(logo => (
                  <img
                    key={logo}
                    src={logo}
                    alt=''
                    style={{ maxWidth: item.value === "paymentInitiation" ? "128px" : "160px", margin: '10px 0'}}
                  />
                ))}
              </LogoContainer>
              <FormControlLabel
                sx={{
                  "& .Mui-checked": {
                    color: `${mainColor} !important`,
                  },
                }}
                value={item.value} control={<Radio/>} label=''/>
            </LabelBox>
          ))}

        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default UserConfirmRegistrationPage
