import { Box, useMediaQuery } from "@mui/material"
import { type LocationResponse } from "_api/data-contracts"
import LengvaButton from "_components/LengvaButton"
import LengvaInput from "_components/LengvaInput"
import LengvaSelect from "_components/LengvaSelect"
import useStore from "_state/useStore"
import AutoSubmitToken from "_utils/AutoSubmitToken"
import { getLocationMeetingProviderSelectOptions, locationMeetingProviderSelectOptions, MeetingProvider } from "_utils/ObjectUtils"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from 'yup'

interface Props {
  location?: LocationResponse;
  handleSubmit: (values: LocationFormValues) => void;
  handleDelete?: () => void;
  existingLocations?: LocationResponse[];
}

const LocationForm = ({location, handleSubmit, handleDelete, existingLocations}: Props) => {
  const [currentMeetingProvider, setCurrentMeetingProvider] = useState<MeetingProvider | "">("")
  const { account } = useStore()

  const { t } = useTranslation('translation', { keyPrefix: 'LocationForm' })
  const isMobile = useMediaQuery('(max-width: 600px)')

  const validationSchema = yup.object({
    name: yup.string().when('meetingProvider', (meetingProvider, schema) => {
      if (meetingProvider[0] === MeetingProvider.IN_PERSON) {
        return yup.string().required(t('nameRequired'))
      }

      return yup.string()
    }),
    meetingProvider: yup.string().required(t('meetingProviderRequired')),
    defaultAddress: yup.string().when('meetingProvider', (meetingProvider, schema) => {
      if (meetingProvider[0] === MeetingProvider.IN_PERSON) {
        return yup.string().required(t('addressRequired'))
      }

      if (meetingProvider[0] === MeetingProvider.CALL) {
        return yup.string().required(t('phoneNumberRequired')).min(9, t('invalidPhoneNumber')).max(12, t('invalidPhoneNumber'))
      }

      return yup.string()
    }),
  })

  return (
    <Formik
      validateOnChange
      initialValues={getInitialLocationFormValues(location)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
          }}
        >
          {formik.values.meetingProvider === MeetingProvider.IN_PERSON && (
            <LengvaInput
              title={t('Name')}
              label='name'
            />
          )}
          <LengvaSelect
            label='meetingProvider'
            title={t('Meeting Provider')}
            optionsMain={getLocationMeetingProviderSelectOptions(t, existingLocations)}
          />
          {(formik.values.meetingProvider === MeetingProvider.IN_PERSON || formik.values.meetingProvider === MeetingProvider.CALL) && (
            <LengvaInput
              title={formik.values.meetingProvider === MeetingProvider.IN_PERSON ? t('Address') : t('Phone Number')}
              label='defaultAddress'
            />
          )}
          <AutoSubmitToken
            dependantValue={formik.values.meetingProvider}
            handleSubmit={() => {
              if (formik.values.meetingProvider === MeetingProvider.CALL) {
                formik.setFieldValue('defaultAddress', account?.phoneNumber ?? '')
              }

              if (!currentMeetingProvider) {
                setCurrentMeetingProvider(formik.values.meetingProvider)
                return
              }

              if (currentMeetingProvider !== formik.values.meetingProvider) {
                formik.setFieldValue('defaultAddress', '')
                if (formik.values.meetingProvider === MeetingProvider.CALL) {
                  formik.setFieldValue('defaultAddress', account?.phoneNumber ?? '')
                }

                setCurrentMeetingProvider(formik.values.meetingProvider)
              }
            }}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: handleDelete ? 'space-between' : 'flex-end',
              alignItems: 'center',
              paddingTop: '0.5rem',
              position: isMobile ? 'fixed' : 'static',
              bottom: 16,
              width: isMobile ? "calc(100% - 48px)" : '100%',
            }}
          >
            {handleDelete && (
              <LengvaButton
                title={t('Delete')}
                color='secondary'
                onClick={handleDelete}
              />
            )}

            <LengvaButton
              title={t('Save')}
              type='submit'
              disabled={!formik.isValid || formik.isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export interface LocationFormValues {
  name: string;
  defaultAddress: string;
  meetingProvider: MeetingProvider | "";
}

const initialValues: LocationFormValues = {
  name: "",
  defaultAddress: "",
  meetingProvider: "",
}

const getInitialLocationFormValues = (location?: LocationResponse): LocationFormValues => {
  if (!location) {
    return initialValues
  }

  return {
    name: location.name,
    defaultAddress: location.defaultAddress ?? "",
    meetingProvider: location.meetingProvider as MeetingProvider,
  }
}

export default LocationForm
