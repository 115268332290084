/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ScheduleCreateRequest, ScheduleResponse, ScheduleUpdateRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Schedules<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags schedule-specialist-controller
   * @name Update
   * @request PUT:/api/v1/specialist/schedules/{id}
   */
  update = (id: string, data: ScheduleUpdateRequest, params: RequestParams = {}) =>
    this.request<ScheduleResponse, any>({
      path: `/api/v1/specialist/schedules/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags schedule-specialist-controller
   * @name Delete
   * @request DELETE:/api/v1/specialist/schedules/{id}
   */
  delete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/specialist/schedules/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags schedule-specialist-controller
   * @name FindAllBySpecialist
   * @request GET:/api/v1/specialist/schedules
   */
  findAllBySpecialist = (params: RequestParams = {}) =>
    this.request<ScheduleResponse[], any>({
      path: `/api/v1/specialist/schedules`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags schedule-specialist-controller
   * @name Create
   * @request POST:/api/v1/specialist/schedules
   */
  create = (data: ScheduleCreateRequest, params: RequestParams = {}) =>
    this.request<ScheduleResponse, any>({
      path: `/api/v1/specialist/schedules`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
