import { Box, Button, ButtonTypeMap, Input, Typography } from "@mui/material"
import CheckIcon from "../_assets/check.svg"
import InfoIcon from "../_assets/info.svg"
import CloseIcon from "../_assets/close.svg"
import RefreshIcon from "../_assets/refresh-yellow.svg"

interface Props {
  type: "success" | "warning" | "error";
  text: string;
}

export default function LengvaLabel({ text, type }: Props) {
  const getBackground = () => {
    if (type === "success") {
      return "#D1EFDB"
    }

    if (type === "warning") {
      return "#FCEFCE"
    }

    if (type === "error") {
      return "#FBCDCD"
    }
  }

  const getIcon = (): any => {
    if (type === "success") {
      return CheckIcon
    }

    if (type === "warning") {
      return RefreshIcon
    }

    if (type === "error") {
      return CloseIcon
    }

    return CheckIcon
  }

  return (
    <Box
      style={{
        display: "flex",
        padding: "4px 8px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "6px",
        background: getBackground(),
        width: "fit-content",
      }}
    >
      <img src={getIcon()}/> {text}
    </Box>
  )
}
