import {
  Box,
  Hidden,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material"
import { type Account } from "_api/Account"
import { type Appointments } from "_api/Appointments"
import { type Locations } from "_api/Locations"
import { type Services } from "_api/Services"
import {
  type AccountResponse,
  type AppointmentResponse,
  type ClientResponse,
  type LocationResponse,
  type ServiceResponse,
} from "_api/data-contracts"
import CheckGreen from '_assets/check.svg'
import DownloadIcon from "_assets/download.svg"
import PlusIcon from "_assets/plus.svg"
import PrinterIcon from "_assets/printer.svg"
import PendingIcon from '_assets/pending-icon.svg'
import FailedIcon from '_assets/red-cross.svg'
import LengvaButton from "_components/LengvaButton"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import LengvaTable, { type ColumnConfig } from "_components/LengvaTable"
import {
  type FilterItem,
  type FilterValues,
} from "_components/LengvaTableFilterModal"
import { createApi } from "_utils/ApiCreator"
import AutoSubmitToken from "_utils/AutoSubmitToken"
import { convertTime, locationIconMap } from "_utils/ObjectUtils"
import { Formik, type FormikProps } from "formik"
import i18next, { type TFunction } from "i18next"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryPortal,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory"

export default function AppointmentListPage() {
  const [appointments, setAppointments] = useState<AppointmentResponse[]>([])
  const [graphAppointments, setGraphAppointments] = useState<AppointmentResponse[]>([])
  const [graphData, setGraphData] = useState<AppointmentGraphData[]>([])
  const [clients, setClients] = useState<ClientResponse[]>([])
  const [services, setServices] = useState<ServiceResponse[]>([])
  const [locations, setLocations] = useState<LocationResponse[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 600px)")
  const graphStartDate = moment().hour(0).minute(0).second(0).toDate()
  const graphEndDate = moment().add(30, "days").hour(23).minute(59).second(59).toDate()
  const accountApi = useRef(createApi("account") as Account)
  const locationAPI = useRef(createApi("location") as Locations)
  const serviceAPI = useRef(createApi("service") as Services)
  const appointmentAPI = useRef(createApi("appointment") as Appointments)
  const { t } = useTranslation("translation", {
    keyPrefix: "AppointmentListPage",
  })
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })

  useEffect(() => {
    getAppointments()
  // eslint-disable-next-line
  }, [])

  const getServices = async () => {
    const response = await serviceAPI.current.findAllMyServices()
    setServices(response)
  }

  const getLocations = async () => {
    const response = await locationAPI.current.findAllMyLocations()
    setLocations(response)
  }

  const getClients = async () => {
    const response = await accountApi.current.findAllClientsBySpecialist()
    setClients(response)
    // setIsLoading(false)
  }

  const getAppointments = async () => {
    setIsLoading(true)
    const response = await appointmentAPI.current.findAllFilteredBy({})
    const query = {
      dateFrom: graphStartDate.toISOString(),
      dateTo: graphEndDate.toISOString(),
    }

    const graphResponse = await appointmentAPI.current.findAllFilteredBy(query)

    const approvedAppointments = graphResponse.filter(
      appointment => appointment.payments?.map(payment => payment.status).includes("PAID"),
    )

    const newGraphData = mapAppointmentsToGraphData(
      approvedAppointments,
      graphStartDate,
      graphEndDate,
    )

    setAppointments(response)
    setGraphAppointments(graphResponse)
    setGraphData(newGraphData)

    await getServices()
    await getLocations()
    await getClients()
    setIsLoading(false)
  }

  const handleFilterSubmit = async (values: AppointmentFilterValues) => {
    const query = {
      patientId: values.client,
      serviceId: values.service,
      locationId: values.location,
      status: values.status,
      dateFrom: values.dateFrom
        ? moment(values.dateFrom).hour(0).minute(0).second(0).toISOString()
        : undefined,
      dateTo: values.dateTo
        ? moment(values.dateTo).hour(23).minute(59).second(59).toISOString()
        : undefined,
      paymentStatus: values.paymentStatus,
      searchString: values.searchValue,
    }

    const filteredAppointments = await appointmentAPI.current.findAllFilteredBy(
      query,
    )
    setAppointments(filteredAppointments)
  }

  return (
    <Formik initialValues={initialFilterValues} onSubmit={handleFilterSubmit}>
      {(formik: FormikProps<any>) => (
        <DashboardContainer
          sx={{
            paddingTop: "0",
          }}
        >
          <DashboardTitleContainer
            sx={{
              flexWrap: "wrap",
              marginBottom: isMobile ? "0px" : "1rem",
              gap: "1rem",
            }}
          >
            <Typography variant='h2'>{t("Appointments")}</Typography>
            <Box display='flex' gap='1rem' flexWrap='wrap'>
              <LengvaButton
                title={isMobile ? t("New") : t("New appointment")}
                color='primary'
                icon={PlusIcon}
                onClick={() => navigate("/admin/appointments/create")}
              />
              {/* <Hidden mdDown>
                <LengvaButton
                  title={t("Download")}
                  color='secondary'
                  icon={DownloadIcon}
                />
                <LengvaButton
                  title={t("Print")}
                  color='secondary'
                  icon={PrinterIcon}
                />
              </Hidden> */}
            </Box>
          </DashboardTitleContainer>
          {!isMobile && (
            <DashboardSectionContainer
              sx={{
                flexDirection: "row",
              }}
            >
              <DashboardSectionContainer
                flex={1}
                sx={{
                  padding: 0,
                }}
              >
                <PillContainer>
                  <Typography variant='h3'>
                    {
                      graphAppointments.filter(
                        appointment => appointment.payments?.map(payment => payment.status).includes("PAID"),
                      ).length
                    }
                  </Typography>
                  <Typography variant='body' fontSize={14}>
                    {t("Approved")}
                  </Typography>
                </PillContainer>
                <PillContainer>
                  <Typography variant='h3'>
                    {
                      graphAppointments.filter(
                        appointment => !appointment.payments?.map(payment => payment.status).includes("PAID"),
                      ).length
                    }
                  </Typography>
                  <Typography variant='body' fontSize={14}>
                    {t("Pending")}
                  </Typography>
                </PillContainer>
              </DashboardSectionContainer>
              <DashboardSectionContainer
                flex={5}
                sx={{
                  padding: 0,
                }}
              >
                {!isLoading && (
                  <VictoryChart
                    maxDomain={{y: getMaxDomain(graphData)}}
                    minDomain={{y: 0}}
                    width={window.innerWidth}
                    height={300}
                    style={{
                      parent: {
                        height: '100%',
                        width: '100%',
                        border: '1px solid #E9E9EB',
                        borderRadius: '12px',
                        padding: '36px 16px 16px 20px',
                        boxSizing: 'border-box',
                      },
                    }}
                    containerComponent={
                      <VictoryVoronoiContainer
                        height={300}
                        width={window.innerWidth}
                        labels={({ datum }: { datum: any }) => `${datum.appointments}`}
                        labelComponent={
                          <VictoryTooltip
                            style={{
                              fontSize: "20px",
                              fontFamily: 'Open Sans',
                            }}
                            flyoutStyle={{
                              fill: "transparent",
                              stroke: "none",
                            }}
                          />
                        }
                      />
                    }
                  >
                    <VictoryAxis
                      dependentAxis={false}
                      tickCount={4}
                      width={window.innerWidth}
                      style={{
                        axis: { stroke: "transparent" },
                      }}
                      tickLabelComponent={
                        <VictoryLabel
                          style={{
                            fontSize: "24px",
                            fontFamily: 'Open Sans',
                            fill: '#787878',
                            lineHeight: '20px',
                          }}
                        />
                      }
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      axisComponent={<></>}
                    />
                    <VictoryAxis
                      dependentAxis
                      tickCount={getMaxDomain(graphData) < 4 ? getMaxDomain(graphData) : 4}
                      style={{
                        grid: {
                          stroke: '#E9E9EB',
                          strokeWidth: 1,
                          strokeDasharray: '4, 4',
                        },
                      }}
                      tickFormat={tick => tick}
                      tickLabelComponent={
                        <VictoryLabel
                          style={{
                            fontSize: "24px",
                            fontFamily: 'Open Sans',
                            fill: '#787878',
                            lineHeight: '20px',
                          }}
                        />
                      }
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      axisComponent={<></>}
                    />
                    <VictoryPortal>
                      <VictoryLegend
                        x={50}
                        y={-5}
                        title={t('ApprovedAppointments')}
                        orientation='horizontal'
                        style={{ title: {fontSize: 24, fontFamily: 'Open sans', fontWeight: 600, fill: '#202632' } }}
                        data={[]}
                      />
                    </VictoryPortal>

                    <radialGradient id='gradient' cx='50%' cy='50%' r='100%' fx='50%' fy='50%'>
                      <stop offset='0%' style={{ stopColor: "#5C8FBF", stopOpacity: 0.3 }}/>
                      <stop offset='65%' style={{ stopColor: "#5C8FBF", stopOpacity: 0 }}/>
                    </radialGradient>
                    <VictoryArea
                      interpolation='cardinal'
                      x='date'
                      y='appointments'
                      data={graphData}
                      style={{
                        data: { fill: 'url(#gradient)', stroke: '#5C8FBF', strokeWidth: 4, strokeOpacity: 0.5, marginBottom: '1rem' },
                        labels: { margin: "10px"},
                      }}
                      animate={{
                        duration: 1000,
                        onLoad: { duration: 1200 },
                      }}
                    />
                  </VictoryChart>
                )}
                {isLoading && <LengvaLoadingSpinner/> }
              </DashboardSectionContainer>
            </DashboardSectionContainer>
          )}
          <DashboardSectionContainer>
            <Box
              sx={{
                width: "calc(100% + 3rem)",
                marginLeft: "-1.5rem",
              }}
            >
              {!isLoading && (
                <LengvaTable
                  isViewOnly
                  isLoading={isLoading}
                  searchPlaceholder={t("Search appointments")}
                  title='Rezervacijos'
                  columns={createAppointmentConfig(appointments.map((appointment: AppointmentResponse) => appointment.patient))}
                  viewMore={(appointment: any) => navigate(`/admin/appointments/${appointment.id}`)}
                  data={mapAppointmentsToTableValues(appointments, tServices.t, t)}
                  filter={getAppointmentFilterConfig(services, locations, clients, t, tServices.t)}
                  rowType='checkbox'
                  allow={["add", "edit", "delete"]}
                  onFilterValueClear={(property: string) => {
                    formik.setFieldValue(property, undefined)
                    formik.handleSubmit() // should the form be instantly submitted here?
                  }} onAllFilterValueClear={() => {
                    formik.setValues(initialFilterValues)
                    formik.handleSubmit() // should the form be instantly submitted here?
                  }}
                  onFilterSubmit={formik.handleSubmit}/>
              )}
              {isLoading && <LengvaLoadingSpinner/>}
            </Box>
          </DashboardSectionContainer>
          <AutoSubmitToken
            handleSubmit={formik.handleSubmit}
            dependantValue={formik.values.searchValue}
            delay={1500}
          />
        </DashboardContainer>
      )}
    </Formik>
  )
}

interface AppointmentTableValue {
  id: number;
  status: string;
  paymentStatus: {
    variant: "success" | "error" | "warning";
    label: string;
    beforeText: string;
  };
  date: string;
  startTime: string;
  service: string;
  serviceLength: string;
  location: string;
  specialist: {
    id: number;
    name: string;
    lastName: string;
    email: string;
  };
  client: {
    id: number;
    name: string;
    lastName: string;
    email: string;
  };
}

interface AppointmentFilterValues {
  client?: number;
  service?: number;
  location?: number;
  status?: "CREATED" | "OCCURRED" | "NOT_OCCURRED" | "CANCELLED" ;
  dateFrom?: Date;
  dateTo?: Date;
  paymentStatus?:
  | "PENDING"
  | "PAID"
  | "VOIDED"
  | "PARTIALLY_REFUNDED"
  | "REFUNDED"
  | "ABANDONED"
  | "AUTHORIZED";
  searchValue?: string;
}

const initialFilterValues: AppointmentFilterValues = {
  client: undefined,
  service: undefined,
  location: undefined,
  status: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  paymentStatus: undefined,
  searchValue: undefined,
}

const getAppointmentFilterConfig = (
  services: ServiceResponse[],
  locations: LocationResponse[],
  clients: ClientResponse[],
  t: TFunction,
  tServices: TFunction,
) => {
  const serviceOptions: FilterValues[] = services.map(
    (service: ServiceResponse) => ({
      title: tServices(`Services.${service.serviceDescription.name}`),
      value: service.id.toString(),
      isActive: false,
    }),
  )

  const locationOptions: FilterValues[] = locations.map(
    (location: LocationResponse) => ({
      title:
        location.name ?? location.defaultAddress ?? location.meetingProvider,
      value: location.id.toString(),
      icon: locationIconMap.get(location.meetingProvider) ?? null,
      isActive: false,
    }),
  )

  const paymentStatusOptions: FilterValues[] = [
    {
      title: t("Paid"),
      value: "PAID",
      isActive: false,
      status: "success",
      icon: CheckGreen,
    },
    {
      title: t("Pending"),
      value: "PENDING",
      isActive: false,
      status: "warning",
      icon: PendingIcon,
    },
  ]

  const appointmentStatusOptions: FilterValues[] = [
    {
      title: t("Happened"),
      value: "OCCURRED",
      isActive: false,
      status: "success",
    },
    {
      title: t("Pending"),
      value: "CREATED",
      isActive: false,
      status: "warning",
    },
    {
      title: t("Cancelled"),
      value: "CANCELLED",
      isActive: false,
      status: "error",
    },
  ]

  const clientOptions: FilterValues[] = clients.map(
    (client: ClientResponse) => ({
      title: `${client.name} ${client.lastName}`,
      value: client.id.toString(),
      isActive: false,
    }),
  )

  const appointmentFilterConfig: FilterItem[] = [
    {
      property: "dateFrom",
      property2: "dateTo",
      label: "Date",
      type: "dateRange",
      showFastFilter: true,
      values: [],
    },
    {
      property: "client",
      label: "Client",
      type: "avatar",
      values: clientOptions,
    },
    {
      property: "service",
      label: "Service",
      type: "chip",
      values: serviceOptions,
    },
    {
      property: "status",
      label: "Status",
      type: "status",
      values: appointmentStatusOptions,
    },
    {
      property: "paymentStatus",
      label: "Payment Status",
      type: "status",
      values: paymentStatusOptions,
    },
  ]

  return appointmentFilterConfig
}

const mapAppointmentsToTableValues = (
  appointments: AppointmentResponse[],
  tServices: TFunction,
  t: TFunction,
): AppointmentTableValue[] => {
  const tableValues: AppointmentTableValue[] = appointments.map(
    (appointment: AppointmentResponse) => ({
      id: appointment.id,
      status: appointment.status,
      paymentStatus: {
        variant: appointment.payments
          ?.map(payment => payment.status)
          .includes("PAID")
          ? "success"
          : "warning",
        label: appointment.payments
          ?.map(payment => payment.status)
          .includes("PAID")
          ? t("Paid")
          : t("Pending"),
        beforeText: `${
          appointment.service.price
        }€`,
      },
      date: new Date(appointment.startDate).toLocaleDateString("lt-LT", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      startTime:
        new Date(appointment.startDate).toLocaleTimeString("lt-LT", {
          hour: "2-digit",
          minute: "2-digit",
        })
        + " - "
        + new Date(appointment.endDate).toLocaleTimeString("lt-LT", {
          hour: "2-digit",
          minute: "2-digit",
        }),
      service: tServices(`Services.${appointment.service.serviceDescription.name}`),
      serviceLength: convertTime(appointment.service.duration),
      location:
        appointment.address && appointment.address !== ""
          ? appointment.address
          : getAppointmentLocationFromMeetingProvider(
            appointment.location.meetingProvider,
          ) ?? "",
      specialist: {
        id: appointment.specialist.id ?? 0,
        name: appointment.specialist.name ?? "",
        lastName: appointment.specialist.lastName ?? "",
        email: appointment.specialist.email ?? "",
      },
      client: {
        id: appointment.patient.id ?? 0,
        name: appointment.patient.name ?? "",
        lastName: appointment.patient.lastName ?? "",
        email: appointment.patient.email ?? "",
      },
    }),
  )

  return tableValues
}

export const getAppointmentLocationFromMeetingProvider = (
  meetingProvider: string,
) => {
  switch (meetingProvider) {
    case "GOOGLE_MEET": {
      return "Google Meet"
    }

    case "ZOOM": {
      return "Zoom"
    }

    case "IN_PERSON": {
      return "In person meeting"
    }

    case "CALL": {
      return "Call"
    }

    default: {
      return ""
    }
  }
}

export const createAppointmentConfig = (accounts: AccountResponse[]) => {
  const account1: AccountResponse = accounts[0]
  const config: ColumnConfig[] = [
    {
      property: "date",
      property2: "startTime",
      label: "Date",
      type: "string",
      hideOnCreate: true,
      orderId: 1,
    },
    {
      property: "client",
      label: "Client",
      type: "string",
      options: accounts.map((account: AccountResponse) => ({
        label: account.name,
        value: account.id,
      })),
      valuesFormFn: (client: any) => client.id,
      valueFn(account: AccountResponse) {
        return `${account?.name} ${account?.lastName}`
      },
      orderId: 2,
    },
    {
      property: "service",
      property2: "serviceLength",
      label: "Service",
      type: "string",
      orderId: 3,
    },
    {
      property: "location",
      label: "Location",
      type: "string",
      hideOnCreate: true,
      orderId: 4,
    },
    {
      property: "paymentStatus",
      label: "Payment Status",
      type: "label",
      hideOnCreate: true,
      orderId: 5,
    },
  ]

  return config
}

export const DashboardContainer = styled("div")(() => {
  const isMobile = useMediaQuery("(max-width: 600px)")
  return {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    boxSizing: "border-box",
    gap: isMobile ? "0.75rem" : "1rem",
  }
})

export const DashboardTitleContainer = styled("div")(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
}))

export const DashboardSectionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1.5rem",
  boxSizing: "border-box",
  gap: "1rem",
  backgroundColor: "#FFFFFF",
  borderRadius: "12px",
}))

export const PillContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  boxSizing: "border-box",
  gap: "4px",
  border: "1px solid #E9E9EB",
  borderRadius: "12px",
  flex: "1",
}))

interface AppointmentGraphData {
  date: string;
  appointments: number;
}

const mapAppointmentsToGraphData = (
  appointments: AppointmentResponse[],
  graphStartDate: Date,
  graphEndDate: Date,
): AppointmentGraphData[] => {
  const graphData: AppointmentGraphData[] = []
  const currentDate = moment(graphStartDate)

  while (currentDate.isSameOrBefore(graphEndDate)) {
    const filteredAppointments = appointments.filter(appointment =>
      moment(appointment.startDate).isSame(currentDate, "day"),
    )
    graphData.push({
      date: currentDate.locale(i18next.language).format("DD MMM"),
      appointments: filteredAppointments.length,
    })
    currentDate.add(1, "day")
  }

  return graphData
}

const getMaxDomain = (graphData: AppointmentGraphData[]) => graphData.reduce(
  (max, p) => (p.appointments > max ? p.appointments : max),
  0,
) + 1

export const sampleData = [
  {
    date: "30 Jul",
    appointments: 7,
  },
  {
    date: "31 Jul",
    appointments: 8,
  },
  {
    date: "01 Aug",
    appointments: 5,
  },
  {
    date: "02 Aug",
    appointments: 2,
  },
  {
    date: "03 Aug",
    appointments: 7,
  },
  {
    date: "04 Aug",
    appointments: 5,
  },
  {
    date: "05 Aug",
    appointments: 7,
  },
  {
    date: "06 Aug",
    appointments: 8,
  },
  {
    date: "07 Aug",
    appointments: 7,
  },
  {
    date: "08 Aug",
    appointments: 9,
  },
  {
    date: "09 Aug",
    appointments: 6,
  },
  {
    date: "10 Aug",
    appointments: 2,
  },
  {
    date: "11 Aug",
    appointments: 4,
  },
  {
    date: "12 Aug",
    appointments: 2,
  },
  {
    date: "13 Aug",
    appointments: 4,
  },
  {
    date: "14 Aug",
    appointments: 6,
  },
  {
    date: "15 Aug",
    appointments: 8,
  },
  {
    date: "16 Aug",
    appointments: 7,
  },
  {
    date: "17 Aug",
    appointments: 8,
  },
  {
    date: "18 Aug",
    appointments: 9,
  },
  {
    date: "19 Aug",
    appointments: 10,
  },
  {
    date: "20 Aug",
    appointments: 11,
  },
  {
    date: "21 Aug",
    appointments: 9,
  },
  {
    date: "22 Aug",
    appointments: 7,
  },
  {
    date: "23 Aug",
    appointments: 6,
  },
  {
    date: "24 Aug",
    appointments: 4,
  },
  {
    date: "25 Aug",
    appointments: 7,
  },
  {
    date: "26 Aug",
    appointments: 9,
  },
  {
    date: "27 Aug",
    appointments: 11,
  },
]

export enum RegistrationStatus {
  CREATED = "CREATED",
  OCCURRED = "OCCURRED",
  NOT_OCCURRED = "NOT_OCCURRED",
  CANCELLED = "CANCELLED",
}
