import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Pagination,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material"
import Switch, { type SwitchProps } from "@mui/material/Switch"
import { styled } from "@mui/material/styles"
import ChevronRight from "_assets/chevron-right.svg"
import cloneDeep from "lodash/cloneDeep"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import AdjustmentIcon from "../_assets/adjustments.svg"
import ArrowDownIcon from "../_assets/arrow-down.svg"
import ChevronDownIcon from "../_assets/chevronDown.svg"
import ChevronUpIcon from "../_assets/chevronUp.svg"
import DotsIcon from "../_assets/dots.svg"
import EditIcon from "../_assets/edit.svg"
import SearchIcon from "../_assets/search.svg"
import TableIcon from "../_assets/table.svg"
import LengvaButton from "./LengvaButton"
import LengvaIcon from "./LengvaIcon"
import LengvaInput from "./LengvaInput"
import LengvaLabel from "./LengvaLabel"
import LengvaLoadingSpinner from "./LengvaLoadingSpinner"
import LengvaSelect from "./LengvaSelect"
import LengvaTableColumnConfigModal from "./LengvaTableColumnConfigModal"
import LengvaTableFilterModal, {
  type FilterItem,
} from "./LengvaTableFilterModal"
import { Formik } from "formik"

interface Props {
  title?: string;
  allow?: Array<"add" | "edit" | "delete">;
  isViewOnly?: boolean;
  columns: ColumnConfig[];
  filter: FilterItem[];
  data: any[];
  rowType?: "dropdown" | "checkbox";
  itemCreated?: Function;
  itemUpdated?: Function;
  itemDeleted?: Function;
  itemCustomFunctionIcon?: any;
  itemCustomFunction?: Function;
  onCreateEditButtonClick?: Function;
  viewMore?: Function;
  showFilters?: boolean;
  showColumnConfig?: boolean;
  selectedAction?: Function;
  selectedActionLabel?: string;
  isHeaderTransparent?: boolean;
  showPagination?: boolean;
  searchPlaceholder?: string;
  onFilterSubmit?: Function;
  onFilterValueClear?: Function;
  onAllFilterValueClear?: Function;
  isLoading?: boolean;
  emptyStateText?: string;
}

export interface ColumnConfig {
  property: string;
  property2?: string;
  label: string;
  type: "boolean" | "string" | "label" | "button" | "avatar" | undefined;
  options?: string[] | any[];
  valuesFormFn?: Function;
  hideOnTable?: boolean;
  hideOnCreate?: boolean;
  readonly?: boolean;
  hideOnForm?: boolean;
  multiple?: boolean;
  valueFn?: Function;
  onClick?: Function;
  orderId: number;
}

export default function LengvaTable({
  title,
  allow = [],
  columns,
  data,
  rowType,
  itemCreated,
  itemUpdated,
  itemDeleted,
  itemCustomFunctionIcon,
  itemCustomFunction,
  isViewOnly,
  viewMore,
  filter,
  onCreateEditButtonClick,
  showFilters = true,
  showColumnConfig = true,
  selectedAction,
  selectedActionLabel,
  isHeaderTransparent,
  showPagination = true,
  searchPlaceholder,
  onFilterSubmit,
  onFilterValueClear,
  onAllFilterValueClear,
  isLoading,
  emptyStateText,
}: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "LengvaTable" })
  const [sideFilterOpen, setSideFilterOpen] = useState<boolean>(false)
  const [sideColumnConfigOpen, setSideColumnConfigOpen]
    = useState<boolean>(false)

  const isAllowed = (toAllow: "add" | "edit" | "delete") =>
    allow.find(a => a === toAllow)

  const handleClearColumnConfig = () => {
    const clearedColumnsConfig = columns.map(columnConfig => ({
      ...columnConfig,
      hideOnTable: false,
    }))
    setActiveColumnConfig(clearedColumnsConfig)
  }

  const toggleFilterDrawer = (open: boolean) => (event: any) => {
    if (
      event
      && event.type === "keydown"
      && (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setSideFilterOpen(open)
  }

  const toggleColumnConfigDrawer = (open: boolean) => (event: any) => {
    if (
      event
      && event.type === "keydown"
      && (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setSideColumnConfigOpen(open)
  }

  const [createUpdateItem, setCreateUpdateItem] = useState<any>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [activeColumnConfig, setActiveColumnConfig]
    = useState<ColumnConfig[]>(columns)
  const [activeFilter, setActiveFilter] = useState<FilterItem[]>(filter)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const handleSelect = (id: number) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter(row => row !== id))
    } else {
      setSelectedRows([...selectedRows, id])
    }
  }

  const isMobile = useMediaQuery("(max-width: 600px)")

  const rowIds = data.map(item => item.id)
  const handleSelectMultiple = () => {
    if (rowIds.length === selectedRows.length) {
      setSelectedRows([])
      return
    }

    setSelectedRows(rowIds)
  }

  const handleFilterChange = (filterItem: FilterItem, key: any) => {
    const targetFilterItem = activeFilter.find(
      filter => filter.property === filterItem.property,
    )
    if (!targetFilterItem) {
      return
    }

    const clieredSelect
      = filterItem.type === "select"
        ? targetFilterItem.values.map(value => {
          if (!value.isActive) {
            return value
          }

          return { ...value, isActive: false }
        })
        : targetFilterItem.values
    const modifiedItem = clieredSelect.map(item => {
      if (item.value !== key) {
        return item
      }

      item.isActive = !item.isActive
      return item
    })
    const filter = activeFilter.map(item => {
      if (item.property !== targetFilterItem.property) {
        return item
      }

      return { ...item, values: modifiedItem }
    })
    setActiveFilter(filter)
  }

  const handleClearFilterItem = (filterItem: FilterItem) => {
    const item = activeFilter.find(
      value => value.property === filterItem.property,
    )
    if (!item) {
      return
    }

    const clieredValues = item.values.map(value => ({
      ...value,
      isActive: false,
    }))
    const result = activeFilter.map(item => {
      if (item.property !== filterItem.property) {
        return item
      }

      return { ...filterItem, values: clieredValues }
    })
    setActiveFilter(result)
  }

  const handleClearAllFilters = () => {
    const item = activeFilter.map(filter => {
      const values = filter.values.map(value => ({
        ...value,
        isActive: false,
      }))
      return { ...filter, values }
    })
    setActiveFilter(item)
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const deepClone = (object: any) => cloneDeep(object)

  if (isLoading) {
    return (
      <LengvaLoadingSpinner/>
    )
  }

  return (
    <Formik initialValues={{}} onSubmit={() => console.log("lengva table")}>
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Box style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        {(showFilters || showColumnConfig) && (
          <Box
            style={{
              display: "flex",
              gap: "16px",
              overflow: "auto",
              width: "100%",
              boxSizing: "border-box",
              alignItems: isMobile ? "flex-start" : "center",
              flexDirection: isMobile ? "column" : "row",
              padding: isHeaderTransparent ? "0px" : "0px 24px",
              backgroundColor: isHeaderTransparent ? "#FAFAFA" : "#fff",
            }}
          >
            <LengvaInput
              label='searchValue'
              size='small'
              startIcon={SearchIcon}
              placeholder={searchPlaceholder ?? t("Search")}
              containerStyle={{
                maxWidth: isMobile ? "none" : "400px",
                flex: "1",
              }}
            />
            <Box
              style={{
                marginLeft: "auto",
                display: "flex",
                gap: "16px",
                alignItems: "center",
                flexWrap: isMobile ? "wrap" : "nowrap",
              }}
            >
              {activeFilter.map(
                (filter, key) =>
                  filter.fastFilter === true
                  && filter.type === "select" && (
                    <LengvaSelect
                      key={filter.property}
                      label={`filters.${key}`}
                      optionsMain={filter.values.map(value => ({
                        value: value.value,
                        label: value.title,
                        icon: value.icon ?? undefined,
                      }))}
                      placeholder='Pasirinkite...'
                      icon={filter.icon}
                      // value={filter.values.find(value => value.isActive)?.value}
                      // onChange={(key: any) => {
                      //   handleFilterChange(filter, key)
                      // }}
                    />
                  ),
              )}
              {showFilters && (
                <LengvaButton
                  title={t("Filters")}
                  icon={AdjustmentIcon}
                  variant='table'
                  onClick={toggleFilterDrawer(true)}
                />
              )}
              {showColumnConfig && (
                <LengvaButton
                  title={t("Column settings")}
                  icon={TableIcon}
                  variant='table'
                  onClick={toggleColumnConfigDrawer(true)}
                />
              )}
            </Box>
          </Box>
        )}
        <TableContainer
          sx={{
            backgroundColor: isHeaderTransparent ? "#FFFFFF" : "none",
            borderRadius: isHeaderTransparent
              ? "12px"
              : (showPagination
                ? "0px"
                : "0px 0px 12px 12px"),
          }}
        >
          <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow
                sx={{
                  borderTop: (!showColumnConfig && !showFilters) || isHeaderTransparent ? 'none' : '1px solid #E9E9EB',
                }}
              >
                {!rowType && (
                  <TableCell sx={{ width: "0px" }}/>
                )}
                {rowType
                  && rowType === "checkbox" && (
                  <TableCell sx={{ width: "30px" }}>
                    <Checkbox
                      size='small'
                      checked={rowIds.length === selectedRows.length}
                      onChange={handleSelectMultiple}
                    />
                  </TableCell>
                )}
                {rowType && rowType !== 'checkbox' && <TableCell sx={{ width: "30px" }}/>}
                {selectedRows.length > 0 && (
                  <TableCell colSpan={activeColumnConfig.length + 1}>
                    {selectedAction && (
                      <Button
                        color='primary'
                        sx={{
                          padding: "8px 12px",
                        }}
                        onClick={() => {
                          selectedAction?.(selectedRows)
                        }}
                      >
                        <Typography variant='button'>
                          {selectedActionLabel}
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                )}
                {selectedRows.length === 0 && (
                  <>
                    {activeColumnConfig
                      .sort((a, b) => (a.orderId < b.orderId ? -1 : 1))
                      .filter(column => !column.hideOnTable)
                      .map((column, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableCell key={i}>
                          <Typography
                            variant='body'
                            fontSize={14}
                            fontWeight={600}
                            sx={{
                              display: "flex",
                              color:
                                selectedRows.length > 0 ? "#505352" : "#9F9F9F",
                            }}
                          >
                            {t(column.label)}
                            {column.label !== "" && (
                              <img src={ArrowDownIcon as any} alt=''/>
                            )}
                          </Typography>
                        </TableCell>
                      ))}
                  </>
                )}
                {selectedRows.length === 0 && (
                  <TableCell>
                    <Typography/>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {data.length > 0 && (

              <TableBody>
                {data?.slice(((page - 1) * rowsPerPage), (page * rowsPerPage)).map(item => (
                  <TableRow
                    key={item.id}
                    color='primary'
                    sx={{ "&:last-child td, &:last-child th": { border: 0, borderRadius: '12px' }, width: "100% !important" }}
                    onClick={() => {
                      // console.log("TODO: dropdown!")
                    }}
                  >
                    {!rowType && (
                      <TableCell
                        sx={{
                          borderBottom: 'none',
                        }}
                      />
                    )}
                    {rowType === "dropdown"
                    && (dropDownOpen ? (
                      <TableCell>
                        <LengvaIcon icon={ChevronUpIcon}/>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <LengvaIcon icon={ChevronDownIcon}/>
                      </TableCell>
                    ))}
                    {rowType === "checkbox" && (
                      <TableCell
                        sx={{
                          borderBottom: 'none',
                        }}
                      >
                        <Checkbox
                          size='small'
                          checked={selectedRows.includes(item.id)}
                          onChange={() => {
                            handleSelect(item.id)
                          }}
                        />
                      </TableCell>
                    )}
                    {activeColumnConfig
                      .filter(column => !column.hideOnTable)
                      .map((column, i) => (
                        <TableCell
                        // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          sx={{
                            borderBottom: 'none',
                            padding: column.property2 ? '14px 16px' : '1.5rem 1rem',
                            ":hover": {
                              cursor: (column.onClick ?? viewMore) ? "pointer" : "default",
                            },
                          }}
                          onClick={() => {
                            viewMore?.(item)
                          }}
                        >
                          {column.type === "label" && (
                            <Box
                              display='flex'
                              alignItems='center'
                              width='min-content'
                            >
                              {item[column.property]?.beforeText && (
                                <Typography variant='body2' sx={{ mr: 2 }}>
                                  {item[column.property].beforeText}
                                </Typography>
                              )}
                              <LengvaLabel type={item[column.property]?.variant} text={item[column.property]?.label}/>
                            </Box>
                          )}
                          {column.type === "string" && (
                            <Typography
                              variant='h5'
                              color='text.secondary'
                              style={{
                                fontWeight: column.property2 ? 600 : 400,
                              }}
                            >
                              {column.valueFn
                                ? column.valueFn(item[column.property])
                                : item[column.property]}
                              {column.property2 && (
                                <Typography
                                  variant='h6'
                                  color='text.tertiary'
                                >
                                  {item[column.property2]}
                                </Typography>
                              )}
                            </Typography>
                          )}
                          {column.type === "button" && !item[column.property].isHidden && (
                            <LengvaButton
                              title={item[column.property].title}
                              color={item[column.property].color}
                              onClick={() => {
                                column.onClick?.(item)
                              }}
                            />
                          )}
                          {column.type === "avatar" && (
                            <Box
                              display='flex'
                              gap='12px'
                              alignItems='center'
                            >
                              <Avatar
                                src={item[column.property].src}
                                sx={{
                                  height: "32px",
                                  width: "32px",
                                }}
                              />
                              <Typography variant='body'>
                                {item[column.property].label}
                              </Typography>
                            </Box>
                          )}
                        </TableCell>
                      ))}
                    <TableCell
                      sx={{
                        borderBottom: 'none',
                      }}
                    >
                      {/* <>{console.log("item:", item, "column:", columns)}</> */}
                      {isViewOnly && viewMore && (
                        <Box
                          sx={{
                            width: "fit-content",
                            marginLeft: "auto",
                          }}
                        >
                          <LengvaIcon
                            variant='v3'
                            icon={ChevronRight}
                            padding='8px'
                            onClick={() => {
                              viewMore(item)
                            }}
                          />
                        </Box>
                      )}
                      {!isViewOnly && (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "16px",
                          }}
                        >
                          {isAllowed("edit") && (
                            <LengvaIcon
                              icon={EditIcon}
                              onClick={() => {
                                setOpen(true)
                                setCreateUpdateItem(item)
                                itemUpdated?.(item)
                              }}
                            />
                          )}
                          {isAllowed("delete") && (
                            <LengvaIcon
                              icon={DotsIcon}
                              onClick={() => {
                                itemDeleted?.(item)
                              }}
                            />
                          )}
                          {itemCustomFunction && itemCustomFunctionIcon && (
                            <LengvaIcon
                              icon={itemCustomFunctionIcon}
                              width={23}
                              onClick={() => {
                                itemCustomFunction?.(item)
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {data.length === 0 && (
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '12px',
                width: '100%',
                padding: '2rem',
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px',
              }}
            >
              <Typography variant='h3'>
                {emptyStateText ? emptyStateText : t('No Data')}
              </Typography>
            </Box>
          )}

        </TableContainer>
        <>
          <SwipeableDrawer
            anchor='right'
            open={sideFilterOpen}
            PaperProps={{
              sx: {
                width: "100%",
                maxWidth: "600px",
              },
            }}
            onClose={toggleFilterDrawer(false)}
            onOpen={toggleFilterDrawer(true)}
          >
            <LengvaTableFilterModal
              config={deepClone(activeFilter)}
              onClose={toggleFilterDrawer(false)}
              onChange={handleFilterChange}
              onClearFilterItem={onFilterValueClear ?? handleClearFilterItem}
              onClearAllFilters={onAllFilterValueClear ?? handleClearAllFilters}
              onSubmit={onFilterSubmit}
            />
          </SwipeableDrawer>
          <SwipeableDrawer
            anchor='right'
            open={sideColumnConfigOpen}
            onClose={toggleColumnConfigDrawer(false)}
            onOpen={toggleColumnConfigDrawer(true)}
          >
            <LengvaTableColumnConfigModal
              columns={deepClone(activeColumnConfig)}
              onClose={toggleColumnConfigDrawer(false)}
              onChange={setActiveColumnConfig}
              onClearConfig={handleClearColumnConfig}
            />
          </SwipeableDrawer>
        </>
      </Box>
      {showPagination && data.length > rowsPerPage && (
        <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "center", gap: "12px"}}>
          <Pagination
            count={Math.ceil(data.length / rowsPerPage)}
            shape='rounded'
            page={page}
            onChange={handleChange}
          />
        </Box>
      )}
    </Box>
    </Formik>
  )
}

export const LengvaSwitch = styled((props: SwitchProps) => (
  <Switch disableRipple focusVisibleClassName='.Mui-focusVisible' {...props}/>
))(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#3872E1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#F5F5F5",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}))
