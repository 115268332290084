import { Box, Dialog, Divider, Typography, useMediaQuery } from "@mui/material"
import LengvaButton from "_components/LengvaButton"
import { RadioGroupPaymentButtons } from "_pages/public/auth/UserConfirmRegistrationPage"
import { type PaymentMethod } from "_utils/ObjectUtils"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  open: boolean;
  appointmentIds: number[];
  handleClose: () => void;
  handleSubmit: (values: PaymentMethodFormValues) => void;
}

const PaymentSelectionDialog = ({open, appointmentIds, handleClose, handleSubmit}: Props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('')

  const { t } = useTranslation('translation', { keyPrefix: 'PaymentSelectionDialog' })
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Dialog
      fullWidth
      keepMounted
      open={open}
      maxWidth='md'
      PaperProps={{
        sx: {
          padding: isMobile ? '1rem' : "2rem",
          boxSizing: "border-box",
          border: 'none',
          borderRadius: isMobile ? "0px" : "24px",
          minWidth: isMobile ? "100%" : 'md',
          minHeight: isMobile ? "100%" : 'none',
          overflowX: "hidden",
          overflowY: "auto",
          boxShadow: "0px 3px 17px 0px rgba(0, 0, 0, 0.14)",
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "1.5rem",
              width: "100%",
            }}
          >
            <Typography variant='h3' alignSelf='flex-start'>
              {t("Payment methods")}
            </Typography>
            <RadioGroupPaymentButtons
              selectedValue={selectedPaymentMethod}
              setSelectedValue={(value: string) => {
                setSelectedPaymentMethod(value)
                formik.setFieldValue('paymentMethod', value)
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: isMobile ? '1.5rem' : "2rem",
                width: '100%',
                position: isMobile ? 'absolute' : 'relative',
                bottom: isMobile ? '1rem' : 'unset',
              }}
            >
              <Divider
                sx={{
                  width: isMobile ? 'calc(100% + 2rem)' : 'calc(100% + 4rem)',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  flexWrap: 'wrap',
                  gap: '1rem',
                  alignItems: 'center',
                  padding: isMobile ? '0px 1rem' : '0px',
                  boxSizing: 'border-box',
                }}
              >
                <LengvaButton
                  title={t("Cancel")}
                  color='secondary'
                  onClick={handleClose}
                />
                <LengvaButton
                  title={t("Pay now")}
                  color='primary'
                  disabled={selectedPaymentMethod === ''}
                  onClick={() => formik.handleSubmit()}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export interface PaymentMethodFormValues {
  paymentMethod: PaymentMethod | undefined;
}

const initialValues: PaymentMethodFormValues = {
  paymentMethod: undefined,
}

export default PaymentSelectionDialog
