import { Typography } from "@mui/material"
import { type Account } from "_api/Account"
import { type Auth } from "_api/Auth"
import { type PasswordUpdateRequest } from "_api/data-contracts"
import LengvaLogo from "_assets/lengva-logo.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaInput from "_components/LengvaInput"
import { FormContainer, RegisterPageContainer, RegisterPageInnerContainer } from "_pages/public/auth/RegisterLoginPage"
import useStore, { hasRole } from "_state/useStore"
import { createApi } from "_utils/ApiCreator"
import { Formik } from "formik"
import { type TFunction } from "i18next"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import * as yup from 'yup'

interface Props {
  isCreate?: boolean;
  isForgot?: boolean;
}

const PasswordResetPage = ({ isCreate, isForgot }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PasswordResetPage' })
  const { account, isSpecialist } = useStore()
  const accountAPI = useRef(createApi("account") as Account)
  const authAPI = useRef(createApi("auth") as Auth)
  const navigate = useNavigate()
  const uuid = useSearchParams()[0].get('uuid')

  const handleSubmit = async (values: PasswordResetFormValues) => {
    if (isForgot) {
      if (!uuid) {
        toast.error(t('PasswordResetFailed'))
        return
      }

      await authAPI.current.resetPassword({
        uuid,
        password: values.password,
      })

      toast.success(t('PasswordResetSuccess'))
      navigate('/login')
      return
    }

    const passwordUpdateRequest: PasswordUpdateRequest = {
      newPassword: values.password,
    }
    await accountAPI.current.updatePassword(passwordUpdateRequest)
    toast.success(t('PasswordUpdateSuccess'))
    navigate(isSpecialist ? '/admin' : '/appointments')
  }

  return (
    <RegisterPageContainer>
      <RegisterPageInnerContainer>
        <img
          style={{
            maxWidth: "162px",
          }}
          src={LengvaLogo as any}
          alt='icon'
        />
        <Typography variant='h3'>
          {isCreate ? t('Create Password') : t('Reset Password')}
        </Typography>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={getValidationSchema(t)}
          onSubmit={handleSubmit}
        >
          {formik => (
            <FormContainer>
              <LengvaInput
                label='password'
                title={t('Password')}
                type='password'
              />
              <LengvaInput
                label='confirmPassword'
                title={t('Confirm Password')}
                type='password'
              />
              <LengvaButton
                sx={{
                  width: "100%",
                }}
                title={t('Submit')}
                onClick={() => formik.handleSubmit()}
              />
            </FormContainer>
          )}
        </Formik>
      </RegisterPageInnerContainer>
    </RegisterPageContainer>
  )
}

export default PasswordResetPage

interface PasswordResetFormValues {
  password: string;
  confirmPassword: string;
}

const initialValues: PasswordResetFormValues = {
  password: '',
  confirmPassword: '',
}

const getValidationSchema = (t: TFunction) => yup.object({
  password: yup.string().required(t('Password is required')).min(8, t('Password must be at least 8 characters long')).oneOf([yup.ref('confirmPassword')], t('Passwords must match')),
  confirmPassword: yup.string().required(t('Confirm password is required')).oneOf([yup.ref('password')], t('Passwords must match')),
})

