import { Box } from "@mui/material"

interface Props {
  icon: any;
  onClick?: Function;
  width?: number;
  padding?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  border?: number;
  variant?: "v1" | "v2" | "v3";
}

export default function LengvaIcon({
  icon,
  onClick,
  width,
  padding,
  variant,
}: Props) {
  const v3Style = {
    display: "flex",
    padding: padding ? padding : "8px",
    alignItems: "flex-start",
    gap: "8px",
    borderRadius: "32px",
    width: "fit-content",
    "&:hover": onClick
      ? {
        backgroundColor: "#EBEBEB !important",
        cursor: "pointer",
      }
      : {},
  }

  return (
    <Box
      sx={
        variant === "v3"
          ? { ...v3Style }
          : {
            display: "flex",
            padding: padding ? padding : "8px",
            alignItems: "flex-start",
            gap: "8px",
            borderRadius: "32px",
            // border: onClick ? "1px solid #E9E9EB" : "",
            "&:hover": onClick
              ? {
                // border: "1px solid #A4A4A4 !important",
                backgroundColor: "#EBEBEB !important",
                cursor: "pointer",
              }
              : {},
          }
      }
      onClick={(event: any) => onClick?.(event)}
    >
      <img src={icon} style={width ? { width: width + "px" } : {}} alt=''/>
    </Box>
  )
}
