import { Box, useMediaQuery } from "@mui/material"
import Footer from "_components/Footer"
import LengvaBanner from '_components/LengvaBanner'
import LengvaNavbar from "_components/LengvaNavbar"
import LengvaSidebar from "_components/LengvaSidebar"
import { ContentBox, FlexBox } from "_pages/admin/AdminPage"
import { changeLanguage } from "i18next"
import { getAllPublicMenuItems } from "index"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation } from "react-router-dom"

export default function UserLayout() {
  const location = useLocation()
  const [activeMenuItem, setActiveMenuItem] = useState<any>((getAllPublicMenuItems().find(
    item => item.path === location.pathname.slice('/admin/'.length)))?.menuLabel)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [banner, setBanner] = useState<any>(JSON.parse(window.localStorage.getItem('BANNER_STATE') ?? "{}"))
  const { i18n } = useTranslation('translation', { keyPrefix: "AdminPage" })
  const isMobile = useMediaQuery('(max-width: 600px)')

  const handleLanguageChange = () => {
    switch (i18n.language) {
      case "en": {
        changeLanguage("lt")
        break
      }

      case "lt": {
        changeLanguage("en")
        break
      }

      default: {
        break
      }
    }

    window.location.reload()
  }

  const handleCloseBanner = () => {
    setBanner({ ...banner, open: false })
    window.localStorage.removeItem('BANNER_STATE')
  }

  return (
    <FlexBox>
      <LengvaSidebar
        menuItems={getAllPublicMenuItems()}
        activeMenuItem={activeMenuItem}
        homePageLink='/appointments'
        keyPrefix='PublicPage'
        openDrawer={openDrawer}
        setActiveMenuItem={setActiveMenuItem}
        setOpenDrawer={setOpenDrawer}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto' }}>
        <LengvaNavbar
          isAdmin={false}
          openDrawer={openDrawer}
          keyPrefix='PublicPage'
          homePageLink='/appointments'
          setOpenDrawer={setOpenDrawer}
          handleLanguageChange={handleLanguageChange}
        />
        {banner?.open && (
          <LengvaBanner
            title={banner?.title}
            message={banner?.message}
            severity={banner?.severity}
            handleClose={handleCloseBanner}
          />
        )}
        <ContentBox
          sx={{
            padding: isMobile ? '1rem' : "2rem 2.5rem",
          }}
        >
          <Outlet/>
        </ContentBox>
        {!isMobile && (
          <Footer/>
        )}
      </Box>
    </FlexBox>
  )
}

