import { Box, Divider, Typography, styled, useMediaQuery } from "@mui/material"
import { type Account } from "_api/Account"
import { type Auth } from "_api/Auth"
import { type UpdateAccountInfoRequest, type UpdatePasswordRequest, type MeResponse, InvoiceDetails } from "_api/data-contracts"
import LengvaButton from "_components/LengvaButton"
import LengvaInput from "_components/LengvaInput"
import LengvaSelect from "_components/LengvaSelect"
import useStore from "_state/useStore"
import { createApi } from "_utils/ApiCreator"
import { phoneNumberPrefixOptions } from "_utils/ObjectUtils"
import { Form, Formik, type FormikHelpers } from "formik"
import { type TFunction } from "i18next"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import * as yup from 'yup'
import ProfilePublicDataForm from "./ProfilePublicDataForm"

const ProfilePage = () => {
  const { account } = useStore()
  const { t } = useTranslation('translation', { keyPrefix: 'ProfilePage' })
  const authAPI = useRef(createApi('auth') as Auth)
  const accountAPI = useRef(createApi('account') as Account)

  const handleProfileUpdateSubmit = async (values: ProfileFormValues) => {
    const updateAccountInfoRequest: UpdateAccountInfoRequest = {
      name: values.name,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumberPrefix + values.phoneNumber,
    }
    await accountAPI.current.updateAccountInfo(updateAccountInfoRequest)
    toast.success(t('ProfileUpdateSuccess'))
  }

  const handlePasswordChangeSubmit = (values: ChangePasswordFormValues, formik: FormikHelpers<ChangePasswordFormValues>) => {
    try {
      const passwordUpdateRequest: UpdatePasswordRequest = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      }
      authAPI.current.changePassword(passwordUpdateRequest)
      toast.success(t('PasswordUpdateSuccess'))
      formik.resetForm()
    } catch {
      toast.error(t('PasswordUpdateFailed'))
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: '1000px',
          padding: '1rem',
        }}
      >
        <Typography align='left' variant='h2'>
          {t('Profile')}
        </Typography>
      </Box>
      <ProfileContainer
        sx={{
          border: '1px solid #E5E5E5',
        }}
      >
        <Formik
          initialValues={getInitialProfileFormValues(account)}
          validationSchema={getProfileFormValidationSchema(t)}
          onSubmit={handleProfileUpdateSubmit}
        >
          {formik => (
            <ProfilePublicDataForm/>
          )}
        </Formik>
        <Divider
          sx={{
            width: '100%',
          }}
        />
        <Formik
          initialValues={initialChangePasswordFormValues}
          validationSchema={getChangePasswordFormValidationSchema(t)}
          onSubmit={handlePasswordChangeSubmit}
        >
          {formik => (
            <ProfileForm>
              <Typography variant='h3'>
                {t('PasswordChange')}
              </Typography>
              <LengvaInput
                label='oldPassword'
                title={t('OldPassword')}
                type='password'
              />
              <LengvaInput
                label='newPassword'
                title={t('NewPassword')}
                type='password'
              />
              <LengvaInput
                label='newPasswordRepeat'
                title={t('NewPasswordRepeat')}
                type='password'
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <LengvaButton
                  title={t('ChangePassword')}
                  color='primary'
                  type='submit'
                />
              </Box>
            </ProfileForm>
          )}
        </Formik>
      </ProfileContainer>
    </Box>
  )
}

export default ProfilePage

export interface ProfileFormValues {
  id?: number;
  name: string;
  lastName: string;
  email: string;
  phoneNumberPrefix: string;
  phoneNumber: string;
  roles: number[];
  invoiceDetails?: InvoiceDetails;
}

interface ChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export const getInitialInvoiceDetails = (): InvoiceDetails => ({
  workplaceType: undefined,
  iban: '',
  address: '',
  companyName: '',
  vatCode: '',
})

export const initialProfileFormValues: ProfileFormValues = {
  name: '',
  lastName: '',
  email: '',
  phoneNumberPrefix: '',
  phoneNumber: '',
  roles: [],
  invoiceDetails: getInitialInvoiceDetails(),
}

const initialChangePasswordFormValues: ChangePasswordFormValues = {
  oldPassword: '',
  newPassword: '',
  newPasswordRepeat: '',
}

export const getInitialProfileFormValues = (account?: MeResponse): ProfileFormValues => {
  if (!account) {
    return initialProfileFormValues
  }

  // TODO: this is prone to error, prefixes could be size of 2,3.
  const phoneNumberPrefix = account.phoneNumber?.slice(0, 4) ?? ''
  const phoneNumber = account.phoneNumber?.slice(4) ?? ''
  return {
    name: account.name ?? '',
    lastName: account.lastName ?? '',
    email: account.email ?? '',
    phoneNumberPrefix,
    phoneNumber,
    // roles: account.roles?.map((role: any) => role.id!) ?? [],
    roles: [],
    // invoiceDetails: account.invoiceDetails ?? getInitialInvoiceDetails(),
  }
}

const getProfileFormValidationSchema = (t: TFunction) => yup.object({
  name: yup.string().required(t('ValidationErrors.NameRequired')),
  lastName: yup.string().required(t('ValidationErrors.LastNameRequired')),
  email: yup.string().email(t('ValidationErrors.EmailInvalid')).required(t('ValidationErrors.EmailRequired')),
  phoneNumberPrefix: yup.string().required(t('ValidationErrors.PhoneNumberPrefixRequired')),
  phoneNumber: yup.number().required(t('ValidationErrors.PhoneNumberRequired'))
    .min(10_000_000, t('ValidationErrors.PhoneNumberInvalid'))
    .max(99_999_999, t('ValidationErrors.PhoneNumberInvalid'))
    .typeError(t('ValidationErrors.PhoneNumberInvalid')),
})

const getChangePasswordFormValidationSchema = (t: TFunction) => yup.object({
  oldPassword: yup.string().required(t('ValidationErrors.OldPasswordRequired')),
  newPassword: yup.string().required(t('ValidationErrors.NewPasswordRequired')).oneOf([yup.ref('newPasswordRepeat')], t('ValidationErrors.NewPasswordsMustMatch')).notOneOf([yup.ref('oldPassword')], t('ValidationErrors.NewPasswordMustBeDifferent')),
  newPasswordRepeat: yup.string().required(t('ValidationErrors.NewPasswordRepeatRequired')).oneOf([yup.ref('newPassword')], t('ValidationErrors.NewPasswordsMustMatch')),
})

const ProfileForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  width: '100%',
})

const ProfileContainer = styled(Box)(() => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  return {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1000px',
    width: '100%',
    borderRadius: '1rem',
    gap: '2rem',
    padding: isMobile ? '1rem' : '2rem',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
  }
})

