import { Box, Typography, styled } from "@mui/material"
import LengvaButton from "_components/LengvaButton"

interface Props {
  title: string;
  text: string;
  subtext?: string;
  textIcon?: any;
  icon?: any;
  primaryButtonText?: string;
  primaryButtonOnClick?: Function;
  primaryButtonIcon?: any;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: Function;
  secondaryButtonIcon?: any;
  borderColor?: string;
}

const ItemDetailRow = ({
  title,
  text,
  subtext,
  textIcon,
  icon,
  primaryButtonText,
  primaryButtonOnClick,
  primaryButtonIcon,
  secondaryButtonText,
  secondaryButtonOnClick,
  secondaryButtonIcon,
  borderColor = '#E9E9EB',
}: Props) => (
  <ItemDetailRowContainer>
    <Typography
      variant='body'
      fontWeight={600}
      fontSize={16}
    >
      {title}
    </Typography>
    <ItemDetailRowBox
      flexWrap='wrap'
      gap='1rem'
      sx={{
        borderColor,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '1.5rem',
            alignItems: 'center',
          }}
        >
          {icon && (
            <img
              src={icon} alt='' style={{
                width: '32px',
                height: '32px',
              }}/>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              {text && (
                <Typography variant='body' fontWeight={600} fontSize={18}>
                  {text}
                </Typography>
              )}
              {textIcon && (
                <img src={textIcon} alt=''/>
              )}
            </Box>
            {subtext && (
              <Typography
                variant='body' sx={{
                  wordBreak: 'break-word',
                }}
              >
                {subtext}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {((primaryButtonText && primaryButtonOnClick) ?? (secondaryButtonText && secondaryButtonOnClick)) && (
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',
          }}
        >
          {secondaryButtonText && secondaryButtonOnClick && (
            <LengvaButton
              title={secondaryButtonText} color='secondary' icon={secondaryButtonIcon}
              onClick={() => secondaryButtonOnClick()}
            />
          )}
          {primaryButtonText && primaryButtonOnClick && (
            <LengvaButton title={primaryButtonText} endIcon={primaryButtonIcon} onClick={() => primaryButtonOnClick()}/>
          )}
        </Box>
      )}
    </ItemDetailRowBox>
  </ItemDetailRowContainer>
)

export const ItemDetailRowContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
}))

export const ItemDetailRowBox = styled(Box)(() => ({
  display: 'flex',
  padding: '1.5rem',
  boxSizing: 'border-box',
  border: '1px solid #E9E9EB',
  borderRadius: '12px',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export default ItemDetailRow
