/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PaymentCreateRequest, PaymentResponse, UpdatePaymentStatusRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Payment<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags payment-admin-controller
   * @name UpdatePaymentStatus
   * @request PUT:/api/v1/admin/payment/status
   */
  updatePaymentStatus = (data: UpdatePaymentStatusRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/admin/payment/status`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-user-controller
   * @name Pay
   * @request POST:/api/v1/user/payment/pay
   */
  pay = (data: PaymentCreateRequest, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v1/user/payment/pay`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-user-controller
   * @name FindAllUserPayments
   * @request GET:/api/v1/user/payment
   */
  findAllUserPayments = (params: RequestParams = {}) =>
    this.request<PaymentResponse[], any>({
      path: `/api/v1/user/payment`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-user-controller
   * @name SearchPayments
   * @request GET:/api/v1/user/payment/search
   */
  searchPayments = (
    query?: {
      searchString?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<PaymentResponse[], any>({
      path: `/api/v1/user/payment/search`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags payment-user-controller
   * @name IsPaymentSuccess
   * @request GET:/api/v1/user/payment/is-success/{paymentId}
   */
  isPaymentSuccess = (paymentId: number, params: RequestParams = {}) =>
    this.request<"WAITING" | "SUCCESS" | "FAILED", any>({
      path: `/api/v1/user/payment/is-success/${paymentId}`,
      method: "GET",
      ...params,
    });
}
