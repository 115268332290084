
import { Box, type PaletteColorOptions, createTheme } from "@mui/material"
import CheckBoxChecked from "_assets/checkbox-checked.svg"
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded'
import React from "react"
import { fontGrid } from "@mui/material/styles/cssUtils"
import { Background } from "victory"
import { min } from "lodash"

let AppTheme = createTheme({
  palette: {
    mindPrimary: {
      main: "#75451D",
    },
    mindSecondary: {
      main: "#F9F5F0",
    },
  },
})

AppTheme = createTheme(AppTheme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          display: "flex",
          padding: "13px 20px 13px 17px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          borderRadius: "12px",
          "&.Mui-disabled": {
            color: "#FFFFFF",
            opacity: 0.5,
          },
        },
      },
      variants: [
        {
          props: { disabled: true },
          style: {
            backgroundColor: "#C3D5F6",
            color: "#FFFFFF",
          },
        },
        {
          props: { color: "primary" },
          style: {
            textTransform: "none",
            background: "#3872E1",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#0F56DF",
            },
          },
        },
        {
          props: { color: "secondary" },
          style: {
            background: "#EBF1FC",
            color: "#2765DE",
            fontWeight: 700,
            "&:hover": {
              backgroundColor: "#D7E3F9",
            },
          },
        },
        {
          props: { color: "inherit" },
          style: {
            color: "#3872E1",
            fontWeight: 700,
            "&:hover": {
              color: "#0F56DF",
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { color: "mindPrimary" },
          style: {
            background: "#75451D",
            color: "#FFFFFF",
            fontWeight: 700,
            borderRadius: "44px",
            "&:hover": {
              background: "#75451D",
              opacity: 0.8,
            },
            "&.Mui-disabled": {
              background: "#75451D",
              opacity: 0.5,
            },
          },
        },
        {
          props: { color: "mindSecondary" },
          style: {
            background: "#F9F5F0",
            color: "#75451D",
            borderRadius: "44px",
            fontWeight: 700,
            "&:hover": {
              background: "#F9F5F0",
              opacity: 0.8,
            },
          },
        },
        {
          props: { variant: "table" },
          style: {
            background: '#F4F4F5',
            padding: "9px 16px",
            color: '#585C65',
            "&:hover": {
              backgroundColor: "#E9E9EB",
              color: '#202632',
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root": {
            ":hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3872E1 !important",
                borderWidth: "1px",
              },
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: '#9F9F9F',
                borderWidth: "1px",
              },
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#E9E9EB",
        },
      },
    },
    MuiTableRow: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            backgroundColor: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#FAFAFA",
            },
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          width: "100%",
          dispaly: "flex",
          padding: "11px 16px",
          alignItems: "center",
          gap: "12px",
          borderRadius: "12px",
          flexShrink: 0,
          alignSelf: "strech",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "#F5F5F5 !important",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          display: "flex",
          padding: "4px 12px 4px 4px",
          alignItems: "center",
          gap: "8px",
          borderRadius: "32px",
          background: "#F5F5F5",
          "&:hover": {
            backgroundColor: "#EBEBEB !important",
            "&:hover .MuiChip-label": {
              color: "#081F17 !important",
            },
          },
          "&:hover .MuiChip": {
            backgroundColor: "#EBEBEB !important",
            color: "red !important",
          },
        },
        label: {
          padding: 0,
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "20px",
          color: "#505352",
        },
      },
      variants: [
        {
          props: { color: "secondary" },
          style: {
            backgroundColor: "#3872E1",
            ".MuiChip-label": {
              color: "#FFFFFF",
            },
            ".MuiChip-avatar": {
              background: "#FFFFFF",
            },
            "&:hover": {
              backgroundColor: "#3872E1 !important",
              "&:hover .MuiChip-label": {
                color: "#FFFFFF !important",
              },
            },
          },
        },
        {
          props: { color: "primary" },
          style: {
            backgroundColor: "#F5F5F5",
            ".MuiChip-label": {
              color: "#505352",
            },
            ".MuiChip-avatar": {
              background: "#F5F5F5",
            },
            "&:hover": {
              backgroundColor: "#EBEBEB",
            },
          },
        },
      ],
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          margin: "0px !important",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <Box
          sx={{
            height: "14px",
            width: "14px",
            border: "1px solid #9B9DA3",
            borderRadius: "4px",
          }}
        />,
        checkedIcon: <img
          src={CheckBoxChecked as any} alt=''
          style={{
            width: "16px",
            height: "16px",
          }}
        />,
      },
      styleOverrides: {
        root: {
          color: "#3872E1 !important",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#585C65",
          "&.Mui-checked": {
            color: "#3872E1",
          },
        },
      },
    },
  },
  palette: {
    text: {
      primary: "#202632",
      secondary: "#585C65",
      disabled: "#B1B3B7",
      tertiary: "#757980",
      light: "#9B9DA3",
      brand: "#3872E1",
      mindPrimary: "#75451D",
      mindSecondary: "#F9F5F0",
    },
    primary: {
      main: "#3872E1",
      mind: "#75451D",
      mindSecondary: "#F9F5F0",
    },
    secondary: {
      main: "#2765DE",
    },
    border: {
      primary: "#E5E5E5",
      secondary: "#9B9DA3",
      tertiary: "#E9E9EB",
    },
  },
  typography: {
    body: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "23.8px",
      color: "#202632",
    },
    body2: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      color: "#202632",
    },
    h2: {
      fontFamily: "Poppins",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      [AppTheme.breakpoints.down('md')]: {
        fontSize: "24px",
      },
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: '24px',
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      [AppTheme.breakpoints.down('md')]: {
        fontSize: "20px",
      },
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
    },
    h5: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
    },
    h6: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    },
    button: {
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      textTransform: "none",
    },
    hyperlink: {
      color: "#3872E1",
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
})

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    hyperlink: true;
    overline: false;
    body: true;
    subtitle: true;
    subtitle1: false;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    hyperlink: React.CSSProperties;
    body: React.CSSProperties;
    subtitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    hyperlink?: React.CSSProperties;
    body?: React.CSSProperties;
    subtitle?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    table: true;
  }

  interface ButtonPropsColorOverrides {
    mindPrimary: true;
    mindSecondary: true;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    mindPrimary: {
      main: string;
    };
    mindSecondary: {
      main: string;
    };
  }

  interface PaletteOptions {
    mindPrimary: {
      main: string;
    };
    mindSecondary: {
      main: string;
    };
  }
}

export default AppTheme
