import { type Auth } from "_api/Auth"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import useAuthStore from "_state/useStore"
import { createApi } from "_utils/ApiCreator"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

interface MagicLoginHandlerProps {
  isPasswordCreation?: boolean;
}

const MagicLoginHandler = ({ isPasswordCreation }: MagicLoginHandlerProps) => {
  const navigate = useNavigate()
  const authAPI = useRef(createApi("auth") as Auth)
  const [message, setMessage] = useState<string>("Validating uuid and email")
  const [searchParameters] = useSearchParams()
  const authStore = useAuthStore()
  const {isSpecialist} = useAuthStore(state => state)

  const email = searchParameters.get("email")
  const uuid = searchParameters.get("uuid")
  const specialistId = searchParameters.get("specialistId")

  useEffect(() => {
    handleMagicLogin()
  // eslint-disable-next-line
  }, [])

  async function handleMagicLogin() {
    try {
      await authAPI.current.loginMagic({
        email: email?.toLocaleLowerCase() ?? "N/A",
        uuid: uuid ?? "N/A",
      })

      await authStore.getMe() // todo: do we really really need it?
      if (isPasswordCreation) {
        navigate('/password-reset/new')
        return
      }

      isSpecialist ? navigate('/admin/register/specialist/confirm') : navigate('/register/confirm' + (specialistId ? `/${specialistId}` : ''))
    } catch (error: any) {
      setMessage(error.response.data)
      navigate('/login')
    }
  }

  return (
    <LengvaLoadingSpinner/>
  )
}

export default MagicLoginHandler
