import { CloseRounded } from "@mui/icons-material"
import { Box, Dialog, IconButton, Typography, useMediaQuery } from "@mui/material"
import { type AccountPublicResponse, type AppointmentResponse, type ClientResponse, type ServiceResponse } from "_api/data-contracts"
import AppointmentCreateEditForm from "_pages/admin/appointments/AppointmentCreateEditForm"
import { type CalendarEvent } from "_pages/admin/calendar/CalendarPage"

interface Props {
  isEdit?: boolean;
  isClientView?: boolean;
  open: boolean;
  dialogTitle?: string;
  appointment?: AppointmentResponse;
  clients?: ClientResponse[];
  specialists?: AccountPublicResponse[];
  services?: ServiceResponse[];
  newEvent?: CalendarEvent;
  handleSubmit: (values: any) => void;
  handleClose: () => void;
  handleCancel?: () => void;
  showStickyFooter?: boolean;
  showSubmitButton?: boolean;
}

const AppointmentCreateEditDialog = ({open, isEdit, isClientView, appointment, specialists, dialogTitle, clients, services, newEvent, handleClose, handleCancel, handleSubmit, showStickyFooter, showSubmitButton}: Props) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Dialog
      open={open}
      sx={{
        width: '100%',
      }}
      PaperProps={{
        sx: {
          maxWidth: isMobile ? '100%' : '1000px',
          maxHeight: isMobile ? '100%' : '817px',
          width: '100%',
          borderRadius: '12px',
          margin: isMobile ? '0' : '2rem',
          height: '100%',
          overflowX: 'hidden',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '1rem',

          justifyContent: dialogTitle ? 'space-between' : 'flex-end',
        }}
      >
        {dialogTitle && (
          <Typography variant='h3'>
            {dialogTitle}
          </Typography>
        )}
        <IconButton onClick={handleClose}>
          <CloseRounded/>
        </IconButton>
      </Box>
      <AppointmentCreateEditForm
        showStickyFooter={showStickyFooter}
        isEdit={isEdit}
        isClientView={isClientView}
        appointment={appointment}
        clients={clients}
        specialists={specialists}
        services={services}
        showSubmitButton={showSubmitButton}
        newEvent={newEvent}
        handleSubmit={handleSubmit}
        handleCancel={isEdit ? handleCancel : undefined}
      />
    </Dialog>
  )
}

export default AppointmentCreateEditDialog
