import { AutorenewOutlined, AutorenewRounded } from "@mui/icons-material"
import { Avatar, Box, Divider, Menu, Typography } from "@mui/material"
import { type Auth } from "_api/Auth"
import { type Change } from "_api/Change"
import { type RoleResponse, type AccountPublicResponse } from "_api/data-contracts"
import LogoutIcon from "_assets/logout-icon.svg"
import UserIcon from "_assets/user-icon.svg"
import LengvaButton from "_components/LengvaButton"
import useStore from "_state/useStore"
import { createApi } from "_utils/ApiCreator"
import { getRoleName } from "_utils/ObjectUtils"
import { type RoleNames } from "_utils/RequireAuth"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

interface Props {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  otherProfiles?: AccountPublicResponse[];

}

const LengvaProfileMenu = ({ open, anchorEl, handleClose, otherProfiles }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LengvaProfileMenu' })
  const currentProfile = useStore(state => state.account)
  const accountStore = useStore(state => state)
  const navigate = useNavigate()

  const authAPI = useRef(createApi('auth') as Auth)
  const activeRoleAPI = useRef(createApi('change') as Change)

  const logout = async () => {
    await authAPI.current.logoff()
    navigate('/')
    window.location.reload()
  }

  const changeRole = async (roleName: RoleNames) => {
    await activeRoleAPI.current.logoff({role: roleName as any})
    window.location.reload()
  }

  return (
    <Menu
      id='profile-menu'
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          sx: {
            padding: "1.5rem",
            paddingBottom: "0.75rem",
            boxSizing: "border-box",
            borderRadius: "12px",
            boxShadow: "0px 3px 9px 0px rgba(0, 0, 0, 0.14);",
          },
        },
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "1.5rem",
          gap: "1rem",
        }}
      >
        <Avatar
          sx={{
            width: "56px",
            height: "56px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.25rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.75rem",
            }}
          >

            <Typography
              variant='body'
              fontWeight={700}
              fontSize={18}
            >
              {currentProfile?.name} {currentProfile?.lastName}
            </Typography>
            {accountStore.isSpecialist && (
              <Typography
                variant='body'
                fontSize={14}
                color={getTagMainColor('SPECIALIST')}
                sx={{
                  padding: "0.25rem 0.5rem",
                  borderRadius: "6px",
                  background: getTagSecondaryColor('SPECIALIST'),
                }}
              >
                {t('Specialist')}
              </Typography>
            )}

          </Box>
          <Typography
            variant='body'
            fontSize={14}
            color='text.tertiary'
          >
            {currentProfile?.email}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          width: 'calc(100% + 3rem)',
          marginLeft: '-1.5rem',
        }}
      />

      {otherProfiles && otherProfiles.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            paddingTop: "1.5rem",
          }}
        >
          <Typography
            variant='body'
            fontSize={14}
            color='text.tertiary'
          >
            {t('Other profiles')}
          </Typography>
          {otherProfiles.map(profile => (
            <SmallProfileBox
              key={profile.id}
              profile={profile}
            />
          ))}
          <Divider
            sx={{
              width: 'calc(100% + 3rem)',
              marginLeft: '-1.5rem',
            }}
          />
        </Box>

      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "0.75rem",
        }}
      >
        <LengvaButton
          sx={{
            padding: '12px 16px',
            justifyContent: 'flex-start',
          }}
          title={t('My profile')}
          color='inherit'
          icon={UserIcon}
          onClick={() => {
            handleClose()
            navigate(accountStore.isSpecialist ? '/admin/profile' : '/profile')
          }}
        />
        {accountStore.account?.availableRoles?.filter((r: RoleResponse) => r.name && r.name !== accountStore.account?.role?.name)
          .map((role: RoleResponse) => (
            <Box
              key={role.id}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                changeRole(role.name as any)
              }}
            >
              <AutorenewRounded
                sx={{
                  color: 'primary.main',
                  paddingLeft: '0.75rem',
                }}
              />
              <Typography
                variant='h5'
                color='primary.main'
                sx={{
                  padding: "0.75rem 0 0.75rem",
                }}
              >
                {t('LoginAs')}
              </Typography>
              <Typography
                variant='body'
                fontSize={14}
                color={getTagMainColor(role.name)}
                sx={{
                  padding: "0.25rem 0.5rem",
                  borderRadius: "6px",
                  background: getTagSecondaryColor(role.name),
                }}
              >
                {getRoleName(role?.name, t)}
              </Typography>
            </Box>
          ))}
        <LengvaButton
          sx={{
            padding: '12px 16px',
            justifyContent: 'flex-start',
          }}
          title={t('Log out')}
          color='inherit'
          icon={LogoutIcon}
          onClick={() => {
            handleClose()
            logout()
          }}
        />
      </Box>
    </Menu>
  )
}

const SmallProfileBox = ({ profile }: { profile: AccountPublicResponse }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
    }}
  >
    <Avatar
      sx={{
        width: "41px",
        height: "41px",
      }}
    />
    <Box>
      <Typography
        variant='body'
        fontWeight={600}
        fontSize={14}
      >
        {profile?.name} {profile?.lastName}
      </Typography>
      <Typography
        variant='body'
        fontSize={14}
        color='text.tertiary'
      >
        {profile?.email}
      </Typography>
    </Box>
  </Box>
)

export default LengvaProfileMenu

const getTagMainColor = (roleName: "ADMIN" | "MANAGER" | "SPECIALIST" | "USER" | undefined) => {
  switch (roleName) {
    case "ADMIN": {
      return "rgba(32, 38, 50, 1)"
    }

    case "MANAGER": {
      return "rgba(56, 114, 225, 1)"
    }

    case "SPECIALIST": {
      return "rgba(53, 187, 171, 1)"
    }

    case "USER": {
      return "rgba(121, 110, 251, 1)"
    }

    default: {
      return ""
    }
  }
}

const getTagSecondaryColor = (
  roleName: "ADMIN" | "MANAGER" | "SPECIALIST" | "USER" | undefined,
) => {
  switch (roleName) {
    case "ADMIN": {
      return "rgba(32, 38, 50, 0.1)"
    }

    case "MANAGER": {
      return "rgba(56, 114, 225, 0.1)"
    }

    case "SPECIALIST": {
      return "rgba(53, 187, 171, 0.1)"
    }

    case "USER": {
      return "rgba(121, 110, 251, 0.1)"
    }

    default: {
      return ""
    }
  }
}
