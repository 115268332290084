import { send } from "node:process"
import { Box, Hidden, Typography, useMediaQuery } from "@mui/material"
import { type Account } from "_api/Account"
import { type ClientResponse } from "_api/data-contracts"
import ChevronDownIcon from '_assets/chevronDown.svg'
import DownloadIcon from '_assets/download.svg'
import PlusIcon from '_assets/plus.svg'
import UploadIcon from '_assets/upload.svg'
import LengvaButton from "_components/LengvaButton"
import LengvaLoadingSpinner from "_components/LengvaLoadingSpinner"
import LengvaTable, { type ColumnConfig } from "_components/LengvaTable"
import { DashboardContainer, DashboardSectionContainer, DashboardTitleContainer, PillContainer, sampleData } from "_pages/admin/appointments/AppointmentListPage"
import { createApi } from "_utils/ApiCreator"
import AutoSubmitToken from "_utils/AutoSubmitToken"
import { Formik, type FormikProps } from "formik"
import i18next, { type TFunction } from "i18next"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { VictoryArea, VictoryAxis, VictoryChart, VictoryLabel, VictoryLegend, VictoryPortal, VictoryTooltip, VictoryVoronoiContainer } from "victory"

const ClientPage = () => {
  const [initialClients, setInitialClients] = useState<ClientResponse[]>([])
  const [clients, setClients] = useState<ClientResponse[]>([])
  const [newClients, setNewClients] = useState<number>(0)
  const [graphData, setGraphData] = useState<ClientGraphData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const accountAPI = useRef(createApi('account') as Account)
  const { t } = useTranslation('translation', { keyPrefix: 'ClientPage' })
  const graphStartData = moment().subtract(30, 'days').toDate()
  const graphEndData = moment().toDate()

  useEffect(() => {
    setIsLoading(true)
    getAllClients()
    getNewClients()
  // eslint-disable-next-line
  }, [])

  const getAllClients = async () => {
    const response = await accountAPI.current.searchClients({searchString: ''})
    const graphData = mapClientsToGraphData(response, graphStartData, graphEndData)

    setGraphData(graphData)
    setClients(response)
    setInitialClients(response)
    setIsLoading(false)
  }

  const getNewClients = async () => {
    const response = await accountAPI.current.countNewClientsFromTheLastMonth()
    setNewClients(response)
  }

  const handleSearchSubmit = async (values: ClientSearchValues) => {
    const query = {
      searchString: values.searchValue ?? '',
    }
    const response = await accountAPI.current.searchClients(query)
    setClients(response)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSearchSubmit}
    >
      {(formik: FormikProps<any>) => (
        <DashboardContainer
          sx={{
            paddingTop: '0',
          }}
        >
          <DashboardTitleContainer
            sx={{
              flexWrap: 'wrap',
              marginBottom: '2rem',
              gap: '1rem',
            }}
          >
            <Typography variant='h2'>
              {t("Clients")}
            </Typography>
            <Box
              display='flex'
              gap='1rem'
              flexWrap='wrap'
            >
              {/* <LengvaButton
                title={isMobile ? t('New') : t('New appointment')}
                color='primary'
                icon={PlusIcon}
                onClick={() => navigate("/admin/appointments/create")}
              /> */}
              <LengvaButton
                title={isMobile ? t('New') : t('NewClient')}
                color='primary'
                icon={PlusIcon}
                onClick={() => navigate("/admin/clients/create")}
              />
              {/* <Hidden mdDown>
                <LengvaButton
                  title={t('Download')}
                  color='secondary'
                  icon={DownloadIcon}
                />
                <LengvaButton
                  title={t('Import')}
                  color='secondary'
                  icon={UploadIcon}
                />
              </Hidden> */}
            </Box>
          </DashboardTitleContainer>
          <DashboardSectionContainer
            sx={{
              flexDirection: 'row',
              padding: isMobile ? '0.75rem' : '1.5rem',
            }}
          >
            <DashboardSectionContainer
              flex={1}
              sx={{
                padding: '0',
              }}
            >
              <DashboardSectionContainer
                flex={1}
                sx={{
                  flexDirection: isMobile ? 'row' : 'column',
                  padding: '0',
                }}
              >
                <PillContainer>
                  <Typography variant='h3'>
                    {newClients}
                  </Typography>
                  <Typography variant='body' fontSize={14}>
                    {t("New this month")}
                  </Typography>
                </PillContainer>
                <PillContainer>
                  <Typography variant='h3'>
                    {initialClients.length}
                  </Typography>
                  <Typography variant='body' fontSize={14}>
                    {t("All clients")}
                  </Typography>
                </PillContainer>
              </DashboardSectionContainer>
              {isMobile && (
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                >
                  <LengvaButton
                    title={t('More')}
                    endIcon={ChevronDownIcon}
                    color='inherit'
                    sx={{
                      padding: '0.5rem 1rem',
                    }}
                  />
                </Box>
              )}
            </DashboardSectionContainer>
            {!isMobile && (
              <DashboardSectionContainer
                flex={5}
                sx={{
                  padding: 0,
                }}
              >
                {!isLoading && (
                  <VictoryChart
                    maxDomain={{y: getMaxDomain(graphData)}}
                    minDomain={{y: 0}}
                    width={window.innerWidth}
                    height={300}
                    style={{
                      parent: {
                        height: '100%',
                        width: '100%',
                        border: '1px solid #E9E9EB',
                        borderRadius: '12px',
                        padding: '42px 16px 16px 20px',
                        boxSizing: 'border-box',
                      },
                    }}
                    containerComponent={
                      <VictoryVoronoiContainer
                        height={300}
                        width={window.innerWidth}
                        labels={({ datum }: { datum: any }) => `${datum.clients}`}
                        labelComponent={
                          <VictoryTooltip
                            style={{
                              fontSize: "20px",
                              fontFamily: 'Open Sans',
                            }}
                            flyoutStyle={{
                              fill: "transparent",
                              stroke: "none",
                            }}
                          />
                        }
                      />
                    }
                  >
                    <VictoryAxis
                      dependentAxis={false}
                      tickCount={4}
                      width={window.innerWidth}
                      style={{
                        axis: { stroke: "transparent" },
                      }}
                      tickLabelComponent={
                        <VictoryLabel
                          style={{
                            fontSize: "24px",
                            fontFamily: 'Open Sans',
                            fill: '#787878',
                            lineHeight: '20px',
                          }}
                        />
                      }
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      axisComponent={<></>}
                    />
                    <VictoryAxis
                      dependentAxis
                      tickCount={getMaxDomain(graphData) < 4 ? getMaxDomain(graphData) : 4}
                      style={{
                        grid: {
                          stroke: '#E9E9EB',
                          strokeWidth: 1,
                          strokeDasharray: '4, 4',
                        },
                      }}
                      tickFormat={tick => tick}
                      tickLabelComponent={
                        <VictoryLabel
                          style={{
                            fontSize: "24px",
                            fontFamily: 'Open Sans',
                            fill: '#787878',
                            lineHeight: '20px',
                          }}
                        />
                      }
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      axisComponent={<></>}
                    />
                    <VictoryPortal>
                      <VictoryLegend
                        x={50}
                        y={-5}
                        title={t('All clients')}
                        orientation='horizontal'
                        style={{ title: {fontSize: 24, fontFamily: 'Open sans', fontWeight: 600, fill: '#202632' } }}
                        data={[]}
                      />
                    </VictoryPortal>

                    <radialGradient id='gradient' cx='50%' cy='50%' r='100%' fx='50%' fy='50%'>
                      <stop offset='0%' style={{ stopColor: "#5C8FBF", stopOpacity: 0.3 }}/>
                      <stop offset='65%' style={{ stopColor: "#5C8FBF", stopOpacity: 0 }}/>
                    </radialGradient>
                    <VictoryArea
                      interpolation='cardinal'
                      x='date'
                      y='clients'
                      data={graphData}
                      style={{
                        data: { fill: 'url(#gradient)', stroke: '#5C8FBF', strokeWidth: 4, strokeOpacity: 0.5, marginBottom: '1rem' },
                        labels: { margin: "10px"},
                      }}
                      animate={{
                        duration: 1000,
                        onLoad: { duration: 1200 },
                      }}
                    />
                  </VictoryChart>
                )}
                {isLoading && <LengvaLoadingSpinner/>}
              </DashboardSectionContainer>
            )}
          </DashboardSectionContainer>
          <DashboardSectionContainer>
            <Box
              sx={{
                width: 'calc(100% + 3rem)',
                marginLeft: '-1.5rem',
              }}
            >
              <LengvaTable
                isViewOnly
                isLoading={isLoading}
                showFilters={false}
                title='Rezervacijos'
                columns={createClientConfig()}
                viewMore={(client: any) => navigate(`/admin/clients/${client.id}`)}
                data={mapAccountToTableValue(clients, t)}
                filter={[]}
                rowType='checkbox'
                allow={["add", "edit", "delete"]}
                itemCreated={async (item: any) => {
                  // console.log(item)
                }} itemUpdated={async (item: any) => {
                  // console.log(item)
                }} itemDeleted={async (item: any) => {
                  // console.log(item)
                }}
                onFilterSubmit={() => console.log('filter submit')}/>
            </Box>
          </DashboardSectionContainer>
          <AutoSubmitToken
            handleSubmit={formik.handleSubmit}
            dependantValue={formik.values.searchValue}
            delay={1500}
          />
        </DashboardContainer>

      )}
    </Formik>
  )
}

export const createClientConfig = () => {
  const config: ColumnConfig[] = [
    {
      property: "name",
      label: "Name",
      type: "string",
      orderId: 1,
    },
    {
      property: "email",
      label: "Email",
      type: "string",
      orderId: 2,
    },
    {
      property: "isEmailConfirmed",
      label: "Email Status",
      type: "label",
      hideOnCreate: true,
      orderId: 3,
    },
    {
      property: "lastAppointment",
      label: "Last Appointment",
      type: "string",
      hideOnCreate: true,
      hideOnForm: true,
      orderId: 4,
    },
    {
      property: "totalAppointments",
      label: "Total Appointments",
      type: "string",
      hideOnCreate: true,
      hideOnForm: true,
      orderId: 5,
    },
    {
      property: "payments",
      label: "Payments",
      type: "string",
      hideOnCreate: true,
      orderId: 6,
    },
  ]

  return config
}

interface ClientTableValue {
  id: number;
  name: string;
  email: string;
  isEmailConfirmed: {
    variant: string;
    label: string;
  };
  lastAppointment: string;
  totalAppointments: number;
  payments: string;
}

const mapAccountToTableValue = (accounts: ClientResponse[], t: TFunction): ClientTableValue[] => {
  const tableValues = accounts.map((account: ClientResponse) => ({
    id: account.id ?? 0,
    name: account.name ?? '',
    email: account.email ?? '',
    isEmailConfirmed: {
      variant: account.isEmailConfirmed ? 'success' : 'warning',
      label: account.isEmailConfirmed ? t('Confirmed') : t('Pending'),
    },
    lastAppointment: account.lastAppointmentDate ? new Date(account.lastAppointmentDate).toLocaleDateString('lt-LT', { year: 'numeric', month: '2-digit', day: '2-digit' })
      : new Date().toLocaleDateString('lt-LT', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    totalAppointments: account.totalAppointments ?? 0,
    payments: `${account.totalSpend} €`,
  }))

  return tableValues
}

interface ClientGraphData {
  date: string;
  clients: number;
}

const mapClientsToGraphData = (clients: ClientResponse[], graphStartDate: Date, graphEndDate: Date): ClientGraphData[] => {
  const graphData: ClientGraphData[] = []
  const currentDate = moment(graphStartDate)

  while (currentDate.isSameOrBefore(graphEndDate)) {
    // eslint-disable-next-line
    const clientsOnDate = clients.filter(client => client.appointments && moment(client.appointments[client.appointments.length - 1].startDate).isSame(currentDate, 'day'))
    graphData.push({
      date: currentDate.locale(i18next.language).format('DD MMM'),
      clients: clientsOnDate.length,
    })
    currentDate.add(1, 'day')
  }

  return graphData
}

const getMaxDomain = (graphData: ClientGraphData[]) => graphData.reduce((max, p) => p.clients > max ? p.clients : max, 0) + 1

interface ClientSearchValues {
  searchValue?: string;
}

const initialValues: ClientSearchValues = {
  searchValue: undefined,
}

export default ClientPage
