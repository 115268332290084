import { Avatar, Box, Checkbox, Divider, Typography, styled, useMediaQuery } from "@mui/material"
import { useGoogleLogin } from "@react-oauth/google"
import { type Account } from "_api/Account"
import { type Auth } from "_api/Auth"
import { type AccountPublicResponse, type EmailPasswordLoginRequest } from "_api/data-contracts"
import ArrowNarrowLeft from "_assets/arrow-narrow-left.svg"
import GoogleLogo from "_assets/google-logo.svg"
import LengvaLogo from "_assets/lengva-logo.svg"
import LengvaMindLogo from "_assets/lengva-mind-logo.png"
import LengvaButton from "_components/LengvaButton"
import LengvaInput from "_components/LengvaInput"
import useAuthStore, { hasRole } from "_state/useStore"
import { createApi } from "_utils/ApiCreator"
import { RoleNames } from "_utils/RequireAuth"
import { Formik, type FormikHelpers, type FormikProps } from "formik"
import { changeLanguage, type TFunction } from "i18next"
import { useEffect, useRef, useState } from "react"
import { ReactCountryFlag } from "react-country-flag"
import { useTranslation } from "react-i18next"
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import * as yup from "yup"

interface Props {
  isLoginPage?: boolean;
  isUserPage?: boolean;
  isForgotPasswordPage?: boolean;
}

const RegisterLoginPage = ({ isLoginPage, isUserPage, isForgotPasswordPage }: Props) => {
  const { specialistId } = useParams()
  const [searchParameters, setSearchParameters] = useSearchParams()
  const platform = useSearchParams()[0].get('platform')

  const [aggreeWithTerms, setAggreeWithTerms] = useState<boolean>(false)
  const [rememberMe, setRememberMe] = useState<boolean>(false)
  const [stage, setStage] = useState<number>(0)
  const [email, setEmail] = useState<string>("")
  const [specialist, setSpecialist] = useState<AccountPublicResponse | null>()

  const authStore = useAuthStore()
  const account = useAuthStore(state => state.account)
  const { isSpecialist } = useAuthStore(state => state)
  const authAPI = useRef(createApi("auth") as Auth)
  const accountAPI = useRef(createApi("account") as Account)
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 600px)")
  const { t, i18n } = useTranslation('translation', { keyPrefix: "LoginPage" })

  useEffect(() => {
    if (account?.email && !account.isEmailConfirmed) {}
  }, [account])

  useEffect(() => {
    getSpecialist()
    // eslint-disable-next-line
  }, [])

  const getSpecialist = async () => {
    if (!specialistId) {
      return
    }

    const specialist = await accountAPI.current.findSpecialistById(Number(specialistId))
    setSpecialist(specialist)
  }

  const toggleAggreWithTerms = () => {
    setAggreeWithTerms(!aggreeWithTerms)
  }

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe)
  }

  const handleLanguageChange = () => {
    switch (i18n.language) {
      case "en": {
        changeLanguage("lt")
        break
      }

      case "lt": {
        changeLanguage("en")
        break
      }

      default: {
        break
      }
    }

    // window.location.reload()
  }

  const toggleRegisterAndLogin = () => {
    if (isLoginPage) {
      navigate({
        pathname: isUserPage ? `/register${specialistId ? '/appointment/' + specialistId : ''}` : "/register/specialist",
        search: platform ? createSearchParams({ platform }).toString() : undefined,
      })
      return
    }

    navigate({
      pathname: isUserPage ? `/login${specialistId ? '/appointment/' + specialistId : ''}` : "/login/specialist",
      search: platform ? createSearchParams({ platform }).toString() : undefined,
    })
  }

  const toggleClientAndSpecialist = () => {
    navigate({
      pathname: isLoginPage ? (isUserPage ? '/login/specialist' : '/login') : (isUserPage ? '/register/specialist' : '/register'),
      search: platform ? createSearchParams({ platform }).toString() : undefined,
    })
  }

  const handleGoogleSuccess = async (response: any) => {
    await (isUserPage ? authAPI.current.loginGoogle({ authToken: response.code }) : authAPI.current.loginGoogleSpecialist({ authToken: response.code }))

    const user = await authStore.getMe()

    if (user.isActive) {
      if (specialistId) {
        window.location.href = '/appointment/new/' + specialistId
        return
      }

      if (user.role?.name === RoleNames.SPECIALIST) {
        navigate("/admin/")
        return
      }

      navigate("/appointments")
      return
    }

    if (user.role?.name === RoleNames.SPECIALIST) {
      window.location.href = "/admin/register/specialist/confirm"
      return
    }

    window.location.href = "/register/confirm" + (specialistId ? `/${specialistId}` : '') + (platform ? `?platform=${platform}` : "")
  }

  const handleGoogleError = (error: any) => {
    console.log(error)
  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleError,
    select_account: true,
    flow: 'auth-code',
    // scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email  https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/calendar",
    scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email  https://www.googleapis.com/auth/user.phonenumbers.read",
  })

  const sendConfirmationEmail = async () => {
    if (!authStore.account?.email) {
      return
    }

    await authAPI.current.sendEmailConfirmation({
      email: authStore.account?.email,
    })
  }

  const handleGoBackButtonClick = async () => {
    setStage(0)
    await authAPI.current.logoff()
  }

  const handleSubmit = async (values: RegisterValues, formik: FormikHelpers<RegisterValues>) => {
    if (isForgotPasswordPage) {
      await authAPI.current.sendResetPasswordEmail({
        email: values.email,
      })
      toast.success(t("ResetPasswordEmailSent"))
      formik.resetForm()
      return
    }

    if (isLoginPage) {
      const loginRequest: EmailPasswordLoginRequest = {
        email: values.email,
        password: values.password,
      }
      await authAPI.current.login(loginRequest)
      const user = await authStore.getMe()
      if (!user.isEmailConfirmed) {
        if (specialistId) {
          window.location.href = '/appointment/new/' + specialistId + (platform ? `?platform=${platform}` : "")
          return
        }

        setStage(1)
        return
      }

      if (specialistId) {
        window.location.href = '/appointment/new/' + specialistId + (platform ? `?platform=${platform}` : "")
        return
      }

      navigate(isSpecialist ? "/admin" : "/appointments")
      return
    }

    const registerRequest: EmailPasswordLoginRequest = {
      email: values.email,
      password: values.password,
      specialistId: specialistId ? Number(specialistId) : undefined,
    }

    setEmail(values.email)
    await (isUserPage ? authAPI.current.register(registerRequest) : authAPI.current.registerSpecialist(registerRequest))

    if (isUserPage && specialistId) {
      window.location.href = '/register/confirm/' + specialistId + (platform ? `?platform=${platform}` : "")
      return
    }

    setStage(stage + 1)
  }

  const getLogo = () => {
    if (platform === "mind") {
      return LengvaMindLogo
    }

    return LengvaLogo
  }

  return (
    <RegisterPageContainer
      sx={{
        paddingTop: isForgotPasswordPage ? "16rem" : "6.5rem",
      }}
    >
      <RegisterPageInnerContainer>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <img
            style={{
              maxWidth: "162px",
            }}
            src={getLogo()}
            alt='icon'
          />
          {!specialistId && !platform && !isForgotPasswordPage && stage !== 1 && (
            <Typography
              variant='h5'
              sx={{
                color: isUserPage ? '#3872E1' : '#35BBAB',
                borderRadius: '12px',
                backgroundColor: isUserPage ? '#EBF1FC' : '#EBF8F7',
                padding: '0.75rem 1.5rem',
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() =>
                toggleClientAndSpecialist()}
            >
              {isUserPage ? t("Client") : t("Specialist")}
            </Typography>
          )}
        </Box>
        {isUserPage && specialist && (
          <Box
            display='flex'
            alignItems='center'
            gap='12px'
          >
            <Typography variant='body'>
              {t("VisitReservationWith")}
            </Typography>
            <Box
              display='flex'
              alignItems='center'
              gap='8px'
            >
              <Avatar
                alt='specialist' src=''
                sx={{
                  width: isMobile ? '24px !important' : "32px",
                  height: isMobile ? '24px !important' : "32px",
                }}
              />
              <Typography variant='body'>
                {specialist.name} {specialist.lastName}
              </Typography>
            </Box>
          </Box>
        )}
        {stage === 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant='h3'>
                {
                  isForgotPasswordPage
                    ? t("ResetPassword")
                    : isUserPage
                      ? (isLoginPage  // eslint-disable-line
                        ? t("PleaseLogIn")
                        : t("PleaseRegister"))
                      : (isLoginPage  // eslint-disable-line 
                        ? t("WelcomeBack")
                        : t("InviteToRegister"))
                }
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  paddingRight: '0.5rem',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                  opacity: 0.8,
                }}
              >
                <ReactCountryFlag
                  svg
                  style={{ width: "30px", height: "20px" }}
                  countryCode={i18n.language === "en" ? "GB" : i18n.language.toUpperCase()}
                  cdnSuffix='svg'
                  title={i18n.language.toUpperCase()}
                  onClick={handleLanguageChange}
                />
              </Box>
            </Box>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={initialValues}
              validationSchema={validationSchema(t, isForgotPasswordPage)}
              onSubmit={handleSubmit}
            >
              {(formik: FormikProps<RegisterValues>) => (
                <FormContainer>
                  <LengvaInput label='email' title={t("Email")}/>
                  {!isForgotPasswordPage && (
                    <LengvaInput type='password' title={t("Password")} label='password'/>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {!isForgotPasswordPage && (
                      <Checkbox
                        checked={isLoginPage ? rememberMe : aggreeWithTerms}
                        onChange={isLoginPage ? toggleRememberMe : toggleAggreWithTerms}
                      />
                    )}
                    {!isLoginPage && !isForgotPasswordPage && (
                      <Box>
                        <Typography variant='body'>
                          {t("Consent")}{" "}
                        </Typography>
                        <Typography variant='hyperlink' color={platform === "mind" ? "text.mindPrimary" : "text.brand"}>
                          {t("PrivacyPolicy")}
                        </Typography>
                      </Box>
                    )}
                    {isLoginPage && !isForgotPasswordPage && (
                      <Typography variant='body'>
                        {t("RememberMe")}
                      </Typography>
                    )}
                  </div>
                  <LengvaButton
                    sx={{
                      width: "100%",
                    }}
                    color={getButtonVariant("primary", platform)}
                    disabled={getIsDisabled(formik, aggreeWithTerms, isLoginPage, isForgotPasswordPage)}
                    title={isForgotPasswordPage ? t("Continue") : (isLoginPage ? t("LogIn") : t("Register"))}
                    onClick={() => formik.handleSubmit()}
                  />
                  {!isForgotPasswordPage && (
                    <>
                      <Divider
                        sx={{
                          borderWidth: "1px", width: "100%",
                        }}
                      >
                        <Typography variant='body'>{t("Or")}</Typography>
                      </Divider>
                      <LengvaButton
                        sx={{
                          width: "100%",
                          gap: "0.75rem",
                        }}
                        title={t("LogInWithGoogle")}
                        color={getButtonVariant("secondary", platform)}
                        icon={GoogleLogo}
                        onClick={handleGoogleLogin}
                      />
                    </>
                  )}
                  {!isLoginPage && !isForgotPasswordPage && (
                    <Box width='100%'>
                      <Typography variant='body'>
                        {t("AlreadyHaveAccount")}{" "}
                      </Typography>
                      <Typography
                        color={platform === "mind" ? "text.mindPrimary" : "text.brand"}
                        variant='hyperlink'
                        onClick={() => toggleRegisterAndLogin()}
                      >
                        {t("LogIn")}
                      </Typography>
                    </Box>
                  )}
                  {isLoginPage && !isForgotPasswordPage && (
                    <Box
                      width='100%'
                      display='flex'
                      flexDirection='column'
                      gap='1.5rem'
                    >
                      <Typography
                        variant='hyperlink'
                        color={platform === "mind" ? "text.mindPrimary" : "text.brand"}
                        onClick={() => navigate("/forgot-password")}
                      >
                        {t("ForgotPassword")}
                      </Typography>
                      <Box>
                        <Typography variant='body'>
                          {t("DontHaveAccount")}{" "}
                        </Typography>
                        <Typography
                          variant='hyperlink'
                          color={platform === "mind" ? "text.mindPrimary" : "text.brand"}
                          onClick={() => toggleRegisterAndLogin()}
                        >
                          {t("DoRegistration")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </FormContainer>
              )}
            </Formik>
          </>
        )}
        {stage === 1 && (
          <Box
            display='flex'
            flexDirection='column'
            gap='1rem'
          >
            <Typography variant='h3'>
              {t("EmailConfirmation")}
            </Typography>
            <Typography variant='body'>
              {t("ConfirmYourEmailWithLink")} {email}
            </Typography>
            <Box>
              <Typography variant='body'>
                {t("DidntGetEmail")}{" "}
              </Typography>
              <Typography
                variant='hyperlink'
                onClick={sendConfirmationEmail}
              >
                {t("ResendConfirmationEmail")}
              </Typography>
            </Box>
            <LengvaButton
              title={t("GoBack")}
              color='secondary'
              sx={{
                paddingTop: "0.5rem",
                maxWidth: "116px",
                gap: "0.5rem",
              }}
              icon={ArrowNarrowLeft}
              onClick={async () => handleGoBackButtonClick()}
            />
          </Box>
        )}
      </RegisterPageInnerContainer>
    </RegisterPageContainer>
  )
}

export const RegisterPageContainer = styled("div")(() => {
  const isMobile = useMediaQuery("(max-width: 600px)")
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    boxSizing: "border-box",
    padding: isMobile ? "1rem" : "0",
    paddingTop: isMobile ? "2.5rem" : "6.5rem",
  }
})

export const RegisterPageInnerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "720px",
  gap: "2rem",
})

export const FormContainer = styled(Box)(() => {
  const isMobile = useMediaQuery("(max-width: 600px)")
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.5rem",
    width: "100%",
    padding: isMobile ? "0.75rem" : "2rem",
    boxSizing: "border-box",
    borderRadius: "24px",
    background: "#FFFFFF",
    boxShadow: "0px 3px 17px 0px rgba(0, 0, 0, 0.14)",
  }
})

const getIsDisabled = (formik: FormikProps<RegisterValues>, aggreeWithTerms: boolean, isLoginPage?: boolean, isForgotPasswordPage?: boolean) => {
  if (isForgotPasswordPage) {
    return !formik.values.email
  }

  if (isLoginPage) {
    return !formik.values.email || !formik.values.password
  }

  return !aggreeWithTerms || !formik.values.email || !formik.values.password
}

interface RegisterValues {
  email: string;
  password: string;
}

const initialValues: RegisterValues = {
  email: "",
  password: "",
}

const validationSchema = (t: TFunction, isForgotPasswordPage?: boolean) => yup.object({
  email: yup
    .string()
    .email(t("ValidationErrors.WrongEmail"))
    .required(t("ValidationErrors.RequiredField")),
  password: yup.string().when('isForgotPasswordPage', (isForgotPasswordPage, schema) => {
    if (isForgotPasswordPage) {
      return yup.string()
    }

    return yup.string().required(t("ValidationErrors.RequiredField")).min(8, t("ValidationErrors.PasswordMinLength"))
  }),
})

export const getButtonVariant = (variant: "primary" | "secondary", platform: string | null) => {
  if (!platform) {
    return variant
  }

  switch (platform) {
    case "mind": {
      return variant === "primary" ? "mindPrimary" : "mindSecondary"
    }

    default: {
      return variant
    }
  }
}

export default RegisterLoginPage
