import { Box, Divider, Typography } from "@mui/material"

interface Props {
  steps: string[];
  activeStep: number;
  hideText?: boolean;
  colorInactiveOverride?: string;
  colorActiveOverride?: string;

}

const LengvaStepper = ({ steps, activeStep, hideText, colorInactiveOverride, colorActiveOverride }: Props) => (
  <Box
    display='flex'
    flexDirection='column'
    gap='2rem'
  >
    <Box
      display='flex'
      gap='0.5rem'
    >
      {steps.map((step, index) => (
        <Box
          key={step}
          width='100%'
        >
          <Divider
            sx={{
              transition: "all 0.4s ease-in-out",
              borderColor: getColor(index, activeStep, colorInactiveOverride, colorActiveOverride),
              borderWidth: "2px",
              width: "100%",
              borderRadius: index === 0
                ? "12px 0px 0px 12px"
                : (index === steps.length - 1 ? "0px 12px 12px 0px" : "0px"),

            }}
          />
        </Box>
      ))}
    </Box>
    {!hideText && (
      <Box
        display='flex'
      >
        <Typography variant='h3'>
          {activeStep + 1}
        </Typography>
        <Typography variant='h3' color='#9B9DA3'>
          /{`${steps.length}  `}
        </Typography>
        <Typography
          variant='h3'
          paddingLeft='0.5rem'
        >
          {steps[activeStep]}
        </Typography>
      </Box>
    )}
  </Box>
)

const getColor = (index: number, activeStep: number, colorInactiveOverride?: string, colorActiveOverride?: string) => (
  index === activeStep ? colorActiveOverride ?? "#3872E1" : colorInactiveOverride ?? "#EBF1FC"
)

export default LengvaStepper
