
import { Box, Typography, useMediaQuery } from "@mui/material"
import { type Appointments } from "_api/Appointments"
import { type Payment } from "_api/Payment"
import { type AppointmentResponse, type PaymentCreateRequest, type PaymentResponse } from "_api/data-contracts"
import LengvaTable, { type ColumnConfig } from "_components/LengvaTable"
import { DashboardContainer, DashboardSectionContainer, DashboardTitleContainer } from "_pages/admin/appointments/AppointmentListPage"
import PaymentSelectionDialog, { type PaymentMethodFormValues } from "_pages/public/payments/PaymentSelectionDialog"
import { createApi } from "_utils/ApiCreator"
import AutoSubmitToken from "_utils/AutoSubmitToken"
import { PaymentSubtype, convertTime } from "_utils/ObjectUtils"
import { Formik } from "formik"
import { type TFunction } from "i18next"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const ClientPaymentsPage = () => {
  const [appointments, setAppointments] = useState<AppointmentResponse[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState<boolean>(false)
  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState<number[]>([])

  const isMobile = useMediaQuery('(max-width: 600px)')
  const navigate = useNavigate()

  const appointmentAPI = useRef(createApi("appointment") as Appointments)
  const paymentAPI = useRef(createApi("payment") as Payment)
  const { t } = useTranslation('translation', {keyPrefix: "ClientPaymentsPage"})
  const tServices = useTranslation("translation", {
    keyPrefix: "ServiceForm",
  })

  const getAppointments = async () => {
    setIsLoading(true)
    const query = {
      searchString: '',
    }

    const appointments = await appointmentAPI.current.findClientAllFilteredBy(query)
    setAppointments(appointments)
    setIsLoading(false)
  }

  useEffect(() => {
    getAppointments()
  }, [])

  const handleOpenPaymentDialog = () => {
    setIsPaymentDialogOpen(true)
  }

  const handleClosePaymentDialog = () => {
    setIsPaymentDialogOpen(false)
    setSelectedAppointmentIds([])
  }

  const handleButtonClick = (item: any) => {
    setSelectedAppointmentIds([item.id])
    handleOpenPaymentDialog()
  }

  const handleSubmitPayment = async (values: PaymentMethodFormValues) => {
    if (!values.paymentMethod) {
      return
    }

    const paymentCreateRequest: PaymentCreateRequest = {
      appointmentIds: selectedAppointmentIds,
      paymentMethod: values.paymentMethod,
    }

    const paymentURL = await paymentAPI.current.pay(paymentCreateRequest)
    window.location.href = paymentURL
  }

  const handleSearchSubmit = async (values: PaymentTableSearchValues) => {
    const query = {
      searchString: values.searchValue,
    }

    const filteredAppointments = await appointmentAPI.current.findClientAllFilteredBy(query)
    setAppointments(filteredAppointments)
  }

  return (
    <Formik
      initialValues={initialSearchValues}
      onSubmit={handleSearchSubmit}
    >
      {formik => (
        <DashboardContainer>
          <DashboardTitleContainer
            sx={{
              flexWrap: 'wrap',
              marginBottom: '2rem',
              gap: '1rem',
            }}
          >
            <Typography variant='h2'>
              {t('Payments')}
            </Typography>
            <DashboardSectionContainer
              sx={{
                backgroundColor: '#FAFAFA',
              }}
            >
              <Box
                sx={{
                  width: 'calc(100% + 3rem)',
                  marginLeft: '-1.5rem',
                }}
              >
                <LengvaTable
                  isViewOnly
                  isHeaderTransparent
                  isLoading={isLoading}
                  showFilters={false}
                  columns={createClientPaymentConfig(handleButtonClick)}
                  data={mapPaymentsToTableData(appointments, t, tServices.t)}
                  filter={[]}
                  emptyStateText={t('No Payments')}
                  rowType='checkbox'
                  selectedAction={(rowIds: any) => {
                    setSelectedAppointmentIds(rowIds)
                    handleOpenPaymentDialog()
                  }}
                  selectedActionLabel={t('PaySelected')}
                  allow={["add", "edit", "delete"]} itemCreated={async (item: any) => {
                    // console.log('itemCreated')
                  }} itemUpdated={async (item: any) => {
                    // console.log('itemUpdated')
                  }}
                  itemDeleted={async (item: any) => {
                    // console.log('itemDeleted')
                  }}/>
              </Box>
            </DashboardSectionContainer>
          </DashboardTitleContainer>
          <AutoSubmitToken
            handleSubmit={formik.handleSubmit}
            dependantValue={formik.values.searchValue}
            delay={1500}
          />
          {selectedAppointmentIds.length > 0 && (
            <PaymentSelectionDialog
              open={isPaymentDialogOpen}
              appointmentIds={selectedAppointmentIds}
              handleClose={handleClosePaymentDialog}
              handleSubmit={handleSubmitPayment}
            />
          )}
        </DashboardContainer>
      )}
    </Formik>
  )
}

interface PaymentTableValue {
  id: number;
  paymentStatus: {
    beforeText?: string;
    label: string;
    variant: 'success' | 'error';
  };
  price: string;
  paymentType: string;
  service: string;
  serviceLength: string;
  specialist: {
    label: string;
    src: string;
  };
  date: string;
  payButton: {
    title: string;
    isHidden: boolean;
    color: 'primary' | 'secondary';
  };
}

export const createClientPaymentConfig = (handleButtonClick: (item: any) => void) => {
  const config: ColumnConfig[] = [
    {
      property: "paymentStatus",
      label: "Status",
      type: "label",
      hideOnCreate: true,
      orderId: 1,
    },
    {
      property: "price",
      label: "Amount",
      type: "string",
      hideOnCreate: true,
      orderId: 2,
    },
    {
      property: "paymentType",
      label: "Payment Type",
      type: "string",
      orderId: 3,
    },
    {
      property: "service",
      property2: "serviceLength",
      label: "Service",
      type: "string",
      hideOnCreate: true,
      orderId: 4,
    },
    {
      property: "specialist",
      label: "Specialist",
      type: "avatar",
      orderId: 5,
    },
    {
      property: "date",
      property2: "time",
      label: "Date",
      type: "string",
      hideOnCreate: true,
      orderId: 6,
    },
    {
      property: "payButton",
      label: "",
      type: "button",
      onClick(item: any) {
        handleButtonClick(item)
      },
      orderId: 7,
    },
  ]

  return config
}

interface PaymentTableSearchValues {
  searchValue?: string;
}

const initialSearchValues: PaymentTableSearchValues = {
  searchValue: undefined,
}

const mapPaymentsToTableData = (appointments: AppointmentResponse[], t: TFunction, tService: TFunction): PaymentTableValue[] => {
  const tableValues = appointments.map(appointment => {
    const paidAppointment = appointment.payments?.find(payment => payment.status === "PAID")
    const payments = appointment.payments ?? []
    const tableValue: PaymentTableValue = {
      id: appointment.id ?? 0,
      paymentStatus: {
        label: appointment.payments?.map(payment => payment.status).includes("PAID") ? t('Paid') : t('Not paid'),
        variant: appointment.payments?.map(payment => payment.status).includes("PAID") ? 'success' : 'error',
      },
      price: `${paidAppointment?.amount ?? payments.length > 0 ? payments[0].amount : appointment.service.price}€`,
      // price: `${appointment.payments ? appointment.payments[0].amount : appointment.service.price}€`,
      paymentType: getPaymentMethod(t, paidAppointment),
      service: tService(`Services.${appointment.service.serviceDescription.name}`),
      serviceLength: convertTime(appointment.service.duration),
      specialist: {
        label: `${appointment.specialist.name} ${appointment.specialist.lastName?.charAt(0)}.`,
        src: '',
      },
      date: new Date(appointment.startDate).toLocaleDateString('lt-LT', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      payButton: {
        title: t('Pay'),
        isHidden: appointment.payments?.map(payment => payment.status).includes("PAID") ?? false,
        color: 'secondary',
      },
    }

    return tableValue
  })

  return tableValues
}

const getPaymentMethod = (t: TFunction, payment?: PaymentResponse) => {
  if (!payment) {
    return '-'
  }

  if (payment.provider === 'PAYPAL') {
    return 'PayPal'
  }

  if (payment.subtype === PaymentSubtype.CARD) {
    return t('Card')
  }

  if (payment.subtype === PaymentSubtype.BANK) {
    return t('Bank')
  }

  if (payment.subtype === PaymentSubtype.APPLE_PAY) {
    return 'Apple Pay'
  }

  if (payment.subtype === PaymentSubtype.GOOGLE_PAY) {
    return 'Google Pay'
  }

  return '-'
}

export default ClientPaymentsPage
