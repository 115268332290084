/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ClientCreateRequest,
  ClientResponse,
  EmailPasswordLoginRequest,
  MagicLoginRequest,
  MeResponse,
  RegisterConfirmationRequest,
  UpdatePasswordRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Auth<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags auth-controller
   * @name RegisterConfirmation
   * @request PUT:/api/v1/public/auth/register/confirm
   */
  registerConfirmation = (data: RegisterConfirmationRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/register/confirm`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name ChangePassword
   * @request PUT:/api/v1/public/auth/password/change
   */
  changePassword = (data: UpdatePasswordRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/password/change`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-specialist-controller
   * @name SendEmailConfirmationToClient
   * @request POST:/api/v1/specialist/auth/send-confirmation/{accountId}
   */
  sendEmailConfirmationToClient = (accountId: number, params: RequestParams = {}) =>
    this.request<boolean, any>({
      path: `/api/v1/specialist/auth/send-confirmation/${accountId}`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-specialist-controller
   * @name CreateClient
   * @request POST:/api/v1/specialist/auth/create-client
   */
  createClient = (data: ClientCreateRequest, params: RequestParams = {}) =>
    this.request<ClientResponse, any>({
      path: `/api/v1/specialist/auth/create-client`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name Register
   * @request POST:/api/v1/public/auth/register
   */
  register = (data: EmailPasswordLoginRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/register`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name RegisterSpecialist
   * @request POST:/api/v1/public/auth/register/specialist
   */
  registerSpecialist = (data: EmailPasswordLoginRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/register/specialist`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name ResetPassword
   * @request POST:/api/v1/public/auth/password/reset
   */
  resetPassword = (
    query: {
      uuid: string;
      password: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/password/reset`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name SendResetPasswordEmail
   * @request POST:/api/v1/public/auth/password/reset/send-email
   */
  sendResetPasswordEmail = (
    query: {
      email: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/password/reset/send-email`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name Logoff
   * @request POST:/api/v1/public/auth/logoff
   */
  logoff = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/logoff`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name Login
   * @request POST:/api/v1/public/auth/login
   */
  login = (data: EmailPasswordLoginRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/login`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name LoginMicrosoft
   * @request POST:/api/v1/public/auth/login/microsoft
   */
  loginMicrosoft = (
    query: {
      code: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/login/microsoft`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name LoginSpecialistMicrosoft
   * @request POST:/api/v1/public/auth/login/microsoft/specialist
   */
  loginSpecialistMicrosoft = (
    query: {
      code: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/login/microsoft/specialist`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name LoginMagic
   * @request POST:/api/v1/public/auth/login/magic
   */
  loginMagic = (data: MagicLoginRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/login/magic`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name LoginGoogle
   * @request POST:/api/v1/public/auth/login/google
   */
  loginGoogle = (
    query: {
      authToken: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/login/google`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name LoginGoogleSpecialist
   * @request POST:/api/v1/public/auth/login/google/specialist
   */
  loginGoogleSpecialist = (
    query: {
      authToken: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/login/google/specialist`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name GetMe
   * @request GET:/api/v1/public/auth/me
   */
  getMe = (params: RequestParams = {}) =>
    this.request<MeResponse, any>({
      path: `/api/v1/public/auth/me`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags auth-controller
   * @name SendEmailConfirmation
   * @request GET:/api/v1/public/auth/login/magic/send-email
   */
  sendEmailConfirmation = (
    query: {
      email: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v1/public/auth/login/magic/send-email`,
      method: "GET",
      query: query,
      ...params,
    });
}
