import { Typography, useMediaQuery} from "@mui/material"
import { type Account } from "_api/Account"
import RefreshIcon from "_assets/refresh.svg"
import LengvaButton from "_components/LengvaButton"
import LengvaTable, { type ColumnConfig } from "_components/LengvaTable"
import {
  type ScheduleConfig,
  type ServiceConfig,
  type StepConfig,
} from "_components/LengvaTableCreateEditModal"
import { DashboardContainer, DashboardTitleContainer } from "_pages/admin/appointments/AppointmentListPage"
import PlusIcon from "_assets/plus.svg"
import { createApi } from "_utils/ApiCreator"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { type AccountAdminResponse, type AccountPublicResponse } from "_api/data-contracts"
import { toast } from "react-toastify"

export default function AccountPage() {
  const { t } = useTranslation('translation', { keyPrefix: 'AccountPage' })
  const accountAPI = useRef(createApi("account") as Account)

  const [specialists, setSpecialists] = useState<AccountAdminResponse[]>([])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const response = await accountAPI.current.findAll()
    setSpecialists(response)
  }

  const updateStrapiProfileId = async (id: number) => {
    await accountAPI.current.updateStrapiAccountId(id)
    toast.success(t('ProfileUpdateSuccess'))
  }

  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <DashboardContainer
      sx={{
        paddingTop: "0",
      }}
    >
      <DashboardTitleContainer
        sx={{
          flexWrap: "wrap",
          marginBottom: isMobile ? "0px" : "1rem",
          gap: "1rem",
        }}
      >
        <Typography variant='h2'>{t("Users")}</Typography>
        <LengvaButton
          title={isMobile ? (t("New")) : (t("New user"))}
          color='primary'
          icon={PlusIcon}
          onClick={() => navigate(`/admin/accounts/manage/new`)}
        />

      </DashboardTitleContainer>
      <LengvaTable
        viewMore={(specialist: AccountPublicResponse) => navigate(`/admin/accounts/manage/${specialist.id}`)}
        itemCustomFunction={async (specialist: AccountPublicResponse) => updateStrapiProfileId(specialist.id)}
        itemCustomFunctionIcon={RefreshIcon}
        title={t('Specialists')}
        columns={createAccountConfig()}
        allow={[]}
        data={specialists}
        rowType='dropdown'
        filter={[]}
        showFilters={false}
        onCreateEditButtonClick={() => navigate("/admin/accounts/new")}
      />
    </DashboardContainer>
  )
}

export const modalStepConfig: StepConfig[] = [
  {
    id: 1,
    title: "PersonalDetails",
    status: "active",
  },
  {
    id: 2,
    title: "Services",
    status: "inactive",
  },
  {
    id: 3,
    title: "Schedules",
    status: "inactive",
  },
  {
    id: 4,
    title: "DaysOff",
    status: "inactive",
  },
]

const createAccountConfig = (): ColumnConfig[] => [
  {
    property: "name",
    label: "Name",
    type: "string",
    property2: "lastName",
    orderId: 2,
    hideOnTable: false,
  },
  {
    property: "lastName",
    label: "Last Name",
    type: "string",
    orderId: 3,
    hideOnTable: false,
  },
  {
    property: "email",
    label: "Email",
    type: "string",
    orderId: 4,
    hideOnTable: false,
  },
  {
    property: "status",
    label: "Status",
    type: 'label',
    orderId: 5,
    hideOnTable: false,
  },
]

export const createServiceTestData = (): ServiceConfig[] => [
  {
    id: "1",
    title: "Inidividuali konsultacija",
    supportTitleText: "Lorem ipsum dolor sit amet consectetur. Dapibus",
    price: 50,
    duration: "1h",
    bufferTimeBefore: "5 min",
    bufferTimeAfter: "5 min",
  },
  {
    id: "2",
    title: "Vaikų terapija",
    supportTitleText: "Support text",
    price: 35,
    duration: "1h",
    bufferTimeBefore: "5 min",
    bufferTimeAfter: "5 min",
  },
  {
    id: "3",
    title: "Inidividuali konsultacija",
    supportTitleText: "Lorem ipsum dolor sit amet consectetur. Dapibus",
    price: 50,
    duration: "1h",
    bufferTimeBefore: "5 min",
    bufferTimeAfter: "5 min",
  },
  {
    id: "4",
    title: "Vaikų terapija",
    supportTitleText: "Support text",
    price: 35,
    duration: "1h",
    bufferTimeBefore: "5 min",
    bufferTimeAfter: "5 min",
  },
  {
    id: "5",
    title: "Inidividuali konsultacija",
    supportTitleText: "Lorem ipsum dolor sit amet consectetur. Dapibus",
    price: 50,
    duration: "1h",
    bufferTimeBefore: "5 min",
    bufferTimeAfter: "5 min",
  },
  {
    id: "6",
    title: "Vaikų terapija",
    supportTitleText: "Support text",
    price: 35,
    duration: "1h",
    bufferTimeBefore: "5 min",
    bufferTimeAfter: "5 min",
  },
]

export const createScheduleTestData = (): ScheduleConfig[] => []
