import { ExpandMoreOutlined } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, DialogTitle, Divider, Typography, useMediaQuery } from "@mui/material"
import { type Schedules } from "_api/Schedules"
import { type ScheduleEntryCreateRequest, type ScheduleEntryResponse, type ScheduleResponse, type ScheduleUpdateRequest } from "_api/data-contracts"
import CalendarBigIcon from "_assets/calendar-filled-icon.svg"
import ChevronLeftIcon from "_assets/chevronLeft.svg"
import ClipboardBigIcon from "_assets/clipboard-big.svg"
import CloseBlackIcon from "_assets/closeBlack.svg"
import TrashIcon from '_assets/trash-blue.svg'
import LengvaButton from "_components/LengvaButton"
import LengvaDeleteConfirm from "_components/LengvaDeleteConfirm"
import LengvaIcon from "_components/LengvaIcon"
import ScheduleAvailabilityForm, { type AvailableDay, type ScheduleAvailabilityFormValues, type TimeInterval } from "_pages/admin/schedule/ScheduleAvailabilityForm"
import ScheduleDetailsForm, { type LocationType, type ScheduleDetailsFormValues } from "_pages/admin/schedule/ScheduleDetailsForm"
import { createApi } from "_utils/ApiCreator"
import { type MeetingProvider } from "_utils/ObjectUtils"
import { type FormikHelpers } from "formik"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  open: boolean;
  schedule?: ScheduleResponse;
  handleClose: () => void;
  refetchSchedules?: () => Promise<void>;
}

const EditScheduleDialog = ({open, schedule, handleClose, refetchSchedules}: Props) => {
  const [detailAccordionExpanded, setDetailAccordionExpanded] = useState<boolean>(false)
  const [availabilityAccordionExpanded, setAvailabilityAccordionExpanded] = useState<boolean>(false)
  const [availabilityFormValues, setAvailabilityFormValues] = useState<ScheduleAvailabilityFormValues | undefined>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [detailsFormValues, setDetailsFormValues] = useState<ScheduleDetailsFormValues | undefined>()

  const isMobile = useMediaQuery('(max-width: 600px)')
  const scheduleAPI = useRef(createApi('schedule') as Schedules)
  const submitAvailabilityButtonRef = useRef<HTMLButtonElement | null>(null)
  const submitDetailsButtonRef = useRef<HTMLButtonElement | null>(null)
  const { t } = useTranslation('translation', { keyPrefix: 'SchedulesPage' })

  const handleDetailAccordionChange = () => {
    setDetailAccordionExpanded(!detailAccordionExpanded)
  }

  const handleAvailabilityAccordionChange = () => {
    setAvailabilityAccordionExpanded(!availabilityAccordionExpanded)
  }

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  useEffect(() => {
    if (availabilityFormValues && detailsFormValues) {
      sendRequest()
    }
  // eslint-disable-next-line
  }, [availabilityFormValues, detailsFormValues])

  const handleDeleteSchedule = async () => {
    if (!schedule?.id) {
      return null
    }

    await scheduleAPI.current.delete(schedule.id)
    handleClose()
    refetchSchedules?.()
  }

  const handleAvailabilityFormSubmit = async (values: ScheduleAvailabilityFormValues, formikHelpers: FormikHelpers<ScheduleAvailabilityFormValues>) => {
    const errors = await formikHelpers.validateForm()
    if (Object.keys(errors).length > 0) {
      return
    }

    setAvailabilityFormValues(values)
  }

  const handleDetailsFormSubmit = async (values: ScheduleDetailsFormValues, formikHelpers: FormikHelpers<ScheduleDetailsFormValues>) => {
    const errors = await formikHelpers.validateForm()
    if (Object.keys(errors).length > 0) {
      return
    }

    setDetailsFormValues(values)
  }

  const handleSubmit = async () => {
    if (submitAvailabilityButtonRef.current) {
      submitAvailabilityButtonRef.current.click()
    }

    if (submitDetailsButtonRef.current) {
      submitDetailsButtonRef.current.click()
    }
  }

  const sendRequest = async () => {
    if (!schedule?.id || !availabilityFormValues || !detailsFormValues) {
      return null
    }

    const scheduleUpdateRequest: ScheduleUpdateRequest = {
      id: schedule.id,
      name: detailsFormValues?.scheduleName,
      description: detailsFormValues?.description,
      type: "REPEATING",
      repeatFrequency: "WEEKLY",
      maxBookingsPerDay: availabilityFormValues?.isMaxBookingsPerDayActive ? availabilityFormValues?.maxBookingsPerDay : undefined,
      maxDaysInAdvance: availabilityFormValues?.isMaxDaysInAdvanceActive ? availabilityFormValues?.maxDaysInAdvance : undefined,
      scheduleEntries: mapAvailableDaysToScheduleEntries(availabilityFormValues?.availableDays),
      locationIds: detailsFormValues?.locations.map(location => location.id),
    }

    await scheduleAPI.current.update(schedule.id.toString(), scheduleUpdateRequest)
    handleClose()
    refetchSchedules?.()
  }

  return (
    <Dialog
      fullWidth
      keepMounted
      open={open}
      maxWidth='md'
      PaperProps={{
        sx: {
          height: "100%",
          padding: isMobile ? '1rem' : "1.5rem",
          boxSizing: "border-box",
          border: 'none',
          borderRadius: isMobile ? "0px" : "24px",
          minWidth: isMobile ? "100%" : 'md',
          minHeight: isMobile ? "100%" : 'none',
          overflowX: "hidden",
          overflowY: "auto",
        },
      }}
    >

      <DialogTitle
        sx={{
          display: "flex",
          padding: "0",
          paddingTop: isMobile ? '0' : '0.5rem',
          paddingBottom: isMobile ? '1rem' : '1.5rem',
          alignItems: "center",
          justifyContent: "space-between",
          gap: "8px",
          alignSelf: "stretch",
        }}
      >
        <LengvaIcon
          variant='v3'
          icon={ChevronLeftIcon}
          onClick={handleClose}
        />
        <Typography variant='body' fontWeight={600}>{t(`Edit schedule`)}</Typography>
        <LengvaIcon variant='v3' icon={CloseBlackIcon} onClick={handleClose}/>
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          // height: "100%",
          marginBottom: "6rem",
        }}
      >
        <Accordion
          expanded={detailAccordionExpanded}
          sx={{
            boxShadow: "none",
            border: '1px solid #E9E9EB',
            borderRadius: "12px !important",
            "& :before": {
              display: "none",
            },
          }}
          onChange={handleDetailAccordionChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined/>}
            aria-controls='panel1bh-content'
            id='panel1bh-header'
            sx={{
              display: "flex",
              alignItems: "center",
              padding: isMobile ? '16px' : "16px 24px",
              borderBottom: detailAccordionExpanded ? "1px solid #E9E9EB" : "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <img src={ClipboardBigIcon as any} alt=''/>
              <Typography variant='body' fontWeight={600} color='text.secondary'>{t(`Details`)}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "1.5rem",
              boxSizing: "border-box",
            }}
          >
            {schedule && (
              <ScheduleDetailsForm
                hideTitle
                submitRef={submitDetailsButtonRef}
                values={mapScheduleToFormValues(schedule)}
                handleSubmit={handleDetailsFormSubmit}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={availabilityAccordionExpanded}
          sx={{
            boxShadow: "none",
            border: '1px solid #E9E9EB',
            borderRadius: "12px !important",
            "&:before": {
              display: "none !important",
            },
          }}
          onChange={handleAvailabilityAccordionChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined/>}
            aria-controls='panel1bh-content'
            id='panel1bh-header'
            sx={{
              display: "flex",
              alignItems: "center",
              padding: isMobile ? '16px' : "16px 24px",
              borderBottom: availabilityAccordionExpanded ? "1px solid #E9E9EB" : "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <img src={CalendarBigIcon as any} alt=''/>
              <Typography variant='body' fontWeight={600} color='text.secondary'>{t(`Availability`)}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "1.5rem",
              boxSizing: "border-box",
            }}
          >
            {schedule && (
              <ScheduleAvailabilityForm
                hideTitle
                submitRef={submitAvailabilityButtonRef}
                values={mapScheduleToFormValues(schedule)}
                handleSubmit={handleAvailabilityFormSubmit}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          bottom: isMobile ? '0px' : '32px',
          right: '0',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#FFFFFF',
          borderRadius: '0px 0px 12px 12px',
          maxWidth: '900px',
          boxSizing: 'border-box',
          width: isMobile ? '100%' : 'calc(100% - 64px)',
        }}
      >
        <Divider
          sx={{
            width: "100%",
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            padding: isMobile ? '0.75rem' : '1.5rem',
            width: '100%',
          }}
        >
          <LengvaButton
            title={t('Delete')}
            color='inherit'
            icon={TrashIcon}
            onClick={handleDeleteDialogOpen}
          />

          <LengvaButton
            type='submit'
            title={t('Save')}
            // endIcon={isEdit ? undefined : (isMobile && activeStep === 0 ? ArrowRightIcon : undefined)}
            color='primary'
            onClick={async () => handleSubmit()}
          />
        </Box>
      </Box>
      {/* </Box> */}
      <LengvaDeleteConfirm
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleDelete={handleDeleteSchedule}
        objectName='Schedule'
      />
      {/* // TODO: Open accordion if there are errors */}
    </Dialog>
  )
}

export interface ScheduleFormValues {
  scheduleName: string;
  description: string;
  locations: LocationType[];
  repeatFrequency: "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY" | "";
  isMaxBookingsPerDayActive: boolean;
  maxBookingsPerDay: number;
  isMaxDaysInAdvanceActive: boolean;
  maxDaysInAdvance: number;
  availableDays: AvailableDay[];
}

const mapScheduleToFormValues = (schedule: ScheduleResponse): ScheduleFormValues => ({
  repeatFrequency: schedule.repeatFrequency ?? "",
  isMaxBookingsPerDayActive: schedule.maxBookingsPerDay !== null,
  maxBookingsPerDay: schedule.maxBookingsPerDay ?? 0,
  isMaxDaysInAdvanceActive: schedule.maxDaysInAdvance !== null,
  maxDaysInAdvance: schedule.maxDaysInAdvance ?? 0,
  availableDays: mapScheduleEntriesToAvailableDays(schedule),
  scheduleName: schedule.name ?? "",
  description: schedule.description ?? "",
  locations: schedule.locations?.map(location => ({
    id: location.id,
    type: location.meetingProvider as MeetingProvider,
    locationName: location.name,
    address: location.defaultAddress ?? "",
    meetingLink: location.defaultAddress ?? "",
  })) ?? [],

})

const mapScheduleEntriesToAvailableDays = (schedule: ScheduleResponse): AvailableDay[] => weekdays?.map(weekday => ({
  id: schedule.scheduleEntries?.find(scheduleEntry => scheduleEntry.weekday === weekday)?.id ?? undefined,
  day: toLowerCaseWeekday(weekday),
  isActive: schedule.scheduleEntries?.some(scheduleEntry => scheduleEntry.weekday === weekday) ?? false,
  timeIntervals: mapScheduleToTimeIntervals(schedule, weekday),
})) ?? []

const toLowerCaseWeekday = (weekday?: string): string => {
  if (!weekday) {
    return ""
  }

  return weekday.charAt(0) + weekday.slice(1).toLowerCase()
}

const mapScheduleToTimeIntervals = (schedule: ScheduleResponse, weekDay?: string): TimeInterval[] => {
  const timeIntervals: TimeInterval[] | undefined = schedule.scheduleEntries?.filter((scheduleEntry: ScheduleEntryResponse) => scheduleEntry.weekday === weekDay).map((scheduleEntry: ScheduleEntryResponse) => {
    const timeInterval: TimeInterval = { id: scheduleEntry.id, start: new Date(scheduleEntry.startTime), end: new Date(scheduleEntry.endTime) }

    return timeInterval
  })

  return timeIntervals?.filter(timeInterval => timeInterval.end && timeInterval.start) ?? []
}

const weekdays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]

export enum ScheduleRepeatFrequency {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export const mapAvailableDaysToScheduleEntries = (availableDays: AvailableDay[]): ScheduleEntryCreateRequest[] | undefined => {
  const scheduleEntries: ScheduleEntryCreateRequest[] = []

  availableDays.forEach(availableDay => {
    if (availableDay.isActive) {
      availableDay.timeIntervals.forEach(timeInterval => {
        scheduleEntries.push({
          id: timeInterval.id,
          weekday: availableDay.day.toUpperCase() as any,
          startTime: timeInterval.start?.toISOString() ?? "",
          endTime: timeInterval.end?.toISOString() ?? "",
        })
      })
    }
  })

  return scheduleEntries
}

export default EditScheduleDialog
