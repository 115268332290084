import { Box, Typography } from "@mui/material"

interface Props {
  value: string | number;
  textColor?: string;
}

export default function LengvaTableBadge({ value, textColor }: Props) {
  return (
    <Box
      style={{
        display: "flex",
        padding: "4px 8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "8px",
        backgroundColor: "#F5F8FD",
      }}
    >
      <Typography
        variant='body' fontWeight={600}
        sx={{
          color: textColor ? textColor : "text.secondary",
        }}
      >
        {value}
      </Typography>
    </Box>
  )
}
