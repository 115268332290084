import { Box, Drawer, Hidden, styled } from "@mui/material"
import { type PathItem } from "index"
import LengvaLogo from "_assets/lengva-logo.svg"
import { useTranslation } from "react-i18next"
import useAuthStore, { hasRole } from "_state/useStore"
import { Link, useNavigate } from "react-router-dom"

interface LengvaSidebarProps {
  menuItems: PathItem[];
  activeMenuItem: string;
  homePageLink: string;
  keyPrefix: string;
  openDrawer: boolean;
  setActiveMenuItem: (activeMenuItem?: string) => void;
  setOpenDrawer: (openDrawer: boolean) => void;
}

const LengvaSidebar = ({menuItems, activeMenuItem, homePageLink, keyPrefix, openDrawer, setActiveMenuItem, setOpenDrawer}: LengvaSidebarProps) => {
  const { t } = useTranslation('translation', { keyPrefix })
  const navigate = useNavigate()
  const account = useAuthStore(state => state)
  console.log("acount: ", account.account?.role?.name)

  return (
    <>
      <Hidden mdDown>
        <SidebarContainer>
          <Link to={homePageLink}>
            <img src={LengvaLogo as any} style={{ width: "106px" }}/>
          </Link>
          {menuItems.filter(menuItem => menuItem.access.includes(account.account?.role?.name ?? "")).map(element => (
            <SidebarItem
              key={element.menuLabel}
              isActive={activeMenuItem === element.menuLabel}
              onClick={() => {
                navigate(element.path)
                setActiveMenuItem(element.menuLabel)
                setOpenDrawer(false)
              }}
            >
              {activeMenuItem === element.menuLabel ? (
                element.activeMenuIcon
              ) : (
                element.menuIcon
              )}
              {element.menuLabel && <SidebarIconSpace>{t(element.menuLabel)}</SidebarIconSpace>}
            </SidebarItem>
          ))}
        </SidebarContainer>
      </Hidden>
      <Drawer
        open={openDrawer}
        PaperProps={{ style: { position: 'relative', top: 81, height: `calc(100% - 64px)`, zIndex: 1250, width: "260px" } }}
        ModalProps={{
          BackdropProps: {
            style: {
              top: '81px',
            },
          },
        }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >

        {menuItems.filter(menuItem => menuItem.access.includes(account.account?.role?.name ?? "")).map(element => (
          <SidebarItem
            key={element.menuLabel}
            isActive={activeMenuItem === element.menuLabel}
            onClick={() => {
              navigate(element.path)
              setActiveMenuItem(element.menuLabel)
              setOpenDrawer(false)
            }}
          >
            {activeMenuItem === element.menuLabel ? (
              element.activeMenuIcon
            ) : (
              element.menuIcon
            )}
            <SidebarIconSpace>{element.menuLabel}</SidebarIconSpace>
          </SidebarItem>
        ))}
      </Drawer>
    </>
  )
}

export const SidebarContainer = styled(Box)({
  padding: '1rem',
  minWidth: '250px',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  borderRight: "1px solid var(--border-border-tertiary, #EBEBEB)",
})

export interface SidebarItemProps {
  isActive: boolean;
}

export const SidebarItem = styled(Box, { shouldForwardProp: prop => prop !== 'isActive' })<SidebarItemProps>(({ isActive }) => ({
  padding: '1rem',
  borderRadius: '12px',
  background: isActive ? '#F5F5F5' : 'transparent',
  color: isActive ? '#3872E1' : 'text.primary',
  fontWeight: '700',
  display: 'flex',
  cursor: 'pointer',
  transition: 'background 0.4s',
  '&:hover': {
    background: '#F5F5F5',
  },
}))

export const SidebarIconSpace = styled(Box)({
  margin: '0 1rem',
})

export default LengvaSidebar
