import { Box, Checkbox } from '@mui/material'
import { type Account } from '_api/Account'
import { AccountAdminResponse, AccountResponse, AccountUpdateRequest, RoleResponse } from '_api/data-contracts'
import { type AccountPublicResponse, type UpdateAccountInfoRequest } from '_api/data-contracts'
import { SelectOption } from '_components/LengvaSelect'
import { type ProfileFormValues, getInitialProfileFormValues, getInitialInvoiceDetails } from '_pages/public/ProfilePage'
import ProfilePublicDataForm from '_pages/public/ProfilePublicDataForm'
import { createApi } from '_utils/ApiCreator'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function AccountCreateEditPage() {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation('translation', { keyPrefix: 'AccountPage' })
  const accountAPI = useRef(createApi("account") as Account)
  const [accountEdit, setAccountEdit] = useState<AccountAdminResponse>()
  const [roles, setRoles] = useState<SelectOption[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleProfileUpdateSubmit = async (values: ProfileFormValues) => {
    console.log("handleProfileUpdate is activated:", values)
    if (!id) {
      // save
      accountAPI.current.create({...values, roles: values.roles})
      return
    }

    const updateAccountRequest: AccountUpdateRequest = {
      id: Number(id),
      name: values.name,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumberPrefix + values.phoneNumber,
      roles: values.roles,
      invoiceDetails: values.invoiceDetails,
    }
    console.log("updateAccountRequest:", updateAccountRequest)
    await accountAPI.current.update(Number(id), {...updateAccountRequest})

    toast.success(t('ProfileUpdateSuccess'))
  }

  const getUserInfo = async () => {
    setIsLoading(true)
    if (id) {
      const response = await accountAPI.current.findById(Number(id))
      setAccountEdit(response)
    }

    const allRoles = await accountAPI.current.getAllRoles()
    setRoles(allRoles.map(role => ({ value: role.id!, label: role.name!})))
    // console.log("accountEdit:", response)
    setIsLoading(false)
  }

  useEffect(() => {
    getUserInfo()
  }, [])

  if (isLoading) {
    return <>loading... </>
  }

  return (
    <Formik<ProfileFormValues>
      initialValues={{...getInitialProfileFormValues(accountEdit),
        roles: accountEdit?.roles ? accountEdit?.roles.map(role => role.id!) : [],
        invoiceDetails: accountEdit?.invoiceDetails ?? getInitialInvoiceDetails()}}
      onSubmit={handleProfileUpdateSubmit}
    >
      {formik => (
        <>
          <>{console.log("formik:", formik.values)}</>
          <ProfilePublicDataForm roles={roles}/>
        </>
      )}
    </Formik>
  )
}
