/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AvailableTimesRequest, AvailableTimesResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AvailableTimes<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags available-times-user-controller
   * @name FindAvailableTimes
   * @request POST:/api/v1/user/available-times
   */
  findAvailableTimes = (data: AvailableTimesRequest, params: RequestParams = {}) =>
    this.request<AvailableTimesResponse[], any>({
      path: `/api/v1/user/available-times`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
