/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ServiceCreateRequest, ServiceResponse, ServiceUpdateRequest } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Services<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags service-specialist-controller
   * @name Update
   * @request PUT:/api/v1/specialist/services/{serviceId}
   */
  update = (serviceId: number, data: ServiceUpdateRequest, params: RequestParams = {}) =>
    this.request<ServiceResponse, any>({
      path: `/api/v1/specialist/services/${serviceId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags service-specialist-controller
   * @name Delete
   * @request DELETE:/api/v1/specialist/services/{serviceId}
   */
  delete = (serviceId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v1/specialist/services/${serviceId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags service-specialist-controller
   * @name FindAllMyServices
   * @request GET:/api/v1/specialist/services
   */
  findAllMyServices = (params: RequestParams = {}) =>
    this.request<ServiceResponse[], any>({
      path: `/api/v1/specialist/services`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags service-specialist-controller
   * @name Create
   * @request POST:/api/v1/specialist/services
   */
  create = (data: ServiceCreateRequest, params: RequestParams = {}) =>
    this.request<ServiceResponse, any>({
      path: `/api/v1/specialist/services`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags service-controller
   * @name FindAllBySpecialistId
   * @request GET:/api/v1/user/services/specialist/{specialistId}
   */
  findAllBySpecialistId = (specialistId: number, params: RequestParams = {}) =>
    this.request<ServiceResponse[], any>({
      path: `/api/v1/user/services/specialist/${specialistId}`,
      method: "GET",
      ...params,
    });
}
