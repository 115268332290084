import { Box, Dialog, DialogTitle, Typography, useMediaQuery } from "@mui/material"
import LengvaIcon from "_components/LengvaIcon"
import { useTranslation } from "react-i18next"
import CloseBlackIcon from "_assets/closeBlack.svg"
import { type ServiceResponse } from "_api/data-contracts"
import ServiceForm, { type ServiceFormValues } from "_pages/admin/services/ServiceForm"

interface Props {
  open: boolean;
  service?: ServiceResponse;
  handleClose: () => void;
  handleSubmit: (values: ServiceFormValues) => void;
  handleDelete?: () => void;
}

const ServiceDialog = ({open, service, handleSubmit, handleClose, handleDelete}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ServiceForm' })
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      PaperProps={{
        sx: {
          padding: "1.5rem",
          boxSizing: "border-box",
          border: 'none',
          borderRadius: isMobile ? "0px" : "24px",
          minWidth: isMobile ? "100%" : 'md',
          overflowX: "hidden",
          width: "100%",
          height: isMobile ? "100%" : "auto",
          minHeight: isMobile ? "100%" : "auto",
          margin: isMobile ? "0px" : "auto",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px",
          paddingBottom: "1rem",
          justifyContent: "space-between",
          gap: "8px",
          alignSelf: "stretch",
        }}
      >
        <Typography variant='h4'>{service?.serviceDescription.name ? t(`Services.${service.serviceDescription.name}`) : t("Create New Service")}</Typography>
        <LengvaIcon variant='v3' icon={CloseBlackIcon} onClick={handleClose}/>
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <ServiceForm
          service={service}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
        />
      </Box>

    </Dialog>
  )
}

export default ServiceDialog
