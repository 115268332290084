import { Box, Dialog, DialogTitle, Typography, useMediaQuery } from "@mui/material"
import { type LocationResponse } from "_api/data-contracts"
import CloseBlackIcon from "_assets/closeBlack.svg"
import LengvaIcon from "_components/LengvaIcon"
import LocationForm, { type LocationFormValues } from "_pages/admin/locations/LocationForm"
import { useTranslation } from "react-i18next"

interface Props {
  open: boolean;
  location?: LocationResponse;
  handleClose: () => void;
  handleSubmit: (values: LocationFormValues) => void;
  handleDelete?: () => void;
  existingLocations?: LocationResponse[];
}

const LocationDialog = ({open, location, handleClose, handleSubmit, handleDelete, existingLocations}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LocationForm' })
  const isMobile = useMediaQuery('(max-width: 600px)')
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      PaperProps={{
        sx: {
          padding: "1.5rem",
          boxSizing: "border-box",
          border: 'none',
          borderRadius: isMobile ? "0px" : "24px",
          minWidth: isMobile ? "100%" : 'md',
          overflowX: "hidden",
          width: "100%",
          height: isMobile ? "100%" : "auto",
          minHeight: isMobile ? "100%" : "auto",
          margin: isMobile ? "0px" : "auto",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px",
          paddingBottom: "1rem",
          justifyContent: "space-between",
          gap: "8px",
          alignSelf: "stretch",
        }}
      >
        <Typography variant='h4'>{location?.name ?? t("Create New Location")}</Typography>
        <LengvaIcon variant='v3' icon={CloseBlackIcon} onClick={handleClose}/>
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <LocationForm
          location={location}
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          existingLocations={existingLocations}
        />
      </Box>

    </Dialog>
  )
}

export default LocationDialog
